import { useDrag } from 'react-dnd';

export default function DragComp<T>(props: IProps<T>) {
  const { children, draggable, data, dndType, id } = props;
  const [, drag] = useDrag(
    () => ({
      type: dndType,
      item: {
        id,
        data,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [dndType],
  );

  if (draggable === false) {
    return <>{children}</>;
  }

  return (
    <div ref={drag} style={{ flex: 1 }}>
      {children}
    </div>
  );
}

interface IProps<T> {
  data: T;
  children: React.ReactNode;
  draggable?: boolean;
  dndType: string;
  id: string;
}
