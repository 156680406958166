import t from '@/locales';
import { ReactComponent as DeleteIcon } from '@/assets/icon/Close-light-line.svg';
import { Input, Table, Radio } from '@aloudata/aloudata-design';
import { useCallback, useMemo } from 'react';
import styles from './index.less';

interface IProps {
  value?: IValue[];
  onChange?: (value: IValue[]) => void;
}

interface IValue {
  value: string;
  isDefault?: boolean;
}

export default function SelectInputExtTable({ value = [], onChange }: IProps) {
  const data = useMemo(() => {
    return [...value, { value: '', isDefault: false }];
  }, [value]);

  const onChangeValue = useCallback(
    (index: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = [...value];
        if (newValue[index]) {
          newValue[index].value = e.target.value;
        } else {
          newValue[index] = { value: e.target.value };
        }
        onChange?.(newValue);
      };
    },
    [onChange, value],
  );

  const onDeleteItem = useCallback(
    (index: number) => {
      return () => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange?.(newValue);
      };
    },
    [onChange, value],
  );

  const onChangeDefaultValue = useCallback(
    (index: number) => {
      return () => {
        const newValue = [...value];
        newValue.forEach((item, i) => {
          if (i === index) {
            item.isDefault = !item.isDefault;
          } else {
            item.isDefault = false;
          }
        });
        onChange?.(newValue);
      };
    },
    [onChange, value],
  );

  const columns = [
    {
      title: t.property.define.select.title.candidate,
      dataIndex: 'value',
      render: (val: string, record: IValue, index: number) => {
        return (
          <Input
            className={styles.input}
            value={val}
            bordered={false}
            onChange={onChangeValue(index)}
            placeholder={t.property.define.select.placeholder}
          />
        );
      },
    },
    {
      title: t.property.define.select.title.default,
      dataIndex: 'isDefault',
      width: 68,
      render: (isDefault: boolean, record: IValue, index: number) => {
        if (data.length === index + 1) {
          return null;
        }
        return (
          <Radio checked={isDefault} onClick={onChangeDefaultValue(index)} />
        );
      },
    },
    {
      title: t.property.define.select.title.delete,
      width: 56,
      render(__: undefined, ___: IValue, index: number) {
        if (data.length === index + 1) {
          return null;
        }
        return (
          <DeleteIcon
            onClick={onDeleteItem(index)}
            className={styles.deleteIcon}
          />
        );
      },
    },
  ];
  return (
    <div className={styles.wrap}>
      <Table columns={columns} data={data} />
    </div>
  );
}
