import { IUser } from '@/typings';
import { init } from 'aloudata-tracking';
interface IReportErrorProps {
  sentryDSN: string;
  sentryTargetHosts: string[];
  ignoreHosts?: string[];
  customTargetHosts: string[];
  userInfo?: IUser;
  appName: string;
}

export function reportError({
  sentryDSN,
  ignoreHosts,
  sentryTargetHosts,
  customTargetHosts,
  userInfo,
  appName,
}: IReportErrorProps) {
  const host = window.location.hostname;

  if (ignoreHosts?.includes(host)) {
    return null;
  }

  // 如果是demo或者release环境就将错序信息发送到sentry
  if (sentryTargetHosts.includes(host)) {
    return init({
      sentryDSN,
      release: 'can_release',
      appName,
      user: {
        id: userInfo?.userId,
        username: userInfo?.nickname,
      },
    });
  } else {
    init({
      sentryDSN: '',
      release: 'can_release',
      appName,
      user: {
        id: userInfo?.userId,
        username: userInfo?.nickname,
      },
      customTargetHosts,
    });
  }
}
