import { getUniqId } from '@/common/utils';
import t from '@/locales';
import { EColumnDataType } from '@/typings';
import dayjs from 'dayjs';
import _ from 'lodash';
import styles from './index.less';
export enum ESpecialColumnValue {
  NULL = 'null',
  EMPTY = 'empty',
}
export const specialColumnValueMap = {
  [ESpecialColumnValue.NULL]: {
    label: 'null',
    value: getUniqId() + '_aloudata',
    className: styles.nullLabel,
  },
  // 这里是因为后端返回的“”也就是引号之间什么都没有的时候，前端不能正常勾选，因此就特殊处理了一下
  [ESpecialColumnValue.EMPTY]: {
    label: t.filter.filter.emptyStr,
    value: getUniqId() + '_aloudata',
    className: styles.nullLabel,
  },
};
/**
 * 尝试将自定义输入值合法化
 * @returns 处理过后的值，处理不了则返回 null
 */
export function parseCustomValue(
  value: string,
  dataType: EColumnDataType,
): string | null {
  const val = _.trim(value);
  if (dataType === EColumnDataType.DATE) {
    return isValidDateWithFormat(val, 'YYYY-MM-DD') ? val : null;
  }
  if (dataType === EColumnDataType.DATE_TIME) {
    return isValidDateWithFormat(val, 'YYYY-MM-DD HH:mm:ss') ||
      isValidDateWithFormat(val, 'YYYY-MM-DD HH:mm:ss.SSS')
      ? val
      : null;
  }
  if (
    dataType === EColumnDataType.INT ||
    dataType === EColumnDataType.DOUBLE ||
    dataType === EColumnDataType.DECIMAL
  ) {
    return isNaN(parseFloat(val)) ? null : val;
  }
  if (val === '') {
    return null;
  }
  return val;
}
/**
 * 校验日期类字符串是否符合某个格式
 */
export function isValidDateWithFormat(date: string, format: string): boolean {
  const d = dayjs(date, format);
  return d.format(format) === date && d.isValid();
}
