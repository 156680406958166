import { useGqlLazyQuery, useGqlMutation } from '@/common/utils/gqlRequest';
import { gql } from '@apollo/client';
import { TDefineProperty } from './type';
import {
  EAssetPropertyType,
  EEnableStatus,
  TAssetProperty,
} from '@/typings/property';
import { propertyBaseTpl } from './gql';

/**
 *  创建自定义属性
 */
export function useAddAssetProperty() {
  const schema = gql`
    mutation addAssetPropertyDef(
      $assetPropertyDefRequest: AssetPropertyDefRequest
    ) {
      addAssetPropertyDef(assetPropertyDefRequest: $assetPropertyDefRequest)
    }
  `;
  const { run: addAssetProperty, loading } = useGqlMutation<
    boolean,
    {
      assetPropertyDefRequest: TDefineProperty;
    }
  >(schema);

  return {
    addAssetProperty,
    loading,
  };
}

/**
 *  更新自定义属性
 */
export function useUpdateAssetProperty() {
  const schema = gql`
    mutation updateAssetPropertyDef(
      $assetPropertyDefRequest: AssetPropertyDefRequest
    ) {
      updateAssetPropertyDef(assetPropertyDefRequest: $assetPropertyDefRequest)
    }
  `;
  const { run: updateAssetProperty, loading } = useGqlMutation<
    boolean,
    {
      assetPropertyDefRequest: TDefineProperty;
    }
  >(schema);

  return {
    updateAssetProperty,
    loading,
  };
}

/**
 *  查询单个自定义属性
 */
export function useQueryAssetProperty() {
  const schema = gql`
    query queryAssetPropertyDef($name: String, $type: String) {
      queryAssetPropertyDef(name: $name, type: $type) {
        ${propertyBaseTpl}
      }
    }
  `;
  const { run: queryAssetProperty, loading } = useGqlLazyQuery<
    {
      queryAssetPropertyDef: TAssetProperty;
    },
    {
      name: string;
      type: EAssetPropertyType;
    }
  >(schema);

  return {
    queryAssetProperty,
    loading,
  };
}

/**
 *  查询属性列表
 */

export const QUERY_ALL_ASSET_PROPERTY_DEF_LIST = gql`
query queryAllAssetPropertyDefList($type: String) {
  queryAllAssetPropertyDefList(type: $type) {
    ${propertyBaseTpl}
    isCreatingDisplayed
    }
  }
`;
export function useQueryAssetPropertyList() {
  const schema = QUERY_ALL_ASSET_PROPERTY_DEF_LIST;
  const { run: queryAssetPropertyList, loading } = useGqlLazyQuery<
    {
      queryAllAssetPropertyDefList: TAssetProperty[];
    },
    {
      type: EAssetPropertyType;
    }
  >(schema);

  return {
    queryAssetPropertyList,
    loading,
  };
}

/**
 *  修改属性定义的顺序
 */
export function useMoveAssetProperty() {
  const schema = gql`
    mutation moveAssetPropertyDef(
      $name: String
      $frontDef: String
      $type: String
    ) {
      moveAssetPropertyDef(name: $name, frontDef: $frontDef, type: $type)
    }
  `;
  const { run: moveAssetProperty, loading } = useGqlMutation<
    boolean,
    {
      name: string;
      frontDef: string | null;
      type: EAssetPropertyType;
    }
  >(schema);

  return {
    moveAssetProperty,
    loading,
  };
}

/**
 *  修改属性定义的Status
 */
export function useUpdateAssetPropertyStatus() {
  const schema = gql`
    mutation updateAssetPropertyDefStatus(
      $type: String
      $name: String
      $status: String
    ) {
      updateAssetPropertyDefStatus(type: $type, name: $name, status: $status)
    }
  `;
  const { run: updatePropertyStatus, loading } = useGqlMutation<
    boolean,
    {
      type: EAssetPropertyType;
      name: string;
      status: EEnableStatus;
    }
  >(schema);

  return {
    updatePropertyStatus,
    loading,
  };
}

/**
 *  删除属性定义
 */
export function useDeleteAssetProperty() {
  const schema = gql`
    mutation deleteAssetPropertyDef($type: String, $name: String) {
      deleteAssetPropertyDef(type: $type, name: $name)
    }
  `;
  const { run: deleteAssetProperty, loading } = useGqlMutation<
    boolean,
    {
      type: EAssetPropertyType;
      name: string;
    }
  >(schema);
  return {
    deleteAssetProperty,
    loading,
  };
}
