export const fileDetail = `#graphql
fileId
displayName
name
description
replace
updateTime
createTime
columns {
  name
  displayName
  originDataType
  description
}
datasourceItems {
  id
  name
  queryEngineType
}
fileOpt {
  delimiter
  quote
  lineSeparator
  encoding
  containsHeader
  columns {
    name
    originDataType
    description
    format
  }
  data
  name
}
owner {
  userId
  nickname
  account
}
authority {
  canEdit
  canDelete
  canAuth
  canUsage
  canMove
  authRoleList
  canTransfer
}
`;
