import { getUniqId } from '@/common/utils';
import {
  EDependencyType,
  EQueryStatus,
  EQueryType,
  IAbortQueryInfo,
  IQueryInput,
  IQueryResult,
  IQueryResultData,
  IQuerySourceItem,
  IResultTableData,
  TResultDataItem,
} from './types';
import { post } from '@/common/utils/request';
import {
  EColumnType,
  ESourceType,
  IDataset,
  IDatasetRelation,
  IDatasetSourceInfo,
  IDefColumn,
} from '@/typings/dataset';
import _ from 'lodash';
import {
  EDateGranularityType,
  EOriginDataType,
  IErrorResponse,
} from '@/typings';
import { useRequest } from 'ahooks';
import { useCallback } from 'react';
import { EFormulaType, IColumnFormula, TFormula } from '@/typings/formula';
import { originDataType2DataTypeMap } from '@/components/Formula/constant';
import { visitFormulaAst } from '@/components/Formula/functions/iterate';
import { wrapColumnRef } from '@/components/Formula/helper';

/**
 * 查询物理表数据
 */
export function useQueryTable(options?: IQueryColumnsOptions) {
  const { run, ...others } = useQueryColumns(options);

  const queryTableData = useCallback<
    (tableSourceInfo: IDatasetSourceInfo, limit?: number) => IAbortQueryInfo
  >(
    (tableSourceInfo, limit) => {
      const columns = getColumnsByTableSource(tableSourceInfo);

      const sourceType = tableSourceInfo.type;
      return run({
        queryType: getQueryTypeFromSourceType(sourceType),
        columns,
        relations: [],
        limit,
        source: {
          name: tableSourceInfo.name,
          content: tableSourceInfo.content,
          type: getDepTypeFromSourceType(tableSourceInfo.type),
        },
      });
    },
    [run],
  );

  return {
    ...others,
    run: queryTableData,
  };
}

/**
 * 查询已定义的数据集数据
 */
export function useQueryDataset() {
  const { run, ...others } = useQueryColumns();

  const queryDatasetData = useCallback<(dataset: IDataset) => IAbortQueryInfo>(
    (dataset) => {
      const columns = getColumnsByDataset(dataset);

      return run({
        queryType: EQueryType.TABLE,
        columns,
        relations: [],
        source: {
          name: dataset.name,
          content: dataset.name,
          type: EDependencyType.DATASET,
        },
      });
    },
    [run],
  );

  return {
    ...others,
    run: queryDatasetData,
  };
}

/**
 * 查询定义中的数据集数据
 */
export function useQueryDefDataset() {
  const { run, ...others } = useQueryColumns();

  const queryDatasetData = useCallback<
    (params: IQueryDefDatasetParams) => IAbortQueryInfo
  >(
    (params) => {
      const { tableSourceInfo, columns: defColumns, relations } = params;

      return run({
        queryType:
          tableSourceInfo.type === ESourceType.SQL
            ? EQueryType.SQL
            : EQueryType.RELATIONS,
        columns: defColumns,
        relations,
        source: {
          name: tableSourceInfo.name,
          content: tableSourceInfo.content,
          type: getDepTypeFromSourceType(tableSourceInfo.type),
        },
      });
    },
    [run],
  );

  return {
    ...others,
    run: queryDatasetData,
  };
}

/**
 * 查询指定数据集中指定字段的值列表
 */
export function useQueryDatasetFilterValue() {
  const { run, ...others } = useQueryColumns();

  const DEFAULT_LIMIT = 200;

  const queryDatasetData = useCallback<
    (
      dataset: IDataset,
      columnName: string,
      granularity?: EDateGranularityType,
    ) => IAbortQueryInfo
  >(
    (dataset, columnName, granularity, limit = DEFAULT_LIMIT) => {
      const columns = getColumnsForFilterValue(
        dataset,
        columnName,
        granularity,
      );

      return run({
        queryType: EQueryType.TABLE,
        columns,
        relations: [],
        source: {
          name: dataset.name,
          content: dataset.name,
          type: EDependencyType.DATASET,
        },
        limit,
      });
    },
    [run],
  );

  return {
    ...others,
    run: queryDatasetData,
  };
}

/**
 * 根据模型关系查询数据
 */

export function useQueryDatasetByModel() {
  const { run, ...others } = useQueryColumns();

  const queryDatasetData = useCallback<
    ({
      columns,
      datasetName,
    }: {
      columns: IDefColumn[];
      datasetName: string;
    }) => IAbortQueryInfo
  >(
    (params) => {
      const { columns: defColumns, datasetName } = params;

      const columns = _.map(defColumns, (col) => {
        if (col.ast.type === EFormulaType.COLUMN) {
          return {
            ...col,
            ast: {
              type: EFormulaType.COLUMN,
              path: [datasetName, col.name],
            } as TFormula,
          };
        } else {
          return col;
        }
      });

      return run({
        queryType: EQueryType.MODEL,
        columns,
        relations: [],
        source: {
          name: datasetName,
          content: '',
          type: EDependencyType.MODEL,
        },
      });
    },
    [run],
  );

  return {
    ...others,
    run: queryDatasetData,
  };
}

interface IQueryDefDatasetParams {
  tableSourceInfo: IDatasetSourceInfo;
  columns: IDefColumn[];
  relations: IDatasetRelation[];
}

interface IQueryColumnsOptions {
  onSuccess?: (data: IQueryResultData) => void;
}

function useQueryColumns(options?: IQueryColumnsOptions): {
  data: IQueryResultData | null; // 查询完成后返回的数据，正在查询中时则返回 null
  loading: boolean; // 是否正在查询中
  run: (params: TQueryParams) => IAbortQueryInfo; // 手动触发执行查询
} {
  const { onSuccess } = options || {};
  const {
    data = null,
    loading,
    run,
  } = useRequest<IQueryResultData, [IPostQueryParams]>(postQuery, {
    manual: true,
    onSuccess,
  });

  const runQuery = useCallback<(params: TQueryParams) => IAbortQueryInfo>(
    (params) => {
      const { queryType, columns, relations, source, limit } = params;
      const queryId = getUniqId();
      const abortController = new AbortController();
      const cancelSignal = abortController.signal;

      run({
        queryId,
        queryType,
        columns,
        relations,
        source,
        limit,
        signal: cancelSignal,
      });

      return {
        abortController,
        queryId,
      };
    },
    [run],
  );

  return {
    data,
    loading,
    run: runQuery,
  };
}

type TQueryParams = Omit<IPostQueryParams, 'queryId' | 'signal'>;

async function postQuery(params: IPostQueryParams) {
  const {
    queryId,
    queryType,
    columns,
    relations,
    source,
    limit: reqLimit,
    signal,
  } = params;

  const DEFAULT_LIMIT = 1000;

  const relationsWithoutSrcDatasetName =
    removeSrcDatasetNameInRelations(relations);

  const queryInput: IQueryInput = {
    queryId,
    meta: {
      queryType,
    },
    query: {
      columns: _.reduce<IDefColumn, { [columnName: string]: IDefColumn }>(
        columns,
        (obj, col) => {
          obj[col.name] = col;
          return obj;
        },
        {},
      ),
      base: {
        id: 'base',
        columns: _.map(columns, 'name'),
      },
    },
    contents: {
      relations: relationsWithoutSrcDatasetName,
      source,
    },
    pager: {
      offset: 0,
      limit: (reqLimit || DEFAULT_LIMIT) + 1,
    },
  };

  try {
    const res = await post<IQueryResult>('/query', queryInput, {
      silent: true,
      signal,
    });
    const limit = queryInput.pager.limit;

    // 根据返回的数据计算本次返回结果里的总行数
    const rowCount = getResRowsCount(res.table);
    const hasMore = rowCount > limit;

    return {
      status: EQueryStatus.SUCCESS,
      warning: res.warning || '',
      data: res.table,
      limit,
      rowCount,
      hasMore,
    };
  } catch (e) {
    console.error(e);
    return {
      status: EQueryStatus.FAILED,
      warning: '',
      data: {},
      limit: queryInput.pager.limit,
      rowCount: 0,
      hasMore: true,
      errorMsg: (e as IErrorResponse).errorMsg,
      detailErrorMsg: (e as IErrorResponse).detailErrorMsg,
    };
  }
}

/**
 * 在发送 queryColumns 请求时，去掉关联中的 srcDatasetName
 * 因为查询需要带关联的情况是在定义数据集的情况，此时数据集的 name 可以认为没有确定，如果加上了 name，
 * 意味着指向了已发布的数据集的字段，而不是定义中的数据集的字段，是不正确的
 * @param relations
 * @returns
 */
function removeSrcDatasetNameInRelations(relations: IDatasetRelation[]) {
  return _.map(relations, (relation) => {
    const { relationConditions, srcDataset } = relation;
    return {
      ...relation,
      relationConditions: _.map(relationConditions, (condition) => {
        return {
          ...condition,
          srcAst: removeDatasetNameInSrcAst(condition.srcAst, srcDataset.name),
        };
      }),
      filters: _.map(relation.filters, (filter) => {
        return removeDatasetNameInSrcAst(filter);
      }),
    };
  });
}

export function removeDatasetNameInSrcAst(ast: TFormula, datasetName?: string) {
  return visitFormulaAst(ast, {
    [EFormulaType.COLUMN]: (node) => {
      const { path } = node as IColumnFormula;
      const PATH_LENGTH_WITH_DATASET_NAME = 2;
      const DATASET_NAME_INDEX = 0;
      if (
        path.length === PATH_LENGTH_WITH_DATASET_NAME &&
        path[DATASET_NAME_INDEX] === datasetName
      ) {
        const [, columnName] = path;
        return wrapColumnRef(columnName);
      }
      return node;
    },
  });
}

interface IPostQueryParams {
  queryType: EQueryType;
  queryId: string;
  columns: IDefColumn[];
  relations: IDatasetRelation[];
  source: IQuerySourceItem;
  limit?: number; // 行数
  signal: AbortSignal; // 取消请求的标记
}

/**
 * 计算返回的数据的行数，考虑存在跨行的情况
 * @param tableData
 */
export function getResRowsCount(tableData: IResultTableData) {
  const col = _.values(tableData)[0];
  if (!col) {
    return 0;
  }
  return _.reduce<TResultDataItem, number>(
    col,
    (count, cellData) => {
      const ROW_NUM_INDEX = 1; // 在 cellData 数组中，表示跨的行数的索引
      count += cellData[ROW_NUM_INDEX];
      return count;
    },
    0,
  );
}

/**
 * 根据物理表的 meta 信息，获取 query 接口需要的字段定义结构
 * @param tableSourceInfo 物理表 meta 信息
 */
function getColumnsByTableSource(
  tableSourceInfo: IDatasetSourceInfo,
): IDefColumn[] {
  const { columns, name: tableName } = tableSourceInfo;

  return _.map(columns, (col) => {
    return {
      displayName: col.displayName,
      name: col.name,
      isHidden: false,
      ast: {
        type: EFormulaType.COLUMN,
        path: [tableName, col.name],
      },
      originDataType: col.originDataType,
      dataType: originDataType2DataTypeMap[col.originDataType],
      description: col.description,
      type: EColumnType.DIMENSION, // 这里因为是查物理表，没有维度度量的概念，所以都先设为维度
      isPartition: col.isPartition,
      isFirstPartition: col.isFirstPartition,
    };
  });
}

/**
 * 根据数据集的 meta 信息，获取 query 接口需要的字段定义结构
 * @param tableSourceInfo 物理表 meta 信息
 */
function getColumnsByDataset(dataset: IDataset) {
  const { columns, name: datasetName } = dataset;

  return _.map(columns, (col) => {
    return {
      displayName: col.name,
      name: col.name,
      isHidden: false,
      ast: {
        type: EFormulaType.COLUMN,
        path: [datasetName, col.name],
      } as TFormula,
      originDataType: col.originDataType,
      dataType: originDataType2DataTypeMap[col.originDataType],
      description: col.description,
      type: col.type,
      isPartition: col.isPartition,
      isFirstPartition: col.isFirstPartition,
    };
  });
}

function getColumnsForFilterValue(
  dataset: IDataset,
  columnName: string,
  granularity?: EDateGranularityType,
): IDefColumn[] {
  const col = _.find(dataset.columns, { name: columnName });
  if (!col) {
    throw new Error(
      `column ${columnName} not found in dataset ${dataset.name}`,
    );
  }
  const refAst = {
    type: EFormulaType.COLUMN,
    path: [dataset.name, col.name],
  };

  const arg = granularity
    ? {
        type: EFormulaType.FUNCTION,
        op: 'DATETRUNC',
        args: [
          refAst,
          {
            type: EFormulaType.CONSTANT,
            val: {
              type: EOriginDataType.VARCHAR,
              val: granularity,
            },
          },
        ],
      }
    : refAst;

  return [
    {
      displayName: col.description || col.name,
      name: col.name,
      ast: {
        type: EFormulaType.FUNCTION,
        op: 'DISTINCT',
        args: [arg],
      } as TFormula,
      originDataType: col.originDataType,
      dataType: originDataType2DataTypeMap[col.originDataType],
      description: col.description,
      type: col.type,
      isPartition: col.isPartition,
      isFirstPartition: col.isFirstPartition,
    },
  ];
}

function getDepTypeFromSourceType(sourceType: ESourceType) {
  switch (sourceType) {
    case ESourceType.TABLE:
      return EDependencyType.TABLE;
    case ESourceType.FILE:
      return EDependencyType.FILE;
    case ESourceType.SQL:
      return EDependencyType.SQL;
    default:
      throw new Error(`sourceType error：${sourceType}`);
  }
}
function getQueryTypeFromSourceType(sourceType: ESourceType) {
  switch (sourceType) {
    case ESourceType.TABLE:
      return EQueryType.TABLE;
    case ESourceType.SQL:
      return EQueryType.SQL;
    default:
      return EQueryType.FILE;
  }
}
/**
 * 取消正在进行的查询
 */
export function cancel(abortQueryInfo: IAbortQueryInfo) {
  const { queryId, abortController } = abortQueryInfo;
  abortController.abort();
  post('/cancel', {
    queryId,
  });
}
