import sqlContent from './mockSql';
import { originDataType2DataTypeMap } from '../../src/components/Formula/constant';
import { rest } from 'msw';
import { IDefColumn } from '../../src/typings/dataset';
import {
  IQueryInput,
  IQueryResult,
  IResultTableData,
} from '../../src/services/queryColumns/types';
import { IPostQueryReqParams } from '../../src/services/queryMetrics/types';
import _ from 'lodash';
import { EColumnDataType } from '../../src/typings';
import { getRestfulResponse } from '../utils';

export const query = [
  rest.post('/query', async (req, res, ctx) => {
    const { queryId, query } = (await req.json()) as IQueryInput;
    const { columns } = query;

    const data: IQueryResult = {
      queryId,
      sql: 'select * from table_1',
      warning: '',
      table: getResDataByColumns(columns),
    };

    return res(ctx.json(getRestfulResponse(data)), ctx.delay(1000));
  }),

  rest.post('/queryMetrics', async (req, res, ctx) => {
    const { metrics, dimensions } = (await req.json()) as IPostQueryReqParams;
    // 由于请求入参不会传 dataType 相关信息，因此假设维度都是日期，指标都是浮点数，构建 mock 数据
    const columns: Array<{
      id: string;
      name: string;
      datasetName?: string;
      dataType: EColumnDataType;
      type: string;
    }> = [];
    _.forOwn(metrics, (metric) => {
      columns.push({
        id: metric.id,
        name: metric.code,
        dataType: EColumnDataType.DOUBLE,
        type: 'metric',
      });
    });
    _.forOwn(dimensions, (dimension) => {
      columns.push({
        id: dimension.id,
        name: dimension.column.column,
        dataType: EColumnDataType.DATE,
        type: 'column',
        datasetName: dimension.column.dataset || '',
      });
    });

    const tableData: IResultTableData = {};
    _.forEach(columns, (col) => {
      tableData[col.id] = _.range(1000).map((i) => {
        const dataType = col.dataType;
        return [getCellData(dataType), 1, 0];
      });
    });
    const data: Omit<IQueryResult, 'queryId'> = {
      sql: sqlContent,
      warning: '',
      table: tableData,
    };

    return res(ctx.json(getRestfulResponse(data)), ctx.delay(2000));
  }),

  rest.post('/cancel', (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(true)));
  }),
];

function getResDataByColumns(
  defColumnMap: { [columnId: string]: IDefColumn },
  limit: number = 1000,
) {
  const res: IResultTableData = {};

  _.forIn(defColumnMap, (defCol, colName) => {
    res[colName] = _.range(limit).map((i) => {
      const dataType = originDataType2DataTypeMap[defCol.originDataType];
      return [getCellData(dataType), 1, 0];
    });
  });

  return res;
}

function getCellData(dataType: EColumnDataType) {
  const random = _.random(100);
  if (random > 90) {
    return null;
  }
  switch (dataType) {
    case EColumnDataType.BOOLEAN:
      return random > 45 ? true : false;
    case EColumnDataType.DATE:
    case EColumnDataType.DATE_TIME:
      return _.sample(['2022-10-06', '2022-10-07', '2022-10-08', '', null]) as
        | string
        | null;
    case EColumnDataType.INT:
    case EColumnDataType.DECIMAL:
    case EColumnDataType.DOUBLE:
      return _.sample([_.random(1000000), '', null]) as number | string | null;
    case EColumnDataType.TEXT:
      return _.sample(['2022-10-06', '', null]) as string | null;
    default:
      return null;
  }
}
