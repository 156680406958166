import { history } from 'umi';

const ignoreUrls = ['dataset/create'];

const urlStack: string[] = [];

export function initHistoryListen() {
  history.listen((update) => {
    const preUrl = urlStack[urlStack.length - 1] || '';
    const nextPath = update.location.pathname;
    const { action } = update;
    const nextUrl = `${nextPath}${update.location.search}`;
    if (nextUrl === preUrl) {
      return;
    }
    if (action === 'REPLACE') {
      if (urlStack.length > 0) {
        urlStack[urlStack.length - 1] = nextUrl;
        return;
      }
    }
    if (
      ignoreUrls.some((ignoreUrl) => {
        return nextUrl.includes(ignoreUrl);
      })
    ) {
      return;
    }
    urlStack.push(nextUrl);
  });
}

export default function goBack(defaultUrl?: string) {
  // urlStack[urlStack.length - 1] 是现在的路由，-2 是上一个路由
  const PREV_INDEX = 2;

  if (urlStack.length < PREV_INDEX) {
    history.push(defaultUrl || '/');
    return;
  }
  const previousUrl = urlStack[urlStack.length - PREV_INDEX];
  const currentHost = window.location.host;
  const previousHost = new URL(previousUrl, window.location.origin).host;

  if (currentHost === previousHost) {
    const prev = urlStack[urlStack.length - PREV_INDEX];
    urlStack.pop();
    history.push(prev);
  } else {
    history.push(defaultUrl || '/');
  }
}
