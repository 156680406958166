import { EditorView } from '@codemirror/view';
export const normalTheme = EditorView.theme({
  '&.cm-editor': {
    width: '100%',
    height: '100%',
  },
  '.cm-gutters': {
    display: 'none',
  },
  '&.cm-editor .cm-scroller': {
    outline: '0',
  },
  '&.cm-editor  .cm-line': {
    fontFamily: `'Roboto Mono', 'PingFang SC', sans-serif`,
    wordBreak: 'break-word',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    overflow: 'auto',
    height: '100%',
  },

  '&.cm-editor.cm-focused': {
    outline: 0,
  },
  '&.cm-editor.cm-focused .cm-scroller': {
    outline: '0',
  },
  '&.cm-editor .cm-line .cm-placeholder': {
    color: '#858585',
  },

  // 选中样式
  '&.cm-editor .cm-scroller ::selection': {
    backgroundColor: '#D6E3F4 !important',
  },

  '& .cm-selectionBackground': {
    backgroundColor: '#F4F4F4',
  },
  '&.cm-focused .cm-selectionBackground, ::selection': {
    backgroundColor: '#D6E3F4',
  },
  '.errorLine': {
    backgroundImage: 'none',
    textDecoration: 'red wavy underline',
  },
  '& .cm-tooltip': {
    borderRadius: '2px',
    border: '1px solid #dbdbdb',
    backgroundColor: 'fff',
    boxShadow:
      '0px 5px 13px -5px rgba(0, 0, 0, 0.05), 0px 2px 4px -5px rgba(0, 0, 0, 0.02)',
  },
});

export const readonlyTheme = EditorView.theme({
  '&.cm-editor .cm-content .cm-line': {
    backgroundColor: '#F5F6F7',
  },
});
