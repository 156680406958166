import t from '@/locales';
import { EColumnDataType } from '@/typings';
import { EOriginDataType, IFunctionOperator } from '@/typings/formula';
import _ from 'lodash';
import { ECheckResultType, TCheckResult } from '../function';
enum ECastType {
  BOOLEAN = 'bool',
  INT = 'int',
  DOUBLE = 'double',
  DATE = 'date',
  DATE_TIME = 'datetime',
  DECIMAL = 'decimal',
  JSON = 'json',
  TEXT = 'text',
}
export const columnDataType2CastType: Record<EColumnDataType, ECastType> = {
  [EColumnDataType.BOOLEAN]: ECastType.BOOLEAN,
  [EColumnDataType.INT]: ECastType.INT,
  [EColumnDataType.DOUBLE]: ECastType.DOUBLE,
  [EColumnDataType.DATE]: ECastType.DATE,
  [EColumnDataType.DATE_TIME]: ECastType.DATE_TIME,
  [EColumnDataType.DECIMAL]: ECastType.DECIMAL,
  [EColumnDataType.JSON]: ECastType.JSON,
  [EColumnDataType.TEXT]: ECastType.TEXT,
};
const checkCast = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { op, args } = params;
  const enumArg = (
    _.get(params.ast.args[1], 'val.val') || ''
  )?.toLowerCase() as ECastType;
  const twoArgsEnum = [
    ECastType.BOOLEAN,
    ECastType.INT,
    ECastType.DOUBLE,
    ECastType.DATE,
    ECastType.DATE_TIME,
    ECastType.JSON,
    ECastType.TEXT,
  ];
  const CAST_ARGS_LENGTH = 2;
  const DECIMAL_ARGS_LENGTH = 3;
  if (
    (twoArgsEnum.includes(enumArg) && args.length !== CAST_ARGS_LENGTH) ||
    (enumArg === ECastType.DECIMAL &&
      args.length !== DECIMAL_ARGS_LENGTH &&
      args.length !== CAST_ARGS_LENGTH)
  ) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }
  if (enumArg === ECastType.BOOLEAN) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.BOOLEAN,
    };
  }
  if (enumArg === ECastType.INT) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.BIGINT,
    };
  }
  if (enumArg === ECastType.DOUBLE) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.DOUBLE,
    };
  }
  if (enumArg === ECastType.DECIMAL) {
    const PRECISION_INDEX = 2;
    const MAX_PRECISION = 12;
    const precision = _.get(params.ast.args[PRECISION_INDEX], 'val.val') || 0;
    const precisionType = _.get(params.ast.args[PRECISION_INDEX], 'val.type');
    if (precisionType) {
      if (
        precisionType !== EOriginDataType.BIGINT ||
        precision === 0 ||
        precision > MAX_PRECISION
      ) {
        return {
          type: ECheckResultType.ERROR,
          errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
        };
      }
    }
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.DECIMAL,
    };
  }
  if (enumArg === ECastType.DATE) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.DATE,
    };
  }
  if (enumArg === ECastType.DATE_TIME) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.TIMESTAMP,
    };
  }
  if (enumArg === ECastType.TEXT) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.VARCHAR,
    };
  }
  if (enumArg === ECastType.JSON) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.STRUCT,
    };
  }
  return {
    type: ECheckResultType.ERROR,
    errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
  };
};
export default checkCast;
