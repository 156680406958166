import { ScrollArea } from '@aloudata/aloudata-design';
import styles from './index.less';
import classNames from 'classnames';
import { CSSProperties } from 'react';
export default function LayoutInLargeModal({
  left,
  right,
  middle,
}: ILargeModalLayout) {
  const style = middle
    ? ({
        '--middle-width-in-large-modal': middle.width + 'px',
      } as CSSProperties)
    : {};
  return (
    <div
      className={classNames(styles.layout, {
        [styles.hasMiddle]: middle,
      })}
      style={style}
    >
      <ScrollArea innerClassName={styles.scrollInner} className={styles.left}>
        {left}
      </ScrollArea>
      {middle && (
        <ScrollArea
          innerClassName={styles.scrollInner}
          className={styles.middle}
        >
          {middle.children}
        </ScrollArea>
      )}
      <ScrollArea innerClassName={styles.scrollInner} className={styles.right}>
        {right}
      </ScrollArea>
    </div>
  );
}
interface ILargeModalLayout {
  left: React.ReactNode;
  right: React.ReactNode;
  middle?: {
    width: number;
    children: React.ReactNode;
  };
}
