import t from '@/locales';
import {
  EFormulaType,
  EOriginDataType,
  IFunctionOperator,
} from '@/typings/formula';
import _ from 'lodash';
import functionConfig from '../../functionConfig/functionConfig';
import specialTreatment from './specialTreatment';
import { getEngineType } from '../tool';
import { IFilterFunctionsList } from '../../type';
export enum ECheckResultType {
  ORIGIN_DATA_TYPE = 'ORIGIN_DATA_TYPE',
  ERROR = 'ERROR',
}
export type TCheckResult =
  | {
      type: ECheckResultType.ORIGIN_DATA_TYPE;
      originDataType: EOriginDataType;
    }
  | {
      type: ECheckResultType.ERROR;
      errorMsg: string;
    };
const specialFnName = [
  'IF',
  'CAST',
  'IN',
  'SUBTOTAL',
  'POWERFIX',
  'POWERADD',
  'POWERSUB',
  'LEAD',
  'LAG',
  'NTH',
  'DATE',
  'DATETIME',
  'JSON',
  'DECIMAL',
  'TEXT',
  'INT',
  'DOUBLE',
  'BOOL',
];
const checkFunction = (option: {
  type: EFormulaType.FUNCTION;
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
  filterFunctionList: IFilterFunctionsList; //是否需要对函数进行限制
}): TCheckResult => {
  const { op, args, type, ast, filterFunctionList } = option;
  const queryEngineType = getEngineType();
  // 类型不是函数
  if (type !== EFormulaType.FUNCTION) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: t.formula.parse.error.notFunction,
    };
  }
  const functionInfo =
    functionConfig[queryEngineType].functions[op.toUpperCase()];
  if (!functionInfo) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionNotExist}`,
    };
  }
  // 函数不存在
  // 暂不支持聚合函数、窗口函数和分析函数
  if (!filterFunctionList(functionInfo)) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionNotExist}`,
    };
  }
  if (specialFnName.includes(op.toUpperCase())) {
    return specialTreatment({ op, args, ast, queryEngineType });
  }
  const { signatures } = functionInfo;
  // 函数不需要参数
  if (signatures[0].args.length === 0) {
    // 实际传了参数
    if (args.length) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionNotNeedArgs}`,
      };
    }
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: signatures[0].returnType,
    };
  }
  // 函数参数长度不一致
  const paramsLength = signatures.map((item) => item.args.length);
  if (!signatures[0].repeated && !paramsLength.includes(args.length)) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }
  // TODO: 优化错误提示
  // 函数需要参数
  const originDataType = signatures.find((item) => {
    const { args: functionArgs, repeated, limit } = item;
    const argsLength = functionArgs.length;
    return functionArgs.every((arg, index) => {
      // 非枚举值只需判断参数类型
      if (
        limit &&
        limit > functionArgs.length - 1 - index &&
        repeated &&
        !ast.args[index]
      ) {
        return true;
      }
      const actualArgType = repeated ? args[index % argsLength] : args[index];
      if (isEOriginDataType(arg)) {
        if (arg === 'ANY') {
          return true;
        }
        if (repeated) {
          return (
            args.every((argsItem, argsIndex) => {
              return argsItem === args[argsIndex % argsLength];
            }) && arg === actualArgType
          );
        }
        // 参数不是枚举值
        return arg === actualArgType;
      } else {
        // 参数是枚举值，需要判断实际值
        const actualArgVal = repeated
          ? ast.args[index % argsLength]
          : ast.args[index];
        return arg
          .split(',')
          .map((enumItem) => _.trim(enumItem))
          .map((enumItem) => enumItem.toUpperCase())
          .includes((_.get(actualArgVal, 'val.val') || '')?.toUpperCase());
      }
    });
  })?.returnType;
  if (originDataType) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType,
    };
  } else {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }
};
const isEOriginDataType = (value: string): value is EOriginDataType | 'ANY' => {
  return value.split(',').length === 1;
};
export default checkFunction;
