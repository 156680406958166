import { EColumnDataType, EDateGranularityType } from '@/typings';
import {
  EBinaryOperator,
  EExtTypeEnum,
  EFormulaType,
  EOriginDataType,
  EUnaryOperator,
  IColumnFormula,
  IConstantFormula,
  IFunctionOperator,
  IOperatorFormula,
  IParenthesisFormula,
  IUnaryOperator,
  IUserConstantFormula,
  TCorrectFormula,
  TFormula,
} from '@/typings/formula';
import _ from 'lodash';
import { generateParser } from '../functions/parser/astParser';
import {
  ECastFn,
  ECensusFn,
  EDatePartOpName,
  IBooleanArg,
  IDoubleArg,
  IIntArg,
  IStringArg,
  TCellData,
  TConstantVal,
} from '../type';
import { recoverDataTrunc } from './tool';
import iterate from '../functions/iterate';

export enum EArgsType {
  STRING = 'STRING',
  INT = 'INT',
  DOUBLE = 'DOUBLE',
  BOOLEAN = 'BOOLEAN',
  FORMULA = 'FORMULA',
}

export type TArgs = IStringArg | IIntArg | IDoubleArg | IBooleanArg | TFormula;

export const getStringArg = (arg: string): IStringArg => {
  return {
    type: EArgsType.STRING,
    val: arg,
  };
};
export const getNumberArg = (arg: string): IIntArg | IDoubleArg => {
  if (arg.includes('.')) {
    return {
      type: EArgsType.DOUBLE,
      val: arg,
    };
  } else {
    return {
      type: EArgsType.INT,
      val: arg,
    };
  }
};

export const getBooleanArg = (arg: string): IBooleanArg => {
  return {
    type: EArgsType.BOOLEAN,
    val: arg,
  };
};

export const isArgIsFormula = (arg: TArgs): arg is TFormula => {
  return ![
    EArgsType.STRING,
    EArgsType.INT,
    EArgsType.DOUBLE,
    EArgsType.BOOLEAN,
  ].includes(arg.type as EArgsType);
};
// 公式DSL转AST
export const parseFormula = (formula: string): TFormula => {
  const parser = generateParser();
  parser.feed(formula);
  return parser.results[0];
};

// 调用函数转AST
export const wrapCallOp = (
  callOpName: string,
  args: TArgs[],
): IFunctionOperator => {
  return {
    type: EFormulaType.FUNCTION,
    op: callOpName,
    args: args.map((arg) => {
      if (arg.type === EArgsType.INT) {
        return {
          type: EFormulaType.CONSTANT,
          val: {
            type: EOriginDataType.BIGINT,
            val: arg.val,
          },
        };
      } else if (arg.type === EArgsType.DOUBLE) {
        return {
          type: EFormulaType.CONSTANT,
          val: {
            type: EOriginDataType.DOUBLE,
            val: arg.val,
          },
        };
      } else if (arg.type === EArgsType.BOOLEAN) {
        return {
          type: EFormulaType.CONSTANT,
          val: {
            type: EOriginDataType.BOOLEAN,
            val: arg.val,
          },
        };
      } else if (arg.type === EArgsType.STRING) {
        return {
          type: EFormulaType.CONSTANT,
          val: {
            type: EOriginDataType.VARCHAR,
            val: arg.val,
          },
        };
      } else if (isArgIsFormula(arg)) {
        return arg;
      }
      throw new Error('type error');
    }) as TCorrectFormula[],
  };
};

// 调用函数生成二元运算
export const wrapBinaryOp = (
  x: TCorrectFormula,
  y: TCorrectFormula,
  op: EBinaryOperator,
): IOperatorFormula => {
  return {
    type: EFormulaType.BINARY_OPERATOR,
    op,
    x,
    y,
  };
};

// 调用函数生成一元运算
export const wrapUnaryOp = (
  x: TCorrectFormula,
  op: EUnaryOperator,
): IUnaryOperator => {
  return {
    type: EFormulaType.UNARY_OPERATOR,
    op,
    x,
  };
};

// 调用函数生成列引用
export const wrapColumnRef = (
  columnId: string,
  tableId?: string,
): IColumnFormula => {
  const path = tableId ? [tableId, columnId] : [columnId];
  return {
    type: EFormulaType.COLUMN,
    path,
  };
};

export const wrapConstant = (
  value: string | number | boolean,
): IConstantFormula => {
  if (_.isString(value)) {
    return {
      type: EFormulaType.CONSTANT,
      val: {
        type: EOriginDataType.VARCHAR,
        val: value,
      },
    };
  } else if (_.isNumber(value)) {
    return {
      type: EFormulaType.CONSTANT,
      val: {
        type: value % 1 === 0 ? EOriginDataType.BIGINT : EOriginDataType.DOUBLE,
        val: value.toString(),
      },
    };
  } else if (_.isBoolean(value)) {
    return {
      type: EFormulaType.CONSTANT,
      val: {
        type: EOriginDataType.BOOLEAN,
        val: value,
      },
    };
  }
  throw new Error('type error');
};

export function wrapUserConstant(
  value: string | number | boolean,
): IUserConstantFormula {
  const constantFormula = wrapConstant(value);
  return {
    ...constantFormula,
    extType: EExtTypeEnum.USER_PROPERTY,
  };
}

export const wrapParenthesis = (x: TCorrectFormula): IParenthesisFormula => {
  return {
    type: EFormulaType.PARENTHESIS,
    x,
  };
};

// 包裹函数转AST
export const wrapCallOpWithRepulsion = (callOpName: string, args: TArgs[]) => {
  // 互斥函数组，可以适当补充
  const mutexDyadicArr = [
    [
      ECensusFn.AVERAGE,
      ECensusFn.COUNT,
      ECensusFn.COUNTDISTINCT,
      ECensusFn.MAX,
      ECensusFn.MIN,
      ECensusFn.SUM,
    ],
    [
      EDatePartOpName.YEAR,
      EDatePartOpName.QUARTER,
      EDatePartOpName.MONTH,
      EDatePartOpName.DAY,
      EDatePartOpName.WEEKDAY,
      EDatePartOpName.WEEKNUM,
      EDatePartOpName.HOUR,
      EDatePartOpName.MINUTE,
      EDatePartOpName.SECOND,
    ],
  ];

  const specialOpNames = ['DATETRUNC', 'CAST', 'TRUNC'];
  const castOpNames = [
    ECastFn.BOOL,
    ECastFn.DATE,
    ECastFn.DATETIME,
    ECastFn.JSON,
    ECastFn.TEXT,
    ECastFn.INT,
    ECastFn.DOUBLE,
    ECastFn.DECIMAL,
  ].map((item) => item.toUpperCase());

  const judgeMutex = _.curryRight(
    (sourceArg: IFunctionOperator, targetOp: string, mutexArr: string[][]) => {
      return mutexArr
        .find((mutex) =>
          mutex
            .map((value) => value.toUpperCase())
            .includes(sourceArg.op.toUpperCase()),
        )
        ?.map((value) => value.toUpperCase())
        ?.includes(targetOp.toUpperCase());
    },
  )(callOpName, mutexDyadicArr);

  const isFunction = (arg: TFormula): arg is IFunctionOperator => {
    return arg.type === EFormulaType.FUNCTION;
  };

  const needPull = (arg: TArgs): arg is IFunctionOperator => {
    return [_.isObject, isArgIsFormula, isFunction, judgeMutex].every((fn) =>
      fn(arg),
    );
  };
  const isEligibleOpName = (
    arg: TArgs,
    targetOp: string,
    opNames: string[],
  ): arg is IFunctionOperator => {
    if (_.isObject(arg) && isArgIsFormula(arg) && isFunction(arg)) {
      return (
        arg.op.toUpperCase() === targetOp.toUpperCase() &&
        opNames.includes(targetOp.toUpperCase())
      );
    }
    return false;
  };

  const isCastFn = (arg: TArgs, targetOp: string): arg is IFunctionOperator => {
    if (_.isObject(arg) && isArgIsFormula(arg) && isFunction(arg)) {
      return (
        castOpNames.includes(targetOp.toUpperCase() as ECastFn) &&
        castOpNames.includes(arg.op.toUpperCase() as ECastFn)
      );
    }
    return false;
  };

  if (isCastFn(args[0], callOpName)) {
    return wrapCallOp(callOpName, [args[0].args[0], ...args.slice(1)]);
  }
  if (isEligibleOpName(args[0], callOpName, specialOpNames)) {
    // 如果是特殊函数，则取里面固定的参数重新包裹
    return wrapCallOp(callOpName, [
      (args[0] as IFunctionOperator).args[0],
      ...args.slice(1),
    ]);
  }
  // 外部函数互斥，则替换该函数
  if (needPull(args[0]))
    return wrapCallOp(callOpName, [(args[0] as IFunctionOperator).args[0]]);

  return wrapCallOp(callOpName, args);
};

// 取最外层的函数名
export const getWrapCallOp = (ast: TFormula): string | null => {
  return ast.type === EFormulaType.FUNCTION ? ast.op : null;
};

/**
 * 移除日期抽取
 * 如果有其他场景则再添加
 */
export const recoverConvertForDataTrunc = (ast: TFormula): TFormula => {
  const conversionFnArr: {
    opNames: string[];
    conversionFn: (ast: IFunctionOperator) => TFormula;
  }[] = [
    {
      opNames: ['DATETRUNC'],
      conversionFn: recoverDataTrunc,
    },
  ];

  if (ast.type === EFormulaType.FUNCTION) {
    // ast类型为 IFunctionOperator 肯定有函数名
    const callOpName = getWrapCallOp(ast) as string;

    const conversionFn = conversionFnArr.find((item) =>
      item.opNames.includes(callOpName.toUpperCase()),
    )?.conversionFn;

    if (conversionFn) return conversionFn(ast);
  }

  return ast;
};

export function getConstantFormula(
  colDataType: EColumnDataType,
  value: TCellData,
): TConstantVal {
  if (value === null) {
    return {
      type: EOriginDataType.VARCHAR,
      val: null,
    };
  }
  if (colDataType === EColumnDataType.INT) {
    return {
      type: EOriginDataType.BIGINT,
      val: _.toString(value),
    };
  }
  if (
    colDataType === EColumnDataType.DECIMAL ||
    colDataType === EColumnDataType.DOUBLE
  ) {
    return {
      type: EOriginDataType.DOUBLE,
      val: _.toString(value),
    };
  }
  return {
    type: EOriginDataType.VARCHAR,
    val: value.toString(),
  };
}

export enum ELooseDataType {
  NUMBER = 'number',
  TEXT = 'text',
  TIME = 'time',
  BOOLEAN = 'boolean',
  JSON = 'json',
}

export const looseDataTypeMap = {
  [EColumnDataType.INT]: ELooseDataType.NUMBER,
  [EColumnDataType.DECIMAL]: ELooseDataType.NUMBER,
  [EColumnDataType.DOUBLE]: ELooseDataType.NUMBER,
  [EColumnDataType.TEXT]: ELooseDataType.TEXT,
  [EColumnDataType.DATE]: ELooseDataType.TIME,
  [EColumnDataType.DATE_TIME]: ELooseDataType.TIME,
  [EColumnDataType.BOOLEAN]: ELooseDataType.BOOLEAN,
  [EColumnDataType.JSON]: ELooseDataType.JSON,
};

export function looseDataTypeMatch(types: EColumnDataType[]) {
  const firstLooseType = looseDataTypeMap[types[0]];
  return types.every((type) => {
    if (looseDataTypeMap[type] !== firstLooseType) {
      return false;
    }
    return true;
  });
}

export const getDateTruncGranularity = (
  ast: TFormula,
): EDateGranularityType | undefined => {
  if (isDateTrunc(ast)) {
    const granularity = getDateTruncGranularity(ast)?.toLowerCase();
    if (granularity && isGranularity(granularity)) {
      return granularity;
    }
  }
};

const isDateTrunc = (ast: TFormula): ast is IFunctionOperator => {
  return getWrapCallOp(ast)?.toUpperCase() === 'DATETRUNC';
};

const isGranularity = (
  granularity: string,
): granularity is EDateGranularityType => {
  return Object.values(EDateGranularityType).includes(
    granularity as EDateGranularityType,
  );
};

export const getDataTypeWithCastFn = (castFn: ECastFn) => {
  const castFn2DataTypeMap = {
    [ECastFn.INT]: EColumnDataType.INT,
    [ECastFn.DECIMAL]: EColumnDataType.DECIMAL,
    [ECastFn.DOUBLE]: EColumnDataType.DOUBLE,
    [ECastFn.TEXT]: EColumnDataType.TEXT,
    [ECastFn.DATE]: EColumnDataType.DATE,
    [ECastFn.DATETIME]: EColumnDataType.DATE_TIME,
    [ECastFn.BOOL]: EColumnDataType.BOOLEAN,
    [ECastFn.JSON]: EColumnDataType.JSON,
  };

  return castFn2DataTypeMap[castFn];
};

export const getCastFnByDataType = (dataType: EColumnDataType) => {
  const dataType2CastFnMap = {
    [EColumnDataType.INT]: ECastFn.INT,
    [EColumnDataType.DECIMAL]: ECastFn.DECIMAL,
    [EColumnDataType.DOUBLE]: ECastFn.DOUBLE,
    [EColumnDataType.TEXT]: ECastFn.TEXT,
    [EColumnDataType.DATE]: ECastFn.DATE,
    [EColumnDataType.DATE_TIME]: ECastFn.DATETIME,
    [EColumnDataType.BOOLEAN]: ECastFn.BOOL,
    [EColumnDataType.JSON]: ECastFn.JSON,
  };

  return dataType2CastFnMap[dataType];
};

export const getAstColumnPaths = (ast: TFormula): string[][] => {
  const { type } = ast;
  if (type === EFormulaType.PARTIAL || type === EFormulaType.EMPTY) {
    return [];
  }
  const initial: string[][] = [];

  const pathArr = iterate(ast, initial, (astItem, previous) => {
    const { type: astItemType } = astItem;
    if (astItemType === EFormulaType.COLUMN) {
      return [...previous.flat(1), astItem.path];
    }
    if (astItemType === EFormulaType.BINARY_OPERATOR) {
      const [x, y] = previous;
      return [...x, ...y];
    }
    if (astItemType === EFormulaType.FUNCTION) {
      return previous.flat(1);
    }
    if (astItemType === EFormulaType.PARENTHESIS) {
      const [x] = previous;
      return x;
    }
    if (astItemType === EFormulaType.UNARY_OPERATOR) {
      const [x] = previous;
      return x;
    }
    return [] as string[][];
  });

  return _.uniqWith(pathArr, _.isEqual);
};
