import _ from 'lodash';
import { graphql, rest } from 'msw';
import {
  EDateGranularityType,
  EOriginDataType,
  EQueryEngineType,
} from '../../src/typings';
import {
  EDatasourceEntityType,
  ERefreshMethod,
  ERefreshPeriod,
  ERefreshSettingDateFormat,
  ESign,
  IDatasourceChildrenItem,
  IDatasourceItem,
  IDatasourcePath,
  IFileTableData,
  IRefreshConfig,
} from '../../src/typings/dataSource';
import datasourceTypeList from './typeList';
import { getUniqId } from '../../src/common/utils';
import { csvUpload } from './fileUpload';
import { EUserRole } from '../../src/typings/authority';
import { getMockTableSourceInfo } from '../utils';
import datasourceConfig from './datasourceConfig';
import { ESourceType } from '../../src/typings/dataset';

export const getOriginDataType = () => {
  return _.sample([
    EOriginDataType.INT,
    EOriginDataType.BOOLEAN,
    EOriginDataType.VARCHAR,
  ]);
};

function getDatasourceList(): IDatasourceItem[] {
  const guid = getUniqId();
  return _.range(6).map((val, index) => {
    const id = `datasource${index}`;
    return {
      id,
      name: `datasourceName${index}`,
      displayName: `displayName${index}`,
      originDataType: getOriginDataType(),
      entityType: EDatasourceEntityType.CATALOG,
      description: `description${index}`,
      icon: `icon${index}`,
      owner: {
        id: `user${index}`,
        name: `useName${index}`,
        account: `account${index}`,
        photo: '',
      },
      isEngine: false,
      createTime: new Date().getTime(),
      guid: id,
      queryEngineType: EQueryEngineType.STARROCKS,
      authority: {
        canCreate: true,
        canEdit: true,
        canDelete: true,
        canUsage: true,
        canAuth: true,
        canMove: true,
      },
    };
  });
}

function getDatasourceChildren(
  path: IDatasourcePath[],
  entityType: EDatasourceEntityType,
): IDatasourceChildrenItem[] {
  if (entityType === EDatasourceEntityType.SCHEMA) {
    // 数据库
    return _.range(20).map((i) => {
      const guid = `database${i}`;
      const name = `database${i}`;
      return {
        name,
        entityType,
        guid,
        path,
      };
    });
  } else if (entityType === EDatasourceEntityType.FILE) {
    // 文件
    return _.range(20).map((i) => {
      const guid = `file${i}`;
      const name = `file${i}`;
      return {
        name,
        description: `file_description${i}`,
        entityType,
        guid,
        path,
      };
    });
  } else {
    // 表
    return _.range(20).map((i) => {
      const guid = `table${i}`;
      const name = `table${i}`;
      return {
        name,
        entityType,
        guid,
        path,
      };
    });
  }
}
function generateFileTableDataMock(): IFileTableData[] {
  const result: IFileTableData[] = [];
  for (let i = 0; i < 10; i++) {
    const data: IFileTableData = {
      guid: getUniqId(),
      fileId: `file_${i}`,
      name: `fileName${i}`,
      description: `description${i}`,
      displayName: `displayName${i}`,
      entityType: EDatasourceEntityType.FILE,
      owner: {
        userId: `${i}`,
        account: `account${i}`,
        type: 'USER',
        nickname: `name${i}`,
      },
      createTime: new Date().getTime(),
      updateTime: new Date().getTime(),
      authority: {
        canAuth: true,
        canDelete: true,
        canCreate: true,
        canEdit: true,
        canMove: true,
        canUsage: true,
      },
    };
    result.push(data);
  }
  return result;
}
export const datasource = [
  graphql.query('queryDatasourceList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDatasourceList: getDatasourceList(),
      }),
      ctx.delay(1000),
    );
  }),

  graphql.query('queryDatasourceChildrenList', (req, res, ctx) => {
    const { guid, entityType } = req.variables;
    let path: IDatasourcePath[] = [];
    let childEntityType: EDatasourceEntityType;
    if (entityType === EDatasourceEntityType.CATALOG) {
      path = [
        {
          guid,
          name: guid,
          entityType: EDatasourceEntityType.CATALOG,
        },
      ];
      childEntityType = EDatasourceEntityType.SCHEMA;
    } else {
      path = [
        {
          guid: 'datasource1',
          name: 'datasource1',
          entityType: EDatasourceEntityType.CATALOG,
        },
        {
          guid,
          name: guid,
          entityType: EDatasourceEntityType.SCHEMA,
        },
      ];
      childEntityType = EDatasourceEntityType.TABLE;
    }
    return res(
      ctx.data({
        queryDatasourceChildrenList: getDatasourceChildren(
          path,
          childEntityType,
        ),
      }),
    );
  }),
  graphql.query('queryFileTableList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryFileTableList: generateFileTableDataMock(),
      }),
    );
  }),
  graphql.query('queryFileList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryFileList: [
          {
            id: 'a55dbb56-02ad-4aa0-bda5-4c57a2429d6f',
            fileName: 'en_na13',
            description: '',
            createTime: 1710312670564,
          },
          {
            id: 'a55dbb56-02ad-4aa0-bda51-4c57a2429d6f',
            fileName: 'en_na14',
            description: '',
            createTime: 1710312670564,
          },
        ],
      }),
    );
  }),
  graphql.query('queryFileDetail', (req, res, ctx) => {
    const { fileId } = req.variables;
    return res(
      ctx.data({
        queryFileDetail: {
          fileId,
          displayName: 'cnasd',
          name: 'en_na13',
          description: null,
          updateTime: 1710312671521,
          createTime: 1710312670564,
          owner: {
            userId: '349134354502397952',
            nickname: 'qianqian0.2.1',
            account: 'qianqian0.2.1',
          },
          datasourceItems: [],
          columns: [
            {
              name: 'column_0',
              description: 'col1',
              originDataType: EOriginDataType.VARCHAR,
              displayName: '中文列名_0',
            },
            {
              name: 'column_1',
              description: 'col2',
              originDataType: EOriginDataType.VARCHAR,
              displayName: '中文列名_1',
            },
          ],
          fileOpt: {
            delimiter: ',',
            quote: '"',
            lineSeparator: null,
            encoding: 'utf-8',
            containsHeader: true,
            name: 'en_na13',
            data: [
              ['bad', 'b12das'],
              ['aasd', 'a1as2d'],
            ],
            columns: [
              {
                name: 'column_0',
                description: 'col1',
                originDataType: EOriginDataType.VARCHAR,
              },
              {
                name: 'column_1',
                description: 'col2',
                originDataType: EOriginDataType.VARCHAR,
              },
            ],
          },
          authority: {
            canCreate: true,
            canEdit: true,
            canDelete: true,
            canMove: true,
            canUsage: true,
            canAuth: true,
            authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          },
          replace: '',
        },
      }),
    );
  }),
  graphql.query('datasourceSearch', (req, res, ctx) => {
    const searchKey = req.variables.keyword;
    if (searchKey) {
      return res(
        ctx.data({
          datasourceSearch: getDatasourceChildren(
            [],
            EDatasourceEntityType.TABLE,
          ).filter((item) =>
            item.name.toLowerCase().includes(searchKey.toLowerCase()),
          ),
        }),
        ctx.delay(3000),
      );
    }
    return res(
      ctx.data({
        queryDatasourceChildrenList: getDatasourceChildren(
          [],
          EDatasourceEntityType.TABLE,
        ),
      }),
      ctx.delay(3000),
    );
  }),

  graphql.query('queryDatasourceTypeSchemaList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDatasourceTypeSchemaList: datasourceTypeList,
        success: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryDatasourceConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDatasourceConfig: datasourceConfig,
      }),
    );
  }),
  graphql.query('queryDatasourceItemDetail', (req, res, ctx) => {
    const { guid, entityType } = req.variables;

    let path: IDatasourcePath[] = [];
    let childEntityType: EDatasourceEntityType;
    if (entityType === EDatasourceEntityType.CATALOG) {
      path = [];
      childEntityType = EDatasourceEntityType.SCHEMA;
    } else {
      path = [
        {
          guid: 'datasource1',
          name: 'datasource1',
          entityType: EDatasourceEntityType.CATALOG,
        },
      ];
      childEntityType = EDatasourceEntityType.TABLE;
    }

    return res(
      ctx.data({
        queryDatasourceItemDetail: {
          guid,
          entityType,
          name: guid,
          children: getDatasourceChildren(path, childEntityType),
          path,
        },
      }),
    );
  }),
  graphql.query('checkFileName', (req, res, ctx) => {
    const { fileName } = req.variables;
    let flag: boolean = true;
    if (fileName === 'aaa') {
      flag = false;
    }
    return res(
      ctx.data({
        checkFileName: flag,
      }),
    );
  }),
  graphql.mutation('updateFileInfo', (req, res, ctx) => {
    return res(
      ctx.data({
        updateFileInfo: true,
      }),
    );
  }),
  graphql.mutation('deleteFileTable', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteFileTable: true,
      }),
    );
  }),
  graphql.mutation('deleteFile', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteFile: true,
      }),
    );
  }),
  graphql.query('queryTableDetail', (req, res, ctx) => {
    const { content } = req.variables;
    return res(
      ctx.data({
        queryTableDetail: getMockTableSourceInfo(ESourceType.TABLE, content),
      }),
    );
  }),
  ...csvUpload,
  graphql.mutation('setupRefreshConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        setupRefreshConfig: true,
      }),
    );
  }),
  graphql.query('queryRefreshConfig', (req, res, ctx) => {
    const hasPartitionConfig: IRefreshConfig = {
      partitionColumnName: 'year_month',
      dateGranularity: _.sample([
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ])!,
      dateFormat: ERefreshSettingDateFormat.YYYYMMDD,
      refreshPeriod: _.sample([ERefreshPeriod.MANUAL, ERefreshPeriod.TIMING]),
      refreshMethod: _.sample([ERefreshMethod.ALL, ERefreshMethod.INCREMENT]),
      incrementRefreshScope: {
        interval: 1,
        sign: _.sample([ESign.POSITIVE, ESign.NEGATIVE]),
      },
    };

    const noPartitionConfig: IRefreshConfig = {
      partitionColumnName: '',
      refreshPeriod: _.sample([ERefreshPeriod.MANUAL, ERefreshPeriod.TIMING]),
      refreshMethod: _.sample([ERefreshMethod.ALL, ERefreshMethod.INCREMENT]),
    };

    return res(
      ctx.data({
        queryRefreshConfig: _.sample([hasPartitionConfig]),
      }),
    );
  }),
  graphql.query('checkDatasourceConnection', (req, res, ctx) => {
    return res(
      ctx.data({
        checkDatasourceConnection: true,
      }),
    );
  }),
  graphql.mutation('deleteDatasourceCatalog', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteDatasourceCatalog: true,
      }),
    );
  }),
  graphql.mutation('createDatasourceCatalog', (req, res, ctx) => {
    return res(
      ctx.data({
        createDatasourceCatalog: true,
      }),
    );
  }),
  graphql.mutation('updateDatasourceCatalog', (req, res, ctx) => {
    return res(
      ctx.data({
        updateDatasourceCatalog: true,
      }),
    );
  }),
  graphql.mutation('refreshTable', (req, res, ctx) => {
    return res(
      ctx.data({
        refreshTable: true,
      }),
    );
  }),

  graphql.query('queryDownstreamMtRefresh', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDownstreamMtRefresh: Array.from({ length: 10 }, (_, i) => ({
          tableName: `table_${i}`,
          refreshTime: new Date().getTime(),
        })),
      }),
    );
  }),
];
