import { ECastFn } from '@/components/Formula/type';
import t from '@/locales';
import { EOriginDataType, IFunctionOperator } from '@/typings/formula';
import _ from 'lodash';
import { ECheckResultType, TCheckResult } from '../function';
const funWithOneArg = [
  ECastFn.BOOL,
  ECastFn.JSON,
  ECastFn.TEXT,
  ECastFn.INT,
  ECastFn.DOUBLE,
].map((item) => item.toUpperCase());
const ARGS_LENGTH_TWO = 2;
const checkCastFn = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { args } = params;
  const op = params.op.toUpperCase();
  if (funWithOneArg.includes(op as ECastFn) && args.length === 1) {
    if (args.length !== 1) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
      };
    }
    if (op === ECastFn.BOOL.toUpperCase()) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.BOOLEAN,
      };
    }
    if (op === ECastFn.INT.toUpperCase()) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.BIGINT,
      };
    }
    if (op === ECastFn.DOUBLE.toUpperCase()) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.DOUBLE,
      };
    }
    if (op === ECastFn.TEXT.toUpperCase()) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.VARCHAR,
      };
    }
    if (op === ECastFn.JSON.toUpperCase()) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.STRUCT,
      };
    }
  }
  if (op === ECastFn.DATETIME.toUpperCase()) {
    if (args.length > ARGS_LENGTH_TWO) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
      };
    }
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.TIMESTAMP,
    };
  }
  if (op === ECastFn.DATE.toUpperCase()) {
    if (args.length > ARGS_LENGTH_TWO) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
      };
    }
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.DATE,
    };
  }
  if (op === ECastFn.DECIMAL.toUpperCase()) {
    if (args.length !== ARGS_LENGTH_TWO) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
      };
    }
    const PRECISION_INDEX = 1;
    const MAX_PRECISION = 12;
    const precision = _.get(params.ast.args[PRECISION_INDEX], 'val.val') || 0;
    const precisionType = _.get(params.ast.args[PRECISION_INDEX], 'val.type');
    if (precisionType) {
      if (
        precisionType !== EOriginDataType.BIGINT ||
        precision === 0 ||
        precision > MAX_PRECISION
      ) {
        return {
          type: ECheckResultType.ERROR,
          errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
        };
      }
    }
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.DECIMAL,
    };
  }
  return {
    type: ECheckResultType.ERROR,
    errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
  };
};
export default checkCastFn;
