import { IDatasetRelation, IDefColumn } from '@/typings/dataset';

export interface IQuerySheet {
  base: {
    id: 'base';
    columns: string[];
  };
  columns: {
    [columnId: string]: IDefColumn;
  };
}

export interface IQueryInput {
  queryId: string;
  meta: {
    queryType: EQueryType;
  };
  contents: IQueryContents;
  query: IQuerySheet;
  pager: {
    offset: number;
    limit: number;
  };
}

export enum EQueryType {
  TABLE = 'TABLE', // 物理表
  SQL = 'SQL', // SQL
  FILE = 'FILE', // 文件
  RELATIONS = 'RELATIONS', // 带上临时关系的查询，用在数据集定义时
  MODEL = 'MODEL', // 模型查询
}

export interface IQueryContents {
  relations: IDatasetRelation[]; // 主动关联
  source: IQuerySourceItem;
}

export interface IQuerySourceItem {
  name: string;
  content: string;
  type: EDependencyType;
}

export enum EDependencyType {
  SQL = 'SQL',
  FILE = 'FILE',
  TABLE = 'TABLE',
  DATASET = 'DATASET',
  MODEL = 'MODEL',
}

// 执行查询后的返回
export interface IQueryResult {
  queryId: string;
  sql?: string;
  warning?: string; // 执行查询时返回的警告信息，如数据量过大未返回全等
  table: IResultTableData;
}

export enum EQueryStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED', // 执行失败
}

export interface IQueryResultData {
  status: EQueryStatus;
  data: IResultTableData;
  limit: number; // 查询的行数
  rowCount: number; // 返回的总条数，不可能大于 limit + 1
  hasMore: boolean; // 是否有更多数据
  warning?: string; // 服务端返回的警告信息
  errorMsg?: string; // 服务端返回的错误信息
  detailErrorMsg?: string; // 服务端返回的 detailErrorMsg
  // 该结果数据是否过期，在请求新的结果数据时，旧的过期数据依然需要渲染在图表上，因此需要标记过期，但不能直接清除
  expired?: boolean;
}

export interface IResultTableData {
  [columnId: string]: TResultDataItem[];
}

// 数组第一位表示单元格值，第二位表示相同的值所占的行数，第三位（不一定存在）为标识位，代表该列是否有错误以及错误类型
export type TResultDataItem = [TCellData, number, unknown?];

export type TCellData = boolean | string | number | null;

export interface IAbortQueryInfo {
  abortController: AbortController; // 用于在浏览器端取消请求的标记
  queryId: string; // 请求的 ID，用于向服务端发送取消请求
}
