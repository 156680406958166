import { ReactComponent as FolderSvg } from '@/assets/icon/Folder-light-line.svg';
import { ReactComponent as FavFolderSvg } from '@/assets/icon/Folder-Collection-light-line.svg';
import { getCategoryId } from '@/common/utils';
import { ICategoryTreeNode } from '@/pages/Manage/CategoryManage/categoryHelper';
import _ from 'lodash';
import { IDataItem, ITreeNode } from './types';
import t from '@/locales';
import { ALL_METRIC_CATEGORY_KEY } from './constants';
/**
 * 将指标列表放入类目树中，生成一个带类目和指标的树结构
 * @param metricList 指标列表
 * @param categoryTree 类目树
 */
export function fillMetricListIntoTreeData(
  metricList: IDataItem[],
  categoryTree: ICategoryTreeNode[],
  iconSize: number,
  showFavFolder?: boolean,
  showAllCategory?: boolean,
  disabledKeys: string[] = [],
): ITreeNode[] {
  // 构建 {[类目 ID]: 指标列表} Map
  const map: {
    [key: string]: IDataItem[];
  } = {};
  _.forEach(metricList, (metricItem) => {
    const category = getCategoryId(metricItem.metric);
    if (map[category]) {
      map[category].push(metricItem);
    } else {
      map[category] = [metricItem];
    }
  });
  const res = _.map(categoryTree, (category) =>
    makeTreeNode(category, map, iconSize, disabledKeys),
  );
  if (showFavFolder) {
    res.unshift(makeFavTreeNode(metricList, iconSize, disabledKeys));
  }
  if (showAllCategory) {
    res.unshift({
      key: ALL_METRIC_CATEGORY_KEY,
      title: t.components.metricPicker.allFolder,
      isLeaf: true,
      data: null,
      icon: <FolderSvg size={iconSize} />,
      isCategory: true,
      children: [],
    });
  }
  return res;
}

function makeTreeNode(
  categoryItem: ICategoryTreeNode,
  map: {
    [key: string]: IDataItem[];
  },
  iconSize: number,
  disabledKeys: string[],
): ITreeNode {
  const subCategoryList = _.map(categoryItem.children, (item) =>
    makeTreeNode(item, map, iconSize, disabledKeys),
  );
  const metricList = map[categoryItem.key] || [];
  const leafNodes = _.map(metricList, (metricItem) => {
    return {
      key: metricItem.metric.code,
      title: metricItem.metric.name || metricItem.metric.metricName,
      data: metricItem,
      isCategory: false,
      isLeaf: true,
      disabled: disabledKeys.includes(metricItem.metric.code),
    };
  });
  const children = [...subCategoryList, ...leafNodes];
  const category = {
    key: categoryItem.key,
    title: categoryItem.title,
    isLeaf: !children.length,
    data: categoryItem,
    icon: <FolderSvg size={iconSize} />,
    isCategory: true,
    children,
  };
  return category;
}
function makeFavTreeNode(
  metricList: IDataItem[],
  iconSize: number,
  disabledKeys: string[],
): ITreeNode {
  const favList = _.filter(
    metricList,
    (metricItem) => metricItem.metric.collect === true,
  );
  const leafNodes = _.map(favList, (metricItem) => {
    return {
      key: `FAV_${metricItem.metric.code}`,
      title: metricItem.metric.name || metricItem.metric.metricName,
      data: metricItem,
      isLeaf: true,
      isCategory: false,
      disabled: disabledKeys.includes(metricItem.metric.code),
    };
  });
  const category = {
    key: 'METRIC_FAV_FOLDER',
    title: t.components.metricPicker.favFolder,
    isLeaf: !favList.length,
    data: null,
    icon: <FavFolderSvg size={iconSize} />,
    isCategory: true,
    children: leafNodes,
  };
  return category;
}

/**
 * 查找选中的节点的路径，路径不包含自身
 * @param treeData
 * @param key
 * @returns
 */
export function getSelectedItemPath(
  treeData: ISimpleTreeNode[],
  key: string,
): string[] {
  const path: string[] = [];
  const findPath = (node: ISimpleTreeNode, parentPath: string[]) => {
    if (node.key === key) {
      path.push(...parentPath);
    } else if (node.isCategory && node.children) {
      node.children.forEach((child) => {
        findPath(child, [...parentPath, node.key]);
      });
    }
  };
  treeData.forEach((node) => {
    findPath(node, []);
  });
  return path;
}

interface ISimpleTreeNode {
  key: string;
  isCategory?: boolean;
  children?: ISimpleTreeNode[];
}
