import { EColumnDataType, EDateGranularityType } from '@/typings';
import { EDateFilterUnit } from '@/typings/analysisView';
import { EGranularitySelect } from '@/typings/metric';

export enum EFilterOperator {
  AND = 'and',
  OR = 'or',
}

export enum ECommonFilterValueType {
  NULL = 'null',
  EMPTY = 'empty',
  STRING = 'string',
  NUMBER = 'number',
}

export enum ETopNRankType {
  RANK = 'rank',
  RANK_DENSE = 'rankDense',
  ROW_NUMBER = 'rowNumber',
}

export type TFilterSelectValue =
  | {
      type: ECommonFilterValueType.NULL;
    }
  | {
      type: ECommonFilterValueType.EMPTY;
    }
  | {
      type: ECommonFilterValueType.STRING;
      value: string;
    }
  | {
      type: ECommonFilterValueType.NUMBER;
      value: string | number;
    };

export enum ECommonFilterType {
  EQUAL = 'equal',
  NOT_EQUAL = 'notEqual',
  EQUAL_ATTRIBUTE = 'equalAttribute',
  NOT_EQUAL_ATTRIBUTE = 'notEqualAttribute',
  CONTAIN_ATTRIBUTE = 'containAttribute',
  NOT_CONTAIN_ATTRIBUTE = 'notContainAttribute',
}

export type TFilterSelectValues = TFilterSelectValue[];

// **********公共Top N 筛选**************

// export interface ITopNFilter {
//   type: ECommonFilterType.TOP_N;
//   values: {
//     rankType: ERankType;
//     selectValue: number;
//     dimension: [string, string];
//     agg: EAggType;
//     isAsc: EIsAsc;
//   };
// }

// ******文本筛选***********

export enum ETextFilterType {
  INCLUDE = 'include',
  NOT_INCLUDE = 'notInclude',
  START_WITH = 'startWith',
  NOT_START_WITH = 'notStartWith',
  END_WITH = 'endWith',
  NOT_END_WITH = 'notEndWith',
}

// 开头结尾
// 包含不包含,
export type ITextIncludeFilter = {
  type:
    | ETextFilterType.INCLUDE
    | ETextFilterType.NOT_INCLUDE
    | ETextFilterType.START_WITH
    | ETextFilterType.NOT_START_WITH
    | ETextFilterType.END_WITH
    | ETextFilterType.NOT_END_WITH;
  values: string;
};

export type TTextFilter =
  | {
      type:
        | ECommonFilterType.CONTAIN_ATTRIBUTE
        | ECommonFilterType.NOT_CONTAIN_ATTRIBUTE
        | ECommonFilterType.EQUAL_ATTRIBUTE
        | ECommonFilterType.NOT_EQUAL_ATTRIBUTE;
      values: TFilterSelectValues;
    }
  | {
      type: ECommonFilterType.EQUAL | ECommonFilterType.NOT_EQUAL;
      values: TFilterSelectValues;
      granularity?: EDateGranularityType;
    }
  // | ITopNFilter
  | ITextIncludeFilter;

// **********数值筛选**************
export enum ENumberFilterType {
  RANGE = 'range',
}

export enum ENumberFilterValueType {
  NULL = 'null',
  NUMBER = 'number',
}

export enum ENumberRangeOperator {
  LESS = 'less',
  LESSOREQUAL = 'lessOrEqual',
}

export type TNumberRangeValues = {
  type: ENumberFilterType.RANGE;
  values: {
    low: {
      type: ENumberFilterValueType.NUMBER;
      operator: ENumberRangeOperator;
      value: number | undefined;
    };
    high: {
      type: ENumberFilterValueType.NUMBER;
      operator: ENumberRangeOperator;
      value: number | undefined;
    };
  };
};
export type TNumberFilter =
  // 等于，不等于：下拉框选值（需要调用查询接口）
  | {
      type:
        | ECommonFilterType.EQUAL
        | ECommonFilterType.NOT_EQUAL
        | ECommonFilterType.EQUAL_ATTRIBUTE
        | ECommonFilterType.NOT_EQUAL_ATTRIBUTE;
      values: TFilterSelectValues;
    }
  // 区间：用户输入范围的数字
  | TNumberRangeValues;

// **********布尔值筛选**************
export type TBooleanFilter =
  // 等于，不等于：下拉框选值（需要调用查询接口）
  {
    type: ECommonFilterType.EQUAL | ECommonFilterType.NOT_EQUAL;
    values: TFilterSelectValues;
  };

// *********JSON筛选**************

export enum EJSONFilterType {
  CONTAIN = 'json_contain',
  NOT_CONTAIN = 'json_not_contain',
}

export type TJSONFilter = {
  type: EJSONFilterType.CONTAIN | EJSONFilterType.NOT_CONTAIN;
  values: string;
};

// **********日期筛选**************
export enum EDateFilterType {
  RANGE = 'dateRange',
}

export enum EDateRangeType {
  // 日期区间
  RANGE = 'range',
  // 指定日期
  SPECIFY = 'specify',
  // 早于
  BEFORE = 'before',

  // 晚于
  AFTER = 'after',
}

// 日期区间

export enum EDateFilterValueType {
  FIXED = 'fixed',
  //  DateAdd(now(), -1, 'day')
  // 从当前时间往前后偏移相应的粒度
  // 如近3年，从当前时间往前3年
  // DYNAMIC_RANGE = 'dynamicRange',
  // DateAdd(DateTrunc(now(), 'day'), -1, 'day')
  // 从当前时间相依粒度的时间点 往前后偏移相应的粒度
  // 如上周, 从本周的开始时间点往前推一周
  DYNAMIC = 'dynamic',
}

// 供用户选择的日期粒度
export enum EDateUnitKey {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

// 日期时间字段的粒度
export enum EDateTimeUnitKey {
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum EDateDynamicOperator {
  ADD = '+',
  DECREASE = '-',
}

export type TDateDynamicValue = {
  type: EDateFilterValueType.DYNAMIC;
  unit: EDateUnitKey | EDateTimeUnitKey;
  operator: EDateDynamicOperator;
  // 动态偏移值
  value: number;
  granularitySelect: EGranularitySelect;
};

export type TDateFixedValue = {
  type: EDateFilterValueType.FIXED;
  value: number | null;
};

export type TDateRangeValues = {
  type: EDateRangeType.RANGE;
  // 侧边栏：是否包含今天
  includeToday: boolean;
  select?: EDateFilterUnit;
  values: {
    max: TDateDynamicValue | TDateFixedValue;
    min: TDateDynamicValue | TDateFixedValue;
  };
};

//指定日期 (那天的 00:00:00 ~ 23:59:59)
export type TDateSpecifyValues = {
  type: EDateRangeType.SPECIFY;
  values: number;
};

//早于
export type TDateBeforeValues = {
  type: EDateRangeType.BEFORE;
  values: {
    includeSelected: boolean;
    value: number;
  };
};
// 晚于
export type TDateAfterValues = {
  type: EDateRangeType.AFTER;
  values: {
    includeSelected: boolean;
    value: number;
  };
};

export type TDateRangeType = {
  values:
    | TDateRangeValues
    | TDateAfterValues
    | TDateBeforeValues
    | TDateSpecifyValues;
  includeNull: boolean;
  type: EDateFilterType.RANGE;
  select?: EDateFilterUnit;
  granularity?: EDateGranularityType;
};

export type TDateFilter =
  // 等于，不等于：下拉框选值（需要调用查询接口）
  | {
      type: ECommonFilterType.EQUAL | ECommonFilterType.NOT_EQUAL;
      values: null | TFilterSelectValues;
      granularity?: EDateGranularityType;
    }
  | {
      type:
        | ECommonFilterType.EQUAL_ATTRIBUTE
        | ECommonFilterType.NOT_EQUAL_ATTRIBUTE;
      granularity?: EDateGranularityType;
      values: TFilterSelectValues;
    }
  | TDateRangeType;

// 所有的筛选定义
export type TFilter =
  | TTextFilter
  | TNumberFilter
  | TBooleanFilter
  | TJSONFilter
  | TDateFilter;

// 单个筛选条件
export interface IFilterItem {
  id: string;
  sheetId: string;
  columnId: string;
  disabled?: boolean;
  isInnerModel?: boolean;
  dataType?: EColumnDataType;
  filter: TFilter;
}

// 条件组
export interface IFilter<T> {
  id: string;
  logicalOperator: EFilterOperator;
  children: Array<T>;
  visible?: boolean;
}
