// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
  function id(x) {
    return x[0];
  }

  const moo = require('moo');

  const insensitive = require('../insensitive');
  const split = require('../split');

  const lexer = moo.compile({
    ws: { match: /\s+/, lineBreaks: true },
    string: {
      match: /(?:"(?:\\["\\]|[^\n"\\])*")/,
      value: (s) => {
        if (s === '') return '';
        return s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
      },
    },
    number: /(?:[0-9]|[1-9][0-9]+)(?:\.[0-9]+)?(?:[eE][-+]?[0-9]+)?\b/,
    true: insensitive(/true/),
    false: insensitive(/false/),
    '+': '+',
    '-': '-',
    '*': '*',
    '/': '/',
    '<': '<',
    '>': '>',
    and: insensitive(/and/),
    '&&': '&&',
    '||': '||',
    or: insensitive(/or/),
    functionId: /[a-zA-Z0-9_]+/,
    column: {
      match: /(?:\[(?:\\[\[\]\\]|[^\n\]\[])*\])/,
      value: (s) => {
        return split(
          s
            .slice(1, -1)
            .replace(/\\\[/g, '[')
            .replace(/\\\]/g, ']')
            .replace(/\\\\/g, '\\'),
          '/',
        ).map((item) => item.replace(/\\\//g, '/'));
      },
    },
    '(': '(',
    ')': ')',
    ',': ',',
    '=': '=',
    default: /\S+/,
  });
  var grammar = {
    Lexer: lexer,
    ParserRules: [
      { name: 'start', symbols: ['formula'], postprocess: id },
      { name: 'formula', symbols: ['expression'], postprocess: id },
      {
        name: 'expression',
        symbols: ['_', 'logic', '_'],
        postprocess: (d) => d[1],
      },
      {
        name: 'function',
        symbols: [
          lexer.has('functionId') ? { type: 'functionId' } : functionId,
          { literal: '(' },
          '_',
          'params',
          '_',
          { literal: ')' },
        ],
        postprocess: (d) => ({
          type: 'CALL_OP',
          op: d[0].value,
          args: d[3],
          position: [
            { start: d[0].col, line: d[0].line, text: d[0].text },
            { start: d[1].col, line: d[1].line, text: d[1].text },
            { start: d[5].col, line: d[5].line, text: d[5].text },
          ],
        }),
      },
      {
        name: 'function',
        symbols: [
          lexer.has('functionId') ? { type: 'functionId' } : functionId,
          { literal: '(' },
          { literal: ')' },
        ],
        postprocess: (d) => ({
          type: 'CALL_OP',
          op: d[0].value,
          args: [],
          position: [
            { start: d[0].col, line: d[0].line, text: d[0].text },
            { start: d[1].col, line: d[1].line, text: d[1].text },
            { start: d[2].col, line: d[2].line, text: d[2].text },
          ],
        }),
      },
      {
        name: 'parentheses',
        symbols: [{ literal: '(' }, '_', 'logic', '_', { literal: ')' }],
        postprocess: (d) => ({
          type: 'PAREN',
          x: d[2],
          position: [
            { start: d[0].col, line: d[0].line, text: d[0].text },
            { start: d[4].col, line: d[4].line, text: d[4].text },
          ],
        }),
      },
      { name: 'parentheses', symbols: ['calcUnit'], postprocess: id },
      {
        name: 'md',
        symbols: ['md', '_', { literal: '*' }, '_', 'parentheses'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '*',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'md',
        symbols: ['md', '_', { literal: '/' }, '_', 'parentheses'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '/',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      { name: 'md', symbols: ['parentheses'], postprocess: id },
      {
        name: 'as',
        symbols: ['as', '_', { literal: '+' }, '_', 'md'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '+',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'as',
        symbols: ['as', '_', { literal: '-' }, '_', 'md'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '-',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      { name: 'as', symbols: ['md'], postprocess: id },
      {
        name: 'compare',
        symbols: ['compare', '_', { literal: '>' }, '_', 'as'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '>',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'compare',
        symbols: ['compare', '_', { literal: '<' }, '_', 'as'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '<',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'compare',
        symbols: [
          'compare',
          '_',
          { literal: '>' },
          { literal: '=' },
          '_',
          'as',
        ],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '>=',
          x: d[0],
          y: d[5],
          position: [{ start: d[2].col, line: d[2].line, text: '>=' }],
        }),
      },
      {
        name: 'compare',
        symbols: [
          'compare',
          '_',
          { literal: '<' },
          { literal: '=' },
          '_',
          'as',
        ],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '<=',
          x: d[0],
          y: d[5],
          position: [{ start: d[2].col, line: d[2].line, text: '<=' }],
        }),
      },
      {
        name: 'compare',
        symbols: ['compare', '_', { literal: '=' }, '_', 'as'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '=',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'compare',
        symbols: [
          'compare',
          '_',
          { literal: '<' },
          { literal: '>' },
          '_',
          'as',
        ],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '<>',
          x: d[0],
          y: d[5],
          position: [{ start: d[2].col, line: d[2].line, text: '<>' }],
        }),
      },
      { name: 'compare', symbols: ['as'], postprocess: id },
      {
        name: 'logic',
        symbols: [
          'logic',
          '_',
          lexer.has('and') ? { type: 'and' } : and,
          '_',
          'compare',
        ],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: 'AND',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'logic',
        symbols: [
          'logic',
          '_',
          lexer.has('or') ? { type: 'or' } : or,
          '_',
          'compare',
        ],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: 'OR',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'logic',
        symbols: ['logic', '_', { literal: '&&' }, '_', 'compare'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '&&',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      {
        name: 'logic',
        symbols: ['logic', '_', { literal: '||' }, '_', 'compare'],
        postprocess: (d) => ({
          type: 'BIN_OP',
          op: '||',
          x: d[0],
          y: d[4],
          position: [{ start: d[2].col, line: d[2].line, text: d[2].text }],
        }),
      },
      { name: 'logic', symbols: ['compare'], postprocess: id },
      {
        name: 'params',
        symbols: ['logic', '_', { literal: ',' }, '_', 'params'],
        postprocess: (d) => {
          return [d[0], ...d[4]];
        },
      },
      {
        name: 'params',
        symbols: ['logic'],
        postprocess: (d) => {
          return [d[0]];
        },
      },
      { name: 'calcUnit', symbols: ['constant'], postprocess: id },
      { name: 'calcUnit', symbols: ['column'], postprocess: id },
      { name: 'calcUnit', symbols: ['function'], postprocess: id },
      {
        name: 'column',
        symbols: [lexer.has('column') ? { type: 'column' } : column],
        postprocess: (d) => ({
          type: 'NAME_REF',
          path: d[0].value,
          position: [{ start: d[0].col, line: d[0].line, text: d[0].text }],
        }),
      },
      { name: 'constant', symbols: ['number'], postprocess: id },
      { name: 'constant', symbols: ['string'], postprocess: id },
      { name: 'constant', symbols: ['true'], postprocess: id },
      { name: 'constant', symbols: ['false'], postprocess: id },
      { name: 'constant', symbols: ['unitaryOperator'], postprocess: id },
      {
        name: 'unitaryOperator',
        symbols: [{ literal: '-' }, '_', 'number'],
        postprocess: (d) => ({
          type: 'UN_OP',
          op: '-',
          x: d[2],
          position: [{ start: d[0].col, line: d[0].line, text: d[0].text }],
        }),
      },
      {
        name: 'number',
        symbols: [lexer.has('number') ? { type: 'number' } : number],
        postprocess: (d) => {
          return {
            type: 'CONSTANT',
            position: [{ start: d[0].col, line: d[0].line, text: d[0].text }],
            val: {
              type:
                d[0].value.toString().indexOf('.') > -1 ? 'DOUBLE' : 'BIGINT',
              val: d[0].value,
            },
          };
        },
      },
      {
        name: 'true',
        symbols: [lexer.has('true') ? { type: 'true' } : true],
        postprocess: (d) => ({
          type: 'CONSTANT',
          position: [{ start: d[0].col, line: d[0].line, text: d[0].text }],
          val: { type: 'BOOLEAN', val: true },
        }),
      },
      {
        name: 'false',
        symbols: [lexer.has('false') ? { type: 'false' } : false],
        postprocess: (d) => ({
          type: 'CONSTANT',
          position: [{ start: d[0].col, line: d[0].line, text: d[0].text }],
          val: { type: 'BOOLEAN', val: false },
        }),
      },
      {
        name: 'string',
        symbols: [lexer.has('string') ? { type: 'string' } : string],
        postprocess: (d) => ({
          type: 'CONSTANT',
          position: [{ start: d[0].col, line: d[0].line, text: d[0].text }],
          val: { type: 'VARCHAR', val: d[0].value },
        }),
      },
      { name: '_$ebnf$1', symbols: [] },
      {
        name: '_$ebnf$1',
        symbols: ['_$ebnf$1', lexer.has('ws') ? { type: 'ws' } : ws],
        postprocess: function arrpush(d) {
          return d[0].concat([d[1]]);
        },
      },
      { name: '_', symbols: ['_$ebnf$1'] },
    ],
    ParserStart: 'start',
  };
  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = grammar;
  } else {
    window.grammar = grammar;
  }
})();
