export const header = {
  title: '指标目录',
};

export const sidebar = {
  title: '筛选',
  clear: '清空',
  category: '指标类目',
  metricType: '指标类型',
  owner: {
    default: '数据负责人',
    placeholder: '添加负责人',
  },
};

export const mainContent = {
  count: ({ count }: { count: string | number }) => `${count}个指标`,
  empty: {
    desc: '没有符合条件的指标，请重新尝试',
  },
};
