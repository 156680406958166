export const define = {
  label: {
    name: '标识名称',
    enName: '名称',
    description: '描述',
    value: '日期值',
    valueDescription: '符合该列的日期会被添加日期标识',
    table: '表',
    column: '日期列',
    filterSwitch: '筛选',
    filterDescription: '满足以下条件',
  },
  title: {
    create: '新建日期标识',
    edit: '编辑日期标识',
  },
  validate: {
    empty: (filed: string) => `${filed}不能为空`,
    enNamePattern: '只能包含字母、数字、下划线，长度50以内',
  },
  button: {
    create: '创建',
    edit: '保存',
  },
  message: {
    createSuccess: '创建成功',
    editSuccess: '保存成功',
  },
  placeholder: {
    table: '选择表',
    column: '选择字段',
  },
};

export const list = {
  title: '日期标识',
  total: '个日期标识',
  create: '新建日期标识',
  column: {
    name: '名称',
    description: '说明',
    operation: '操作',
    enName: '名称',
  },
  operation: {
    edit: '编辑',
    delete: '删除',
  },
  message: {
    deleteSuccess: '删除成功',
  },
};

export const dateLimitList = {
  title: '时间限定',
  total: '个时间限定',
  create: '新建时间限定',
  column: {
    name: '名称',
    displayName: '展示名',
    timeRange: '时间范围',
  },
  delete: {
    title: '删除时间限定',
    getContent: (name: string) => `是否删除时间限定【${name}】`,
  },
};

export const dateLimitForm = {
  title: {
    edit: '编辑时间限定',
    create: '新建时间限定',
  },
  okText: {
    create: '创建',
    edit: '保存',
  },
  message: {
    createSuccess: '创建成功',
    editSuccess: '保存成功',
  },
  placeholder: {
    input: '请输入',
  },
  validate: {
    nameMust: '请输入名称',
    nameReg: '只能包含字母、数字、下划线，长度50以内',
    displayNameMust: '请输入展示名',
  },
};
