import t from '@/locales';
import { TCustomTimeValue } from '../common/CustomTimePicker/types';
import { getDateDynamicValue } from '../helper';
import { EDateGranularityType } from '@/typings';
import { EFilterType } from '../types';

const THREE_MINUS_ONE = 2;
const THIRTY_MINUS_ONE = 29;
const ONE_HUNDRED_EIGHTY_MINUS_ONE = 179;
const THREE_HUNDRED_SIXTY_FIVE_MINUS_ONE = 364;

export enum EMetricTimeKey {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_180_DAYS = 'LAST_180_DAYS',
  LAST_365_DAYS = 'LAST_365_DAYS',
  MTD = 'MTD',
  YTD = 'YTD',
  QTD = 'QTD',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_QUARTER = 'THIS_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
  LAST_3_YEARS = 'LAST_3_YEARS',
}

export const metricTimeSelectOptions: Array<{
  key: EMetricTimeKey;
  label: string;
  value: TCustomTimeValue;
}> = [
  {
    key: EMetricTimeKey.TODAY,
    label: t.components.filter.metricTimePicker.options.today,
    value: {
      type: EFilterType.DATE_SPECIFY,
      granularity: EDateGranularityType.DAY,
      values: getDateDynamicValue(EDateGranularityType.DAY, 0),
    },
  },
  {
    key: EMetricTimeKey.YESTERDAY,
    label: t.components.filter.metricTimePicker.options.yesterday,
    value: {
      type: EFilterType.DATE_SPECIFY,
      granularity: EDateGranularityType.DAY,
      values: getDateDynamicValue(EDateGranularityType.DAY, 1),
    },
  },
  {
    key: EMetricTimeKey.LAST_30_DAYS,
    label: t.components.filter.metricTimePicker.options.last30Days,
    value: {
      type: EFilterType.RECENT_N,
      granularity: EDateGranularityType.DAY,
      values: {
        min: getDateDynamicValue(EDateGranularityType.DAY, THIRTY_MINUS_ONE),
        max: getDateDynamicValue(EDateGranularityType.DAY, 0),
      },
    },
  },
  {
    key: EMetricTimeKey.LAST_180_DAYS,
    label: t.components.filter.metricTimePicker.options.last180Days,
    value: {
      type: EFilterType.RECENT_N,
      granularity: EDateGranularityType.DAY,
      values: {
        min: getDateDynamicValue(
          EDateGranularityType.DAY,
          ONE_HUNDRED_EIGHTY_MINUS_ONE,
        ),
        max: getDateDynamicValue(EDateGranularityType.DAY, 0),
      },
    },
  },
  {
    key: EMetricTimeKey.LAST_365_DAYS,
    label: t.components.filter.metricTimePicker.options.last365Days,
    value: {
      type: EFilterType.RECENT_N,
      granularity: EDateGranularityType.DAY,
      values: {
        min: getDateDynamicValue(
          EDateGranularityType.DAY,
          THREE_HUNDRED_SIXTY_FIVE_MINUS_ONE,
        ),
        max: getDateDynamicValue(EDateGranularityType.DAY, 0),
      },
    },
  },
  {
    key: EMetricTimeKey.MTD,
    label: t.components.filter.metricTimePicker.options.mtd,
    value: {
      type: EFilterType.MTD,
      granularity: EDateGranularityType.DAY,
      values: {
        min: getDateDynamicValue(EDateGranularityType.MONTH, 0),
        max: getDateDynamicValue(EDateGranularityType.DAY, 0),
      },
    },
  },
  {
    key: EMetricTimeKey.YTD,
    label: t.components.filter.metricTimePicker.options.ytd,
    value: {
      type: EFilterType.MTD,
      granularity: EDateGranularityType.DAY,
      values: {
        min: getDateDynamicValue(EDateGranularityType.YEAR, 0),
        max: getDateDynamicValue(EDateGranularityType.DAY, 0),
      },
    },
  },
  {
    key: EMetricTimeKey.QTD,
    label: t.components.filter.metricTimePicker.options.qtd,
    value: {
      type: EFilterType.MTD,
      granularity: EDateGranularityType.DAY,
      values: {
        min: getDateDynamicValue(EDateGranularityType.QUARTER, 0),
        max: getDateDynamicValue(EDateGranularityType.DAY, 0),
      },
    },
  },
  {
    key: EMetricTimeKey.THIS_WEEK,
    label: t.components.filter.metricTimePicker.options.thisWeek,
    value: {
      type: EFilterType.DATE_SPECIFY,
      granularity: EDateGranularityType.WEEK,
      values: getDateDynamicValue(EDateGranularityType.WEEK, 0),
    },
  },
  {
    key: EMetricTimeKey.THIS_MONTH,
    label: t.components.filter.metricTimePicker.options.thisMonth,
    value: {
      type: EFilterType.DATE_SPECIFY,
      granularity: EDateGranularityType.MONTH,
      values: getDateDynamicValue(EDateGranularityType.MONTH, 0),
    },
  },
  {
    key: EMetricTimeKey.THIS_QUARTER,
    label: t.components.filter.metricTimePicker.options.thisQuarter,
    value: {
      type: EFilterType.DATE_SPECIFY,
      granularity: EDateGranularityType.QUARTER,
      values: getDateDynamicValue(EDateGranularityType.QUARTER, 0),
    },
  },
  {
    key: EMetricTimeKey.THIS_YEAR,
    label: t.components.filter.metricTimePicker.options.thisYear,
    value: {
      type: EFilterType.DATE_SPECIFY,
      granularity: EDateGranularityType.YEAR,
      values: getDateDynamicValue(EDateGranularityType.YEAR, 0),
    },
  },
  {
    key: EMetricTimeKey.LAST_3_YEARS,
    label: t.components.filter.metricTimePicker.options.last3Years,
    value: {
      type: EFilterType.RECENT_N,
      granularity: EDateGranularityType.YEAR,
      values: {
        min: getDateDynamicValue(EDateGranularityType.YEAR, THREE_MINUS_ONE),
        max: getDateDynamicValue(EDateGranularityType.YEAR, 0),
      },
    },
  },
];

// 获取某个固有配置的metricTime配置
export const getGetMetricTimeConfig = (key: EMetricTimeKey) => {
  const config = metricTimeSelectOptions.find((item) => item.key === key);
  return config!;
};
