import ColumnDataTypeIcon from '@/components/ColumnDataTypeIcon';
import {
  ECompleteItemType,
  TAutoCompleteItem,
} from '@/components/Formula/functions/autoComplete';
import t from '@/locales';
import { ReactComponent as FxLine } from '@/assets/icon/fx-light-line.svg';
import { ReactComponent as TableDuotone } from '@/assets/icon/Data-sheet-line.svg';
import { ReactComponent as TurnLine } from '@/assets/icon/swap-light-line.svg';
import { ReactComponent as ReturnLine } from '@/assets/icon/Return-light-line.svg';
import { ReactComponent as StructureColumnLine } from '@/assets/icon/Structure-column-light-line.svg';
import { useKeyPress } from 'ahooks';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { functionHelperDescHref } from '../PromptTrigger';
import { ETokenType, IToken } from '../type';
import styles from './index.less';
import { TextLink } from '@aloudata/aloudata-design';
import { ReactComponent as NewWindowsIcon } from '@/assets/icon/Arrow-New-window-light-line.svg';
import { windowOpen } from '@/common/utils/windowOpen';
import { getNextToken, getTokenEnd } from '../helper/formulaHelper';
import { calculatePopupPosition } from '../helper';
export interface ISelPos {
  x: number;
  y: number;
}
// 获取当前页面上光标位置
interface IAutoCompleteBox {
  show: boolean;
  onClose: () => void;
  options: TAutoCompleteItem[];
  complete?: (
    complete: string,
    partial: string,
    from: number,
    to: number,
    type?: ETokenType,
  ) => void;
  selPos: ISelPos;
  tokenInSolPos: IToken | null;
  tokenList: IToken[];
}
const ICON_SIZE = 16;
const BOX_WIDTH = 580;
export const optionMap = {
  [ECompleteItemType.FUNCTION]: <FxLine size={ICON_SIZE} />,
  [ECompleteItemType.COLUMN]: <StructureColumnLine size={ICON_SIZE} />,
  [ECompleteItemType.TABLE]: <TableDuotone color="#858585" size={ICON_SIZE} />,
  [ECompleteItemType.FUNCTION_PARAM]: <FxLine size={ICON_SIZE} />,
};
const getItemIcon = (option: TAutoCompleteItem) => {
  if (option.type === ECompleteItemType.COLUMN) {
    return <ColumnDataTypeIcon dataType={option.dataType} size={ICON_SIZE} />;
  }
  return optionMap[option.type];
};
/**
 * 判断dom是否在可视区域内
 * @param top dom所在的父节点距离顶部的top值
 * @param dom 选中的dom
 */
const isDomInViewPort = (top: number, dom: Element) => {
  if (!dom) return false;
  const rect = dom.getBoundingClientRect();
  const clientHeight = document.querySelector(
    '#autoCompleteOptionsList',
  )?.clientHeight;
  if (!clientHeight) return false;
  return rect.top - top > 0 && rect.bottom - top < clientHeight;
};

const AutoCompleteList = ({
  show,
  options,
  complete,
  selPos,
  tokenInSolPos,
  tokenList,
}: IAutoCompleteBox) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    setSelectedIndex(0);
  }, [options]);

  const onComplete = (option: TAutoCompleteItem) => {
    if (option.type === ECompleteItemType.FUNCTION && tokenInSolPos) {
      const nextToken = getNextToken(tokenList, tokenInSolPos);

      if (nextToken && nextToken.type === ETokenType.LEFT_BRACKETS) {
        complete?.(
          option.complete,
          tokenInSolPos.text + nextToken.text,
          tokenInSolPos.offset,
          getTokenEnd(tokenInSolPos) + 1,
          ETokenType.FUNCTION_ID,
        );
        return;
      }
    }
    if (tokenInSolPos)
      complete?.(
        option.complete,
        option.partial,
        tokenInSolPos.offset,
        tokenInSolPos.offset + option.partial.length,
        option.type === ECompleteItemType.FUNCTION
          ? ETokenType.FUNCTION_ID
          : undefined,
      );
  };

  useKeyPress(['enter', 'tab'], () => {
    if (show && tokenInSolPos) {
      onComplete(options[selectedIndex]);
    }
    return;
  });
  useKeyPress('downarrow', () => {
    if (show) {
      const nextIndex =
        selectedIndex >= options.length - 1 ? 0 : selectedIndex + 1;
      setSelectedIndex(nextIndex);
      const thisDom = document.querySelector(`#autoCompleteOption${nextIndex}`);
      // 判断thisDom是否在可视区域内
      if (thisDom && !isDomInViewPort(selPos.y, thisDom)) {
        thisDom.scrollIntoView({ block: 'end', inline: 'nearest' });
      }
    }
  });
  useKeyPress('uparrow', () => {
    if (show) {
      const nextIndex =
        selectedIndex <= 0 ? options.length - 1 : selectedIndex - 1;
      setSelectedIndex(nextIndex);
      const thisDom = document.querySelector(`#autoCompleteOption${nextIndex}`);
      if (thisDom && !isDomInViewPort(selPos.y, thisDom)) {
        thisDom.scrollIntoView({ block: 'start', inline: 'nearest' });
      }
    }
  });
  if (options.length === 0) return null;
  const { x, y } = calculatePopupPosition(selPos.x, selPos.y, BOX_WIDTH);
  return (
    <div
      className={styles.autoCompleteBox}
      style={{
        display: show ? 'block' : 'none',
        left: x,
        top: y,
      }}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <div className={styles.optionList} id="autoCompleteOptionsList">
        {options.map((option, index) => (
          <div
            className={classNames(styles.optionItem, {
              [styles.selectedOption]: index === selectedIndex,
            })}
            title={option.label}
            key={index}
            onMouseDown={() => {
              onComplete(option);
            }}
            onMouseMoveCapture={() => {
              setSelectedIndex(index);
            }}
            id={'autoCompleteOption' + index}
          >
            <div className={styles.iconBox}>{getItemIcon(option)}</div>
            <div className={styles.nameBox}>{option.label}</div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <div className={styles.explainItem}>
            <TurnLine size={16} />
            {t.codeEditor.explain.turnPage}
          </div>
          <div className={styles.explainItem}>
            <ReturnLine size={16} />
            {t.codeEditor.explain.choose}
          </div>
        </div>
        <TextLink
          className={styles.footerRight}
          onClick={() => {
            windowOpen(functionHelperDescHref);
          }}
        >
          {t.codeEditor.explain.helper}
          <NewWindowsIcon size={16} />
        </TextLink>
      </div>
    </div>
  );
};
export default AutoCompleteList;
