export const datePart = {
  year: '年份',
  quarter: '季',
  month: '月份',
  day: '当月天数',
  hour: '小时',
  minute: '分钟数',
  second: '秒数',
  weeknum: '当前周数',
  weekday: '周几',
};

export const placeholder = '输入计算公式';

export const dataModel = {
  DSL: {
    source: '源',
  },
};

export const dashboard = {
  DSL: {
    metric: '指标',
  },
};

export const callOp = {
  datatrunc: {
    year: '年',
    quarter: '季',
    month: '月',
    week: '周',
    day: '日',
    hour: '小时',
    minute: '分钟',
    second: '秒',
  },
};

export const derivate = {
  error: {
    referenceError: '引用字段存在错误',
    constantError: '常量错误',
    binaryOperatorError: '二元操作符错误',
    unknownError: '公式类型错误',
    parserError: '公式解析错误',
  },
};

export const parse = {
  error: {
    columnNotFound: '不存在',
    columnNeedAggFn: '字段是n端表中的字段，外部需要嵌套聚合函数',
    isFilteredFnOnlyCanUseDimension: 'isFiltered函数内部只能是维度字段',
    dimensionOnlyCanUsedInIsFilteredFn: '维度字段只能被用于isFiltered函数内部',
    notFunction: '不是函数',
    functionNotExist: '函数不存在',
    functionNotNeedArgs: '函数不需要参数',
    functionArgsLengthError2: '函数参数个数错误',
    functionArgsTypeError: '函数参数类型错误',
  },
};

export const findEntity = {
  error: {
    noAuth: '无权限使用该字段',
  },
};
