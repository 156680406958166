import {
  ECommonFilterType,
  ECommonFilterValueType,
  EDateFilterType,
  EDateFilterValueType,
  EDateRangeType,
  EJSONFilterType,
  ENumberFilterType,
  ENumberFilterValueType,
  ENumberRangeOperator,
  IFilterItem,
  TBooleanFilter,
  TDateFilter,
  TDateFixedValue,
  TDateRangeType,
  TDateRangeValues,
  TFilterSelectValues,
  TJSONFilter,
  TNumberFilter,
  TNumberRangeValues,
  TTextFilter,
} from '@/components/Filter/filter';
import { DashboardIds } from '@/constants';
import { EColumnDataType } from '@/typings/index';
import _ from 'lodash';
import {
  ESpecialColumnValue,
  specialColumnValueMap,
} from '../../FilterDependencyValuesSelect/helper';

const initValue: TDateFixedValue = {
  value: null,
  type: EDateFilterValueType.FIXED,
};

export const initValues: TDateRangeValues = {
  type: EDateRangeType.RANGE,
  includeToday: true,
  values: {
    min: initValue,
    max: initValue,
  },
};

export function getDateDefaultFilter(): TDateFilter {
  return {
    type: EDateFilterType.RANGE,
    includeNull: false,
    values: initValues,
  };
}

export function getTextDefaultFilter(): TTextFilter {
  return {
    type: ECommonFilterType.EQUAL,
    values: [],
  };
}

export function getJsonDefaultFilter(): TJSONFilter {
  return {
    type: EJSONFilterType.CONTAIN,
    values: '',
  };
}

export function getNumberDefaultFilter(): TNumberFilter {
  return {
    type: ENumberFilterType.RANGE,
    values: {
      low: {
        type: ENumberFilterValueType.NUMBER,
        operator: ENumberRangeOperator.LESSOREQUAL,
        value: undefined,
      },
      high: {
        type: ENumberFilterValueType.NUMBER,
        operator: ENumberRangeOperator.LESSOREQUAL,
        value: undefined,
      },
    },
  };
}

export function getBooleanDefaultFilter(): TBooleanFilter {
  return {
    type: ECommonFilterType.EQUAL,
    values: [],
  };
}

export function getDefaultFilter(type: EColumnDataType) {
  switch (type) {
    case EColumnDataType.INT:
    case EColumnDataType.DECIMAL:
    case EColumnDataType.DOUBLE:
      return getNumberDefaultFilter();
    case EColumnDataType.BOOLEAN:
      return getBooleanDefaultFilter();
    case EColumnDataType.DATE_TIME:
    case EColumnDataType.DATE:
      return getDateDefaultFilter();
    case EColumnDataType.TEXT:
      return getTextDefaultFilter();
    case EColumnDataType.JSON:
      return getJsonDefaultFilter();
    default:
      return getTextDefaultFilter();
  }
}

export function transformValues(values: TFilterSelectValues) {
  const result: string[] = [];
  if (!values) return result;
  values.map((item) => {
    if (item.type === ECommonFilterValueType.NULL) {
      result.push(specialColumnValueMap[ESpecialColumnValue.NULL].value);
    } else if (item.type === ECommonFilterValueType.EMPTY) {
      result.push(specialColumnValueMap[ESpecialColumnValue.EMPTY].value);
    } else {
      result.push(String(item.value));
    }
  });
  return result;
}

const getDomId = (elementId: string) => DashboardIds.toolTip + '_' + elementId;

const getPosition = (domId: string) => {
  const thisDom = document.querySelector('#' + domId);
  if (!thisDom) return { x: 0, y: 0 };
  const { x, y, width, height } = thisDom.getBoundingClientRect();

  return {
    x: x + width,
    y: y + height,
  };
};

export const getTooltipPosition = _.flowRight(getPosition, getDomId);

// export function checkIsInnerModel(
//   document: IDashboardDocument,
//   sheetId: string,
// ) {
//   return document.dependencies[sheetId].type === EDependencyType.INNER_MODEL;
// }

export function checkIsNeedQuery(filter: IFilterItem): boolean {
  // 如果是等于/不等于/包含/不包含，且没有选择值的，不需要重新发请求
  if (!filter.filter.values) return false;
  // 判断是否是日期的默认筛选
  const dateFilterValues = filter.filter as TDateRangeType;
  const isDateDefaultValue = _.isEqual(
    getDateDefaultFilter(),
    dateFilterValues,
  );
  // 如果是时间筛选器的默认值，删除时不需要重新发请求
  if (dateFilterValues && isDateDefaultValue) return false;
  // 判断是否是数字的默认筛选
  const numberFilterValues = filter.filter as TNumberRangeValues;
  const isNumberDefaultValue = _.isEqual(
    getNumberDefaultFilter(),
    numberFilterValues,
  );
  // 如果是数值筛选器的默认值，删除时不需要重新发请求
  return !(numberFilterValues && isNumberDefaultValue);
}

// export function isTopNOrBottomNFilter(type: string): boolean {
//   return (
//     type === ECommonFilterType.TOP_N ||
//     type === ECommonFilterType.BOTTOM_N ||
//     type === ECommonFilterType.TOP_N_PERCENT ||
//     type === ECommonFilterType.BOTTOM_N_PERCENT
//   );
// }

export function getFilterSelectKey(key: string, type: ECommonFilterType) {
  return key + type;
}
