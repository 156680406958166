import t from '@/locales';
import {
  EFormulaType,
  EOriginDataType,
  IFunctionOperator,
} from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
import { isArgsPositiveIntegerFn } from './lag';
const checkNth = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const ARGS_LENGTH = 2;
  const { op, args, ast } = params;
  if (args.length !== ARGS_LENGTH) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }
  if (ast.args[0].type !== EFormulaType.COLUMN) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }
  if (!isArgsPositiveIntegerFn(ast.args[1])) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }
  return {
    type: ECheckResultType.ORIGIN_DATA_TYPE,
    originDataType: args[0],
  };
};
export default checkNth;
