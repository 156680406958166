import { metricSimpleTpl } from '../metric/gql';

export const monitorConfig = `#graphql
  id
  displayName
  metric {
    ${metricSimpleTpl},
  }
  dimensionSplit
  execCron
  ruleConfig
  pushConfig
  layout
  monitorLevel
  status
  authority {
    canCreate
    canEdit
    canDelete
    canUsage
    canAuth
    canMove
    authRoleList
  }
`;

export const monitorResult = `#graphql
  id
  metricMonitorConfig {
    ${monitorConfig}
  }
  execTime
  monitorTime
  resultData
  resultStatus
  resultMsgs
  owner {
    userId
    account
    nickname
    photo
  }
`;
