// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

  let result = {};

  const reset = () => {
    result = {};
  };

  const suggestFunction = ({ partialText, value }) => {
    result.suggestFunction = {
      partialText,
      value
    };
  };

  const suggestColumn = ({ partialText, table, value, model }) => {
    const res = {
      partialText,
      table, 
      value, 
      model
    }
    if (!model) {
      Reflect.deleteProperty(res, 'model');
    }
    if (!table) {
      Reflect.deleteProperty(res, 'table');
    }
    result.suggestColumn = res;
  };

  const suggestTable = ({ partialText, value, model }) => {
    if (model) {
      result.suggestTable = {
        partialText,
        value,
        model
      };
    } else {
      result.suggestTable = {
        partialText,
        value
      };
    }
  };

  const suggestModel = ({ partialText, value }) => {
    result.suggestModel = {
      partialText,
      value
    };
  };

  const suggestFunctionParam = ({
    partialText,
    functionName,
    index,
    value
  }) => {
       if(result.suggestFunctionParam) return ;
    result.suggestFunctionParam = {
      partialText,
      functionName,
      index,
      value
    };
  };

  const suggestFunctionDesc = ({ index, functionName }) => {
    // 如果已存在证明是函数嵌套，则以最内部为准
    if (result.suggestFunctionDesc) return;
    result.suggestFunctionDesc = {
      index,
      functionName,
    };
  };


  const insensitive = require('../insensitive');
  const split = require('../split');

  const moo = require('moo');
  let lexer = moo.compile({
    ws: { match: /\s+/, lineBreaks: true },
    string: {
      match: /(?:"(?:\\["\\]|[^\n"\\])*")/,
      value: (s) => {
        return s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
      },
    },
    partialString: {
      match: /(?:"(?:\\["\\]|[^\n"\\\|])*)/,
      value: (s) => {
        return s.slice(1, s.length).replace(/\\"/g, '"').replace(/\\\\/g, '\\')
      }
    },
   number: /(?:-?(?:[0-9]|[1-9][0-9]+)(?:\.[0-9]+)?(?:[eE][-+]?[0-9]+)?\b)/,
    true: insensitive(/true/),
    false: insensitive(/false/),
    column: {
      match: /\[[^\[\]]+\]/,
      value: (s) => {
        return split(s.slice(1, -1).replace(/\\]/g, "]").replace(/\\\\/g, '\\'), '/').map(item => {
            return item.replace(/\\\//g, "/").trim();
          });
      }
    },
    partialColumnName: {
      match: /\[[^\[\]\|]*/,
      value: (s) => {
        return split(s.slice(1, s.length).replace(/\\]/g, "]").replace(/\\\\/g, '\\'), '/').map(item => {
          return item.replace(/\\\//g, "/").trim();
        });
      }
    },
    '+': '+',
    '-': '-',
    '*': '*',
    '<': '<',
    '>': '>',
    and: insensitive(/and/),
    or: insensitive(/or/),
    cursor: '|',
    quot: /['"]/,
    functionId: /[a-zA-Z\u4e00-\u9fa50-9_]+/,
    '(': '(',
    ')': ')',
    ',': ',',
    '[': '[',
    ']': ']',
    '&&': '&&',
    '||': '||',
    default: /[\S+]/,
  });
var grammar = {
    Lexer: lexer,
    ParserRules: [
    {"name": "start", "symbols": ["formula"], "postprocess": () => { const res = {...result }; reset(); return res; }},
    {"name": "formula", "symbols": ["expression_edit"]},
    {"name": "function", "symbols": [(lexer.has("functionId") ? {type: "functionId"} : functionId), {"literal":"("}, "params", {"literal":")"}]},
    {"name": "function_edit", "symbols": [(lexer.has("functionId") ? {type: "functionId"} : functionId), "cursor"], "postprocess":  
        (d, l) => { 
          suggestFunction({ partialText: d[0].text, value: d[0].value }); 
          suggestTable({ partialText: d[0].text, value: d[0].value }); 
          suggestColumn({ partialText: d[0].text, value: d[0].value });
          suggestModel({ partialText: d[0].text, value: d[0].value });
          return d[0];
        } 
            },
    {"name": "function_edit", "symbols": [(lexer.has("functionId") ? {type: "functionId"} : functionId), "_", {"literal":"("}, "_", "cursor"], "postprocess":  
        (d, l) => { 
          suggestFunctionDesc({ index: 0, functionName: d[0].value }); 
          return d[4];
        } 
              },
    {"name": "function_edit", "symbols": [(lexer.has("functionId") ? {type: "functionId"} : functionId), {"literal":"("}, "_", "params", "_", {"literal":","}, "_", "expression_edit"], "postprocess":  
        (d, l) => { 
          suggestFunctionParam({ index: d[3], functionName: d[0].value, partialText: d[7].text, value: d[7].value });
          return d[7];
        } 
              },
    {"name": "function_edit", "symbols": [(lexer.has("functionId") ? {type: "functionId"} : functionId), {"literal":"("}, "_", "expression_edit"], "postprocess":  
        (d, l) => { 
          suggestFunctionParam({ index: 0, functionName: d[0].value, partialText: d[3].text, value: d[3].value });
          return d[3];
        } 
              },
    {"name": "function_edit", "symbols": [(lexer.has("functionId") ? {type: "functionId"} : functionId), {"literal":"("}, "_", "params", "_", {"literal":","}, "_", "cursor"], "postprocess":  
        (d, l) => { 
          suggestFunctionDesc({ index: d[3], functionName: d[0].value });
          return d[7];
        }  
              },
    {"name": "expression", "symbols": ["binary"]},
    {"name": "expression_edit", "symbols": ["binary_edit"], "postprocess": id},
    {"name": "parentheses_edit", "symbols": [{"literal":"("}, "_", "binary_edit"]},
    {"name": "parentheses_edit", "symbols": ["calcUnit_edit"], "postprocess": id},
    {"name": "binary_edit", "symbols": ["binary", "_", "operator", "_", "parentheses_edit"], "postprocess": (d) => (d[2])},
    {"name": "binary_edit", "symbols": ["parentheses_edit"], "postprocess": id},
    {"name": "parentheses", "symbols": [{"literal":"("}, "_", "binary", "_", {"literal":")"}]},
    {"name": "parentheses", "symbols": ["calcUnit"]},
    {"name": "binary", "symbols": ["binary", "_", "operator", "_", "parentheses"]},
    {"name": "binary", "symbols": ["parentheses"]},
    {"name": "operator", "symbols": [{"literal":"+"}]},
    {"name": "operator", "symbols": [{"literal":"-"}]},
    {"name": "operator", "symbols": [{"literal":"*"}]},
    {"name": "operator", "symbols": [{"literal":"/"}]},
    {"name": "operator", "symbols": [{"literal":"<"}]},
    {"name": "operator", "symbols": [{"literal":">"}]},
    {"name": "operator", "symbols": [{"literal":">"}, {"literal":"="}]},
    {"name": "operator", "symbols": [{"literal":"<"}, {"literal":"="}]},
    {"name": "operator", "symbols": [{"literal":"<"}, {"literal":">"}]},
    {"name": "operator", "symbols": [{"literal":"="}]},
    {"name": "operator", "symbols": [(lexer.has("and") ? {type: "and"} : and)]},
    {"name": "operator", "symbols": [(lexer.has("or") ? {type: "or"} : or)]},
    {"name": "operator", "symbols": [{"literal":"&&"}]},
    {"name": "operator", "symbols": [{"literal":"||"}]},
    {"name": "params", "symbols": ["expression"], "postprocess": (d) => 1},
    {"name": "params", "symbols": ["params", "_", {"literal":","}, "_", "expression"], "postprocess": (d) => { return d[0] + 1 }},
    {"name": "calcUnit", "symbols": ["constant"]},
    {"name": "calcUnit", "symbols": ["column"]},
    {"name": "calcUnit", "symbols": ["function"]},
    {"name": "calcUnit_edit", "symbols": ["function_edit"], "postprocess": id},
    {"name": "calcUnit_edit", "symbols": ["column_edit"], "postprocess": id},
    {"name": "calcUnit_edit", "symbols": ["string_edit"], "postprocess": id},
    {"name": "calcUnit_edit", "symbols": ["number_edit"], "postprocess": id},
    {"name": "column", "symbols": [(lexer.has("column") ? {type: "column"} : column)], "postprocess": id},
    {"name": "string_edit", "symbols": [(lexer.has("partialString") ? {type: "partialString"} : partialString), "cursor"], "postprocess": (d) => ({ ...d[0], value: d[0].value })},
    {"name": "number_edit", "symbols": [(lexer.has("number") ? {type: "number"} : number), "cursor"], "postprocess": (d) => { 
         suggestColumn({ partialText: d[0].text, value: d[0].value });
        } },
    {"name": "column_edit", "symbols": [(lexer.has("partialColumnName") ? {type: "partialColumnName"} : partialColumnName), "cursor"], "postprocess":  
        (d, l) => {
          if (d[0].value.length === 1) {
            suggestColumn({ partialText: d[0].text, value: d[0].value[0] });
            suggestModel({ partialText: d[0].text, value: d[0].value[0] });
            suggestTable({ partialText: d[0].text, value: d[0].value[0] });
          }
          if (d[0].value.length === 2) {
            suggestColumn({ partialText: d[0].text, value: d[0].value[1], table: d[0].value[0] });
            suggestTable({ partialText: d[0].text, value: d[0].value[1], model: d[0].value[0] });
          }
          if (d[0].value.length === 3) {
            suggestColumn({ partialText: d[0].text, value: d[0].value[2], table: d[0].value[1], model: d[0].value[0] });
          }
          return {
            ...d[0],
            value: d[0].value.join('/'),
          };
        }
          },
    {"name": "column_edit", "symbols": ["column", "cursor"], "postprocess": 
        (d) => {
          if (d[0].value.length === 1) {
            suggestColumn({ partialText: d[0].text, value: d[0].value[0] });
          }
          if (d[0].value.length === 2) {
            suggestColumn({ partialText: d[0].text, value: d[0].value[1], table: d[0].value[0] });
          }
          if (d[0].value.length === 3) {
            suggestColumn({ partialText: d[0].text, value: d[0].value[2], table: d[0].value[1], model: d[0].value[0] });
          }
        }
          },
    {"name": "cursor", "symbols": [(lexer.has("cursor") ? {type: "cursor"} : cursor)]},
    {"name": "constant", "symbols": ["number"]},
    {"name": "constant", "symbols": ["string"]},
    {"name": "constant", "symbols": ["true"]},
    {"name": "constant", "symbols": ["false"]},
    {"name": "constant", "symbols": ["_null"]},
    {"name": "constant", "symbols": ["unitaryOperator"], "postprocess": id},
    {"name": "unitaryOperator", "symbols": [{"literal":"-"}, "_", "number"], "postprocess": (d) => ({ type: 'UN_OP', op: '-', x: d[2] })},
    {"name": "number", "symbols": [(lexer.has("number") ? {type: "number"} : number)]},
    {"name": "true", "symbols": [(lexer.has("true") ? {type: "true"} : true)]},
    {"name": "false", "symbols": [(lexer.has("false") ? {type: "false"} : false)]},
    {"name": "string", "symbols": [(lexer.has("string") ? {type: "string"} : string)]},
    {"name": "_null", "symbols": [(lexer.has("_null") ? {type: "_null"} : _null)]},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", (lexer.has("ws") ? {type: "ws"} : ws)], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"]}
]
  , ParserStart: "start"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
