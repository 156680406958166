export const role = {
  admin: '管理员',
  owner: '负责人',
  viewer: '查看者',
  analyst: '数据管理员',
  usager: '使用者',
  revoke: '撤销授权',
};

export const auth = {
  noAuth: '无权限',
  applyAuth: '申请权限',
  add: '添加授权',
  authority: {
    default: '授权',
    success: '授权成功',
  },
  notExisted: {
    default: '抱歉，当前资源不存在',
    des: '当前资源可能已经被删除。',
  },
  noPermission: {
    default: '抱歉，您无权访问此页面',
    des: '请与组织管理员联系，获取访问权限。',
  },
  noDatasetPermission: ({ name }: { name: string | number }) =>
    `无权限：数据负责人${name}没有该数据集的使用权限，该维度不可用`,
  list: {
    name: ({ number }: { number: string | number }) => `${number}个用户`,
  },
  goBack: '返回',
};

// 集中授权页面
export const centralizedAuth = {
  title: '集中授权',
  authByUser: '按用户授权',
  authByResource: '按资源授权',
  useAuth: '使用权限',
  rowFilterAuth: '行级权限',
  resource: '资源',
  user: '用户',
  userGroup: '用户组',
  addUser: '添加用户',
  resourceType: {
    metric: '指标',
    dimension: '维度',
    dataset: '数据集',
    analysisView: '指标视图',
    datasource: '数据源',
  },
  accountType: {
    user: '用户',
    userGroup: '用户组',
  },
  empty: (typeName: string) =>
    `当前页面没有任何内容，请选择一个${typeName}以查看`,
  exception: '发生了错误，请刷新页面重试',
  operation: '操作',
  remove: '移除',
  ownerWarning: '不能修改负责人的权限',
  selfWarning: '不能修改自己的权限',
  addUserSuccess: '添加成功',
  removeUserSuccess: '移除成功',
};
