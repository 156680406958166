import { EColumnDataType, EDateGranularityType } from '@/typings/index';

import { ReactComponent as BooleanLine } from '@/assets/icon/boolean-fields-line.svg';
import { ReactComponent as DateLine } from '@/assets/icon/date-fields-line.svg';
import { ReactComponent as DatetimeLine } from '@/assets/icon/datetime-fields-line.svg';
import { ReactComponent as DecimalLine } from '@/assets/icon/decimal-fields-line.svg';
import { ReactComponent as DoubleLine } from '@/assets/icon/double-fields-line.svg';
import { ReactComponent as FieldTextLine } from '@/assets/icon/text-fields-line.svg';
import { ReactComponent as JsonExtractLine } from '@/assets/icon/json-fields-line.svg';
import { ReactComponent as NumbersLine } from '@/assets/icon/numbers-fields-line.svg';

import { ReactComponent as DateYear } from '@/assets/icon/date-year-fields-line.svg';
import { ReactComponent as DateQuarter } from '@/assets/icon/date-quarter-fields-line.svg';
import { ReactComponent as DateMonth } from '@/assets/icon/date-month-fields-line.svg';
import { ReactComponent as DateWeek } from '@/assets/icon/date-week-fields-line.svg';
import { ReactComponent as DateDay } from '@/assets/icon/date-day-fields-line.svg';
import { ReactComponent as TimeHour } from '@/assets/icon/time-hour-fields-line.svg';
import { ReactComponent as TimeMinute } from '@/assets/icon/time-minute-fields-line.svg';
import { ReactComponent as TimeSecond } from '@/assets/icon/time-second-fields-line.svg';

interface IProps {
  type: EColumnDataType;
  className?: string;
  size?: number;
  color?: string;
  granularity?: EDateGranularityType;
}

const getGranularityIcon = (
  granularity: EDateGranularityType,
  props: {
    size: number;
    className: string | undefined;
    color: string | undefined;
  },
) => {
  switch (granularity) {
    case EDateGranularityType.YEAR:
      return <DateYear {...props} />;
    case EDateGranularityType.QUARTER:
      return <DateQuarter {...props} />;
    case EDateGranularityType.MONTH:
      return <DateMonth {...props} />;
    case EDateGranularityType.WEEK:
      return <DateWeek {...props} />;
    case EDateGranularityType.DAY:
      return <DateDay {...props} />;
    case EDateGranularityType.HOUR:
      return <TimeHour {...props} />;
    case EDateGranularityType.MINUTE:
      return <TimeMinute {...props} />;
    case EDateGranularityType.SECOND:
      return <TimeSecond {...props} />;
    default:
      return null;
  }
};

const ICON_DEFAULT_SIZE = 20;

const ColumnIcon = (props: IProps) => {
  const {
    type,
    className,
    size = ICON_DEFAULT_SIZE,
    color,
    granularity,
  } = props;

  const iconProps = {
    size,
    className,
    color,
  };
  switch (type) {
    case EColumnDataType.TEXT:
      return <FieldTextLine {...iconProps} />;
    case EColumnDataType.INT:
      return <NumbersLine {...iconProps} />;
    case EColumnDataType.DECIMAL:
      return <DecimalLine {...iconProps} />;
    case EColumnDataType.DOUBLE:
      return <DoubleLine {...iconProps} />;
    case EColumnDataType.DATE:
      if (granularity && granularity !== EDateGranularityType.AUTO) {
        return getGranularityIcon(granularity, iconProps);
      }
      return <DateLine {...iconProps} />;
    case EColumnDataType.DATE_TIME:
      if (granularity && granularity !== EDateGranularityType.AUTO) {
        return getGranularityIcon(granularity, iconProps);
      }
      return <DatetimeLine {...iconProps} />;
    case EColumnDataType.BOOLEAN:
      return <BooleanLine {...iconProps} />;
    case EColumnDataType.JSON:
      return <JsonExtractLine {...iconProps} />;
    default:
      return null;
  }
};

export default ColumnIcon;
