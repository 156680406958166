import { originDataType2DataTypeMap } from '@/components/Formula/constant';
import {
  ELooseDataType,
  looseDataTypeMap,
  looseDataTypeMatch,
} from '@/components/Formula/helper';
import t from '@/locales';
import { EOriginDataType, IFunctionOperator } from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
const checkIf = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { op, args } = params;
  const IF_ARGS_MIN_LENGTH = 2;
  if (args.length < IF_ARGS_MIN_LENGTH) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }
  const originDataTypeArr: EOriginDataType[] = [];
  if (
    args.some((item, index) => {
      const evenNumber = 2;
      if ((index + 1) % evenNumber === 0) {
        originDataTypeArr.push(item);
        return true;
      }
      if ((index + 1) % evenNumber === 1) {
        if (index === args.length - 1) {
          originDataTypeArr.push(item);
          return true;
        }
        if (item === EOriginDataType.BOOLEAN) {
          return false;
        } else {
          return true;
        }
      }
    })
  ) {
    if (
      !looseDataTypeMatch(
        originDataTypeArr.map((item) => originDataType2DataTypeMap[item]),
      )
    ) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
  }
  const looseDataType =
    looseDataTypeMap[originDataType2DataTypeMap[originDataTypeArr[0]]];
  if (looseDataType === ELooseDataType.TEXT) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.VARCHAR,
    };
  }
  if (looseDataType === ELooseDataType.JSON) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.STRUCT,
    };
  }
  if (looseDataType === ELooseDataType.BOOLEAN) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.BOOLEAN,
    };
  }
  if (looseDataType === ELooseDataType.NUMBER) {
    let returnOriginDataType = EOriginDataType.DOUBLE;
    if (originDataTypeArr.includes(EOriginDataType.DOUBLE)) {
      returnOriginDataType = EOriginDataType.DOUBLE;
    }
    if (originDataTypeArr.includes(EOriginDataType.FLOAT)) {
      returnOriginDataType = EOriginDataType.FLOAT;
    }
    if (originDataTypeArr.includes(EOriginDataType.DECIMAL)) {
      returnOriginDataType = EOriginDataType.DECIMAL;
    }
    if (originDataTypeArr.includes(EOriginDataType.BIGINT)) {
      returnOriginDataType = EOriginDataType.BIGINT;
    }
    if (originDataTypeArr.includes(EOriginDataType.SMALLINT)) {
      returnOriginDataType = EOriginDataType.SMALLINT;
    }
    if (originDataTypeArr.includes(EOriginDataType.INTEGER)) {
      returnOriginDataType = EOriginDataType.INTEGER;
    }
    if (originDataTypeArr.includes(EOriginDataType.INT)) {
      returnOriginDataType = EOriginDataType.INT;
    }
    if (originDataTypeArr.includes(EOriginDataType.BIGINT)) {
      returnOriginDataType = EOriginDataType.BIGINT;
    }
    if (originDataTypeArr.includes(EOriginDataType.DECIMAL)) {
      returnOriginDataType = EOriginDataType.DECIMAL;
    }
    if (originDataTypeArr.includes(EOriginDataType.FLOAT)) {
      returnOriginDataType = EOriginDataType.FLOAT;
    }
    if (originDataTypeArr.includes(EOriginDataType.DOUBLE)) {
      returnOriginDataType = EOriginDataType.DOUBLE;
    }
    if (
      originDataTypeArr.includes(EOriginDataType.FLOAT) &&
      originDataTypeArr.includes(EOriginDataType.DECIMAL)
    ) {
      returnOriginDataType = EOriginDataType.DOUBLE;
    }
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: returnOriginDataType,
    };
  }
  if (looseDataType === ELooseDataType.TIME) {
    if (originDataTypeArr.some((item) => item !== EOriginDataType.DATE)) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.TIMESTAMP,
      };
    } else {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: EOriginDataType.DATE,
      };
    }
  }
  return {
    type: ECheckResultType.ERROR,
    errorMsg: `${op}${t.formula.derivate.error.unknownError}`,
  };
};
export default checkIf;
