import { useQueryDimensionList } from '@/services/dimension';
import PureDimensionList, {
  IPureDimensionListProps,
} from './PureDimensionList';
import { useMount } from 'ahooks';
import { ALL_DIMENSION_LENGTH } from '@/constants';
import { IDimension } from '@/typings/dimension';
import { useGetCategoryList } from '@/services/category';
import { ECategoryType, ICategory } from '@/typings/category';
import { forwardRef } from 'react';
import { IDimensionPickerRef } from './type';

export interface IProps
  extends Omit<IPureDimensionListProps, 'dimensions' | 'categories'> {
  dimensions?: IDimension[] | null;
  categories?: ICategory[];
}

const DimensionList = forwardRef(
  (props: IProps, ref: React.ForwardedRef<IDimensionPickerRef>) => {
    const { loading: loadingFromProps, dimensions } = props;
    const {
      run: queryDimensionList,
      data,
      loading: isDimensionsLoading,
    } = useQueryDimensionList();

    const { data: categoryList, loading: isLoadingCategoryData } =
      useGetCategoryList({ categoryType: ECategoryType.CATEGORY_DIMENSION });

    useMount(() => {
      if (dimensions) {
        return;
      }
      queryDimensionList({
        request: { pager: { pageSize: ALL_DIMENSION_LENGTH, pageNumber: 1 } },
      });
    });

    const isLoading =
      loadingFromProps || isDimensionsLoading || isLoadingCategoryData;
    const dimensionList = dimensions || data?.queryDimensionList.data;

    return (
      <PureDimensionList
        {...props}
        dimensions={dimensionList || []}
        categories={categoryList || []}
        loading={isLoading}
        ref={ref}
      />
    );
  },
);

export default DimensionList;
