export const column = {
  dataType: {
    boolean: '布尔',
    int: '整数',
    double: '小数',
    decimal: '高精度小数',
    dateTime: '日期时间',
    date: '日期',
    text: '文本',
    json: 'JSON',
  },
};

export const tree = {
  name: {
    duplicate: '名称租户内唯一',
  },
  node: {
    error: {
      add: '新增节点失败，请刷新页面',
      update: '更新节点失败，请刷新页面',
      delete: '删除节点失败，请刷新页面',
      move: '移动节点失败，请刷新页面',
    },
  },
};
