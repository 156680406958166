import saveHideSideToMemory from './saveHideSideToMemory';
import saveTokenToMemory from './saveTokenToMemory';

export default function saveThirdEnvToMemory(thirdInfo: {
  token?: string | null;
  hideNav?: boolean;
}) {
  saveHideSideToMemory(thirdInfo);
  saveTokenToMemory(thirdInfo);
}
