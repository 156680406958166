import { IUser } from '.';
import { EUserRole } from './authority';

export interface IAssetPropertyBase {
  // 名称
  name: string;
  // 名称
  displayName: string;
  // 区分 dimension、metric
  type: EAssetPropertyType;
  // 描述
  description?: string;
  // 是否必填
  isRequired: boolean;
  // 新建时是否可见
  isCreatingDisplayed: boolean;
  // 属性分类
  propertyType: EPropertyType;
  // 前一个属性定义的名称, 第一个为空
  frontDef?: string;
  // 负责人
  owner: IOwner;
  // 是否启用
  status: EEnableStatus;
  dataInput: TDataInput;
}

export interface ITextDataInput {
  dataType: EDataType.TEXT;
  inputType: EInputType.TEXT;
}

export interface ILongTextDataInput {
  dataType: EDataType.TEXT;
  inputType: EInputType.LONG_TEXT;
}

export interface ISelectDataInputOption {
  value: string;
  isDefault?: boolean;
  internalValue?: string; // 系统内置属性值，跟value一一对应
}

export interface ISelectDataInput {
  dataType: EDataType.TEXT;
  inputType: EInputType.SELECT;
  options: ISelectDataInputOption[];
}

export interface IUserSelectDataInput {
  dataType: EDataType.USER;
  inputType: EInputType.SELECT;
  useDefaultOwner: boolean;
}

export interface IUserGroupSelectDataInput {
  dataType: EDataType.USER_GROUP;
  inputType: EInputType.SELECT;
  useDefaultOwner: boolean;
}

export interface ICategoryMetricDataInput {
  dataType: EDataType.CATEGORY_METRIC;
  inputType: EInputType.SELECT;
}

export interface ICategoryDimensionDataInput {
  dataType: EDataType.CATEGORY_DIMENSION;
  inputType: EInputType.SELECT;
}

export interface ITimestampDataInput {
  dataType: EDataType.TIMESTAMP;
  inputType: EInputType.TEXT;
}

export interface ICurrencyUnitDataInput {
  dataType: EDataType.CURRENCY_UNIT;
  inputType: EInputType.SELECT;
}

export type TDataInput =
  | ITextDataInput
  | ILongTextDataInput
  | ISelectDataInput
  | IUserSelectDataInput
  | IUserGroupSelectDataInput
  | ICategoryMetricDataInput
  | ICategoryDimensionDataInput
  | ITimestampDataInput
  | ICurrencyUnitDataInput;

export interface IAssetPropertyText extends IAssetPropertyBase {
  dataInput: ITextDataInput;
}

export interface IAssetPropertyBaseLongText extends IAssetPropertyBase {
  dataInput: ILongTextDataInput;
}

export interface IAssetPropertySelect extends IAssetPropertyBase {
  dataInput: ISelectDataInput;
}

export interface IAssetPropertySelectUser extends IAssetPropertyBase {
  dataInput: IUserSelectDataInput;
  bindRole: EUserRole | null; // 绑定的实体角色
}

export interface IAssetPropertySelectUserGroup extends IAssetPropertyBase {
  dataInput: IUserGroupSelectDataInput;
  bindRole: EUserRole | null; // 绑定的实体角色
}

/**
 * 和用户相关的自定义属性
 */
export type TUserAssociatedAssetProperty =
  | IAssetPropertySelectUser
  | IAssetPropertySelectUserGroup;

export interface IAssetPropertyCategoryMetric extends IAssetPropertyBase {
  dataInput: ICategoryMetricDataInput;
}

export interface IAssetPropertyCategoryDimension extends IAssetPropertyBase {
  dataInput: ICategoryDimensionDataInput;
}

export interface IAssetPropertyTimestamp extends IAssetPropertyBase {
  dataInput: ITimestampDataInput;
}

export interface IAssetPropertyCurrencyUnit extends IAssetPropertyBase {
  dataInput: ICurrencyUnitDataInput;
}

export interface IOwner {
  type: EOwnerType;
  owner?: IUser;
}

export enum EOwnerType {
  // 系统
  SYSTEM = 'SYSTEM',
  // 用户
  USER = 'USER',
}

export enum EDataType {
  TEXT = 'TEXT',
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
  CATEGORY_METRIC = 'CATEGORY_METRIC',
  CATEGORY_DIMENSION = 'CATEGORY_DIMENSION',
  TIMESTAMP = 'TIMESTAMP',
  CURRENCY_UNIT = 'CURRENCY_UNIT',
}

export enum EInputType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  LONG_TEXT = 'LONG_TEXT',
  SELECT_INTERNAL = 'SELECT_INTERNAL',
}

export enum EEnableStatus {
  FORCE_OPEN = 'FORCE_OPEN',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export enum EAssetPropertyType {
  // 维度
  DIMENSION = 'DIMENSION',
  // 指标
  METRIC = 'METRIC',
}

export enum EPropertyType {
  // 基础属性
  BASE = 'BASE',
  // 业务属性
  BUSINESS = 'BUSINESS',
  // 管理属性
  MANAGE = 'MANAGE',
  // 技术属性
  TECHNOLOGY = 'TECHNOLOGY',
}

export type TAssetProperty =
  | IAssetPropertyText
  | IAssetPropertyBaseLongText
  | IAssetPropertySelect
  | IAssetPropertySelectUser
  | IAssetPropertySelectUserGroup
  | IAssetPropertyCategoryMetric
  | IAssetPropertyCategoryDimension
  | IAssetPropertyTimestamp
  | IAssetPropertyCurrencyUnit;
