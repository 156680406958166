import { ReactComponent as YearIcon } from '@/assets/icon/date-year-fields-line.svg';
import { ReactComponent as QuarterIcon } from '@/assets/icon/date-quarter-fields-line.svg';
import { ReactComponent as MonthIcon } from '@/assets/icon/date-month-fields-line.svg';
import { ReactComponent as WeekIcon } from '@/assets/icon/date-week-fields-line.svg';
import { ReactComponent as DayIcon } from '@/assets/icon/date-day-fields-line.svg';
import { ReactComponent as HourIcon } from '@/assets/icon/time-hour-fields-line.svg';
import { ReactComponent as MinuteIcon } from '@/assets/icon/time-minute-fields-line.svg';
import { ReactComponent as SecondIcon } from '@/assets/icon/time-second-fields-line.svg';
import { ReactComponent as FolderSvg } from '@/assets/icon/Folder-light-line.svg';
import { METRIC_TIME, metricTimeColumn } from '@/constants';
import _ from 'lodash';
import { ITreeNode, type IPickerDimension } from './type';
import { ICategoryTreeNode } from '@/pages/Manage/CategoryManage/categoryHelper';
import { getCategoryId, isMetricTimeDimension } from '@/common/utils';
import { EDateGranularityType } from '@/typings';
import dateTimeMap from '@/constants/dateTime';
import t from '@/locales';
import { ALL_DIMENSION_CATEGORY_KEY } from './constants';
import {
  IDimension,
  TMetricCodeAvailableDimensionsMap,
} from '@/typings/dimension';

export function getAvailableDimensionMetricMapping(
  metrics: Array<{ code: string }>,
  availableDimensionsMap: TMetricCodeAvailableDimensionsMap,
) {
  const dimension: Record<string, Set<string>> = {};
  const metric: Record<string, Set<string>> = {};
  // 指标日期维度可以筛选所有指标
  const metricTimeSet = new Set<string>();
  _.forEach(metrics, (metricInfo) => {
    const { code } = metricInfo;
    const availableDimensions = availableDimensionsMap[code] || [];
    const dimSet = new Set<string>();
    metricTimeSet.add(code);
    _.forEach(
      _.filter(availableDimensions, (dim) => dim.isEnable),
      (dimensionInfo) => {
        const { name: dimensionId } = dimensionInfo;
        dimSet.add(dimensionId);
        if (!dimension[dimensionId]) {
          dimension[dimensionId] = new Set();
        }
        dimension[dimensionId].add(code);
      },
    );
    dimension[METRIC_TIME] = metricTimeSet;
    metric[code] = dimSet;
  });
  return {
    metric,
    dimension,
  };
}

/**
 * 将维度列表放入类目树中，生成一个带类目和维度的树结构
 * @param dimensionList 维度列表
 * @param categoryTree 类目树
 */
export function fillDimensionListIntoTreeData(
  dimensionList: IPickerDimension[],
  categoryTree: ICategoryTreeNode[],
  getDimensionTreeNode: (dimension: IPickerDimension) => ITreeNode,
  iconSize: number,
  showAllCategory?: boolean,
) {
  // 构建 {[类目 ID]: 维度列表} Map
  const map: {
    [key: string]: IPickerDimension[];
  } = {};
  _.forEach(dimensionList, (dimensionItem) => {
    const category = getCategoryId(dimensionItem);
    if (map[category]) {
      map[category].push(dimensionItem);
    } else {
      map[category] = [dimensionItem];
    }
  });
  const res = _.map(categoryTree, (category) =>
    makeTreeNode(category, map, getDimensionTreeNode, iconSize),
  );
  const metricTimeDimension = _.find(dimensionList, (dim) =>
    isMetricTimeDimension(dim),
  );
  if (metricTimeDimension) {
    // 指标日期没有所属类目，所以不会出现在类目树中，需要加到最前面
    const metricTimeDim = getDimensionTreeNode(metricTimeDimension);
    res.unshift({
      key: 'METRIC_TIME_FOLDER_KEY',
      title: t.common.column.metricTime,
      isLeaf: false,
      icon: <FolderSvg size={iconSize}></FolderSvg>,
      isCategory: true,
      // 类目暂不支持选中
      checkable: false,
      children: [metricTimeDim],
    });
  }
  if (showAllCategory) {
    res.unshift({
      key: ALL_DIMENSION_CATEGORY_KEY,
      title: t.components.dimensionPicker.allFolder,
      isLeaf: true,
      isCategory: true,
      icon: <FolderSvg size={iconSize}></FolderSvg>,
      children: [],
      // 类目暂不支持选中
      checkable: false,
    });
  }
  return res;
}

function makeTreeNode(
  categoryItem: ICategoryTreeNode,
  map: {
    [key: string]: IPickerDimension[];
  },
  getDimensionTreeNode: (dimension: IPickerDimension) => ITreeNode,
  iconSize: number,
): ITreeNode {
  const subCategoryList = _.map(categoryItem.children, (item) =>
    makeTreeNode(item, map, getDimensionTreeNode, iconSize),
  );
  const dimensionList = map[categoryItem.key] || [];
  const leafNodes = _.map(dimensionList, (dimensionItem) => {
    const node = getDimensionTreeNode(dimensionItem);
    return {
      ...node,
      key: dimensionItem.name,
      isCategory: false,
    };
  });
  const children = [...subCategoryList, ...leafNodes];
  const category = {
    key: categoryItem.key,
    title: categoryItem.title,
    isLeaf: !children.length,
    icon: <FolderSvg size={iconSize}></FolderSvg>,
    children,
    isCategory: true,
    // 类目暂不支持选中
    checkable: false,
  };
  return category;
}

/**
 * 获得带时间粒度的维度名称
 * @param displayName 维度展示名
 * @param granularity 时间粒度
 */
export function getDisplayNameWithGranularity(
  displayName: string,
  granularity?: EDateGranularityType,
) {
  if (granularity) {
    const granularityText = dateTimeMap[granularity].name;
    return `${displayName} (${granularityText})`;
  }
  return displayName;
}

/**
 * 排除空的类目
 */
export function excludeEmptyCategory<
  T extends {
    children?: T[];
    isCategory?: boolean;
  },
>(treeData: T[]): T[] {
  return _.map(treeData, (node) => {
    if (!node.isCategory) {
      return node;
    }
    // 是类目的情况
    const children = excludeEmptyCategory(node.children || []);
    // 类目下没有维度
    if (children.length === 0) {
      return null;
    }
    return {
      ...node,
      children,
    };
  }).filter((node): node is T => !!node);
}

const DEFAULT_ICON_SIZE = 20;
export function getGranularityNodeInfo(
  granularityType: Exclude<EDateGranularityType, EDateGranularityType.AUTO>,
  iconSize = DEFAULT_ICON_SIZE,
) {
  const ICON_COLOR = '#6B7280';
  const granularityNodeInfo: {
    [key in Exclude<EDateGranularityType, EDateGranularityType.AUTO>]: {
      icon: React.ReactNode;
      name: string;
    };
  } = {
    [EDateGranularityType.YEAR]: {
      icon: <YearIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.year,
    },
    [EDateGranularityType.QUARTER]: {
      icon: <QuarterIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.quarter,
    },
    [EDateGranularityType.MONTH]: {
      icon: <MonthIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.month,
    },
    [EDateGranularityType.WEEK]: {
      icon: <WeekIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.week,
    },
    [EDateGranularityType.DAY]: {
      icon: <DayIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.day,
    },
    [EDateGranularityType.HOUR]: {
      icon: <HourIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.hour,
    },
    [EDateGranularityType.MINUTE]: {
      icon: <MinuteIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.minute,
    },
    [EDateGranularityType.SECOND]: {
      icon: <SecondIcon size={iconSize} color={ICON_COLOR} />,
      name: t.common.granularity.second,
    },
  };
  return granularityNodeInfo[granularityType];
}

/**
 * 获得维度展示名称
 */
export function getDimensionDisplayName({
  dimensionName,
  granularity,
  dimensions,
  dimensionDisplayName,
}: {
  dimensionName: string;
  dimensions: IDimension[];
  granularity?: EDateGranularityType;
  dimensionDisplayName?: string;
}) {
  const availableDimensions = [metricTimeColumn, ...dimensions];
  const dimension = availableDimensions.find(
    (dim) => dim.name === dimensionName,
  );
  let selectedLabel = dimensionDisplayName;
  if (!dimension) {
    selectedLabel = dimensionDisplayName || dimensionName;
  } else {
    selectedLabel = dimensionDisplayName || dimension.displayName;
  }

  if (granularity && granularity !== EDateGranularityType.AUTO) {
    selectedLabel = `${selectedLabel}(${
      getGranularityNodeInfo(
        granularity as Exclude<EDateGranularityType, EDateGranularityType.AUTO>,
      )?.name
    })`;
  }
  return selectedLabel;
}
