export const sidebar = {
  metricDefine: '指标定义',
};

export const header = {
  menu: {
    updatePassword: '修改密码',
    logout: '退出登录',
  },
  helperCenter: '帮助中心',
};

export const changePassword = {
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmNewPassword: '确认密码',
  placeholder: '请输入',
};

export const personalModal = {
  title: '个人中心',
  account: '用户名',
  avatar: '头像',
  copySuccess: '复制成功',
  nickname: '昵称',
  password: {
    default: '密码',
    change: '修改',
  },
  phone: '手机号',
  email: '邮箱',
  error: {
    nickname: '昵称长度不能为0',
  },
};
