import goBack from '@/common/utils/goBack';
import { post } from '@/common/utils/request';
import t from '@/locales';
import { EResourceType, IPreQuery } from '@/typings/authority';
import { Button, Result } from '@aloudata/aloudata-design';
export default function PreQueryDetail(props: IProps) {
  const { preQueryData, children, ignoreAuthority } = props;

  if (!preQueryData) {
    return null;
  }
  if (!preQueryData.isExist) {
    return (
      <Result
        status="noFound"
        title={t.authority.auth.notExisted.default}
        subTitle={t.authority.auth.notExisted.des}
        extra={
          <Button
            onClick={() => {
              goBack('/');
            }}
          >
            {t.authority.auth.goBack}
          </Button>
        }
      />
    );
  }
  if (ignoreAuthority !== true && !preQueryData.hasAuthority) {
    return (
      <Result
        status="unauthorized"
        title={t.authority.auth.noPermission.default}
        subTitle={t.authority.auth.noPermission.des}
        extra={
          <Button
            onClick={() => {
              goBack('/');
            }}
          >
            {t.authority.auth.goBack}
          </Button>
        }
      />
    );
  }
  return <>{children}</>;
}
function getLastPathname() {
  const currentUrl = window.location.href;
  const pathname = new URL(currentUrl).pathname;
  const pathSegments = pathname.split('/').filter((segment) => segment !== '');
  const lastSegment = pathSegments[pathSegments.length - 1];
  return lastSegment;
}
export async function postPreQuery(type: EResourceType) {
  const lastSegment = getLastPathname();
  const res = await post<IPreQuery>('/preQuery', {
    type,
    id: decodeURI(lastSegment),
  });
  return res;
}
interface IProps {
  preQueryData?: IPreQuery;
  ignoreAuthority?: boolean; // 忽略权限校验
  children: React.ReactNode;
}
