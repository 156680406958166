import { authority } from './../authority/index';
import _ from 'lodash';
import { graphql } from 'msw';
import getMockDocument, {
  getExternalDependencies,
} from '../utils/getMockDocument';
import getUserInfo from '../utils/getUserInfo';

export const workbook = [
  graphql.query('queryWorkbook', (req, res, ctx) => {
    const document = getMockDocument();
    return res(
      ctx.data({
        queryWorkbook: {
          id: 'workbook1',
          name: 'workbook1',
          description:
            'workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1workbook1',
          document,
          dependencies: getExternalDependencies(document),
          gmtCreate: new Date().getTime(),
          gmtUpdate: new Date().getTime(),
          creator: getUserInfo(),
          authority: {
            canAuth: true,
            canEdit: true,
          },
          everPublished: true,
        },
      }),
      ctx.delay(500),
    );
  }),

  graphql.query('queryWorkbookList', (req, res, ctx) => {
    const NUM = 50;
    return res(
      ctx.data({
        queryWorkbookList: {
          data: _.range(NUM).map((i) => ({
            id: i,
            name: `workbook${i}`,
            description: `workbook${i}_desc_long_desc_long_desc_long_desc`,
            gmtCreate: new Date().getTime(),
            gmtUpdate: new Date().getTime(),
            creator: getUserInfo(),
            everPublished: i % 3 !== 0,
            authority: {
              canDelete: i % 3 !== 0,
              canEdit: i % 3 !== 0,
              canAuth: i % 3 !== 0,
            },
          })),
          hasNext: true,
          pageNumber: 1,
          pageSize: NUM,
          total: NUM * 5,
        },
      }),
      ctx.delay(500),
    );
  }),

  graphql.mutation('createWorkbook', (req, res, ctx) => {
    const MOCK_NEW_WORKBOOK_ID = 1000;
    return res(
      ctx.data({
        createWorkbook: MOCK_NEW_WORKBOOK_ID,
      }),
    );
  }),

  graphql.mutation('updateWorkbook', (req, res, ctx) => {
    return res(
      ctx.data({
        updateWorkbook: true,
      }),
    );
  }),

  graphql.mutation('deleteWorkbook', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteWorkbook: true,
      }),
    );
  }),

  graphql.mutation('updateWorkbookBasicInfo', (req, res, ctx) => {
    return res(
      ctx.data({
        updateWorkbookBasicInfo: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('publishWorkbook', (req, res, ctx) => {
    return res(
      ctx.data({
        publishWorkbook: true,
      }),
      ctx.delay(500),
    );
  }),
];
