import { Tooltip } from '@aloudata/aloudata-design';
import { ReactComponent as AlertTriangleLine } from '@/assets/icon/Attention-triangle-light-line.svg';
import { ReactComponent as AlertTriangleDuotone } from '@/assets/icon/Attention-triangle-fill.svg';
import classNames from 'classnames';
import styles from './index.less';

interface IErrorMsgProps {
  isEditorExpand: boolean;
  error: boolean;
  errorMsg?: string;
}

const ErrorMsg = ({ isEditorExpand, errorMsg, error }: IErrorMsgProps) => {
  if (!error) return null;

  if (!isEditorExpand) {
    return (
      <Tooltip title={errorMsg}>
        <div className={classNames(styles.closeErrorBox)}>
          <AlertTriangleLine color="#FFB01F" size={16} />
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div className={styles.openErrorBox}>
        <AlertTriangleDuotone color="#eab308" size={16} />
        <div>{errorMsg}</div>
      </div>
    );
  }
};

export default ErrorMsg;
