import { Checkbox } from '@aloudata/aloudata-design';
import { CheckboxChangeEvent } from '@aloudata/aloudata-design/dist/Checkbox';

interface IProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  children?: React.ReactNode;
}

export default function CustomCheckbox({
  value,
  children,
  onChange: propsOnChange,
}: IProps) {
  const onChange = (e: CheckboxChangeEvent) => {
    if (!propsOnChange) return;
    const { checked } = e.target;
    propsOnChange(checked);
  };
  return (
    <Checkbox onChange={onChange} checked={value}>
      {children}
    </Checkbox>
  );
}
