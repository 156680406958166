import _ from 'lodash';
import {
  IAttributionTableData,
  TSingleAttributionTableRow,
} from '../../src/services/queryAttribution/types';

export function getAttributionDrillDownTable(
  limit: number,
): IAttributionTableData {
  const arr = _.range(20);
  const list = _.map(arr, (i) => {
    return getSingleAttributionTableRow(i);
  });
  return {
    dimensionValue: list.map((item) => item.dimensionValue),
    currentValue: list.map((item) => item.currentValue),
    comparisonValue: list.map((item) => item.comparisonValue),
    growth: list.map((item) => item.growth),
    growthRate: list.map((item) => item.growthRate),
    overallContributionRate: list.map((item) => item.overallContributionRate),
    relativeContributionRate: list.map((item) => item.overallContributionRate),
  };
}

export function getSingleAttributionTableRow(
  i?: number,
): TSingleAttributionTableRow {
  const currentValue = _.random(100) > 95 ? null : _.random(100000, 1000000);
  const comparisonValue = _.random(100) > 95 ? null : _.random(100000, 1000000);
  const growth =
    currentValue !== null && comparisonValue !== null
      ? currentValue - comparisonValue
      : null;
  return {
    dimensionValue: i !== undefined ? `dim_value_${i}` : null,
    currentValue,
    comparisonValue,
    growth,
    growthRate:
      growth !== null && comparisonValue !== null
        ? growth / comparisonValue
        : null,
    overallContributionRate:
      _.random(100) > 95 ? null : _.random(-1000, 1000) / 100,
    relativeContributionRate:
      _.random(100) > 95 ? null : _.random(-1000, 1000) / 100,
  };
}
