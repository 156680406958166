// 大小写不敏感，moo这个库目前不支持/xxx/i的写法去支持大小写不敏感，作者推荐以下方法
// 去转换正则以达到大小写不敏感的目的
function insensitive(r) {
  const esc = (s, a = '', b = '') =>
    s.replace(/[a-z]/gi, (c) => `${a}${c.toUpperCase()}${c.toLowerCase()}${b}`);
  const PART =
    /(\\u[\da-fA-F]{4}|\\x[\da-fA-F]{2}|\\c[a-zA-Z]|\\.)|(\[(?:\\.|[^\]])*\])/;
  const ESCAPE = /(\\u[\da-fA-F]{4}|\\x[\da-fA-F]{2}|\\c[a-zA-Z]|\\.)/;
  return new RegExp(
    r.source
      .split(PART)
      .map((s, i) =>
        i % 3 === 1
          ? s
          : i % 3
          ? s &&
            s
              .split(ESCAPE)
              .map((t, j) => (j % 2 ? t : esc(t)))
              .join('')
          : esc(s, '[', ']'),
      )
      .join(''),
    r.flags.replace('i', ''),
  );
}

module.exports = insensitive;
