export const physicTable = '数据源表';

export const file = '文件';

export const dataset = '数据集';

export const analysisView = '指标视图';

export const node = {
  start: '当前数据',
  columns: '列信息',
};

export const detail = {
  title: '查看详情',
  dataset: {
    displayName: '展示名',
    name: '名称',
    source: '数据来源',
    owner: '负责人',
    desc: '描述',
  },
  metric: {
    type: '指标类型',
    bizOwner: '数据负责人',
    unit: '单位',
    category: '指标类目',
    desc: '业务口径',
  },
  file: {
    displayName: '展示名',
    name: '名称',
    path: '路径',
    owner: '负责人',
    desc: '描述',
  },
  physicalTable: {
    name: '表名称',
    datasourceDisplayName: '数据源',
    path: '路径',
  },
  createTime: '创建时间',
  updateTime: '更新时间',
  analysisView: {
    name: '名称',
    desc: '描述',
    owner: '负责人',
  },
};

export const box = {
  title: {
    baseInfo: '基础信息',
  },
};
