import _ from 'lodash';
import {
  EAssetPropertyType,
  EDataType,
  EEnableStatus,
  EInputType,
  EOwnerType,
  EPropertyType,
  TAssetProperty,
  TDataInput,
} from '../../src/typings/property';
import getUserInfo from '../utils/getUserInfo';
const displayNames: {
  [key: string]: string;
} = {
  dimName: '名称',
  dimCode: '维度编码',
  dimDisplayName: '展示名',
  metricDisplayName: '展示名',
  metricName: '名称',
  metricCode: '指标编码',
  unit: '单位',
  businessOwner: '业务负责人',
  dimDescription: '描述',
  dimCategoryId: '维度类目',
  businessCaliber: '业务口径',
  metricCategory: '指标分类',
  metricCategoryId: '指标类目',
  modifyTime: '修改时间',
  version: '版本',
  owner: '负责人',
};
export const getMockProperty = (
  name: string,
  type: EAssetPropertyType,
  propertyType: EPropertyType = EPropertyType.BASE,
  isSystem: boolean,
  frontDef?: string,
  dataType?: EDataType,
): TAssetProperty => {
  const dataInputs: TDataInput[] = [
    {
      dataType: EDataType.TEXT,
      inputType: EInputType.TEXT,
    },
    {
      dataType: EDataType.USER,
      inputType: EInputType.SELECT,
      useDefaultOwner: true,
    },
    {
      dataType: EDataType.TIMESTAMP,
      inputType: EInputType.TEXT,
    },
    {
      dataType: EDataType.CATEGORY_METRIC,
      inputType: EInputType.SELECT,
    },
    {
      dataType: EDataType.CURRENCY_UNIT,
      inputType: EInputType.SELECT,
    },
    {
      dataType: EDataType.USER_GROUP,
      inputType: EInputType.SELECT,
      useDefaultOwner: true,
    },
    {
      dataType: EDataType.TEXT,
      inputType: EInputType.SELECT,
      options: [
        {
          value: 'option1',
        },
        {
          value: 'option2',
        },
        {
          value: 'option3',
        },
      ],
    },
  ];
  const dt =
    dataInputs.find((item) => item.dataType === dataType) ||
    _.sample(dataInputs)!;
  return {
    name,
    displayName: displayNames[name] || name,
    description:
      'mockDescriptionmockDescriptionmockDescriptionmockDescriptionmockDescriptionmockDescriptionmockDescription',
    isRequired: _.sample([true, false]),
    isCreatingDisplayed: true,
    propertyType,
    status: isSystem
      ? EEnableStatus.FORCE_OPEN
      : _.sample([
          EEnableStatus.FORCE_OPEN,
          EEnableStatus.OPEN,
          EEnableStatus.CLOSE,
        ]),
    dataInput: dt,
    owner: isSystem
      ? {
          type: EOwnerType.SYSTEM,
        }
      : {
          type: EOwnerType.USER,
          owner: getUserInfo(),
        },
    type,
    frontDef,
  } as TAssetProperty;
};

const getMockPropertyByPropertyType = (
  propertyType: EPropertyType,
  type: EAssetPropertyType,
) => {
  let frontDef: string | undefined = undefined;
  return _.map(_.range(10), (item, index) => {
    if (propertyType === EPropertyType.BASE) {
      if (type === EAssetPropertyType.DIMENSION) {
        if (index === 0) {
          const property = getMockProperty(
            'dimName',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'dimName';
          return property;
        }
        if (index === 1) {
          const property = getMockProperty(
            'dimCode',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'dimCode';
          return property;
        }
        if (index === 2) {
          const property = getMockProperty(
            'dimDisplayName',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'dimDisplayName';
          return property;
        }
      }
      if (type === EAssetPropertyType.METRIC) {
        if (index === 0) {
          const property = getMockProperty(
            'metricDisplayName',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'metricDisplayName';
          return property;
        }
        if (index === 1) {
          const property = getMockProperty(
            'metricName',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'metricName';
          return property;
        }

        if (index === 2) {
          const property = getMockProperty(
            'metricCode',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'metricCode';
          return property;
        }
      }

      if (index === 3) {
        const property = getMockProperty(
          'owner',
          EAssetPropertyType.METRIC,
          propertyType,
          true,
          frontDef,
          EDataType.USER,
        );
        frontDef = 'owner';
        return property;
      }

      if (index === 4) {
        const property = getMockProperty(
          'modifyTime',
          EAssetPropertyType.METRIC,
          propertyType,
          true,
          frontDef,
          EDataType.TIMESTAMP,
        );
        frontDef = 'modifyTime';
        return property;
      }
      if (index === 5) {
        const property = getMockProperty(
          'unit',
          EAssetPropertyType.METRIC,
          propertyType,
          true,
          frontDef,
          EDataType.CURRENCY_UNIT,
        );
        frontDef = 'unit';
        return property;
      }
      if (index === 6) {
        const property = getMockProperty(
          'businessOwner',
          EAssetPropertyType.METRIC,
          propertyType,
          true,
          frontDef,
          EDataType.USER,
        );
        frontDef = 'businessOwner';
        return property;
      }
      if (index === 7) {
        const property = getMockProperty(
          'version',
          EAssetPropertyType.METRIC,
          propertyType,
          true,
          frontDef,
          EDataType.TEXT,
        );
        frontDef = 'version';
        return property;
      }
    }
    if (propertyType === EPropertyType.BUSINESS) {
      if (type === EAssetPropertyType.DIMENSION) {
        if (index === 0) {
          const property = getMockProperty(
            'dimDescription',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.TEXT,
          );
          frontDef = 'dimDescription';
          return property;
        }
        if (index === 1) {
          const property = getMockProperty(
            'dimCategoryId',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.CATEGORY_METRIC,
          );
          frontDef = 'dimCategoryId';
          return property;
        }
      }

      if (type === EAssetPropertyType.METRIC) {
        if (index === 0) {
          const property = getMockProperty(
            'metricCategory',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.CATEGORY_METRIC,
          );
          frontDef = 'metricCategory';
          return property;
        }
        if (index === 1) {
          const property = getMockProperty(
            'metricCategoryId',
            EAssetPropertyType.METRIC,
            propertyType,
            true,
            frontDef,
            EDataType.CATEGORY_METRIC,
          );
          frontDef = 'metricCategoryId';
          return property;
        }
      }

      if (index === 2) {
        const property = getMockProperty(
          'businessCaliber',
          EAssetPropertyType.METRIC,
          propertyType,
          true,
          frontDef,
          EDataType.TEXT,
        );
        frontDef = 'businessCaliber';
        return property;
      }
    }
    const property = getMockProperty(
      `property_${index}`,
      EAssetPropertyType.METRIC,
      propertyType,
      false,
      frontDef,
      EDataType.TEXT,
    );
    frontDef = `property_${index}`;
    return property;
  });
};

export const getMockPropertyList = (type: EAssetPropertyType) => {
  return [
    ...getMockPropertyByPropertyType(EPropertyType.BASE, type),
    ...getMockPropertyByPropertyType(EPropertyType.BUSINESS, type),
    ...getMockPropertyByPropertyType(EPropertyType.MANAGE, type),
    ...getMockPropertyByPropertyType(EPropertyType.TECHNOLOGY, type),
  ];
};
