import { ReactComponent as MetricAtomicSvg } from '@/assets/icon/metrics-light-line.svg';
import { ReactComponent as MetricDerivedSvg } from '@/assets/icon/Icon/Light/Files/metrics-Derivative-light-line.svg';
import { ReactComponent as MetricCompositeSvg } from '@/assets/icon/Icon/Light/Files/metrics-Composite-light-line.svg';
import { EMetricType } from '@/typings/metric';

export default function MetricIcon(props: IProps) {
  const { type = EMetricType.ATOMIC, color, size, className } = props;

  const iconProps = { color, size, className };

  const type2iconMap = {
    [EMetricType.ATOMIC]: <MetricAtomicSvg {...iconProps} />,
    [EMetricType.DERIVED]: <MetricDerivedSvg {...iconProps} />,
    [EMetricType.COMPOSITE]: <MetricCompositeSvg {...iconProps} />,
  };

  const icon = type2iconMap[type];

  return icon;
}

interface IProps {
  type: EMetricType;
  color?: string;
  size?: number;
  className?: string;
}
