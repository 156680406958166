import { IPagerInput, IUser } from '@/typings';
import { EUserRole } from '@/typings/authority';
import {
  ESoftIntegrationType,
  ESwitchStatus,
  ISoftIntegrationBaseInfo,
} from '@/typings/softIntegration';
import { TFormula } from '@/typings/formula';
import { ISourceTablePickerValue } from '@/components/SourceTablePicker';
import { TFilter } from '@/components/NewFilter/types';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree';

export interface IAkInfo {
  accessKey: string;
  accessSecret: string;
}

export enum EUserGroupCreationMode {
  MANUAL = 'MANUAL',
  SYNC = 'SYNC',
}

export interface IUserGroup {
  name: string;
  groupId: string;
  parentGroupId: string | null;
  frontGroupId: string | null; // 代表同级前一个groupId，第一个为null
  creationMode: EUserGroupCreationMode;
}

export interface IUserGroupResponse {
  groupId: string;
  name: string;
  groups: IUserGroup[];
  users: IUser[];
}

export enum EUserPropertyType {
  INTERNAL = 'INTERNAL', // 内置的不可修改的
  CUSTOMIZE = 'CUSTOMIZE', // 自定义的可修改的
}

export interface IUserPropertyDef {
  name: string; // 属性名称
  displayName: string; // 属性展示名
  type: EUserPropertyType;
}

export interface IUserProperty {
  account: string;
  disableProperties: string[]; // 是否可以编辑
  properties: {
    name: string; // 属性的名称
    value: string; // 属性的值
    userPropertyDef?: IUserPropertyDef;
  }[];
}

export interface IQueryUserListRequest {
  keyword: string;
  pager: IPagerInput;
  ban?: boolean;
}

export interface IQueryUserAndGroupReq {
  userIds: string[];
  groupIds: string[];
}

export interface IQueryUserAndGroupRes {
  users: IUser[];
  groups: IUserGroup[];
}

// 用户同步相关
export enum ESyncMethodMode {
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
}

export enum ESyncSourceType {
  TABLE = 'TABLE',
  SOFT_INTEGRATION = 'SOFT_INTEGRATION',
}

export enum EUnMatchUserProcessing {
  IGNORE = 'IGNORE',
  BAN = 'BAN',
}

export interface ISyncMethod {
  syncMode: ESyncMethodMode;
  cron: string;
}

export enum EPwdType {
  DEFAULT = 'DEFAULT',
  BIND = 'BIND',
}

export interface IUserSyncConfigBasicInfo {
  sourceType: ESyncSourceType;
  syncConfig: ISyncMethod;
  unMatchUserProcessing: EUnMatchUserProcessing;
  authorityRole: EUserRole;
  layout: {
    filter?: TNode<TFilter>;
  };
  status: ESwitchStatus;
}

// 物理表同步配置
export interface ITableUserSyncConfig extends IUserSyncConfigBasicInfo {
  sourceType: ESyncSourceType.TABLE;
  sourceConfig: {
    physicalTable: ISourceTablePickerValue;
    filter?: TFormula;
    resetPasswordFirstLogin: boolean;
    propertiesMapping: {
      account: IUserSyncConfigProperty;
      pwd: IUserSyncConfigProperty | null; // null代表默认密码
      nickname: IUserSyncConfigProperty;
      email: IUserSyncConfigProperty;
      phone: IUserSyncConfigProperty;
    };
    pwdType: EPwdType;
  };
}

// 字段映射的结构
export interface IUserSyncConfigProperty {
  isExtendedAttr: boolean; // 是否是自定义属性
  value?: string;
}

// 办公软件同步配置
export interface ISoftIntegrationUserSyncConfig
  extends IUserSyncConfigBasicInfo {
  sourceType: ESyncSourceType.SOFT_INTEGRATION;
  sourceConfig: {
    softIntegration: ISoftIntegrationBaseInfo;
    resetPasswordFirstLogin: boolean;
    propertiesMapping: {
      account: IUserSyncConfigProperty;
      nickname: IUserSyncConfigProperty;
      email: IUserSyncConfigProperty;
      phone: IUserSyncConfigProperty;
    };
  };
}

export type TUserSyncConfig =
  | ITableUserSyncConfig
  | ISoftIntegrationUserSyncConfig;

export enum EUserSoftBinConfigType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export interface IManualUserSoftBindConfig {
  type: EUserSoftBinConfigType.MANUAL;
  softIntegrationDetail: { code: string; type: ESoftIntegrationType };
  syncConfig: ISyncMethod;
  status: ESwitchStatus;
}

export interface IAutoUserSoftBindConfig {
  type: EUserSoftBinConfigType.AUTO;
  softIntegrationDetail: { code: string; type: ESoftIntegrationType };
  sourceKey: string;
  targetKey: string;
  syncConfig: ISyncMethod;
  status: ESwitchStatus;
}

// 办公软件绑定配置
export type TUserSoftBindConfig =
  | IManualUserSoftBindConfig
  | IAutoUserSoftBindConfig;

// 用户组同步配置
export interface IGroupSyncConfigBaseInfo {
  sourceType: ESyncSourceType;
  syncConfig: ISyncMethod;
  status: ESwitchStatus;
  layout: {
    organizationFilter: TNode<TFilter>;
    userBindFilter: TNode<TFilter>;
  };
}

export interface ITableGroupSyncConfig extends IGroupSyncConfigBaseInfo {
  sourceType: ESyncSourceType.TABLE;
  sourceConfig: {
    physicalTable: ISourceTablePickerValue;
    filter?: TFormula;
    propertiesMapping: {
      userGroupId: IUserSyncConfigProperty;
      userGroupName: IUserSyncConfigProperty;
      parentUserGroupId: IUserSyncConfigProperty;
    };
    userBindTable: ISourceTablePickerValue;
    userBindFilter?: TFormula;
    userBindPropertiesMapping: {
      account: IUserSyncConfigProperty;
      bindGroupId: IUserSyncConfigProperty;
    };
  };
}

export interface ISoftIntegrationGroupSyncConfig
  extends IGroupSyncConfigBaseInfo {
  sourceType: ESyncSourceType.SOFT_INTEGRATION;
  sourceConfig: {
    softIntegration: {
      code: string;
      type: ESoftIntegrationType;
    };
  };
}

export type TGroupSyncConfig =
  | ITableGroupSyncConfig
  | ISoftIntegrationGroupSyncConfig;

export enum EExecuteType {
  USER_SYNC = 'USER_SYNC',
  USER_GROUP_SYNC = 'USER_GROUP_SYNC',
}

export enum EExecuteStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface IExecuteRecord {
  type: EExecuteType;
  config: TGroupSyncConfig;
  syncMode: ESyncMethodMode;
  executeTime: number;
  finishTime: number;
  status: EExecuteStatus;
}

export interface ISoftwareProperty {
  name: string;
  displayName: string;
}

export enum EUserBindStatus {
  MATCH_SUCCESS = 'MATCH_SUCCESS',
  MATCH_FAILURE = 'MATCH_FAILURE',
  UN_MATCH = 'UN_MATCH',
}

export interface IBindUserDetail {
  userId: string;
  softIntegrationDetail: ISoftIntegrationBaseInfo;
  type: EUserSoftBinConfigType;
  sourceKey: string;
  targetKey: string;
  mappingId: string;
  mappingStatus: EUserBindStatus;
}
