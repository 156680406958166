import { getUniqId } from '@/common/utils';
import { getDimensionId, getMetricId } from '@/common/utils/queryId';
import { IResultTableData } from '@/services/queryColumns/types';
import { postQuery } from '@/services/queryMetrics';
import { EQueryType } from '@/services/queryMetrics/types';
import { EDateGranularityType } from '@/typings';
import { EOrderType } from '@/typings/analysisView';
import _ from 'lodash';

/**
 * 筛选器中查询指标的候选值
 */
export default async function queryMetricValues(
  params: IQueryMetricValuesParams,
) {
  const { metricCode, dimensionName, limit, granularity, signal } = params;

  const metricId = getMetricId({ code: metricCode });
  const dimensionId = getDimensionId(dimensionName, granularity);
  const levelId = 'LEVEL_ID';

  const res = await postQuery({
    queryId: getUniqId(),
    metrics: {
      [metricId]: {
        id: metricId,
        code: metricCode,
      },
    },
    dimensions: {
      [dimensionId]: {
        id: dimensionId,
        column: {
          column: dimensionName,
        },
        granularity,
      },
    },
    levels: {
      [levelId]: {
        id: levelId,
        metrics: [metricId],
        dimensions: [dimensionId],
      },
    },
    metricTimeFilters: null,
    filters: [],
    // 加排序是为了保证数据的顺序
    orders: [
      {
        column: dimensionId,
        type: EOrderType.ASC,
      },
    ],
    signal: signal || new AbortController().signal,
    limit,
    queryType: EQueryType.SYSTEM,
  });
  const { data } = res;
  if (_.isEmpty(data)) {
    return [];
  }
  return (data as IResultTableData)[metricId];
}

interface IQueryMetricValuesParams {
  metricCode: string;
  dimensionName: string;
  limit?: number;
  granularity?: EDateGranularityType;
  signal?: AbortSignal;
}
