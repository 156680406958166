import { createPrefix } from '@/common';

const prefix = createPrefix('file_');

export const FileIds = {
  header: prefix('header'),
  shareModalBtn: prefix('shareModalBtn'),
  shareModalDeleteBtn: prefix('shareModalDeleteBtn'),
  shareToBtn: prefix('shareToBtn'),
  addLinkBtn: prefix('addLinkBtn'),
  removeLinkItem: prefix('removeLinkItem'),
  addLinkItem: prefix('addLinkItem'),
};
