import { graphql, rest } from 'msw';
import _ from 'lodash';
import { getRestfulResponse } from '../utils';
import isPossibilityTrue from '../utils/isPossibilityTrue';
import { getUniqId } from '../../src/common/utils';
import {
  EAccountType,
  EAuthoritySource,
  EUserRole,
} from '../../src/typings/authority';
import {
  generateMockExecuteRecords,
  getMockTGroupSyncConfig,
  getUserGroupInfos,
} from './mock';
import {
  EPwdType,
  ESyncMethodMode,
  ESyncSourceType,
  EUnMatchUserProcessing,
  EUserBindStatus,
  EUserPropertyType,
  EUserSoftBinConfigType,
} from '../../src/services/user/types';
import { EUserCreationMode } from '../../src/typings';
import {
  ESoftIntegrationType,
  ESwitchStatus,
} from '../../src/typings/softIntegration';

const users = new Array(20).fill(0).map((v, i) => ({
  userId: i == 0 ? 'mock_user_id' : getUniqId(),
  nickname: 'nickname' + i,
  account: 'user' + i,
  authorityResource: {
    authorityRole: _.sample([
      EUserRole.ADMIN,
      EUserRole.ANALYST,
      EUserRole.USAGER,
    ]),
    authoritySource: EAuthoritySource.DIRECT,
    authority: {
      canEdit: _.sample([true, false]),
      canDelete: _.sample([true, false]),
      canAuth: true,
      authRoleList: [EUserRole.ANALYST, EUserRole.USAGER],
    },
  },
  ban: _.sample([true, false]),
  creationMode: _.sample([EUserCreationMode.MANUAL, EUserCreationMode.SYNC]),
  disableProperties: _.sample([['password', 'nickname'], []]),
}));

export const user = [
  rest.post('/user/login', async (req, res, ctx) => {
    await req.json();

    const data = {
      redirectUrl: '/',
    };
    const isSuccess = isPossibilityTrue(90);
    if (!isSuccess) {
      return res(
        ctx.json({
          success: false,
          code: 500,
          errorMsg: '用户名或密码错误',
        }),
      );
    }
    return res(ctx.json(getRestfulResponse(data)));
  }),

  rest.post('/user/logout', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse({}))),
  ),
  rest.post('/user/updatePassword', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse({}))),
  ),

  rest.post('/user/checkNameExists', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse(_.sample([true, false])))),
  ),

  rest.post('/user/queryFuzzyAuthorityAccountList', async (req, res, ctx) => {
    const { keyword } = await req.json();

    const data = _.times(10, (index) => ({
      accountType: EAccountType.USER,
      account: 'user' + index,
      displayName: 'name' + index,
      photo: '',
      id: index === 0 ? 'mock_user_id' : 'id' + index,
    }));

    return res(
      ctx.json(
        getRestfulResponse({
          data,
          pageSize: 50,
          pageNumber: 1,
          total: 10,
          hasNext: false,
        }),
      ),
    );
  }),

  rest.post('/user/queryUserByIds', async (req, res, ctx) => {
    const { userIds } = await req.json();
    const data = userIds.split(',').map((id: string) => ({
      userId: id,
      nickname: `userId-${id}`,
    }));

    return res(ctx.json(getRestfulResponse(data)));
  }),

  rest.post('/user/getAk', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          accessKey: '231213sadhe1231',
          accessSecret: '21364gfyashf3123124dhjashf',
        }),
      ),
    );
  }),
  rest.post('/queryUserAndGroup', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          users,
          groups: getUserGroupInfos(),
        }),
      ),
    );
  }),

  rest.post('/user/httpLogin', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          userId: getUniqId(),
          redirectResetPassword: _.sample([true, false]),
          redirectCode: getUniqId(),
        }),
      ),
    );
  }),

  rest.post('/user/enterpriseWechatLogin', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          userId: getUniqId(),
          redirectResetPassword: _.sample([true, false]),
          redirectCode: getUniqId(),
        }),
      ),
    );
  }),

  rest.post('/user/initPassword', async (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(_.sample([true, false]))));
  }),

  rest.post('/user/forceUpdatePassword', async (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(getUniqId())));
  }),

  graphql.query('queryUserDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserDetail: {
          userId: 'mock_user_id',
          nickname: 'nickname',
          account: 'user',
          authorityResource: {
            authorityRole: _.sample([
              EUserRole.ADMIN,
              EUserRole.ANALYST,
              EUserRole.USAGER,
            ]),
            authoritySource: EAuthoritySource.DIRECT,
            authority: {
              canEdit: _.sample([true, false]),
              canDelete: _.sample([true, false]),
              canAuth: true,
              authRoleList: [EUserRole.ANALYST, EUserRole.USAGER],
            },
          },
          groups: [],
        },
      }),
    );
  }),
  graphql.query('queryUserListPage', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserListPage: {
          data: users,
          pageSize: 50,
          total: 20,
          hasNext: false,
          pageNumber: 1,
        },
      }),
    );
  }),
  graphql.mutation('createUser', (req, res, ctx) => {
    return res(
      ctx.data({
        createUser: true,
      }),
    );
  }),
  graphql.mutation('updateUser', (req, res, ctx) => {
    return res(
      ctx.data({
        updateUser: true,
      }),
    );
  }),
  graphql.mutation('deleteUser', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteUser: true,
      }),
    );
  }),

  // 用户组相关
  // 获取用户组列表
  graphql.query('queryAllGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAllGroup: getUserGroupInfos(),
      }),
    );
  }),
  // 新增用户组
  graphql.mutation('createGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        createGroup: getUniqId(),
      }),
    );
  }),
  // 修改用户状态
  graphql.mutation('updateUserBanStatus', (req, res, ctx) => {
    return res(
      ctx.data({
        updateUserBanStatus: true,
      }),
    );
  }),
  // 编辑用户组
  graphql.mutation('updateGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        updateUser: true,
      }),
    );
  }),
  // 删除用户组
  graphql.mutation('deleteGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteUser: true,
      }),
    );
  }),
  // 移动用户组
  graphql.mutation('moveGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        moveGroup: true,
      }),
    );
  }),
  // 添加用户到分组
  graphql.mutation('addUserGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        addUSerGroup: true,
      }),
    );
  }),
  // 从分组中移除用户
  graphql.mutation('deleteUserGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteUserGroup: true,
      }),
    );
  }),
  // 查询分组下的所有资源
  graphql.query('queryUserGroup', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserGroup: {
          groupId: getUniqId(),
          name: 'node',
          groups: getUserGroupInfos(),
          users: new Array(10).fill(0).map((v, i) => ({
            userId: getUniqId(),
            nickname: 'name' + i,
            account: 'account' + i,
            photo: '',
          })),
        },
      }),
    );
  }),
  // 用户属性相关
  graphql.mutation('addUserPropertyDef', (req, res, ctx) => {
    return res(ctx.data({ addUserPropertyDef: true }));
  }),

  graphql.mutation('updateUserPropertyDef', (req, res, ctx) => {
    return res(ctx.data({ updateUserPropertyDef: true }));
  }),

  graphql.mutation('deleteUserPropertyDef', (req, res, ctx) => {
    return res(ctx.data({ deleteUserPropertyDef: true }));
  }),

  graphql.mutation('batchSaveUserProperty', (req, res, ctx) => {
    return res(ctx.data({ batchSaveUserProperty: true }));
  }),

  graphql.mutation('deleteUserProperty', (req, res, ctx) => {
    return res(ctx.data({ deleteUserProperty: true }));
  }),

  graphql.query('queryAllUserPropertyDef', (req, res, ctx) => {
    // Mock data for queryAllUserPropertyDef query
    return res(
      ctx.data({
        queryAllUserPropertyDef: [
          {
            name: 'account',
            displayName: '用户名',
            type: EUserPropertyType.INTERNAL,
          },
          {
            name: 'nickname',
            displayName: '昵称',
            type: EUserPropertyType.INTERNAL,
          },
        ].concat(
          Array.from({ length: 20 })
            .fill(0)
            .map((val, index) => ({
              name: 'property' + index,
              displayName: '属性' + index,
              type: EUserPropertyType.CUSTOMIZE,
            })),
        ),
      }),
    );
  }),

  graphql.query('queryUserPropertyDefByName', (req, res, ctx) => {
    const { name } = req.variables;
    return res(
      ctx.data({
        queryUserPropertyDefByName: {
          name,
          displayName: `${name} Display Name`,
          type: 'CUSTOMIZE',
        },
      }),
    );
  }),

  graphql.query('queryUserProperty', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserProperty: {
          account: 'account1',
          properties: [
            {
              name: 'nickname',
              value: 'nickname1',
              userPropertyDef: {
                name: 'nickname',
                displayName: '昵称',
                type: EUserPropertyType.INTERNAL,
              },
            },
            {
              name: 'account',
              value: 'account1',
              userPropertyDef: {
                name: 'account',
                displayName: '用户名',
                type: EUserPropertyType.INTERNAL,
              },
            },
          ].concat(
            Array.from({ length: 10 })
              .fill(0)
              .map((val, index) => {
                return {
                  name: 'property' + index,
                  value: 'value' + index,
                  userPropertyDef: {
                    name: 'property' + index,
                    displayName: '属性' + index,
                    type: EUserPropertyType.CUSTOMIZE,
                  },
                };
              }),
          ),
        },
      }),
    );
  }),

  graphql.query('queryAllUserProperty', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAllUserProperty: Array.from({ length: 20 })
          .fill(0)
          .map((val, index) => ({
            account: 'account' + index,
            properties: [
              {
                name: 'nickname',
                value: 'nickname' + index,
                userPropertyDef: {
                  name: 'nickname',
                  displayName: '昵称',
                  type: EUserPropertyType.INTERNAL,
                },
              },
              {
                name: 'account',
                value: 'account' + index,
                userPropertyDef: {
                  name: 'account',
                  displayName: '用户名',
                  type: EUserPropertyType.INTERNAL,
                },
              },
            ].concat(
              Array.from({ length: 20 })
                .fill(0)
                .map((val, index) => {
                  return {
                    name: 'property' + index,
                    value: 'value' + index,
                    userPropertyDef: {
                      name: 'property' + index,
                      displayName: '属性' + index,
                      type: EUserPropertyType.CUSTOMIZE,
                    },
                  };
                }),
            ),
          })),
      }),
    );
  }),

  rest.get('/user/supportedPlatforms', async (req, res, ctx) =>
    res(
      ctx.json(
        Math.random() > 0.5
          ? getRestfulResponse([])
          : getRestfulResponse([
              {
                type: 'BOSS',
                name: 'BOSS',
              },
              {
                type: 'LDAP',
                name: 'LDAP',
              },
            ]),
      ),
    ),
  ),
  rest.post('/user/sync/queryLastUserSyncInfo', async (req, res, ctx) =>
    res(
      ctx.json(
        getRestfulResponse([
          {
            cron: '0 10, 20 * * ?',
            status: false,
            lastUpdateStatus: true,
            executionTime: 1617235200000,
            type: 'BOSS',
          },
          {
            cron: '0 10, 8,20 * * 4',
            status: true,
            lastUpdateStatus: false,
            executionTime: 1617235200000,
            type: 'LDAP',
          },
        ]),
      ),
    ),
  ),
  rest.post('/user/sync/immediateSync', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse(true))),
  ),
  rest.get('/user/tip', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse('123@can'))),
  ),

  graphql.mutation('closeUserSyncTask', (req, res, ctx) => {
    return res(ctx.data({ closeUserSyncTask: true }));
  }),
  graphql.mutation('updateUserSyncTask', (req, res, ctx) => {
    return res(ctx.data({ updateUserSyncTask: true }));
  }),
  graphql.mutation('openUserSyncTask', (req, res, ctx) => {
    return res(ctx.data({ openUserSyncTask: true }));
  }),
  graphql.mutation('saveUserSyncConfig', (req, res, ctx) => {
    return res(ctx.data({ saveUserSyncConfig: true }));
  }),
  graphql.query('queryUserSyncTaskConfig', (req, res, ctx) => {
    const { type } = req.variables;
    return res(
      ctx.data({
        queryUserSyncTaskConfig: {
          cron: '0 0 10, 20 * 4 ?',
          type,
          physicalTable: {
            id: 'table0',
            name: 'table0',
            type: 'TABLE',
          },
          immediateSync: true,
          propertiesMapping: {
            username: 'account',
            nickname: 'nickname',
            email: 'email',
            password: 'password',
          },
        },
      }),
    );
  }),
  graphql.query('queryUserSyncConfig', (req, res, ctx) =>
    res(
      ctx.data({
        queryUserSyncConfig: {
          sourceType: ESyncSourceType.TABLE,
          syncConfig: {
            syncMode: ESyncMethodMode.MANUAL,
            cron: '',
          },
          unMatchUserProcessing: EUnMatchUserProcessing.IGNORE,
          authorityRole: EUserRole.USAGER,
          status: 'OPEN',
        },
      }),
    ),
  ),

  graphql.query('queryGroupSyncConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        queryGroupSyncConfig: getMockTGroupSyncConfig(),
      }),
    );
  }),

  graphql.mutation('saveGroupSyncConfig', (req, res, ctx) => {
    return res(ctx.data({ saveGroupSyncConfig: true }));
  }),
  graphql.mutation('triggerSync', (req, res, ctx) => {
    return res(ctx.data({ triggerSync: true }));
  }),
  graphql.query('queryExecuteRecordList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryExecuteRecordList: generateMockExecuteRecords(10),
      }),
    );
  }),
  graphql.query('queryLastExecuteRecord', (req, res, ctx) => {
    return res(
      ctx.data({
        queryLastExecuteRecord: generateMockExecuteRecords(1),
      }),
    );
  }),
  graphql.query('querySoftPropertyKeyList', (req, res, ctx) => {
    return res(
      ctx.data({
        querySoftPropertyKeyList: _.times(100, (index) => ({
          name: 'test' + index,
          displayName: '测试属性' + index,
        })),
      }),
    );
  }),
  graphql.query('queryMappingIdPage', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMappingIdPage: {
          total: 1000,
          data: _.times(100, (index) => 'userId' + index),
          pageNumber: 1,
          pageSize: 10,
          hasNext: true,
        },
      }),
    );
  }),
  graphql.query('queryUserSoftBindConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        queryUserSoftBindConfig: [
          {
            softIntegrationDetail: {
              code: getUniqId(),
              type: ESoftIntegrationType.ENTERPRISE_WECHAT,
            },
            statue: ESwitchStatus.CLOSE,
          },
        ],
      }),
    );
  }),
  graphql.query('queryBindUserDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryBindUserDetail: [
          {
            userId: 'userId',
            softIntegrationDetail: {
              code: getUniqId(),
              type: ESoftIntegrationType.ENTERPRISE_WECHAT,
            },
            type: EUserSoftBinConfigType.AUTO,
            sourceKey: '',
            targetKey: '',
            mappingId: getUniqId(),
            mappingStatus: _.sample([
              EUserBindStatus.MATCH_FAILURE,
              EUserBindStatus.MATCH_SUCCESS,
            ]),
          },
        ],
      }),
    );
  }),
  graphql.mutation('bindUserMappingId', (req, res, ctx) => {
    return res(
      ctx.data({
        bindUserMappingId: true,
      }),
    );
  }),
  // checkMappingIdExists
  graphql.mutation('checkMappingIdExists', (req, res, ctx) => {
    return res(
      ctx.data({
        checkMappingIdExists: true,
      }),
    );
  }),
];
