import { TFormula } from '@/typings/formula';
import { EFilterType } from '@/typings/metric';
import {
  EEnterpriseWeChatMessageType,
  IMonitorConfig,
  TPushChannelReceiver,
} from '@/typings/monitor';
import { EPushMessageType } from '@/typings/softIntegration';

export interface IMonitorConfigRequest
  extends Omit<IMonitorConfig, 'metric' | 'authority'> {
  metricCode: string;
  // 指标日期的公式
  monitorFilter?: {
    type: EFilterType;
    formula: TFormula;
  } | null;
  monitorFilterExplain?: string; // 指标日期筛选的展示名，由前端生成告诉服务端
  monitorLevel?: string; // 监控级别
}

export interface ITestMonitorConfigRequest {
  monitorConfig: IMonitorConfigRequest;
  receivers: TPushChannelReceiver[];
}

export enum EMonitorPushChannelType {
  EMAIL = 'EMAIL',
  WEBHOOK = 'WEBHOOK',
}

export interface IMonitorPushChannelEmail {
  type: EMonitorPushChannelType.EMAIL;
  config: {
    channelId: string;
    channelName: string;
    channelType: 'EMAIL';
  };
}
export interface IMonitorPushChannelWebHook {
  type: EMonitorPushChannelType.WEBHOOK;
  config: {
    channelId: string;
    channelName: string;
    channelType: EPushMessageType;
  };
}

export type TMonitorPushChannel =
  | IMonitorPushChannelEmail
  | IMonitorPushChannelWebHook;

export interface IMonitorLevelDefResponse {
  name: string;
  displayName: string;
  dataInput: {
    isDefault: boolean;
    name: string;
    displayName: string;
  }[];
}

export interface IMonitorPushContentTemplate {
  type: EEnterpriseWeChatMessageType;
  pushChannel: EPushMessageType;
  content: String;
  splitDimension: Boolean;
}
