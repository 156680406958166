import { ICategoryTreeNode } from '@/pages/Manage/CategoryManage/categoryHelper';
import { EOriginDataType } from '@/typings';
import { TMetric } from '@/typings/metric';
import { TreeDataNode } from '@aloudata/aloudata-design';

export interface IDataItem {
  metric: TMetric;
  disabled: boolean;
  disabledReason: string;
}

export interface ITreeNode extends TreeDataNode {
  key: string;
  title: string;
  isCategory?: boolean;
  checkable?: boolean;
  children?: ITreeNode[];
  isLeaf: boolean;
  data: IDataItem | ICategoryTreeNode | null;
  icon?: JSX.Element;
  disabled?: boolean;
}

export enum EDisplayType {
  TREE = 'TREE',
  LIST = 'LIST',
}

export interface IMetricPickerRef {
  focus: () => void;
  reset: () => void;
}

export interface ISelectedMetricTreeItem {
  key: string;
  isCategory: boolean;
}

export interface IMetricPickerValue<TExtraData = unknown> {
  code: string;
  originDataType?: EOriginDataType;
  name?: string;
  extra?: TExtraData;
  // 完整的指标对象
  metric?: TMetric;
}
