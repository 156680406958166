import { Compartment, EditorState, Prec } from '@uiw/react-codemirror';
import { EditorView } from 'codemirror';

// TODO: 补充单测
export const insertText = (
  view: EditorView,
  text: string,
  partial: string,
  from: number,
  to: number,
) => {
  const doc = view.state.doc.toString();
  const cursorPos = view.state.selection.main.head;

  try {
    view.focus();
    view.dispatch({
      changes: {
        from,
        to,
        insert: text,
      },
    });
    setTimeout(() => {
      view.dispatch({
        selection: {
          anchor: from + text.length,
          head: from + text.length,
        },
      });
    }, 0);
  } catch (e) {
    console.error('insertError', e, {
      text,
      partial,
      doc,
      cursorPos,
      from,
      to,
    });
  }
};

export function getCursorPos(state: EditorState) {
  const selection = state.selection;
  const cursorPos = selection.main.head; // 获取光标位置
  return cursorPos;
}

export function getTextBeforeCursor(state: EditorState) {
  const cursorPos = getCursorPos(state);
  const textBeforeCursor = state.doc.sliceString(0, cursorPos); // 提取光标前的文本
  return textBeforeCursor;
}

export function getCursorPagePosition(state: EditorState, view: EditorView) {
  const selection = state.selection;
  const cursorPos = selection.main.head; // 获取光标位置
  const cursorCoords = view.coordsAtPos(cursorPos); // 获取光标的坐标
  if (cursorCoords) {
    return {
      x: cursorCoords.left,
      y: cursorCoords.top,
    };
  }
  return null;
}

export const setArrowAndEnterDisabled = (
  view: EditorView,
  compartment: Compartment,
  disabled: boolean,
) => {
  if (disabled)
    view.dispatch({
      effects: compartment.reconfigure(
        Prec.highest(
          EditorView.domEventHandlers({
            keydown(event) {
              if (
                ['ArrowUp', 'ArrowDown', 'Tab', 'Enter'].includes(event.key)
              ) {
                event.preventDefault();
                return;
              }
            },
          }),
        ),
      ),
    });
  else {
    view.dispatch({
      effects: compartment.reconfigure(
        Prec.highest(
          EditorView.domEventHandlers({
            keydown() {
              return false;
            },
          }),
        ),
      ),
    });
  }
};

export const getDoc = (view: EditorView) => {
  return view.state.doc.toString();
};
