import { getRestfulResponse } from '../utils';
import { ICodeSettings } from './../../src/services/platformSettings/types';
import { rest } from 'msw';

export const platformSettings = [
  rest.get('/platform/settings/code/query', async (req, res, ctx) => {
    const data: ICodeSettings = {
      enableCode: true,
      apiEnableCode: true,
      metricTimeCustomCode: 'metric_time',
    };

    return res(ctx.json(getRestfulResponse(data)), ctx.delay(1000));
  }),

  rest.post('/platform/settings/code/save', async (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(true)), ctx.delay(500));
  }),
];
