import { isMock } from './common';
import {
  anymetricsHosts,
  ignoreHosts,
  loggerPath,
  sentryDSN,
  userInfo,
} from './common/appConfig';
import { reportError } from './common/reportError';
import { APP_NAME } from './common/sentry/constant';

// localhost打开网页的时候会启动 msw mock 服务
if (isMock) {
  const { worker } = require('../mocks/browser');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

// 本地开发环境不上传
if (process.env.UMI_ENV !== 'develop') {
  reportError({
    sentryDSN,
    sentryTargetHosts: anymetricsHosts,
    ignoreHosts,
    customTargetHosts: [loggerPath],
    userInfo,
    appName: APP_NAME,
  });
}
