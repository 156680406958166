import { IFilter, IFilterItem } from '@/components/Filter/filter';
import { EColumnType } from '@/typings/dataset';
import { EOriginDataType } from '@/typings/formula';
import { EColumnDataType, EEntityType } from '@/typings/index';

export interface IFilterProps {
  elementId: string;
  // 设置完毕的回调
  onComplete?: () => void;
}

export interface IRefProps {
  // 获取设置的 filter 条件
  getData: (data: IFilter<IFilterItem>) => void;
}

export interface IItemProps {
  data: IFilterItem;
  id: string;
  sheetId?: string;
  dataType?: EColumnDataType;
  updateFilter: (id: string, config: Partial<IFilterItem>) => void;
  type?: EEntityType;
  filters: IFilterItem[];
  dataset: IFilterDataset;
}

export type TColumns = {
  sheetId: string;
  sheetName: string;
  columns: IBaseColumn[];
}[];

export interface IBaseColumn {
  name: string;
  id: string;
  dataType: EColumnDataType;
  displayName: string;
  columnType?: EColumnType;
  originDataType: EOriginDataType;
}

export interface IFilterDataset {
  name: string;
  columns: IBaseColumn[];
}

export enum EFilterType {
  FILTER = 'FILTER',
  DIMENSION_FILTER = 'DIMENSION_FILTER',
  TABLE_FILTER = 'TABLE_FILTER',
  METRIC_FILTER = 'METRIC_FILTER',
}

export interface IDimensionDep {
  name: string;
  type: EFilterType.DIMENSION_FILTER;
}

export interface IColumnDep {
  columnName: string;
  dataset: IFilterDataset;
  type: EFilterType.FILTER;
}

export interface ITableDep {
  tableGuid: string;
  type: EFilterType.TABLE_FILTER;
  columnName: string;
}

export interface IMetricDep {
  code: string;
  type: EFilterType.METRIC_FILTER;
  dimensionName: string;
}
