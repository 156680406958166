import { EFormulaType, TCorrectFormula, TFormula } from '@/typings/formula';
import _ from 'lodash';
import { isCorrectFormula } from '../helper/tool';

/**
 * 公式 AST 深度优先的遍历器
 * @param {TCorrectFormula} ast 公式的 AST
 * @param start 初始值，用于迭代器之下而上传递值
 * @param callback 回调函数
 * @returns
 */

const iterate = <T>(
  ast: TCorrectFormula,
  start: T,
  callback: (ast: TCorrectFormula, pre: T[]) => T,
): T => {
  const { type } = ast;
  if (type === EFormulaType.BINARY_OPERATOR) {
    const { x, y } = ast;
    const processedX = iterate(x, start, callback);
    const processedY = iterate(y, start, callback);
    return callback({ ...ast }, [processedX, processedY]);
  }
  if (type === EFormulaType.FUNCTION) {
    const { args } = ast;
    const processed = args.map((arg) => iterate(arg, start, callback));
    return callback({ ...ast }, processed);
  }
  if (
    type === EFormulaType.UNARY_OPERATOR ||
    type === EFormulaType.PARENTHESIS
  ) {
    const { x } = ast;
    const processedX = iterate(x, start, callback);
    return callback({ ...ast }, [processedX]);
  }
  return callback(ast, [start]);
};

export default iterate;

const ROOT = '';
// 遍历公式 ast，并可修改节点
export function visitFormulaAst(
  orgAst: TFormula,
  visitor: {
    [formulaType: string]: <T extends TCorrectFormula>(
      currAstNode: T,
      path: string,
    ) => TCorrectFormula;
  },
): TFormula {
  if (!isCorrectFormula(orgAst)) {
    return orgAst;
  }
  let ast = _.cloneDeep(orgAst);
  const astNodes: Array<{
    node: TCorrectFormula;
    path: string;
  }> = [
    {
      node: ast,
      path: ROOT,
    },
  ];
  const MAX_LOOP = 10000;
  let loopCount = 0;
  while (astNodes.length) {
    const astNode = astNodes.shift()!;
    let currAstNode = astNode.node;
    const { path } = astNode;
    if (visitor[currAstNode.type]) {
      currAstNode = visitor[currAstNode.type](currAstNode, path);
      // 更新 ast 节点
      if (path !== ROOT) {
        _.set(ast, path, currAstNode);
      } else {
        // 替换根节点的情况
        ast = currAstNode;
      }
    }
    // 如果还有子元素，则继续处理子元素
    if (currAstNode.type === EFormulaType.BINARY_OPERATOR) {
      astNodes.push({ node: currAstNode.x, path: getPath(path, 'x') });
      astNodes.push({ node: currAstNode.y, path: getPath(path, 'y') });
    } else if (currAstNode.type === EFormulaType.FUNCTION) {
      _.forEach(currAstNode.args, (arg, index) => {
        astNodes.push({
          node: arg,
          path: getPath(path, `args[${index}]`),
        });
      });
    } else if (
      currAstNode.type === EFormulaType.UNARY_OPERATOR ||
      currAstNode.type === EFormulaType.PARENTHESIS
    ) {
      astNodes.push({ node: currAstNode.x, path: getPath(path, 'x') });
    }
    loopCount += 1;
    if (loopCount > MAX_LOOP) {
      // TODO: 主动上报异常
      console.error('visitAst loop count exceed max loop count');
      return ast;
    }
  }
  return ast;
}

function getPath(orgPath: string, subPath: string) {
  return orgPath === ROOT ? subPath : `${orgPath}.${subPath}`;
}
