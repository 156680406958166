import { get } from '@/common/utils/request';
import {
  EVertexType,
  IQueryVertexLineageReq,
  IQueryVertexLineageRes,
  TVertex,
} from './types';
import _ from 'lodash';

/**
 * 获取血缘图数据
 */
export function queryLineageGraph(
  req: IQueryVertexLineageReq,
): Promise<IQueryVertexLineageRes> {
  const { inputStep, outputStep } = req;
  return get<IQueryVertexLineageRes>('/lineage/queryVertexLineage', {
    ...req,
    // 多获取一层上下游，以便标记是否存在更多上下游节点
    inputStep: inputStep + 1,
    outputStep: outputStep + 1,
  }).then((res) => {
    // 为每个节点标记是否有更多上游或下游
    // 目标节点列表
    const inputVertexIds = _(res.edgeList)
      .map((edge) => edge.dstVertex.vertexId)
      .uniq()
      .value();
    // 起始节点列表
    const outputVertexIds = _(res.edgeList)
      .map((edge) => edge.srcVertex.vertexId)
      .uniq()
      .value();

    const dataWithInputOutputMarked = {
      ...res,
      vertexList: res.vertexList.map((vertex) => {
        return {
          ...vertex,
          // 是别的节点的目标节点，则存在上游
          hasMoreInput: inputVertexIds.includes(vertex.vertexId),
          // 是别的节点的起始节点，则存在下游
          hasMoreOutput: outputVertexIds.includes(vertex.vertexId),
        };
      }),
    };

    // 由于为了判断每个节点是否存在上下游，从服务端多拿了一层上下游，在这里去掉多拿的上下游
    const resData = filterMultipleLayers(
      dataWithInputOutputMarked,
      req.vertexId,
      inputStep,
      outputStep,
    ) as IQueryVertexLineageRes;

    return resData;
  });
}

interface ISimpleEdge {
  srcVertex: { vertexId: string };
  dstVertex: { vertexId: string };
}

/**
 * 从图数据中获取指定上下游层数的子图数据
 * @param data 图数据
 * @param vertexId 起始节点
 * @param inputStep 上游层数
 * @param outputStep 下游层数
 */
export function filterMultipleLayers(
  data: {
    vertexList: Array<{ vertexId: string }>;
    edgeList: ISimpleEdge[];
  },
  vertexId: string,
  inputStep: number,
  outputStep: number,
) {
  let filteredVertexList: Array<{ vertexId: string }> = data.vertexList.filter(
    (vertex) => vertex.vertexId === vertexId,
  ); // 加入起始节点
  let filteredEdgeList: ISimpleEdge[] = [];

  let currentUpstreamVertices = [vertexId];
  let currentDownstreamVertices = [vertexId];

  // 获取上游数据
  for (let i = 0; i < inputStep; i++) {
    const nextUpstreamVertices: string[] = [];
    for (let j = 0; j < data.edgeList.length; j++) {
      const edge = data.edgeList[j];
      if (currentUpstreamVertices.includes(edge.dstVertex.vertexId)) {
        nextUpstreamVertices.push(edge.srcVertex.vertexId);
        filteredEdgeList.push(edge);
      }
    }
    filteredVertexList = [
      ...filteredVertexList,
      ...data.vertexList.filter((vertex) =>
        nextUpstreamVertices.includes(vertex.vertexId),
      ),
    ];
    currentUpstreamVertices = nextUpstreamVertices;
  }

  // 获取下游数据
  for (let i = 0; i < outputStep; i++) {
    const nextDownstreamVertices: string[] = [];
    for (let j = 0; j < data.edgeList.length; j++) {
      const edge = data.edgeList[j];
      if (currentDownstreamVertices.includes(edge.srcVertex.vertexId)) {
        nextDownstreamVertices.push(edge.dstVertex.vertexId);
        filteredEdgeList.push(edge);
      }
    }
    filteredVertexList = [
      ...filteredVertexList,
      ...data.vertexList.filter((vertex) =>
        nextDownstreamVertices.includes(vertex.vertexId),
      ),
    ];
    currentDownstreamVertices = nextDownstreamVertices;
  }

  // 去重
  filteredVertexList = Array.from(
    new Set(filteredVertexList.map((v) => JSON.stringify(v))),
  ).map((v) => JSON.parse(v));
  filteredEdgeList = Array.from(
    new Set(filteredEdgeList.map((e) => JSON.stringify(e))),
  ).map((e) => JSON.parse(e));

  return {
    vertexList: filteredVertexList,
    edgeList: filteredEdgeList,
  };
}

// 获取某个资源的下游资源
export function queryDownstreamVertexes(
  vertexId: string | number,
  vertexType: EVertexType,
): Promise<TVertex[]> {
  return get<TVertex[]>('/lineage/queryDownstreamVertexes', {
    vertex_id: vertexId,
    vertex_type: vertexType,
  });
}
