const datasourceTypeList = [
  {
    icon: 'https://as.cdn.aloudata.com/cdn/joinsight/PostgreSQL.png',
    name: 'mc',
    schema: JSON.stringify([
      {
        title: '基础信息',
        container: [
          {
            label: '数据源名称',
            type: 'text',
            name: 'datasourceName',
            validate: {
              pattern: '^(?!\\s)(?!.*\\s$).{1,50}$',
              type: 'string',
              required: true,
              message: '数据源名称，长度1~50字符，且开始和结尾字符不能是空格',
            },
            properties: {},
          },
          {
            label: '描述',
            type: 'textarea',
            name: 'description',
            validate: { type: 'string', required: false, message: '' },
            properties: { maxLength: 200 },
          },
        ],
      },
      {
        title: '连接信息',
        description: '请提供数据源连接所需的账号与密码',
        container: [
          {
            label: '数据库地址',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库的连接地址xxx.xxx.xx.xx',
            },
            name: 'endpoint',
            properties: {},
          },
          {
            label: '项目名称',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库的连接地址xxx.xxx.xx.xx',
            },
            helper: '在 MaxCompute 控制台中查看项目名称。',
            name: 'project',
            properties: {},
          },
          {
            label: 'AccessKey ID',
            name: 'username',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的账号不能为空',
            },
            helper:
              '数据库地址，如 http://service.cn-hangzhou.maxcompute.aliyun-inc.com/api',
            properties: {},
          },
          {
            type: 'password',
            label: 'AccessKey Secret',
            name: 'password',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的密码不能为空',
            },
            helper: 'AccessKey ID对应的的AccessKey Secret',
            properties: {},
          },
          {
            type: 'switch',
            name: 'enableUpload',
            label: '支持文件上传',
            helper:
              '支持用户将本地的文件上传到该数据源的指定位置，需要拥有项目的 CreateTable、Write 权限',
            properties: {},
            validate: { required: false, type: 'boolean' },
          },
        ],
      },
    ]),
    type: 'mc',
    extension: '{\n    "background": "#EBF6FF"\n}',
  },
  {
    icon: 'https://as.cdn.aloudata.com/cdn/joinsight/PostgreSQL.png',
    name: 'postgresql',
    schema: JSON.stringify([
      {
        title: '基础信息',
        container: [
          {
            label: '数据源名称',
            type: 'text',
            name: 'datasourceName',
            validate: {
              pattern: '^(?!\\s)(?!.*\\s$).{1,50}$',
              type: 'string',
              required: true,
              message: '数据源名称，长度 1~50 字符，且开始和结尾字符不能是空格',
            },
            properties: {},
          },
          {
            label: '描述',
            type: 'textarea',
            name: 'description',
            validate: {
              type: 'string',
              required: false,
              message: '',
            },
            properties: {
              maxLength: 200,
            },
          },
        ],
      },
      {
        title: '连接信息',
        description: '请提供数据源连接所需的账号与密码',
        container: [
          {
            label: '连接地址',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库的连接地址 xxx.xxx.xx.xx',
            },
            name: 'hostname',
            properties: {},
            width: '212px',
          },
          {
            label: '端口',
            name: 'port',
            width: '96px',
            type: 'text',
            validate: {
              pattern: '^[0-9]+$',
              required: true,
              type: 'string',
              message: '只能由数字组成',
            },
            properties: {},
          },
          {
            label: '数据库名',
            type: 'text',
            name: 'databaseName',
            validate: {
              pattern:
                '(^([a-zA-Z])[\\w]{0,127}$)|(^[_](?=.*[a-zA-Z])[\\w]{1,127}$)',
              type: 'string',
              required: true,
              message:
                '数据库名称，长度 1~128 字符，使用数字、字母和 _ 组成，须以字母或下划线开头，并必须包含字母',
            },
            properties: {},
          },
          {
            label: '账号',
            name: 'username',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的账号不能为空',
            },
            properties: {},
          },
          {
            type: 'password',
            label: '密码',
            name: 'password',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的密码不能为空',
            },
            properties: {},
          },
          {
            type: 'switch',
            name: 'enableUpload',
            label: '支持文件上传',
            helper:
              '支持用户将本地的文件上传到该数据源的指定位置，请保证当前账号拥有数据写入权限',
            properties: {},
            validate: {
              required: false,
              type: 'boolean',
            },
          },
          {
            name: 'uploadDatabase',
            type: 'selectDatabase',
            label: '选择上传数据库',
            helper: '文件上传位置目录内的数据不会在数据源中展示',
            validate: {
              message: '请选择上传数据库',
              required: true,
              type: 'string',
            },
            visibleDependency: [
              {
                name: 'enableUpload',
                value: [true],
              },
            ],
          },
        ],
      },
    ]),
    type: 'postgresql',
    extension: '{\n    "background": "#EBF6FF"\n}',
  },
  {
    icon: 'https://as.cdn.aloudata.com/cdn/joinsight/hive.png',
    name: 'hive2',
    schema: JSON.stringify([
      {
        title: '基础信息',
        container: [
          {
            label: '数据源名称',
            type: 'text',
            name: 'datasourceName',
            validate: {
              pattern: '^(?!\\s)(?!.*\\s$).{1,50}$',
              type: 'string',
              required: true,
              message: '数据源名称，长度 1~50 字符，且开始和结尾字符不能是空格',
            },
            properties: {},
          },
          {
            label: '描述',
            type: 'textarea',
            name: 'description',
            validate: {
              type: 'string',
              required: false,
              message: '',
            },
            properties: {
              maxLength: 200,
            },
          },
        ],
      },
      {
        title: '连接信息',
        description: '请提供数据源连接所需的账号与密码',
        container: [
          {
            label: '连接地址',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库的连接地址 xxx.xxx.xx.xx',
            },
            name: 'hostname',
            properties: {},
            width: '212px',
          },
          {
            label: '端口',
            name: 'port',
            width: '96px',
            type: 'text',
            validate: {
              pattern: '^[0-9]+$',
              required: true,
              type: 'string',
              message: '只能由数字组成',
            },
            properties: {},
          },
          {
            label: '账号',
            name: 'username',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的账号不能为空',
            },
            properties: {},
          },
          {
            type: 'password',
            label: '密码',
            name: 'password',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的密码不能为空',
            },
            properties: {},
          },
          {
            type: 'switch',
            name: 'enableUpload',
            label: '支持文件上传',
            helper:
              '支持用户将本地的文件上传到该数据源的指定位置，请保证当前账号拥有数据写入权限',
            properties: {
              defaultChecked: false,
            },
            disabled: true,
            validate: {
              required: false,
              type: 'boolean',
            },
          },
          {
            name: 'uploadDatabase',
            type: 'selectDatabase',
            label: '选择上传数据库',
            helper: '文件上传位置目录内的数据不会在数据源中展示',
            validate: {
              message: '请选择上传数据库',
              required: true,
              type: 'string',
            },
            visibleDependency: [
              {
                name: 'enableUpload',
                value: [true],
              },
            ],
          },
        ],
      },
    ]),
    type: 'hive2',
    extension: '{\n    "background": "#FFFAEB"\n}',
  },
  {
    icon: 'https://as.cdn.aloudata.com/cdn/joinsight/MySQL.png',
    name: 'mysql',
    schema: JSON.stringify([
      {
        title: '基础信息',
        container: [
          {
            label: '数据源名称',
            type: 'text',
            name: 'datasourceName',
            validate: {
              pattern: '^(?!\\s)(?!.*\\s$).{1,50}$',
              type: 'string',
              required: true,
              message: '数据源名称，长度 1~50 字符，且开始和结尾字符不能是空格',
            },
            properties: {},
          },
          {
            label: '描述',
            type: 'textarea',
            name: 'description',
            validate: {
              type: 'string',
              required: false,
              message: '',
            },
            properties: {
              maxLength: 200,
            },
          },
        ],
      },
      {
        title: '连接信息',
        description: '请提供数据源连接所需的账号与密码',
        container: [
          {
            label: '连接地址',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库的连接地址 xxx.xxx.xx.xx',
            },
            name: 'hostname',
            properties: {},
            width: '212px',
          },
          {
            label: '端口',
            name: 'port',
            width: '96px',
            type: 'text',
            validate: {
              pattern: '^[0-9]+$',
              required: true,
              type: 'string',
              message: '只能由数字组成',
            },
            properties: {},
          },
          {
            label: '账号',
            name: 'username',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的账号不能为空',
            },
            properties: {},
          },
          {
            type: 'password',
            label: '密码',
            name: 'password',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的密码不能为空',
            },
            properties: {},
          },
          {
            type: 'switch',
            name: 'enableUpload',
            label: '支持文件上传',
            helper:
              '支持用户将本地的文件上传到该数据源的指定位置，请保证当前账号拥有数据写入权限',
            properties: {},
            validate: {
              required: false,
              type: 'boolean',
            },
          },
          {
            name: 'uploadDatabase',
            type: 'selectDatabase',
            label: '选择上传数据库',
            helper: '文件上传位置目录内的数据不会在数据源中展示',
            validate: {
              message: '请选择上传数据库',
              required: true,
              type: 'string',
            },
            visibleDependency: [
              {
                name: 'enableUpload',
                value: [true],
              },
            ],
          },
        ],
      },
    ]),
    type: 'mysql',
    extension: '{\n    "background": "#EBF6FF"\n}',
  },
  {
    icon: 'https://as.cdn.aloudata.com/cdn/joinsight/hive.png',
    name: 'hive3',
    schema: JSON.stringify([
      {
        title: '基础信息',
        container: [
          {
            label: '数据源名称',
            type: 'text',
            name: 'datasourceName',
            validate: {
              pattern: '^(?!\\s)(?!.*\\s$).{1,50}$',
              type: 'string',
              required: true,
              message: '数据源名称，长度 1~50 字符，且开始和结尾字符不能是空格',
            },
            properties: {},
          },
          {
            label: '描述',
            type: 'textarea',
            name: 'description',
            validate: {
              type: 'string',
              required: false,
              message: '',
            },
            properties: {
              maxLength: 200,
            },
          },
        ],
      },
      {
        title: '连接信息',
        description: '请提供数据源连接所需的账号与密码',
        container: [
          {
            label: '连接地址',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库的连接地址 xxx.xxx.xx.xx',
            },
            name: 'hostname',
            properties: {},
            width: '212px',
          },
          {
            label: '端口',
            name: 'port',
            width: '96px',
            type: 'text',
            validate: {
              pattern: '^[0-9]+$',
              required: true,
              type: 'string',
              message: '只能由数字组成',
            },
            properties: {},
          },
          {
            label: '账号',
            name: 'username',
            type: 'text',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的账号不能为空',
            },
            properties: {},
          },
          {
            type: 'password',
            label: '密码',
            name: 'password',
            validate: {
              required: true,
              type: 'string',
              message: '数据库访问的密码不能为空',
            },
            properties: {},
          },
          {
            type: 'switch',
            name: 'enableUpload',
            label: '支持文件上传',
            helper:
              '支持用户将本地的文件上传到该数据源的指定位置，请保证当前账号拥有数据写入权限',
            properties: {
              defaultChecked: false,
            },
            disabled: true,
            validate: {
              required: false,
              type: 'boolean',
            },
          },
          {
            name: 'uploadDatabase',
            type: 'selectDatabase',
            label: '选择上传数据库',
            helper: '文件上传位置目录内的数据不会在数据源中展示',
            validate: {
              message: '请选择上传数据库',
              required: true,
              type: 'string',
            },
            visibleDependency: [
              {
                name: 'enableUpload',
                value: [true],
              },
            ],
          },
        ],
      },
    ]),
    type: 'hive3',
    extension: '{\n    "background": "#FFFAEB"\n}',
  },
];

export default datasourceTypeList;
