import { ITableProps, Table, Tabs, TabsProps } from '@aloudata/aloudata-design';
import styles from './index.less';

export default function ListTab<TDataItem extends object>(
  props: IListTabProps<TDataItem>,
) {
  const {
    title,
    total,
    filterArea,
    operatingArea,
    tabItems,
    onChange,
    activeKey,
    ...restTableProps
  } = props;

  const items = tabItems?.map((item) => {
    return {
      ...item,
      children: (
        <div className={styles.tabContainer}>
          <div className={styles.condition}>
            <div className={styles.total}>{total}</div>
            <div className={styles.filters}>{filterArea}</div>
          </div>
          <div className={styles.tableArea}>
            <Table<TDataItem> {...restTableProps} />
          </div>
        </div>
      ),
    };
  });

  return (
    <div className={styles.content}>
      <div className={styles.headerArea}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.handles}>{operatingArea}</div>
        </div>
      </div>
      <div className={styles.bodyArea}>
        <Tabs
          items={items}
          onChange={onChange}
          activeKey={activeKey}
          size="small"
          adaptHeight
        />
      </div>
    </div>
  );
}

interface IListTabProps<TDataItem extends object>
  extends ITableProps<TDataItem> {
  title: React.ReactNode;
  operatingArea?: React.ReactNode;
  total: React.ReactNode;
  filterArea: React.ReactNode;
  tabItems: TabsProps['items'];
  defaultActiveKey?: string;
  onChange?: TabsProps['onChange'];
  activeKey: string;
}
