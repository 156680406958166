const split = (str, separator) => {
  const result = [];
  let temp = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] === separator && str[i - 1] !== '\\') {
      result.push(temp);
      temp = '';
    } else {
      temp += str[i];
    }
  }
  result.push(temp);
  return result;
};

module.exports = split;
