import { Decoration, DecorationSet, EditorView } from '@codemirror/view';
import { Extension, ViewPlugin, ViewUpdate } from '@uiw/react-codemirror';
import { TExternalInfo } from './type';
import { getTokenListWithIdPath } from './helper/formulaHelper';
import { getColumnHighlight } from './helper';
import { getDoc } from './helper/codemirrorHelper';

// 创建装饰器来高亮字段
export const createFieldDecorator = (
  externalInfo: TExternalInfo,
): Extension => {
  return ViewPlugin.fromClass(
    class {
      decorations: DecorationSet;

      constructor(view: EditorView) {
        this.decorations = this.createDecorations(view);
      }

      createDecorations(view: EditorView): DecorationSet {
        const marks = [];

        const highlightRenders = getColumnHighlight(
          getTokenListWithIdPath(getDoc(view), externalInfo),
        ).codemirrorRender;

        const highlightConfigs = highlightRenders.sort(
          (a, b) => a.offset - b.offset,
        );
        for (const highlightConfig of highlightConfigs) {
          marks.push(
            Decoration.mark({
              attributes: {
                style: `color:${highlightConfig.color};`,
              },
            }).range(highlightConfig.offset, highlightConfig.end),
          );
        }
        return Decoration.set(marks);
      }

      update(update: ViewUpdate) {
        if (update.docChanged || update.viewportChanged) {
          this.decorations = this.createDecorations(update.view);
        }
      }

      get atomicRanges(): DecorationSet {
        return this.decorations;
      }
    },
    {
      decorations: (v) => v.decorations,
      provide: (plugin) => {
        return EditorView.atomicRanges.of(
          (view) => view.plugin(plugin)?.atomicRanges || Decoration.none,
        );
      },
    },
  );
};
