import { EUnit } from '../../src/typings';
import { ICategory } from '../../src/typings/category';
import { IMetricProperties } from '../../src/typings/metric';
import { EAssetPropertyType, EDataType } from '../../src/typings/property';
import _ from 'lodash';
import { getMockPropertyList } from '../property/mock';

export default function getMetricMockProperties(
  category: ICategory[],
  unit: EUnit,
) {
  const propertyList = getMockPropertyList(EAssetPropertyType.METRIC);
  const properties = {} as IMetricProperties;
  propertyList.forEach((property) => {
    const propertyType = property.propertyType;
    const dataInput = property.dataInput;
    const name = property.name;
    let value: unknown;
    if (dataInput.dataType === EDataType.TEXT) {
      value = `${name}_value`;
    } else if (dataInput.dataType === EDataType.TIMESTAMP) {
      value = new Date().getTime();
    } else if (dataInput.dataType === EDataType.CURRENCY_UNIT) {
      value = unit;
    } else if (
      dataInput.dataType === EDataType.CATEGORY_METRIC ||
      dataInput.dataType === EDataType.CATEGORY_DIMENSION
    ) {
      value = category;
    } else {
      value = {
        account: 'qianqian0.2.1',
        cloudAccount: '2nhpss96tcsg@aloudata.com',
        email: 'yunhe.sun@aloudata.com',
        nickname: 'qianqian0.2.1',
        userId: '349134354502397952',
      };
    }
    _.set(properties, `${propertyType}.${name}.value`, value);
  });
  return properties;
}
