import { TSingleAttributionTableRow } from '@/services/queryAttribution/types';

// 对比方式
export enum EComparisonType {
  // 日环比
  DOD = 'DAY-OVER-DAY',
  // 周同比
  WOW = 'WEEK-OVER-WEEK',
  // 月同比
  MOM = 'MONTH-OVER-MONTH',
  // 季同比
  QOQ = 'QUARTER-OVER-QUARTER',
  // 年同比
  YOY = 'YEAR-OVER-YEAR',
  // 自定义
  CUSTOMIZE = 'CUSTOMIZE',
}

// 对比方式
export interface IAttributionAnalysisRange {
  // 当前分析日期
  current: string;
  // 对比方式
  comparisonType: EComparisonType;
  // 对比日期
  comparison: string;
}

// 单个影响因素数据
export interface ISingleInfluenceFactor extends TSingleAttributionTableRow {
  dimension: string;
}

// 维度值表格数据
export interface IDimensionValueAnalysisData
  extends Omit<TSingleAttributionTableRow, 'relativeContributionRate'> {
  parentKey: string;
  depth: number;
  key: string;
  dimension: string;
  children?: IDimensionValueAnalysisData[];
}
