import { graphql } from 'msw';
import { getMockProperty, getMockPropertyList } from './mock';
import {
  EAssetPropertyType,
  EDataType,
  EEnableStatus,
  EInputType,
  EOwnerType,
  EPropertyType,
  TAssetProperty,
} from '../../src/typings/property';
import { METRIC_LEVEL } from '../../src/pages/Property/List/index';

export const property = [
  graphql.mutation('addAssetPropertyDef', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('updateAssetPropertyDef', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('updateAssetPropertyDefStatus', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
    );
  }),

  graphql.query('queryAssetPropertyDef', (req, res, ctx) => {
    if (req.variables.name === METRIC_LEVEL) {
      return res(
        ctx.data({
          queryAssetPropertyDef: {
            name: 'metricLevel',
            displayName: '指标等级',
            description: '',
            isRequired: true,
            isCreatingDisplayed: true,
            propertyType: EPropertyType.BASE,
            status: EEnableStatus.OPEN,
            dataInput: {
              dataType: EDataType.TEXT,
              inputType: EInputType.SELECT_INTERNAL,
              options: [
                {
                  internalValue: 'T1',
                  value: 'level1',
                },
                {
                  internalValue: 'T2',
                  value: 'level2',
                },
                {
                  internalValue: 'T3',
                  value: 'level3',
                },
                {
                  internalValue: 'T4',
                  value: 'level4',
                },
                {
                  internalValue: 'T5',
                  value: 'level5',
                },
                {
                  internalValue: 'T6',
                  value: 'level6',
                },
              ],
            },
            owner: {
              type: EOwnerType.SYSTEM,
            },
            type: EAssetPropertyType.METRIC,
            frontDef: '',
          } as TAssetProperty,
        }),
      );
    }

    return res(
      ctx.data({
        queryAssetPropertyDef: getMockProperty(
          'enname',
          EAssetPropertyType.DIMENSION,
        ),
      }),
      ctx.delay(1000),
    );
  }),

  graphql.query('queryAllAssetPropertyDefList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAllAssetPropertyDefList: getMockPropertyList(req.variables.type),
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('moveAssetPropertyDef', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),
];
