import styles from './index.less';
import { InputNumber } from '@aloudata/aloudata-design';
import _ from 'lodash';
import { IBaseProps } from '../../types';

export default function NumberInput(props: IProps) {
  const { value = 0, onChange, ratio, className, disabled } = props;

  const HUNDRED = 100;

  const radioProps = ratio
    ? {
        formatter: (val?: number | string) => `${val}%`,
        parser: (val?: string) => val?.replace('%', '') as unknown as number,
      }
    : {};

  return (
    <InputNumber
      key={ratio ? 'ratio' : 'number'}
      value={ratio ? value * HUNDRED : value}
      {...radioProps}
      className={className || styles.input}
      onChange={(val) => {
        if (!_.isNumber(val)) {
          return;
        }
        let finalVal = val;
        if (ratio) {
          finalVal = val / HUNDRED;
        }
        onChange?.(finalVal);
      }}
      disabled={disabled}
    />
  );
}

interface IProps extends IBaseProps {
  value?: number;
  onChange?: (value: number) => void;
  // 是否是百分比
  ratio?: boolean;
}
