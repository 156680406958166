import { IUser } from '@/typings';
import { User as AldUser } from '@aloudata/aloudata-design';
interface IProps {
  user?: IUser;
  size?: 'small' | 'large';
}

const User = ({ size, user }: IProps) => {
  if (!user) {
    return <>{'-'}</>;
  }
  const src = user.photo;
  const nickName = user.nickname;
  return <AldUser src={src || ''} nickname={nickName || ''} size={size} />;
};

export default User;
