import t from '@/locales';
import { EBinaryOperator } from '@/typings/formula';
import { EColumnDataType } from '@/typings';
import { EFilterType, ELooseDataType, ENumberRangeOperator } from './types';

export const looseDataTypeMap = {
  [EColumnDataType.INT]: ELooseDataType.NUMBER,
  [EColumnDataType.DECIMAL]: ELooseDataType.NUMBER,
  [EColumnDataType.DOUBLE]: ELooseDataType.NUMBER,
  [EColumnDataType.TEXT]: ELooseDataType.TEXT,
  [EColumnDataType.DATE]: ELooseDataType.TIME,
  [EColumnDataType.DATE_TIME]: ELooseDataType.TIME,
  [EColumnDataType.BOOLEAN]: ELooseDataType.BOOLEAN,
  [EColumnDataType.JSON]: ELooseDataType.JSON,
};

export const operatorMap = {
  [ENumberRangeOperator.LESS]: EBinaryOperator.LESS_THAN,
  [ENumberRangeOperator.LESS_OR_EQUAL]: EBinaryOperator.LESS_THAN_EQUAL,
};

export const commonOperators = [
  {
    label: t.components.filter.operator.common.isNull,
    value: EFilterType.IS_NULL,
  },
  {
    label: t.components.filter.operator.common.notNull,
    value: EFilterType.NOT_NULL,
  },
];
