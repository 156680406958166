import { rest } from 'msw';
export const reportEvent = [
  rest.post('/reportEvent', async (req, res, ctx) => {
    return res(
      ctx.json({
        success: true,
      }),
    );
  }),
];
