import { EApprovalOperationType } from '@/typings/approve';
import t from '@/locales';
import { EResourceType, EUserRole } from '@/typings/authority';
import { userRoleMap } from '@/constants';

export const metricApproveNodeDisplaynameMap = {
  [EApprovalOperationType.CREATE]:
    t.manage.approveSetting.metric + t.manage.approveSetting.nodeType.add,
  [EApprovalOperationType.UPDATE]:
    t.manage.approveSetting.metric + t.manage.approveSetting.nodeType.edit,
  [EApprovalOperationType.OFFLINE]:
    t.manage.approveSetting.metric + t.manage.approveSetting.nodeType.offLine,
  [EApprovalOperationType.ONLINE]:
    t.manage.approveSetting.metric + t.manage.approveSetting.nodeType.onLine,
  [EApprovalOperationType.DELETE]:
    t.manage.approveSetting.metric + t.manage.approveSetting.nodeType.delete,
  [EApprovalOperationType.GRANT_AUTH]:
    t.manage.approveSetting.metric + t.manage.approveSetting.nodeType.edit,
};

export const dimensionApproveNodeDisplaynameMap = {
  [EApprovalOperationType.CREATE]:
    t.manage.approveSetting.dimension + t.manage.approveSetting.nodeType.add,
  [EApprovalOperationType.UPDATE]:
    t.manage.approveSetting.dimension + t.manage.approveSetting.nodeType.edit,
  [EApprovalOperationType.OFFLINE]:
    t.manage.approveSetting.dimension +
    t.manage.approveSetting.nodeType.offLine,
  [EApprovalOperationType.ONLINE]:
    t.manage.approveSetting.dimension + t.manage.approveSetting.nodeType.onLine,
  [EApprovalOperationType.DELETE]:
    t.manage.approveSetting.dimension + t.manage.approveSetting.nodeType.delete,
  [EApprovalOperationType.GRANT_AUTH]:
    t.manage.approveSetting.dimension + t.manage.approveSetting.nodeType.edit,
};

// 渲染权限类型节点名称
export function getPermissionNodeDisplayName(
  approvalResourceType: EResourceType.METRIC | EResourceType.DIMENSION, // 审批类型
  operationType: EApprovalOperationType,
  approvalResourceTargetResult:
    | EUserRole.ANALYST
    | EUserRole.USAGER
    | EUserRole.OWNER, // 审批目标资源值，例如：权限审批中的权限类型-指标使用者
) {
  if (approvalResourceType === EResourceType.METRIC) {
    if (operationType !== EApprovalOperationType.GRANT_AUTH) {
      return metricApproveNodeDisplaynameMap[operationType];
    } else {
      return userRoleMap[approvalResourceTargetResult];
    }
  } else {
    if (operationType !== EApprovalOperationType.GRANT_AUTH) {
      return dimensionApproveNodeDisplaynameMap[operationType];
    } else {
      return userRoleMap[approvalResourceTargetResult];
    }
  }
}
