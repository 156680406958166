import { ReactComponent as SearchIcon } from '@/assets/icon/Search-light-line.svg';
import t from '@/locales';
import { Input } from '@aloudata/aloudata-design';
import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import styles from './index.less';
import classNames from 'classnames';
interface ICustomCSSProperties extends React.CSSProperties {
  '--init-width'?: string;
}
const DEFAULT_WIDTH = 68;
export default function InputSearch(props: IProps) {
  const {
    onSearch,
    className,
    debounce = true,
    placeholder,
    size = 'small',
    initWidth = DEFAULT_WIDTH,
    defaultValue,
    inputMode = false,
  } = props;
  const [searchValue, setSearchValue] = useState(defaultValue);
  const onPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      onSearch(_.trim((e.target as HTMLInputElement).value));
    },
    [onSearch],
  );
  const DEFAULT_DEBOUNCE_TIME = 500;
  const { run: debounceSearch } = useDebounceFn<(val: string) => void>(
    (val) => {
      onSearch(val);
    },
    {
      wait: DEFAULT_DEBOUNCE_TIME,
    },
  );
  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (debounce) {
        debounceSearch(newValue);
      }
      setSearchValue(newValue);
    },
    [debounceSearch, debounce],
  );

  return (
    <div
      className={classNames(styles.inputSearch)}
      style={{ '--init-width': initWidth + 'px' } as ICustomCSSProperties}
    >
      <Input
        className={classNames(className, {
          ['inputSearchHasValue']: searchValue?.length !== 0 || inputMode,
          ['inputMode']: inputMode,
        })}
        size={size}
        onChange={onChangeValue}
        onPressEnter={onPressEnter}
        prefix={<SearchIcon size={16} />}
        placeholder={placeholder || t.common.component.searchPlaceholder}
        allowClear
        defaultValue={defaultValue}
      />
    </div>
  );
}
interface IProps {
  onSearch: (value: string) => void;
  className?: string;
  // 是否开启防抖
  debounce?: boolean;
  placeholder?: string;
  size?: 'small' | 'middle' | 'large';
  initWidth?: number;
  defaultValue?: string;
  inputMode?: boolean; // 输入框模型
}
