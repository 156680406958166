/* eslint-disable i18n/no-chinese-character */
import t from '@/locales';
import {
  EOriginDataType,
  IConstantBooleanVal,
  IConstantNumberVal,
  IConstantStringVal,
} from '@/typings/formula';
import { EArgsType } from './helper';
export type TCellData = boolean | string | number | null;
export type TConstantVal =
  | IConstantNumberVal
  | IConstantStringVal
  | IConstantBooleanVal
  | IConstantNull;
export interface IConstantNull {
  type: EOriginDataType.VARCHAR;
  val: null;
}
export enum EDatePartOpName {
  HOUR = 'Hour',
  MINUTE = 'Minute',
  SECOND = 'Second',
  WEEKNUM = 'Week',
  MONTH = 'Month',
  DAY = 'Day',
  YEAR = 'Year',
  QUARTER = 'Quarter',
  WEEKDAY = 'Weekday',
}
export const EDatePartNameMap = {
  [EDatePartOpName.HOUR]: t.formula.datePart.hour,
  [EDatePartOpName.MINUTE]: t.formula.datePart.minute,
  [EDatePartOpName.SECOND]: t.formula.datePart.second,
  [EDatePartOpName.WEEKNUM]: t.formula.datePart.weeknum,
  [EDatePartOpName.MONTH]: t.formula.datePart.month,
  [EDatePartOpName.DAY]: t.formula.datePart.day,
  [EDatePartOpName.YEAR]: t.formula.datePart.year,
  [EDatePartOpName.QUARTER]: t.formula.datePart.quarter,
  [EDatePartOpName.WEEKDAY]: t.formula.datePart.weekday,
};
export type TDateFn = keyof typeof EDatePartOpName | 'DATETRUNC';
// ['AVERAGE', 'COUNT', 'COUNTDISTINCT', 'mix', 'min', 'sum']
export enum ECensusFn {
  AVERAGE = 'AVG',
  COUNT = 'COUNT',
  COUNTDISTINCT = 'COUNTDISTINCT',
  MIN = 'MIN',
  MAX = 'MAX',
  SUM = 'SUM',
}
export enum ECastFn {
  TEXT = 'Text',
  BOOL = 'Bool',
  JSON = 'Json',
  DATE = 'Date',
  DATETIME = 'DateTime',
  INT = 'Int',
  DOUBLE = 'Double',
  DECIMAL = 'Decimal',
}
/* ------------------------------- 以下为函数配置清单配置类型 ------------------------------ */
export default interface ISignature<T> {
  args: T[];
  returnType: EOriginDataType;
  repeated?: boolean;
  limit?: number;
}
export interface ITemplate {
  zh: string;
  en: string;
}

export enum EFunctionType {
  CAST_TYPE = '类型转换',
  TEXT = '文本',
  DATE_AND_TIME = '日期和时间',
  MATH = '数学和三角',
  LOGIC = '逻辑',
  JSON = 'Json',
  AGG = '聚合',
  WINDOW = '窗口',
  ANALYSES = '分析',
}

export interface IFunctionDesc {
  function: string;
  desc: string;
  type: string;
  grammar: string;
  formula: string;
  paramArr: {
    name: string;
    desc: string;
    typeArr: {
      type: string;
      canOmit: string;
      limit: string;
    }[];
  }[];
}
export interface IFunction<T> {
  signatures: ISignature<T>[];
  name: string;
  template?: ITemplate;
  isScalarFunction?: boolean;
  isAggFunction?: boolean;
  isWindowFunction?: boolean;
  isAnalysesFunction?: boolean;
  isRatioFunction?: boolean;
  isSubAggFunction?: boolean;
  isSubWindowFunction?: boolean;
  descZh: IFunctionDesc;
}
export interface IFunctionConfig {
  functions: {
    [name: string]: IFunction<EOriginDataType | string | 'ANY'>;
  };
  binOperators: {
    [name: string]: IFunction<EOriginDataType>;
  };
  unOperators: {
    [name: string]: IFunction<EOriginDataType>;
  };
  windowFunctions: {
    [name: string]: IFunction<EOriginDataType>;
  };
}
export interface IStringArg {
  type: EArgsType.STRING;
  val: string;
}
export interface IIntArg {
  type: EArgsType.INT;
  val: string;
}
export interface IDoubleArg {
  type: EArgsType.DOUBLE;
  val: string;
}
export interface IBooleanArg {
  type: EArgsType.BOOLEAN;
  val: string;
}
export interface IFilterFunctionsList {
  (functionItem: IFunction<{}>): boolean;
}
