import { EDateGranularityType } from '@/typings';
import { IBaseProps } from '../../types';
import { getPicker, getShowTime } from '../SpecificTimePicker';
import { DatePicker } from '@aloudata/aloudata-design';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekday from 'dayjs/plugin/weekday';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);

/**
 * 精确时间范围选择器
 */
export default function SpecificTimeRangePicker(props: IProps) {
  const { granularity, value, onChange, disabled, className } = props;
  const showTime = getShowTime(granularity);
  return (
    <DatePicker.RangePicker
      picker={getPicker(granularity)}
      showTime={showTime}
      value={value ? [dayjs(value[0]), dayjs(value[1])] : undefined}
      onChange={(values) => {
        onChange?.([values?.[0]?.valueOf()!, values?.[1]?.valueOf()!]);
      }}
      format={showTime ? `YYYY-MM-DD ${showTime.format}` : undefined}
      locale={locale}
      disabled={disabled}
      className={className}
      allowClear={false}
    />
  );
}

interface IProps extends IBaseProps {
  value?: [number, number]; // 选中的值
  onChange?: (value: [number, number]) => void;
  granularity: EDateGranularityType; // 粒度
}
