import { IUserGroup } from '@/services/user/types';
import { IUser } from '@/typings';
import { TSimpleCategory } from '@/typings/category';
import {
  EDataType,
  EInputType,
  ILongTextDataInput,
  ISelectDataInput,
  ITextDataInput,
  IUserGroupSelectDataInput,
  IUserSelectDataInput,
  TAssetProperty,
  TDataInput,
} from '@/typings/property';
import { EUserType } from '@aloudata/aloudata-design/dist/ConfigProvider/getUserList';
import _ from 'lodash';

export function isText(dataInput: TDataInput): dataInput is ITextDataInput {
  if (!dataInput) return false;
  const { dataType, inputType } = dataInput;
  return dataType === EDataType.TEXT && inputType === EInputType.TEXT;
}

export function isLongText(
  dataInput: TDataInput,
): dataInput is ILongTextDataInput {
  if (!dataInput) return false;

  const { dataType, inputType } = dataInput;
  return dataType === EDataType.TEXT && inputType === EInputType.LONG_TEXT;
}

export function isSelect(dataInput: TDataInput): dataInput is ISelectDataInput {
  if (!dataInput) return false;

  const { dataType, inputType } = dataInput;
  return dataType === EDataType.TEXT && inputType === EInputType.SELECT;
}

export function isUser(
  dataInput: TDataInput,
): dataInput is IUserSelectDataInput {
  if (!dataInput) return false;

  const { dataType, inputType } = dataInput;
  return dataType === EDataType.USER && inputType === EInputType.SELECT;
}

export function isUserGroup(
  dataInput: TDataInput,
): dataInput is IUserGroupSelectDataInput {
  if (!dataInput) return false;

  const { dataType, inputType } = dataInput;
  return dataType === EDataType.USER_GROUP && inputType === EInputType.SELECT;
}

export function isCategory(dataInput: TDataInput) {
  if (!dataInput) return false;

  const { dataType } = dataInput;
  return (
    dataType === EDataType.CATEGORY_DIMENSION ||
    dataType === EDataType.CATEGORY_METRIC
  );
}

export function isTimestamp(dataInput: TDataInput) {
  if (!dataInput) return false;

  const { dataType } = dataInput;
  return dataType === EDataType.TIMESTAMP;
}

export function isUnit(dataInput: TDataInput) {
  if (!dataInput) return false;

  const { dataType } = dataInput;
  return dataType === EDataType.CURRENCY_UNIT;
}

export function sortProperties(data: TAssetProperty[]): TAssetProperty[] {
  if (!data) {
    return [];
  }

  const sortList: TAssetProperty[] = [];
  while (data.length > 0) {
    let index = 0;
    if (sortList.length === 0) {
      index = data.findIndex((item) => !item.frontDef);
    } else {
      index = data.findIndex(
        (item) => item.frontDef === _.last(sortList)!.name,
      );
    }
    sortList.push(data.splice(index, 1)[0]);
  }
  return sortList;
}

export function convert(
  data: Record<string, { value?: unknown }>,
): Record<string, unknown> {
  return _.reduce(
    data,
    (result, val, key) => {
      const { value } = val;
      if ((value as IUser)?.userId) {
        result[key] = {
          ...(value as IUser),
          type: EUserType.USER,
        };
      } else if ((value as IUserGroup)?.groupId) {
        result[key] = {
          ...(value as IUserGroup),
          type: EUserType.USER_GROUP,
        };
      } else if (
        Array.isArray(value) &&
        _.last(value as TSimpleCategory[])?.id
      ) {
        result[key] = _.last(value as TSimpleCategory[])?.id;
      } else {
        result[key] = val.value;
      }

      return result;
    },
    {} as Record<string, unknown>,
  );
}

export function convertBack(data: Record<string, unknown>) {
  return _.reduce(
    data,
    (result, value, key) => {
      if ((value as IUser)?.userId) {
        result[key] = (value as IUser).userId;
      } else if ((value as IUserGroup)?.groupId) {
        result[key] = (value as IUserGroup).groupId;
      } else if ((value as TSimpleCategory)?.id) {
        result[key] = (value as TSimpleCategory).id;
      } else {
        result[key] = value;
      }

      return result;
    },
    {} as Record<string, unknown>,
  );
}
