import { EVizType, IBaseField } from '../../typing';

export enum EAxisOrient {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  TOP = 'top',
}

export interface IVizAxisOrient {
  [yId: string]: EAxisOrient;
}

export interface IDefaultLineStyleParams {
  categoryAxisData: IBaseField | null;
  valueAxisDataArr: IBaseField[];
  axisOrient: IVizAxisOrient;
  showLegend?: boolean;
}
export interface IDefaultBarStyleParams {
  categoryAxisData: IBaseField | null;
  valueAxisDataArr: IBaseField[];
  axisOrient: IVizAxisOrient;
  type: EVizType;
  showLegend?: boolean;
}

export interface IDefaultScatterStyleParams {
  categoryAxisData: IBaseField | null;
  valueAxisDataArr: IBaseField[];
  axisOrient: IVizAxisOrient;
  showLegend?: boolean;
}

export const textStyle = {
  fontSize: 12,
  color: '#4B5563',
};

export interface IDefaultComboStyleParams {
  categoryAxisData: IBaseField | null;
  valueAxisDataArr: IBaseField[];
  axisOrient: IVizAxisOrient;
  comboConfig: IComboConfig;
  showLegend?: boolean;
}

export interface IComboConfig {
  [key: string]: EVizType;
}
