import { graphql, rest } from 'msw';
import { mockCategoryList } from './mockData';
import { isPossibilityTrue } from '../utils';
export const category = [
  // 获取权限列表
  graphql.query('queryCategory', (req, res, ctx) => {
    return res(
      ctx.data({
        queryCategory: mockCategoryList.sort(function () {
          return Math.random() - 0.5;
        }),
      }),
      ctx.delay(1000)
    );
  }),

  // addCategory
  graphql.mutation('addCategory', (req, res, ctx) => {
    // 随机生成一个类型为string的id
    const categoryId = Math.random().toString(36).substr(2);
    return res(
      ctx.data({
        addCategory: categoryId,
      }),
      ctx.delay(100),
    );
  }),
  // deleteCategory
  graphql.mutation('deleteCategory', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteCategory: true,
      }),
      ctx.delay(100),
    );
  }),
  // editCategory
  graphql.mutation('editCategory', (req, res, ctx) => {
    return res(
      ctx.data({
        editCategory: true,
      }),
      ctx.delay(100),
    );
  }),
  // moveResource
  graphql.mutation('moveResource', (req, res, ctx) => {
    return res(
      ctx.data({
        moveResource: true,
      }),
      ctx.delay(100),
    );
  }),
];
