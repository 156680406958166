import { visitFormulaAst } from '@/components/Formula/functions/iterate';
import { wrapColumnRef } from '@/components/Formula/helper';
import { TDatasetRelationRequest } from '@/typings/dataset';
import { EFormulaType, IColumnFormula, TFormula } from '@/typings/formula';
import _ from 'lodash';
import { IServerDataset } from './types';
import { IAuthorityResource } from '@/typings/authority';

/**
 * 为数据集的关联列表中的 srcAst 添加 srcDatasetName
 */
export function addSrcDatasetName2Relations(
  currDataset: IServerDataset,
): IServerDataset {
  const { relations } = currDataset;

  return {
    ...currDataset,
    relations: _.map(relations, (relation) => {
      return addSrcDatasetName2Relation(relation, currDataset.name);
    }),
  };
}

/**
 * 为关联配置中的 srcAst 添加当前数据集 name
 * @param relations
 * @param currDatasetName
 */
export function addSrcDatasetName2Relation(
  relation: TDatasetRelationRequest,
  currDatasetName: string,
): TDatasetRelationRequest {
  const { relationConditions, filters } = relation;
  return {
    ...relation,
    relationConditions: _.map(relationConditions, (condition) => {
      const { srcAst } = condition;
      return {
        ...condition,
        srcAst: addDatasetName2SrcAst(srcAst, currDatasetName),
      };
    }),
    filters: _.map(filters, (filter) => {
      return addDatasetName2SrcAst(filter, currDatasetName);
    }),
  };
}

function addDatasetName2SrcAst(ast: TFormula, datasetName: string) {
  return visitFormulaAst(ast, {
    [EFormulaType.COLUMN]: (node) => {
      const { path } = node as IColumnFormula;
      if (path.length === 1) {
        const [columnName] = path;
        return wrapColumnRef(columnName, datasetName);
      }
      return node;
    },
  });
}

// 判断是否拥有使用权限
export function checkHasAuth(authorityResource: IAuthorityResource) {
  return (
    authorityResource &&
    authorityResource.authority &&
    authorityResource.authority.canUsage
  );
}
