import {
  useGqlQuery,
  useGqlMutation,
  IUseGqlQueryOptions,
  useGqlLazyQuery,
} from '@/common/utils/gqlRequest';
import { IMonitorConfig, IMonitorResult } from '@/typings/monitor';
import { gql } from '@apollo/client';
import {
  IMonitorConfigRequest,
  TMonitorPushChannel,
  ITestMonitorConfigRequest,
  IMonitorLevelDefResponse,
  IMonitorPushContentTemplate,
} from './types';
import { monitorConfig, monitorResult } from './gql';
import { IListItems } from '@/typings';

/**
 * 获取监控详情
 */
export function useQueryMonitorConfig(
  params: { id: string },
  options: IUseGqlQueryOptions<{
    queryMonitorConfig: IMonitorConfig;
  }>,
) {
  const schema = gql`
    query queryMonitorConfig($id: String) {
      queryMonitorConfig(id: $id) {
       ${monitorConfig}
      }
    }
  `;
  return useGqlQuery<
    {
      queryMonitorConfig: IMonitorConfig;
    },
    {
      id: string;
    }
  >(schema, params, options);
}

/**
 * 新建监控
 */
export function useCreateMonitorConfig() {
  const schema = gql`
    mutation createMonitorConfig($monitorConfigRequest: MonitorConfigRequest) {
      createMonitorConfig(monitorConfigRequest: $monitorConfigRequest)
    }
  `;
  return useGqlMutation<
    boolean,
    { monitorConfigRequest: IMonitorConfigRequest }
  >(schema);
}

/**
 * 修改监控
 */
export function useUpdateMonitorConfig() {
  const schema = gql`
    mutation updateMonitorConfig($monitorConfigRequest: MonitorConfigRequest) {
      updateMonitorConfig(monitorConfigRequest: $monitorConfigRequest)
    }
  `;
  return useGqlMutation<
    boolean,
    { monitorConfigRequest: IMonitorConfigRequest }
  >(schema);
}

/**
 * 测试推送渠道，向所有渠道发送推送消息
 * 理论上应该用 restful 接口，但因为入参结构较复杂，服务端不想在 restful 接口上再定义一遍，所以这里用 gql
 */
export function useTestMonitorConfig() {
  const schema = gql`
    mutation testMonitorConfig(
      $monitorConfigTestRequest: MonitorConfigTestRequest
    ) {
      testMonitorConfig(monitorConfigTestRequest: $monitorConfigTestRequest)
    }
  `;
  return useGqlMutation<
    boolean,
    { monitorConfigTestRequest: ITestMonitorConfigRequest }
  >(schema);
}

/**
 * 获取渠道列表
 */
export function useQueryPushChannels() {
  const schema = gql`
    query queryPushChannels {
      queryPushChannels {
        type
        config
      }
    }
  `;
  return useGqlQuery<{ queryPushChannels: TMonitorPushChannel[] }, {}>(schema);
}

//  删除监控
export function useDeleteMonitorConfig() {
  const schema = gql`
    mutation deleteMonitorConfig($id: String) {
      deleteMonitorConfig(id: $id)
    }
  `;
  const { run, loading } = useGqlMutation<boolean, { id: string }>(schema);

  return {
    deleteMonitorConfig: run,
    loading,
  };
}

// 获取监控结果列表
export const useQueryMonitorResult = (
  params: {
    monitorQueryRequest: {
      pager: {
        pageSize: number;
        pageNumber: number;
      };
      ownerId?: string;
    };
  },
  onCompleted: (data: {
    queryMonitorResultPage: IListItems<IMonitorResult>;
  }) => void,
) => {
  const schema = gql`
    query queryMonitorResultPage($monitorQueryRequest:MonitorQueryRequest) {
      queryMonitorResultPage(monitorQueryRequest: $monitorQueryRequest) {
        data {
          ${monitorResult} 
          } 
        total
        pageNumber
        pageSize
        hasNext
      }
    }
  `;

  const { data, refetch, loading } = useGqlQuery<
    {
      queryMonitorResultPage: IListItems<IMonitorResult>;
    },
    {
      monitorQueryRequest: {
        pager: {
          pageSize: number;
          pageNumber: number;
        };
        ownerId?: string;
      };
    }
  >(schema, params, {
    onCompleted,
  });

  return {
    data,
    refetch,
    loading,
  };
};

// 获取监控的历史记录
export const useQueryMonitorHistory = () => {
  const schema = gql`
    query queryMonitorRecords($id: String) {
      queryMonitorRecords(id: $id) {
       ${monitorResult} 
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryMonitorRecords: IMonitorResult[];
    },
    {
      id: string;
    }
  >(schema);

  return {
    queryMonitorHistory: run,
    loading,
  };
};

// 获取指定指标下面的监控列表
export const useQueryMonitorConfigsByMetric = () => {
  const schema = gql` 
    query queryMonitorConfigsByMetricCode($metricCode:String) {
      queryMonitorConfigsByMetricCode(metricCode:$metricCode) {
        ${monitorResult}
        }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryMonitorConfigsByMetricCode: IMonitorResult[];
    },
    {
      metricCode: string;
    }
  >(schema);

  return {
    queryMonitorConfigsByMetric: run,
    loading,
  };
};

// 获取监控等级
export const useQueryMonitorLevel = ({
  onCompleted,
}: {
  onCompleted: (data: {
    queryMonitorLevelDef: IMonitorLevelDefResponse;
  }) => void;
}) => {
  const schema = gql`
    query queryMonitorLevelDef {
      queryMonitorLevelDef {
        displayName
        name
        dataInput
      }
    }
  `;

  const { data, loading } = useGqlQuery<
    { queryMonitorLevelDef: IMonitorLevelDefResponse },
    {}
  >(
    schema,
    {},
    {
      onCompleted,
    },
  );
  return {
    data,
    loading,
  };
};

// 获取监控等级
export const useQueryMonitorPushContentTemplate = ({
  onCompleted,
}: {
  onCompleted: (data: {
    queryMonitorPushContentTemplate: IMonitorPushContentTemplate[];
  }) => void;
}) => {
  const schema = gql`
    query queryMonitorPushContentTemplate {
      queryMonitorPushContentTemplate {
        type
        pushChannel
        content
        splitDimension
      }
    }
  `;

  const { data, loading } = useGqlQuery<
    { queryMonitorPushContentTemplate: IMonitorPushContentTemplate[] },
    {}
  >(
    schema,
    {},
    {
      onCompleted,
    },
  );
  return {
    data,
    loading,
  };
};
// 获取指定指标下面的监控列表
export const useModifyMonitorStatus = () => {
  const schema = gql`
    mutation modifyMonitorStatus($status: Boolean, $monitorId: String) {
      modifyMonitorStatus(status: $status, monitorId: $monitorId)
    }
  `;

  const { run, loading } = useGqlMutation<
    {
      modifyMonitorStatus: boolean;
    },
    {
      monitorId: string;
      status: boolean;
    }
  >(schema);

  return {
    modifyMonitorStatus: run,
    loading,
  };
};
