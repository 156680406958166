import { TFilterSelectValues } from '@/components/Filter/filter';
import { EDateGranularityType } from '@/typings';

export enum ENumberRangeOperator {
  LESS = 'LESS',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
}

export interface IBaseProps {
  disabled?: boolean;
  className?: string;
  includeNull?: boolean;
}

export enum ELooseDataType {
  NUMBER = 'number',
  TEXT = 'text',
  TIME = 'time',
  BOOLEAN = 'boolean',
  JSON = 'json',
}

export enum EDependencyType {
  DIMENSION = 'DIMENSION',
  COLUMN = 'COLUMN',
  TABLE = 'TABLE',
}

export enum ERankDirection {
  // 前几名
  DESC = 'DESC',
  // 后几名
  ASC = 'ASC',
}

export type TDependency =
  | {
      type: EDependencyType.DIMENSION;
      name: string;
    }
  | {
      type: EDependencyType.COLUMN;
      columnName: string;
      datasetName: string;
    }
  | {
      type: EDependencyType.TABLE;
      tableName: string;
      columnName: string;
      tableGuid: string;
    };

export enum EFilterType {
  // 等于
  EQUAL = 'EQUAL',
  // 数字等于
  NUMBER_EQUAL = 'NUMBER_EQUAL',
  // 不等于
  NOT_EQUAL = 'NOT_EQUAL',
  // 数字不等于
  NUMBER_NOT_EQUAL = 'NUMBER_NOT_EQUAL',
  // 包含
  INCLUDE = 'INCLUDE',
  // 不包含
  NOT_INCLUDE = 'NOT_INCLUDE',
  // 以... 开头
  START_WITH = 'START_WITH',
  // 不以... 开头
  NOT_START_WITH = 'NOT_START_WITH',
  // 以... 结尾
  END_WITH = 'END_WITH',
  // 不以...结尾
  NOT_END_WITH = 'NOT_END_WITH',
  CONTAIN_ATTRIBUTE = 'CONTAIN_ATTRIBUTE',
  // 包含(json)
  CONTAIN = 'CONTAIN',
  // 不包含(json)
  NOT_CONTAIN = 'NOT_CONTAIN',
  NOT_CONTAIN_ATTRIBUTE = 'NOT_CONTAIN_ATTRIBUTE',
  EQUAL_ATTRIBUTE = 'EQUAL_ATTRIBUTE',
  NOT_EQUAL_ATTRIBUTE = 'NOT_EQUAL_ATTRIBUTE',
  // 数字范围
  NUMBER_RANGE = 'NUMBER_RANGE',
  // 小于
  LESS_THAN = 'LESS_THAN',
  // 小于等于
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  // 大于
  GREATER_THAN = 'GREATER_THAN',
  // 大于等于
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  // 超出范围
  NUMBER_OUTSIDE_RANGE = 'NUMBER_OUTSIDE_RANGE',
  // 日期范围
  DATE_RANGE = 'DATE_RANGE',
  // 超出日期范围
  DATE_OUTSIDE_RANGE = 'DATE_OUTSIDE_RANGE',
  // 晚于
  DATE_AFTER = 'DATE_AFTER',
  // 早于
  DATE_BEFORE = 'DATE_BEFORE',
  // 指定日期
  DATE_SPECIFY = 'DATE_SPECIFY',
  // 最近N天/月/年
  RECENT_N = 'RECENT_N',
  // 本...至今
  MTD = 'MTD',
  // 期初期末
  BEGIN_AND_END = 'BEGIN_AND_END',
  // is null
  IS_NULL = 'IS_NULL',
  // is not null
  NOT_NULL = 'NOT_NULL',
  // 指标满足
  MATCH_METRIC = 'MATCH_METRIC',
  // 松散排名
  RANK = 'RANK',
  // 密集排名
  RANK_DENSE = 'RANK_DENSE',
  // 连续排名
  ROW_NUMBER = 'ROW_NUMBER',
}

export enum EDateValueType {
  // 动态
  DYNAMIC = 'DYNAMIC',
  // 静态
  FIXED = 'FIXED',
}

export enum EDateDynamicOperator {
  // +
  INCREASE = 'INCREASE',
  // -
  DECREASE = 'DECREASE',
}

export enum EDateBeginAndEndDirection {
  // 年/季度/月/周 第一个
  START = 'START',
  // 年/季度/月/周 最后一个
  END = 'END',
}

export type TDateDynamicValue = {
  type: EDateValueType.DYNAMIC;
  unit: EDateGranularityType;
  operator: EDateDynamicOperator;
  value: number;
  dateTag?: string;
};

export type TDateBeginAndEndValue = {
  type: EDateDynamicOperator;
  unit: EDateGranularityType;
  direction: EDateBeginAndEndDirection;
  value: number;
  dateTag?: string;
};

export type TDateFixedValue = {
  type: EDateValueType.FIXED;
  value: number;
};

export type TDateCommonValue = TDateDynamicValue | TDateFixedValue;

export type TNumberRangeValues = {
  low: {
    operator: ENumberRangeOperator;
    value: number;
  };
  high: {
    operator: ENumberRangeOperator;
    value: number;
  };
};

export type TDateRangeValues = {
  max: TDateCommonValue;
  min: TDateCommonValue;
};

export type TStringValues = {
  value: string;
};

export type TNumberValues = {
  value: number;
};

export type TRankValues = {
  direction: ERankDirection;
  value: number;
};

export type TMatchMetricInnerValues =
  | INumberEqualFilterConfig
  | INumberNotEqualFilterConfig
  | IEqualFilterConfig
  | INotEqualFilterConfig
  | ILessThanFilterConfig
  | ILessThanOrEqualFilterConfig
  | IGreaterThanFilterConfig
  | IGreaterThanFilterConfig
  | INumberOutsideRangeFilterConfig
  | INumberRangeFilterConfig
  | IContainFilterConfig
  | INotContainFilterConfig
  | IStartWithFilterConfig
  | INotStartWithFilterConfig
  | IEndWithFilterConfig
  | INotEndWithFilterConfig
  | IIsNullFilterConfig
  | INotNullFilterConfig
  | IRankConfig
  | IRankDenseConfig
  | IRowNumberConfig
  | IDateSpecifyFilterConfig
  | IDateRangeFilterConfig
  | IOutsideDateRangeFilterConfig
  | IDateAfterFilterConfig
  | IDateBeforeFilterConfig;

export interface IMatchMetricValues {
  // 指标名称
  dependency: {
    code: string;
  };
  values: TMatchMetricInnerValues;
}

type TFilterValues =
  | TFilterSelectValues
  | TNumberRangeValues
  | TDateRangeValues
  | TStringValues
  | TNumberValues
  | TDateCommonValue
  | TDateBeginAndEndValue
  | IMatchMetricValues
  | undefined;

export type TFilterSelectValuesCollection =
  | EFilterType.EQUAL
  | EFilterType.NOT_EQUAL
  | EFilterType.CONTAIN_ATTRIBUTE
  | EFilterType.NOT_CONTAIN_ATTRIBUTE
  | EFilterType.EQUAL_ATTRIBUTE
  | EFilterType.NOT_EQUAL_ATTRIBUTE;

export type TStringValuesCollection =
  | EFilterType.INCLUDE
  | EFilterType.NOT_INCLUDE
  | EFilterType.START_WITH
  | EFilterType.NOT_START_WITH
  | EFilterType.END_WITH
  | EFilterType.NOT_END_WITH
  | EFilterType.CONTAIN
  | EFilterType.NOT_CONTAIN;

export type TNumberValuesCollection =
  | EFilterType.LESS_THAN
  | EFilterType.LESS_THAN_OR_EQUAL
  | EFilterType.GREATER_THAN
  | EFilterType.GREATER_THAN_OR_EQUAL;

interface IFilterConfigBase {
  type: EFilterType;
  values: TFilterValues;
  granularity?: EDateGranularityType;
  includeNull?: boolean;
}

interface IFilterDependency {
  dependency: TDependency;
}

export interface IEqualFilterConfig extends IFilterConfigBase {
  type: EFilterType.EQUAL;
  values: TFilterSelectValues;
}

export interface IEqualFilter extends IFilterDependency, IEqualFilterConfig {}

export interface INumberEqualFilterConfig extends IFilterConfigBase {
  type: EFilterType.NUMBER_EQUAL;
  values: TFilterSelectValues;
}

export interface INumberEqualFilter
  extends IFilterDependency,
    INumberEqualFilterConfig {}

export interface INotEqualFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_EQUAL;
  values: TFilterSelectValues;
}

export interface INumberNotEqualFilterConfig extends IFilterConfigBase {
  type: EFilterType.NUMBER_NOT_EQUAL;
  values: TFilterSelectValues;
}

export interface INumberNotEqualFilter
  extends IFilterDependency,
    INumberNotEqualFilterConfig {}

export interface INotEqualFilter
  extends IFilterDependency,
    INotEqualFilterConfig {}

export interface IContainAttributeFilterConfig extends IFilterConfigBase {
  type: EFilterType.CONTAIN_ATTRIBUTE;
  values: TFilterSelectValues;
}

export interface IContainAttributeFilter
  extends IFilterDependency,
    IContainAttributeFilterConfig {}

export interface INotContainAttributeFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_CONTAIN_ATTRIBUTE;
  values: TFilterSelectValues;
}

export interface INotContainAttributeFilter
  extends IFilterDependency,
    INotContainAttributeFilterConfig {}

export interface IEqualAttributeFilterConfig extends IFilterConfigBase {
  type: EFilterType.EQUAL_ATTRIBUTE;
  values: TFilterSelectValues;
}

export interface IEqualAttributeFilter
  extends IFilterDependency,
    IEqualAttributeFilterConfig {}

export interface INotEqualAttributeConfig extends IFilterConfigBase {
  type: EFilterType.NOT_EQUAL_ATTRIBUTE;
  values: TFilterSelectValues;
}

export interface INotEqualAttributeFilter
  extends IFilterDependency,
    INotEqualAttributeConfig {}

export interface IIncludeFilterConfig extends IFilterConfigBase {
  type: EFilterType.INCLUDE;
  values: TStringValues;
}

export interface IIncludeFilter
  extends IFilterDependency,
    IIncludeFilterConfig {}

export interface INotIncludeFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_INCLUDE;
  values: TStringValues;
}

export interface INotIncludeFilter
  extends IFilterDependency,
    INotIncludeFilterConfig {}

export interface IStartWithFilterConfig extends IFilterConfigBase {
  type: EFilterType.START_WITH;
  values: TStringValues;
}

export interface IStartWithFilter
  extends IFilterDependency,
    IStartWithFilterConfig {}

export interface INotStartWithFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_START_WITH;
  values: TStringValues;
}

export interface INotStartWithFilter
  extends IFilterDependency,
    INotStartWithFilterConfig {}

export interface IEndWithFilterConfig extends IFilterConfigBase {
  type: EFilterType.END_WITH;
  values: TStringValues;
}

export interface IEndWithFilter
  extends IFilterDependency,
    IEndWithFilterConfig {}

export interface INotEndWithFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_END_WITH;
  values: TStringValues;
}

export interface INotEndWithFilter
  extends IFilterDependency,
    INotEndWithFilterConfig {}

export interface IContainFilterConfig extends IFilterConfigBase {
  type: EFilterType.CONTAIN;
  values: TStringValues;
}

export interface IContainFilter
  extends IFilterDependency,
    IContainFilterConfig {}

export interface INotContainFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_CONTAIN;
  values: TStringValues;
}

export interface INotContainFilter
  extends IFilterDependency,
    INotContainFilterConfig {}

export interface ILessThanFilterConfig extends IFilterConfigBase {
  type: EFilterType.LESS_THAN;
  values: TNumberValues;
}

export interface ILessThanFilter
  extends IFilterDependency,
    ILessThanFilterConfig {}

export interface ILessThanOrEqualFilterConfig extends IFilterConfigBase {
  type: EFilterType.LESS_THAN_OR_EQUAL;
  values: TNumberValues;
}

export interface ILessThanOrEqualFilter
  extends IFilterDependency,
    ILessThanOrEqualFilterConfig {}

export interface IGreaterThanFilterConfig extends IFilterConfigBase {
  type: EFilterType.GREATER_THAN;
  values: TNumberValues;
}

export interface IGreaterThanFilter
  extends IFilterDependency,
    IGreaterThanFilterConfig {}

export interface IGreaterThanOrEqualFilterConfig extends IFilterConfigBase {
  type: EFilterType.GREATER_THAN_OR_EQUAL;
  values: TNumberValues;
}

export interface IGreaterThanOrEqualFilter
  extends IFilterDependency,
    IGreaterThanOrEqualFilterConfig {}

export interface INumberOutsideRangeFilterConfig extends IFilterConfigBase {
  type: EFilterType.NUMBER_OUTSIDE_RANGE;
  values: TNumberRangeValues;
}

export interface INumberOutsideRangeFilter
  extends IFilterDependency,
    INumberOutsideRangeFilterConfig {}

export interface INumberRangeFilterConfig extends IFilterConfigBase {
  type: EFilterType.NUMBER_RANGE;
  values: TNumberRangeValues;
}

export interface INumberRangeFilter
  extends IFilterDependency,
    INumberRangeFilterConfig {}

export interface IDateRangeFilterConfig extends IFilterConfigBase {
  type: EFilterType.DATE_RANGE;
  values: TDateRangeValues;
  granularity: EDateGranularityType;
}

export interface IDateRangeFilter
  extends IFilterDependency,
    IDateRangeFilterConfig {}

export interface IOutsideDateRangeFilterConfig extends IFilterConfigBase {
  type: EFilterType.DATE_OUTSIDE_RANGE;
  values: TDateRangeValues;
  granularity: EDateGranularityType;
}

export interface IOutsideDateRangeFilter
  extends IFilterDependency,
    IOutsideDateRangeFilterConfig {}

export interface IDateAfterFilterConfig extends IFilterConfigBase {
  type: EFilterType.DATE_AFTER;
  values: TDateCommonValue;
  granularity: EDateGranularityType;
}

export interface IDateAfterFilter
  extends IFilterDependency,
    IDateAfterFilterConfig {}

export interface IDateBeforeFilterConfig extends IFilterConfigBase {
  type: EFilterType.DATE_BEFORE;
  values: TDateCommonValue;
  granularity: EDateGranularityType;
}

export interface IDateBeforeFilter
  extends IFilterDependency,
    IDateBeforeFilterConfig {}

export interface IDateSpecifyFilterConfig extends IFilterConfigBase {
  type: EFilterType.DATE_SPECIFY;
  values: TDateCommonValue;
  reservation?: string;
  granularity: EDateGranularityType;
}

export interface IDateSpecifyFilter
  extends IFilterDependency,
    IDateSpecifyFilterConfig {}

export interface IRecentNFilterConfig extends IFilterConfigBase {
  type: EFilterType.RECENT_N;
  values: {
    min: TDateDynamicValue;
    max: TDateDynamicValue;
  };
  reservation?: string;
  granularity: EDateGranularityType;
}

export interface IRecentNFilter
  extends IFilterDependency,
    IRecentNFilterConfig {}

export interface IMTDFilterConfig extends IFilterConfigBase {
  type: EFilterType.MTD;
  values: {
    min: TDateDynamicValue;
    max: TDateDynamicValue;
  };
  reservation?: string;
  granularity: EDateGranularityType;
}

export interface IMTDFilter extends IFilterDependency, IMTDFilterConfig {}

export interface IBeginAndEndFilterConfig extends IFilterConfigBase {
  type: EFilterType.BEGIN_AND_END;
  values: TDateBeginAndEndValue;
  granularity: EDateGranularityType;
  reservation?: string;
}

export interface IBeginAndEndFilter
  extends IFilterDependency,
    IBeginAndEndFilterConfig {}

export interface IIsNullFilterConfig extends IFilterConfigBase {
  type: EFilterType.IS_NULL;
  values: undefined;
}

export interface IIsNullFilter extends IFilterDependency, IIsNullFilterConfig {}

export interface INotNullFilterConfig extends IFilterConfigBase {
  type: EFilterType.NOT_NULL;
  values: undefined;
}

export interface IMatchMetricFilter
  extends IFilterDependency,
    IMatchMetricConfig {}

export interface IMatchMetricConfig extends IFilterConfigBase {
  type: EFilterType.MATCH_METRIC;
  values: IMatchMetricValues;
}

export interface IRankConfig extends IFilterConfigBase {
  type: EFilterType.RANK;
  values: TRankValues;
}

export interface IRankFilter extends IFilterDependency, IRankConfig {}

export interface IRankDenseConfig extends IFilterConfigBase {
  type: EFilterType.RANK_DENSE;
  values: TRankValues;
}

export interface IRankDenseFilter extends IFilterDependency, IRankDenseConfig {}

export interface IRowNumberConfig extends IFilterConfigBase {
  type: EFilterType.ROW_NUMBER;
  values: TRankValues;
}

export interface IRowNumberFilter extends IFilterDependency, IRowNumberConfig {}

export interface INotNullFilter
  extends IFilterDependency,
    INotNullFilterConfig {}

export type TFixedDateFilter =
  | IDateRangeFilter
  | IOutsideDateRangeFilter
  | IDateSpecifyFilter;

export type TNumberFilter =
  | INumberEqualFilter
  | INumberNotEqualFilter
  | IGreaterThanFilter
  | IGreaterThanOrEqualFilter
  | ILessThanFilter
  | ILessThanOrEqualFilter
  | INumberRangeFilter
  | INumberOutsideRangeFilter;

export type TStringFilter =
  | IEqualFilter
  | INotEqualFilter
  | IIncludeFilter
  | INotIncludeFilter
  | IStartWithFilter
  | INotStartWithFilter
  | IEndWithFilter
  | INotEndWithFilter;

export type TBooleanFilter = IEqualFilter | INotEqualFilter;

export type TJsonFilter = IContainFilter | INotContainFilter;

export type TFilter =
  | IIncludeFilter
  | INotIncludeFilter
  | IStartWithFilter
  | INotStartWithFilter
  | IEndWithFilter
  | INotEndWithFilter
  | IContainAttributeFilter
  | IContainFilter
  | INotContainFilter
  | INotContainAttributeFilter
  | IEqualAttributeFilter
  | INumberEqualFilter
  | INotEqualAttributeFilter
  | INumberNotEqualFilter
  | INumberRangeFilter
  | ILessThanFilter
  | ILessThanOrEqualFilter
  | IGreaterThanFilter
  | IGreaterThanOrEqualFilter
  | INumberOutsideRangeFilter
  | IDateRangeFilter
  | IOutsideDateRangeFilter
  | IDateAfterFilter
  | IDateBeforeFilter
  | IDateSpecifyFilter
  | IRecentNFilter
  | IMTDFilter
  | IBeginAndEndFilter
  | IEqualFilter
  | INotEqualFilter
  | IIsNullFilter
  | INotNullFilter
  | IMatchMetricFilter
  | IRankFilter
  | IRankDenseFilter
  | IRowNumberFilter;

export type TFilterConfig =
  | IIncludeFilterConfig
  | INotIncludeFilterConfig
  | IStartWithFilterConfig
  | INotStartWithFilterConfig
  | IEndWithFilterConfig
  | INotEndWithFilterConfig
  | IContainAttributeFilterConfig
  | IContainFilterConfig
  | INotContainFilterConfig
  | INotContainAttributeFilterConfig
  | IEqualAttributeFilterConfig
  | INotEqualAttributeConfig
  | INumberRangeFilterConfig
  | ILessThanFilterConfig
  | ILessThanOrEqualFilterConfig
  | IGreaterThanFilterConfig
  | IGreaterThanOrEqualFilterConfig
  | INumberOutsideRangeFilterConfig
  | IDateRangeFilterConfig
  | IOutsideDateRangeFilterConfig
  | IDateAfterFilterConfig
  | IDateBeforeFilterConfig
  | IDateSpecifyFilterConfig
  | IRecentNFilterConfig
  | IMTDFilterConfig
  | IBeginAndEndFilterConfig
  | IEqualFilterConfig
  | INumberEqualFilterConfig
  | INotEqualFilterConfig
  | INumberNotEqualFilterConfig
  | IIsNullFilterConfig
  | INotNullFilterConfig
  | INotContainAttributeFilterConfig
  | IRankConfig
  | IRankDenseConfig
  | IRowNumberConfig;
