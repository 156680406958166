import { EColumnDataType } from '@/typings';
import { EFilterType, ELooseDataType, IBaseProps } from '../../../types';
import { Dropdown, MenuItemType, Select } from '@aloudata/aloudata-design';
import { looseDataTypeMap } from '../../../constants';
import { useMemo } from 'react';
import t from '@/locales';

export const operatorLooseDataTypeMap = {
  [ELooseDataType.TIME]: [
    {
      label: t.components.filter.operator.date.specific,
      value: EFilterType.DATE_SPECIFY,
    },
    {
      label: t.components.filter.operator.date.range,
      value: EFilterType.DATE_RANGE,
    },
    {
      label: t.components.filter.operator.date.outOfRange,
      value: EFilterType.DATE_OUTSIDE_RANGE,
    },
    {
      label: t.components.filter.operator.date.earlier,
      value: EFilterType.DATE_BEFORE,
    },
    {
      label: t.components.filter.operator.date.later,
      value: EFilterType.DATE_AFTER,
    },
    {
      label: t.components.filter.operator.date.matchMetric,
      value: EFilterType.MATCH_METRIC,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.NUMBER]: [
    {
      label: t.components.filter.operator.number.equal,
      value: EFilterType.EQUAL,
    },
    {
      label: t.components.filter.operator.number.notEqual,
      value: EFilterType.NOT_EQUAL,
    },
    {
      label: t.components.filter.operator.number.greater,
      value: EFilterType.GREATER_THAN,
    },
    {
      label: t.components.filter.operator.number.greaterEqual,
      value: EFilterType.GREATER_THAN_OR_EQUAL,
    },
    {
      label: t.components.filter.operator.number.less,
      value: EFilterType.LESS_THAN,
    },
    {
      label: t.components.filter.operator.number.lessEqual,
      value: EFilterType.LESS_THAN_OR_EQUAL,
    },
    {
      label: t.components.filter.operator.number.between,
      value: EFilterType.NUMBER_RANGE,
    },
    {
      label: t.components.filter.operator.number.outOfRange,
      value: EFilterType.NUMBER_OUTSIDE_RANGE,
    },
    {
      label: t.components.filter.operator.number.matchMetric,
      value: EFilterType.MATCH_METRIC,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.TEXT]: [
    {
      label: t.components.filter.operator.text.equal,
      value: EFilterType.EQUAL,
    },
    {
      label: t.components.filter.operator.text.notEqual,
      value: EFilterType.NOT_EQUAL,
    },
    {
      label: t.components.filter.operator.text.include,
      value: EFilterType.INCLUDE,
    },
    {
      label: t.components.filter.operator.text.exclude,
      value: EFilterType.NOT_INCLUDE,
    },
    {
      label: t.components.filter.operator.text.startWith,
      value: EFilterType.START_WITH,
    },
    {
      label: t.components.filter.operator.text.notStartWith,
      value: EFilterType.NOT_START_WITH,
    },
    {
      label: t.components.filter.operator.text.endWith,
      value: EFilterType.END_WITH,
    },
    {
      label: t.components.filter.operator.text.notEndWith,
      value: EFilterType.NOT_END_WITH,
    },
    {
      label: t.components.filter.operator.text.matchMetric,
      value: EFilterType.MATCH_METRIC,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.BOOLEAN]: [
    {
      label: t.components.filter.operator.boolean.equal,
      value: EFilterType.INCLUDE,
    },
    {
      label: t.components.filter.operator.boolean.exclude,
      value: EFilterType.NOT_INCLUDE,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
  [ELooseDataType.JSON]: [
    {
      label: t.components.filter.operator.json.include,
      value: EFilterType.CONTAIN,
    },
    {
      label: t.components.filter.operator.json.notInclude,
      value: EFilterType.NOT_CONTAIN,
    },
    {
      label: t.components.filter.operator.common.isNull,
      value: EFilterType.IS_NULL,
    },
    {
      label: t.components.filter.operator.common.notNull,
      value: EFilterType.NOT_NULL,
    },
  ],
};

/**
 * 操作符
 */
export default function DimensionOperatorInDerivedMetric(props: IProps) {
  const {
    columnDataType,
    value,
    onChange,
    className,
    hasMatchMetric = true,
  } = props;

  const looseDataType = looseDataTypeMap[columnDataType];

  const items = useMemo(() => {
    const menuItems: MenuItemType[] = [];

    let hasDivider = false;
    operatorLooseDataTypeMap[looseDataType].forEach((optItem) => {
      if (optItem.value === EFilterType.MATCH_METRIC) {
        menuItems.push({
          type: 'divider',
        });
        hasDivider = true;
      }
      if (optItem.value === EFilterType.IS_NULL && !hasDivider) {
        menuItems.push({
          type: 'divider',
        });
        hasDivider = true;
      }

      menuItems.push({
        key: optItem.value,
        label: optItem.label,
        onClick: () => onChange?.(optItem.value),
      });
    });

    return menuItems;
  }, [looseDataType, onChange]);

  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) => hasMatchMetric || item.key !== EFilterType.MATCH_METRIC,
        ),
      }}
    >
      <Select
        className={className}
        labelInValue
        options={operatorLooseDataTypeMap[looseDataType].filter(
          (item) => hasMatchMetric || item.value !== EFilterType.MATCH_METRIC,
        )}
        value={value}
        open={false}
      />
    </Dropdown>
  );
}

interface IProps extends IBaseProps {
  value?: EFilterType;
  onChange?: (value: EFilterType) => void;
  columnDataType: EColumnDataType;
  hasMatchMetric?: boolean;
}
