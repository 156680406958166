import { useLocation, useParams } from 'umi';

/**
 * 获取分析视图ID
 */
export function useAnalysisViewId(): number | undefined {
  const { id } = useParams();
  if (isCreateMode()) {
    return undefined;
  }
  return parseInt(id!, 10);
}

export function useIsDraft(): boolean {
  const { pathname } = useLocation();
  return pathname.includes('draft');
}

export function isCreateMode() {
  const pathname = window.location.pathname;
  return pathname.includes('create');
}
