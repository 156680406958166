import { createPrefix } from '@/common';

const prefix = createPrefix('dashboard_');

export const DashboardIds = {
  sidebar: prefix('sidebar'),
  header: prefix('header'),
  toolbar: prefix('toolbar'),
  main: prefix('main'),
  footer: prefix('footer'),
  showInfo: prefix('showInfo'),
  moreBtn: prefix('moreBtn'),
  editBasic: prefix('editBasic'),
  edit: prefix('edit'),
  move: prefix('move'),
  deleteDashboard: prefix('deleteDashboard'),
  saveAsDashboard: prefix('saveAsDashboard'),
  shareDashboard: prefix('shareDashboard'),
  publish: prefix('publish'),
  exitEdit: prefix('exitEdit'),
  preview: prefix('preview'),
  exitPreview: prefix('exitPreview'),
  restore: prefix('restore'),
  titleInput: prefix('titleInput'),
  canvasWrapper: prefix('canvasWrapper'),

  filterBtn: prefix('filterBtn'),
  toolTip: prefix('toolTip'),
};
