export const define = {
  label: {
    displayName: '展示名',
    name: '名称',
    category: '维度分类',
    description: '描述',
    owner: '负责人',
    dataset: '数据集',
    content: '内容',
  },
  version: {
    publish: '发布上线',
    publishDraft: '发布草稿',
    editDraft: '编辑草稿',
    online: '上线',
  },
  module: {
    calcLogic: {
      title: '计算逻辑',
      description:
        '该维度映射以下数据，使用时会根据查询的指标自动切换到适合的数据。',
    },
    valueManage: {
      title: '维值管理',
      description: '设置维度的维度值信息',
      queryValue: '查询并填充',
      clear: '清除数据',
      table: {
        value: '维度值',
        name: '维度值名称',
        description: '描述',
        delete: '删除',
        placeholder: {
          value: '输入维度值',
          name: '输入维度值名称',
          description: '输入描述',
        },
      },
      errorText: {
        valueEmpty: '维度值不能为空',
        nameEmpty: '维度值名称不能为空',
        valueRepeat: '维度值重复',
        nameRepeat: '维度值名称重复',
        allEmpty: '至少包含一条维度值信息',
      },
    },
    preview: {
      title: '维值预览',
      desc: '仅展示前100条数据',
      close: '关闭',
      button: '预览数据',
    },
  },
  create: {
    success: '创建维度成功',
    default: '新建维度',
  },
  edit: {
    success: '保存维度成功',
    default: '编辑维度',
    btnTitle: '编辑',
  },
  dimensionType: {
    bind: '字段创建',
    custom: '自定义',
  },
  save: '保存草稿',
  onlySave: '保存',
  saveAndPublish: '保存并发布',
  resetToOnline: '还原为线上配置',
  close: '关闭',
  validate: {
    empty: ({ field }: { field: string | number }) => `${field}不能为空`,
    expr: '表达式不合法',
  },
  changeCategory: '调整类目',
  offLine: '下线',
  message: {
    namePattern: '维度名称只能包含字母、数字、下划线且不能使用双下划线',
  },
  input: {
    placeholder: '请输入',
  },
  list: {
    memberPicker: {
      title: '负责人:',
    },
  },
  content: {
    field: '字段',
    expr: '表达式',
  },
};

export const list = {
  title: '维度',
  category: {
    select: {
      title: '全部维度',
    },
  },
  batch: {
    adjustCategory: (value: string) => {
      return `维度 ${value} 无调整类目权限`;
    },
    transfer: (value: string) => {
      return `维度 ${value} 无转交权限`;
    },
  },
  column: {
    status: '状态',
    version: '版本',
    handle: '操作',
  },
  total: ({ total }: { total: string | number }) => `${total}个维度`,
  empty: '没找到任何内容，快来创建维度吧',
  btn: {
    createDimension: '新建维度',
    transfer: '转交',
  },
  dropdown: {
    transfer: '转交',
    view: '查看',
    edit: '编辑',
    delete: '删除',
    offLine: '下线',
    onLine: '上线',
    publish: '发布上线',
  },
};

export const detail = {
  header: {
    share: '分享',
  },
  tabTitle: {
    overview: '概览',
    define: '定义',
    version: '版本',
    authority: '权限',
  },
  modal: {
    title: '维度详情',
    close: '关闭',
    edit: '编辑',
    publishDraft: '发布草稿至线上',
  },
  title: {
    calcLogic: '计算逻辑',
  },
  define: {
    bind: '字段',
    custom: '表达式',
  },
  subTitle: {
    dataset: '数据集',
    content: '内容',
  },
  preview: {
    desc: '预览前100条维值内容',
    empty: 'json类型的维度无法预览',
  },
};

export const category = {
  all: '全部',
};
