export const colorArray = [
  '#3769AD',
  '#F28E2C',
  '#6A4389',
  '#0A9953',
  '#B82828',
  '#AD5C0B',
  '#4F3267',
  '#0D7240',
  '#832525',
];
export const highLightColors = [
  {
    line: '#3769AD',
    fill: '#F2F5FA',
  },
  {
    line: '#F28E2C',
    fill: '#FEFAF1',
  },
  {
    line: '#6A4389',
    fill: '#F9F2FB',
  },
  {
    line: '#0A9953',
    fill: '#F0FAF5',
  },
  {
    line: '#B82828',
    fill: '#FCF3F3',
  },
  {
    line: '#AD5C0B',
    fill: '#FEFAF1',
  },
  {
    line: '#4F3267',
    fill: '#F9F2FB',
  },
  {
    line: '#0D7240',
    fill: '#F0FAF5',
  },
  {
    line: '#832525',
    fill: '#FCF3F3',
  },
];
export const getColumnHighlightColor = (index: number) => {
  return highLightColors[index % colorArray.length];
};
