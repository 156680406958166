export const markdownApiDoc = `
# 指标数据查询 API
指标数据查询 API 可以查询指定维度下的指标结果。
## 1 接口请求地址
\`\`\`
https://api.anymetrics.cn/api/v1/query
\`\`\`
## 2 请求方式
POST
## 3 公共请求参数
|  参数  | 类型  | 是否必选 | 最大长度 | 描述 | 示例值 |
|  ----  | ----  | --- | ---| ---| ---|
|  user-id  | string  | 是 | 32| 当前账号| account23|
|  tenant-id  | string  | 是 | 32| 当前账号| tenant123|

user-id
\`\`\`
user-id
\`\`\`
tenant-id
\`\`\`
tenant-id
\`\`\`
## 4 请求参数

|  参数  | 类型  | 是否必选 | 最大长度 | 描述 | 示例值 |
|  ----  | ----  | --- | ---| ---| ---|
| metrics  | Array[String]  | 是 |  | 指标列表| [orderCount,orderSum]|
|  dimensions  | Array[String]  | 否 |  | 维度列表| [dimension1,dimension2]|
| filters  | Array[String] | 否 | ---| 筛选器| <pre>{  "filters": <br>["IN(['tn_3585_GraceStarrocks.default.TB_PRODUCT'/'product_id'], 22, 27, 19)"]<br>} </pre>|
| time_constraint  | String  | 否 | ---| 日期范围| <pre>{<br>"timeConstraint": "([metric_time] >=DATEADD(DateTrunc(NOW(), \"DAY\"), -(365), \"DAY\")) AND ([metric_time] < DATEADD(DateTrunc(NOW(), \"DAY\"), 1, \"DAY\"))"<br>}</pre> |
| limit  | int  | 否 | ---| 查询条数限定| 100|
| offset  | int  | 否 | ---| 查询的偏移量| 10|
| orders  | Array[String]  | 否 | ---| 查询的排序规则。引用的维度需要包含在“dimensions”参数中。| <pre>[<br>{"stories.time":"asc"},{"stories.count":"desc"},{"metricname":"asc"}<br>]</pre> |

参数说明
**metrics**
使用指标的名称进行查询，系统提供快速计算衍生的方法，如下：
\`\`\`
 "metrics": 
   [ "指标名称__{dod|wow|mom|qoq|yoy}__{value|growthvalue|growth}" ]
\`\`\`
示例：
\`\`\`
 "metrics": 
   [ "orderCount__dod__value",
     "orderSum__dod__growthvalue",
     "orderCount",
     "orderSum__dod"
   ]
\`\`\`
1. 用户传入 指标名称 时，查询的为指标本体内容
2. 添加衍生后缀 dod|wow|mom|qoq|yoy 可以快速查询衍生 同/环比值的类型，分别对应 日|周|月|季度|年。
3. 通过 value|growthvalue|growth 可以调整同环比的结果类型，分别对应 结果值|增长值|增长率，不填写则默认为 value

|  同环比  | 可用日期粒度  | 
|  ----  | ----  | 
| dod | 秒、分钟、小时、天|
|wow|秒、分钟、小时、天、周|
|mom|秒、分钟、小时、天、月|
|qoq|秒、分钟、小时、天、季度|
|yoy|秒、分钟、小时、天、月、季度、年|

**dimensions**
使用维度进行查询，系统提供了指标日期（metric time），在使用类型为日期或日期时间的维度时，可以通过后缀快速改变其粒度。不指定粒度时，认为是日（day）粒度。
1. 如果这个维度是一个非指标日期列，格式为
数据集的guid/列名称__时间粒度
例子
tn_3540_feilong_1.default.user/city：用户表的city作为维度，因为它不是维度列，所以不能加时间粒度
tn_3540_feilong_1.default.user/registor__week：用户表的注册时间字段先按照周粒度format后，作为维度，因为注册时间是时间类型的字段，所以可以加粒度，支持的粒度为year/quarter/month/week/hour/minute/second
2. 如果这个维度是指标日期，格式为
metric_time__时间粒度
例子
metric_time__month：按照指标日期的月粒度来分析
\`\`\`
"dimensions": [
  "tn_3540_feilong_1.default.user/city",
  "metric_time__day",
  "tn_3540_feilong_1.default.user/registor__week"
]
\`\`\`
## 5 响应参数
|  参数  | 类型  | 是否必选 | 最大长度 | 最大长度 | 示例值 |
|  ---  | ---  | --- | --- | --- | --- |
|code|String|是|32|接口响应码|200|
|mesage|String|是|512|消息体|请求成功|
|traceId|String|是|128|traceId|343f9ff2dcd70.292.16857691758642861|
|data|--|是|--|结果数据|--|

**data结构**
metas
|  参数  | 类型  | 是否必选 | 最大长度|描述|示例值|
|  ---  | ---  | --- | ---|---|---|
|  name  | String  | 是 | 32|字段名称|CATEGORY|
|  dataTypeName  | String  | 是 | 32|字段类型名称|VARCHAR|
|  schemaName  | String  | 是 | 128|schema名称|schema1|
|  tableName  | String  | 是 | 128|table名称|table1|
table
|  参数  | 类型  | 是否必选 | 最大长度|描述|示例值|
|  ---  | Array[Object]  |是 | ---|---|结果数组|

## 6 请求示例
请求
\`\`\`
{
    "metrics": [
        "flOrderCount"
    ],
    "dimensions": [
        "metric_time__day"
    ],
    "filters": [
        "IN(['tn_3585_GraceStarrocks.default.TB_PRODUCT'/'product_id'], 22, 27, 19)"
    ],
    "timeConstraint": "([metric_time] >= DATEADD(DateTrunc(NOW(), \"DAY\"), -(365), \"DAY\")) AND ([metric_time] < DATEADD(DateTrunc(NOW(), \"DAY\"), 1, \"DAY\"))",
    "limit": 10,
    "offset": 1,
    "orders": [
        {
            "metric_time__day": "asc"
        }
    ]
}
\`\`\`
响应
\`\`\`
{
    "data": {
        "queryId": "f8d14bba-918a-410b-b01c-87233fc70940",
        "warning": null,
        "sql": "SELECT \`t4\`.\`f0\` AS \`metric_time__day\`, \`t8\`.\`f1\` AS \`flOrderCount\`\nFROM (SELECT \`t1\`.\`f0\`, COALESCE(\`t1\`.\`f0\`, CAST('1970-01-01 00:00:00' AS DATETIME)) AS \`f1\`, (\`t1\`.\`f0\`) IS NOT NULL AS \`f2\`\nFROM (SELECT CAST(DATE_TRUNC('DAY', \`tb_order\`.\`order_date\`) AS DATETIME) AS \`f0\`\nFROM \`anymetrics\`.\`tb_order\` AS \`tb_order\`\nGROUP BY CAST(DATE_TRUNC('DAY', \`tb_order\`.\`order_date\`) AS DATETIME)) AS \`t1\`\nWHERE \`t1\`.\`f0\` >= DATE_ADD(DATE_TRUNC('DAY', CURRENT_TIMESTAMP ()), INTERVAL - 365 DAY) AND \`t1\`.\`f0\` < DATE_ADD(DATE_TRUNC('DAY', CURRENT_TIMESTAMP ()), INTERVAL 1 DAY)\nORDER BY (\`t1\`.\`f0\`) IS NULL, \`t1\`.\`f0\`\nLIMIT 10) AS \`t4\`\nLEFT JOIN (SELECT COUNT(\`tb_order0\`.\`order_id\`) AS \`f1\`, COALESCE(CAST(DATE_TRUNC('DAY', \`tb_order0\`.\`order_date\`) AS DATETIME), CAST('1970-01-01 00:00:00' AS DATETIME)) AS \`f2\`, (CAST(DATE_TRUNC('DAY', \`tb_order0\`.\`order_date\`) AS DATETIME)) IS NOT NULL AS \`f3\`\nFROM \`anymetrics\`.\`tb_order\` AS \`tb_order0\`\nWHERE CAST(DATE_TRUNC('DAY', \`tb_order0\`.\`order_date\`) AS DATETIME) >= DATE_ADD(DATE_TRUNC('DAY', CURRENT_TIMESTAMP ()), INTERVAL - 365 DAY) AND CAST(DATE_TRUNC('DAY', \`tb_order0\`.\`order_date\`) AS DATETIME) < DATE_ADD(DATE_TRUNC('DAY', CURRENT_TIMESTAMP ()), INTERVAL 1 DAY) AND (\`tb_order0\`.\`product_id\` = CAST(22 AS BIGINT) OR \`tb_order0\`.\`product_id\` = CAST(27 AS BIGINT) OR \`tb_order0\`.\`product_id\` = CAST(19 AS BIGINT))\nGROUP BY CAST(DATE_TRUNC('DAY', \`tb_order0\`.\`order_date\`) AS DATETIME)) AS \`t8\` ON \`t4\`.\`f1\` = \`t8\`.\`f2\` AND \`t4\`.\`f2\` = \`t8\`.\`f3\`\nORDER BY (\`t4\`.\`f0\`) IS NULL, \`t4\`.\`f0\`\nLIMIT 10",
        "table": {
            "columns": {
                "metric_time__day": [
                    {
                        "value": "2022-09-09 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-10 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-13 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-14 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-17 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-18 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-20 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-21 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-23 00:00:00",
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": "2022-09-25 00:00:00",
                        "flag": null,
                        "count": 1
                    }
                ],
                "flOrderCount": [
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": 1,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": 1,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    },
                    {
                        "value": null,
                        "flag": null,
                        "count": 1
                    }
                ]
            }
        },
        "metas": [
            {
                "name": "metric_time__day",
                "dataType": null,
                "dataTypeName": "DATETIME",
                "displaySize": null,
                "schemaName": null,
                "scale": null,
                "precision": null,
                "tableName": null
            },
            {
                "name": "flOrderCount",
                "dataType": null,
                "dataTypeName": "BIGINT",
                "displaySize": null,
                "schemaName": null,
                "scale": null,
                "precision": null,
                "tableName": null
            }
        ]
    },
    "success": true,
    "code": null,
    "message": null,
    "traceId": "add22b18f1524199941fc83ce26dda33.576.16940932763920003"
}
\`\`\`
`;

export const markdownBiDoc = `
# BI 工具集成说明
通过在 BI 工具中添加 Mysql 数据源，即可访问AloudataCAN 中的分析视图数据。
## 步骤1 添加 Mysql 数据源
在BI软件中添加数据源模块中，
找到 Mysql 数据源，选择添加该数据源。

## 步骤2 填写添加信息
根据数据源的要求，填入你的地址和账号密码信息

\`\`\`
地址：https://api.anymetrics.cn/api/v1/query
\`\`\`

\`\`\`
端口：3306
\`\`\`

\`\`\`
账号：test
\`\`\`

\`\`\`
密码：账号对应的登录密码
\`\`\`

## 步骤3 选择数据内容
使用 CAN 中已创建好的 分析视图 进行可视化分析。
`;
