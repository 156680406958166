export const propertyBaseTpl = `#graphql
  name
  type
  displayName
  description
  isRequired
  propertyType
  dataInput
  status
  frontDef
  owner {
    type
    owner {
      userId
      nickname
    }
  }
`;
