import { TResultDataItem } from '@/services/queryColumns/types';
import { EDateGranularityType } from '@/typings';
import { useRequest } from 'ahooks';
import queryMetricValues from './queryMetricValues';

export function useQueryMetricColumnValues() {
  const {
    data = null,
    loading,
    runAsync,
  } = useRequest<
    TResultDataItem[],
    [
      {
        metricCode: string;
        dimensionName: string;
        granularity?: EDateGranularityType;
      },
    ]
  >(queryMetricValues, {
    manual: true,
  });

  return {
    data,
    loading,
    run: runAsync,
  };
}
