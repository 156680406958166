import t from '@/locales';
import {
  EFormulaType,
  EOriginDataType,
  IFunctionOperator,
} from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
const SUBTOTAL_ARGS_LENGTH = 2;
const checkSubtotal = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { op, args, ast } = params;
  if (args.length !== 1 && args.length !== SUBTOTAL_ARGS_LENGTH) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }
  if (args.length === SUBTOTAL_ARGS_LENGTH) {
    const [, integerArg] = ast.args;
    if (integerArg.type !== EFormulaType.CONSTANT) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
    if (integerArg.val.type !== EOriginDataType.BIGINT) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
  }
  const [firstArgOriginDataType] = args;
  if (
    [
      EOriginDataType.BOOLEAN,
      EOriginDataType.INTEGER,
      EOriginDataType.INT,
      EOriginDataType.BIGINT,
      EOriginDataType.FLOAT,
      EOriginDataType.DOUBLE,
      EOriginDataType.DECIMAL,
      EOriginDataType.TIME,
      EOriginDataType.DATE,
      EOriginDataType.TIMESTAMP,
      EOriginDataType.INTERVAL_YEAR_TO_MONTH,
      EOriginDataType.INTERVAL_DAY_TO_SECOND,
      EOriginDataType.VARCHAR,
      EOriginDataType.VARBINARY,
      EOriginDataType.STRUCT,
      EOriginDataType.LIST,
      EOriginDataType.MAP,
    ].includes(firstArgOriginDataType)
  ) {
    return {
      type: ECheckResultType.ORIGIN_DATA_TYPE,
      originDataType: firstArgOriginDataType,
    };
  }
  return {
    type: ECheckResultType.ERROR,
    errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
  };
};
export default checkSubtotal;
