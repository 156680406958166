import { graphql, rest } from 'msw';
import _ from 'lodash';
import { getUniqId } from '../../src/common/utils';
import { getLoginAuthentication, getSoftIntegrationMockData } from './helper';
import {
  ELoginAuthenticationType,
  EPushMessageType,
} from '../../src/typings/softIntegration';
import { getRestfulResponse } from '../utils';

export const softIntegration = [
  graphql.query('querySoftIntegrationList', (req, res, ctx) => {
    return res(
      ctx.data({
        querySoftIntegrationList: [getSoftIntegrationMockData()],
      }),
    );
  }),
  graphql.query('querySoftIntegration', (req, res, ctx) => {
    return res(
      ctx.data({
        querySoftIntegration: getSoftIntegrationMockData(),
      }),
    );
  }),
  graphql.query('queryAllSoftIntegration', (req, res, ctx) => {
    return res(
      ctx.data({
        queryAllSoftIntegration: [
          {
            code: getUniqId(),
            displayName: '企业微信',
            softIntegration: getSoftIntegrationMockData(),
          },
        ],
      }),
    );
  }),
  graphql.mutation('saveSoftIntegration', (req, res, ctx) => {
    return res(
      ctx.data({
        saveSoftIntegration: true,
      }),
    );
  }),
  rest.get('/queryLoginAuthenticationConfigList', (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse(
          [
            ELoginAuthenticationType.ACCOUNT_PASSWORD,
            ELoginAuthenticationType.HTTP,
            ELoginAuthenticationType.ENTERPRISE_WECHAT,
          ].map(getLoginAuthentication),
        ),
      ),
    );
  }),
  graphql.mutation('saveLoginAuthenticationConfig', (req, res, ctx) => {
    const type = req.variables.type;

    return res(
      ctx.data({
        saveLoginAuthenticationConfig: getLoginAuthentication(
          type || ELoginAuthenticationType.ACCOUNT_PASSWORD,
        ),
      }),
    );
  }),

  graphql.query('queryPushMessageList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryPushMessageList: Array.from({ length: 10 })
          .fill(0)
          .map((v, i) => ({
            code: getUniqId(),
            enDisplayName: 'pushMessage' + i,
            type: _.sample([
              EPushMessageType.CUSTOM,
              EPushMessageType.ENTERPRISE_WECHAT,
            ]),
            displayName: '推送' + i,
            pushUrl: i + '.pushUrl.com',
            remark: '这是一条备注',
          })),
      }),
    );
  }),

  graphql.mutation('createPushMessage', (req, res, ctx) => {
    return res(
      ctx.data({
        createPushMessage: true,
      }),
    );
  }),

  graphql.mutation('updatePushMessage', (req, res, ctx) => {
    return res(
      ctx.data({
        updatePushMessage: true,
      }),
    );
  }),
  graphql.mutation('deletePushMessage', (req, res, ctx) => {
    return res(
      ctx.data({
        deletePushMessage: true,
      }),
    );
  }),

  // testPushMessage,
  graphql.mutation('testPushMessage', (req, res, ctx) => {
    return res(
      ctx.data({
        testPushMessage: _.sample([true, false]),
      }),
    );
  }),
];
