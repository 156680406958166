import { EFormulaType, TFormula } from '@/typings/formula';
import { derivateOriginDataType } from './derivation/originDataType';
import { TExternalInfo } from '@/components/CodeEditor/type';
import { EOriginDataType } from '@/typings';
import { parseErrorAdapter } from './parseError';
import { IFilterFunctionsList } from '../type';

export enum EParseResultType {
  PARSE_RESULT = 'PARSE_RESULT',
  ERROR = 'ERROR',
}

export interface IParseResult {
  type: EParseResultType;
  originDataType: EOriginDataType;
  ast: TFormula;
  errorMsg?: string;
}

// 解析dsl，先使用错误校验函数进行校验，如果具有错误则返回错误类型以及partial的ast
// 如果校验正确的话，则可以确保是一个正确的ast结构，则返回其推导的类型
export const parseFormulaAdapter = (
  formula: string,
  externalInfo: TExternalInfo,
  filterFunctionList: IFilterFunctionsList,
): IParseResult => {
  const parseError = parseErrorAdapter(
    formula,
    externalInfo,
    filterFunctionList,
  );

  if (parseError.errorMsg) {
    return {
      type: EParseResultType.ERROR,
      originDataType: EOriginDataType.VARCHAR,
      ast: {
        type: EFormulaType.PARTIAL,
        text: formula,
        position: [
          {
            start: 0,
            line: 1,
            text: formula,
          },
        ],
      },
      errorMsg: parseError.errorMsg,
    };
  } else {
    const { ast } = parseError;
    return {
      type: EParseResultType.PARSE_RESULT,
      originDataType: derivateOriginDataType(
        ast,
        externalInfo,
        filterFunctionList,
      ).originDataType,
      ast,
    };
  }
};
