import { TFormula } from './formula';
import { ISimpleDataset } from './dataset';
import { EColumnDataType, EOriginDataType } from '.';
import { TSimpleCategoryNode } from '@/components/CategoryTree';
import { EOnlineStatus } from './metric';
import { EPropertyType } from './property';
import { TSimpleCategory } from './category';
import { IAuthority } from './authority';
import { EApprovalOperationType } from './approve';

export interface ISimpleDimension {
  // 唯一标识
  name: string;
  // 展示名
  displayName: string;
  // 名称
  dimName: string;
  // 编码
  dimCode?: string;
  description: string;
  hasPermission: boolean;
  originDataType: EOriginDataType;
  category: Array<TSimpleCategory>;
  type: EDimensionType;
  authority: IAuthority;
  // 为true为可用
  isEnable: boolean;
}

export interface IDimensionProperties {
  [EPropertyType.BASE]: {
    // 维度名称
    dimName: {
      value: string;
    };
    // 维度编码
    dimCode: {
      value: string;
    };
    // 展示名
    dimDisplayName: {
      value: string;
    };
    [key: string]: {
      value: unknown;
    };
  };
  [EPropertyType.BUSINESS]: {
    dimDescription: {
      value: string;
    };
    dimCategoryId: {
      value: TSimpleCategoryNode[];
    };
    [key: string]: {
      value: unknown;
    };
  };
  [EPropertyType.MANAGE]: {
    [key: string]: {
      value: unknown;
    };
  };
  [EPropertyType.TECHNOLOGY]: {
    [key: string]: {
      value: unknown;
    };
  };
}
export interface IDimension extends ISimpleDimension {
  category: TSimpleCategoryNode[];
  dimensionDataType: EColumnDataType;
  // 维度创建方式
  type: EDimensionType;
  datasetName: string;
  dataset?: ISimpleDataset;
  isPublished: boolean;
  status: EOnlineStatus;
  hasDraft: boolean; // 是否有草稿
  // 维值映射
  valueList?: IValueManageItem[];
  approvalMutationStatus?: EApprovalOperationType; // 正在进行的审批操作
  approvalAuthorityStatus?: EApprovalOperationType.GRANT_AUTH; // 审批权限操作
  // 属性
  properties: IDimensionProperties;
}

export interface IValueManageItem {
  value: string; //值
  name: string; // 值名称
  description: string; // 描述信息
}

export interface IBindDimension extends IDimension {
  type: EDimensionType.BIND;
  config?: {
    columnName: string;
  };
}

export interface ICustomDimension extends IDimension {
  type: EDimensionType.CUSTOM;
  config?: {
    formula: TFormula;
  };
  layout: {
    expr: string;
  };
}

export enum EDimensionType {
  // 字段映射
  BIND = 'COLUMN_BIND',
  // 自定义
  CUSTOM = 'CUSTOM',
}

export interface IAvailableDimensions {
  code: string;
  availableDimensions: ISimpleDimension[];
}

export type TMetricCodeAvailableDimensionsMap = {
  [metricCode: string]: ISimpleDimension[];
};

export type TDimension = IBindDimension | ICustomDimension;
