export const login = {
  form: {
    account: '账户',
    password: '密码',
    submit: '登录',
    accountRequired: '登录账号错误',
    passwordRequired: '登录密码错误',
  },
  sessionOutTitle: '登录状态异常',
  sessionOutDesc: '登录状态异常，请重新登录',
  reLogin: '重新登录',
  loginType: '其他登录方式',
  authenticationType: {
    account: '账号密码',
    enterpriseWechart: '企业微信',
  },
};

export const updatePassword = {
  title: '修改密码',
  desc: '为了保障账号安全，请修改/设置你的账号密码',
  oldPassword: {
    must: '请输入旧密码',
  },
  success: '修改成功，请重新登录',
  password: {
    must: '请输入密码',
  },
  newPassword: {
    title: '新密码',
    placeholder: '输入新密码',
    must: '请输入新密码',
    format: '数字、字母、_ 、@、#、!、%?、-、()、*，必须包含数字和字母，6-20',
  },
  newPasswordRepeat: {
    title: '确认密码',
    placeholder: '重复新密码',
    must: '请再次输入密码',
    sameWithName: '密码不能与用户名相同',
    format: '新旧密码不一致',
    notSame: '两次密码不一致',
  },
  operate: {
    cancel: '取消',
    confirm: '确认',
  },
};
