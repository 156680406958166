import t from '@/locales';
import { EColumnDataType } from '@/typings';
import { Input, Modal } from '@aloudata/aloudata-design';
import { ReactComponent as InfoCircleLine } from '@/assets/icon/Information-Circle-light-line.svg';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { parseCustomValue, specialColumnValueMap } from '../helper';
import styles from './index.less';
export default function MultipleInput(props: IProps) {
  const { values, onChange, open, onClose, dataType } = props;
  const [text, setText] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (open) {
      setText(parseValues2Text(values));
      // 在 Modal 中的输入框，直接 focus 无效，需要在 setTimeout 中才生效
      setTimeout(() => {
        textAreaRef.current?.focus();
      });
    }
  }, [open, values]);
  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(e.target.value);
    },
    [],
  );
  const onSubmit = useCallback(() => {
    const newValues = parseText2Values(text, dataType);
    // 保留原来 values 里的特殊值
    const orgSpecialValues = values.filter((value) => {
      return _.values(specialColumnValueMap)
        .map((item) => item.value)
        .includes(value);
    });
    onChange([...orgSpecialValues, ...newValues]);
    onClose();
  }, [text, onChange, onClose, dataType, values]);
  return (
    <Modal
      width={380}
      open={open}
      onOk={onSubmit}
      onCancel={onClose}
      title={t.filter.filter.multipleInput.default}
      okText={t.filter.filter.btn.submit}
      cancelText={t.filter.filter.btn.cancel}
      // 默认情况下 modal 的 zIndex 比 dropdown 的低，导致 modal 无法显示在 dropdown 上方
      zIndex={2000}
    >
      <div className={styles.container}>
        <div className={styles.info}>
          <InfoCircleLine color="#3271C9" size={16} />
          <div>
            <div>{t.filter.filter.multipleInput.tip}</div>
          </div>
        </div>
        <div className={styles.textAreaContainer}>
          <Input.TextArea
            onChange={onChangeText}
            className={styles.textArea}
            value={text}
            placeholder={t.filter.filter.multipleInput.placeholder}
            onKeyDown={(e) => {
              // 禁止冒泡，否则回车不会换行，而是失焦
              e.stopPropagation();
            }}
            ref={textAreaRef}
          />
        </div>
        <div className={styles.introduce}>
          <div>{t.filter.filter.multipleInput.textRule}</div>
          <div>{t.filter.filter.multipleInput.numberRule}</div>
          <div>{t.filter.filter.multipleInput.dateRule}</div>
        </div>
      </div>
    </Modal>
  );
}
export function parseText2Values(
  text: string,
  dataType: EColumnDataType,
): string[] {
  const MAX_ITEM_COUNT = 1000;
  const rawValues = text.split('\n');
  return _(rawValues)
    .map((value) => parseCustomValue(value, dataType))
    .filter((value): value is string => {
      return !!value;
    })
    .uniq()
    .take(MAX_ITEM_COUNT)
    .value();
}
function parseValues2Text(values: string[]) {
  // 过滤掉特殊值
  return values
    .filter(
      (val) =>
        !_.values(specialColumnValueMap)
          .map((item) => item.value)
          .includes(val),
    )
    .join('\n');
}
interface IProps {
  values: string[];
  onChange: (values: string[]) => void;
  open: boolean;
  dataType: EColumnDataType;
  onClose: () => void;
}
