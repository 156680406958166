/**
 * 规则: 由大小写字母、数字、'@'、'#'、'!'、'%' 构成，必须包含字母和数字，长度为6-20字符
 * 需求文档: https://alidocs.dingtalk.com/i/nodes/mM3zoYAw1Rr8DmMmKxeMWnZ07y9NpXxD
 * 正则预览: https://wangwl.net/static/projects/visualRegex#source=%5E(%3F%3D.*%5Ba-zA-Z%5D)(%3F%3D.*%5B0-9%5D)%5Ba-zA-Z0-9%40%23!%25%5D%7B6%2C20%7D%24
 */
export const passwordReg =
  /^(?=.*[a-zA-Z])(?=.*\d)[\da-zA-Z_@#!%?\-\(\)\[\]\*]{6,20}$/;

/**
 * 昵称。至少1个字符且不超过50个字符。
 */
export const nickNameReg = /^[\S\s]{1,50}$/;

// 数据集展示名：中文、英文、数字以及标点符号和特殊符号，剔除首尾空格，不超过50个字符
export const dataSetDisplayNameReg = /^[\S\s]{1,50}$/;

// 数据集名称：英文、数字以及下划线，不超过五十个字符
export const dataSetNameReg = /^[a-zA-Z0-9_]{1,50}$/;

/**
 * 用户名正则 英文，数字，_、 -
 */
export const accountReg = /^[a-zA-Z0-9_-]{1,50}$/;

// 手机号
export const phoneReg = /^1[3-9]\d{9}$/;

// 邮箱
export const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
