import { EOriginDataType, EQueryEngineType } from '@/typings';
import { TSimpleCategory } from '@/typings/category';
import { EMetricType } from '@/typings/metric';

export interface IQueryVertexLineageReq {
  vertexId: string; // 唯一标识 ID
  vertexType: EVertexType; // 节点类型
  inputStep: number; // 输入方向查询几层
  outputStep: number; // 输出方向查询几层
}

export enum EVertexType {
  METRIC = 'METRIC', // 指标
  DATASET = 'DATASET', // 数据集
  ANALYSIS_VIEW = 'ANALYSIS_VIEW', // 分析视图
  PHYSICAL_TABLE = 'PHYSICAL_TABLE', // 物理表
  FILE = 'FILE', // 文件
  DIMENSION = 'DIMENSION', //维度
}

export interface IQueryVertexLineageRes {
  vertexList: TVertex[];
  edgeList: IEdge[];
}

export interface IVertex {
  vertexId: string; // 唯一标识 ID
  vertexType: EVertexType; // 节点类型
  displayName: string; // 显示名称
  description: string; // 描述
  hasMoreInput?: boolean; // 是否有更多上游，由前端计算
  hasMoreOutput?: boolean; // 是否有更多下游，由前端计算
}

export type TVertex = IMetricVertex | IPhysicalTableVertex | IVertex;

export interface IMetricVertex extends IVertex {
  vertexType: EVertexType.METRIC;
  custom: {
    type: EMetricType;
    category: TSimpleCategory[];
    originDataType: EOriginDataType;
  };
}

export interface IPhysicalTableVertex extends IVertex {
  vertexType: EVertexType.PHYSICAL_TABLE;
  custom: {
    datasourceType: EQueryEngineType;
    datasourceDisplayName: string;
  };
}

export interface IEdge {
  srcVertex: IEdgeVertex;
  dstVertex: IEdgeVertex;
  edgeType: EEdgeType; // 边类型
}

export interface IEdgeVertex {
  vertexId: string;
  vertexType: EVertexType;
}

export enum EEdgeType {
  TABLE_IMPACT = 'TABLE_IMPACT', // 节点关系
  COLUMN_IMPACT = 'COLUMN_IMPACT', // 列关系
}
