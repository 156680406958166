import { useGqlLazyQuery, useGqlMutation } from '@/common/utils/gqlRequest';
import { gql } from '@apollo/client';
import {
  EDimensionBatchSaveAction,
  IBatchSaveDimensionError,
  IDefineBindConfig,
  IDefineCustomConfig,
  TBatchSaveDimension,
  TDefineDimensionDraft,
} from './type';
import { EDimensionType, TDimension } from '@/typings/dimension';
import { EDateGranularityType, IListItems } from '@/typings';
import { get, post } from '@/common/utils/request';
import { IQueryResult } from '../queryColumns/types';
import { useRequest } from 'ahooks';
import { simpleDatasetTpl } from '../dataset/gql';
import { IStatusFilter } from '../metric/types';

/**
 * 新建维度
 */
export function useCreateDimension() {
  const schema = gql`
    mutation createDimension($createRequest: DimensionCreateRequest) {
      createDimension(createRequest: $createRequest)
    }
  `;
  return useGqlMutation<
    {
      createDimension: string;
    },
    { createRequest: TDefineDimensionDraft }
  >(schema);
}

/**
 * 更新维度
 */
export function useUpdateDimension() {
  const schema = gql`
    mutation updateDimension($updateRequest: DimensionUpdateRequest) {
      updateDimension(updateRequest: $updateRequest)
    }
  `;
  return useGqlMutation<boolean, { updateRequest: TDefineDimensionDraft }>(
    schema,
  );
}

/**
 * 删除自定义维度
 */
export function useDeleteCustomDimension() {
  const schema = gql`
    mutation deleteDimension($name: String) {
      deleteDimension(name: $name)
    }
  `;
  return useGqlMutation<boolean, { name: string }>(schema);
}

/**
 * 查询维度
 */
export function useQueryDimension() {
  const schema = gql`
    query queryDimension($name: String,$version:Int) {
      queryDimension(name: $name,version: $version) {
        name
        dimName
        dimCode
        description
        displayName
        type
        isPublished
        status
        hasDraft
        config {
          columnName
          formula
        }
        layout
        datasetName
        approvalMutationStatus
        approvalAuthorityStatus
        dataset {
          ${simpleDatasetTpl}
        }
        originDataType
        category {
          id
          name
        }
        valueList {
          value
          name
         description 
        }
        properties
        authority {
          canCreate
          canEdit
          canDelete
          canUsage
          canAuth
          canMove
          authRoleList
          canTransfer
        }
        owner {
          userId
          nickname
          account
          photo
        }
      }
    }
  `;

  return useGqlLazyQuery<
    { queryDimension: TDimension },
    { name: string; version?: number }
  >(schema);
}

export function getDimensionListSchema({
  withProperties = false,
  withOwner = false,
}: {
  withProperties?: boolean;
  withOwner?: boolean;
}) {
  return gql`
    query queryDimensionList($request: SearchDimensionRequest) {
      queryDimensionList(request: $request) {
        data {
          authority {
            canCreate
            canEdit
            canDelete
            canUsage
            canAuth
            canMove
            authRoleList
            canTransfer
          }
          config {
            columnName
            formula
          }
          originDataType
          name
          dimName
          dimCode
          description
          type
          isPublished
          status
          hasDraft
          displayName
          datasetName
          approvalMutationStatus
          approvalAuthorityStatus
          ${
            withProperties
              ? `
          properties
              `
              : ''
          }
          ${
            withOwner
              ? `
          owner {
            userId
            nickname
            account
            photo
          }
            `
              : ''
          }
          category {
            id
            name
          }
        }
        hasNext
        total
        pageNumber
        pageSize
      }
    }
  `;
}

/**
 * 查询维度列表
 */

export function useQueryDimensionList(opt?: {
  withProperties?: boolean;
  withOwner?: boolean;
}) {
  const { withProperties, withOwner } = opt || {};
  return useGqlLazyQuery<
    { queryDimensionList: IListItems<TDimension> },
    {
      request: {
        owners?: string[];
        keyword?: string; // 关键词
        pager: {
          pageNumber?: number;
          pageSize?: number;
        };
        datasetName?: string;
        statusFilters?: IStatusFilter[];
        categoryId?: string;
      };
    }
  >(
    getDimensionListSchema({
      withProperties,
      withOwner,
    }),
  );
}

/**
 * 查询维度值使用配置
 */
interface IQueryDimensionValuesRequest {
  name: string;
  displayName: string;
  type: EDimensionType;
  datasetName: string;
  config: IDefineBindConfig | IDefineCustomConfig;
  onlyQueryDimension: boolean;
  pager: {
    pageNumber: number;
    pageSize: number;
  };
}

export async function queryDimensionValuesByConfig(
  params: IQueryDimensionValuesRequest,
) {
  return post<IQueryResult>('/dimension/value_preview_by_config', params);
}

export function useQueryDimensionValuesByConfig() {
  const {
    data = null,
    loading,
    runAsync,
  } = useRequest<IQueryResult, [IQueryDimensionValuesRequest]>(
    queryDimensionValuesByConfig,
    {
      manual: true,
    },
  );

  return {
    data,
    loading,
    run: runAsync,
  };
}

export async function queryDimensionValues({
  name,
  version,
  pager,
  granularity,
}: {
  name: string;
  pager?: { pageNumber: number; pageSize: number };
  granularity?: EDateGranularityType;
  version?: number;
}) {
  const DEFAULT_PAGE_SIZE = 100000;
  return post<IQueryResult>('/dimension/value_preview_by_name', {
    name,
    onlyQueryDimension: true,
    granularity,
    version,
    pager: {
      pageNumber: pager?.pageNumber ?? 1,
      pageSize: pager?.pageSize ?? DEFAULT_PAGE_SIZE,
    },
  });
}

export function useQueryDimensionValues() {
  const {
    data = null,
    loading,
    runAsync,
  } = useRequest<
    IQueryResult,
    [
      {
        name: string;
        pager?: { pageNumber: number; pageSize: number };
        granularity?: EDateGranularityType;
        version?: number;
      },
    ]
  >(queryDimensionValues, {
    manual: true,
  });

  return {
    data,
    loading,
    run: runAsync,
  };
}

export const useBatchSaveDimension = (
  onSuccess: (data: { items: IBatchSaveDimensionError[] }) => void,
  onError?: () => void,
) => {
  const batchSaveDimension = (params: {
    autoResolveDuplicatedName: boolean;
    items: TBatchSaveDimension[];
  }) => {
    return post<{
      items: IBatchSaveDimensionError[];
    }>('/dimension/batch_save', params);
  };

  return useRequest<
    {
      items: IBatchSaveDimensionError[];
    },
    [
      {
        autoResolveDuplicatedName: boolean;
        items: TBatchSaveDimension[];
      },
    ]
  >(batchSaveDimension, {
    manual: true,
    onSuccess,
    onError,
  });
};

export const useCheckDimensionDimName = (onSuccess?: () => void) => {
  const checkName = (params: {
    name?: string;
    dimName: string;
    action: EDimensionBatchSaveAction;
  }) => {
    return get<{
      result: boolean;
      errorMsg: string;
    }>('/dimension/check_name', params);
  };

  return useRequest<
    {
      result: boolean;
      errorMsg: string;
    },
    [
      {
        name?: string;
        dimName: string;
        action: EDimensionBatchSaveAction;
      },
    ]
  >(checkName, {
    manual: true,
    onSuccess,
  });
};

export const useCheckDimensionDimCode = (onSuccess?: () => void) => {
  const checkCode = (params: {
    name?: string;
    dimCode: string;
    action: EDimensionBatchSaveAction;
  }) => {
    return get<{
      result: boolean;
      errorMsg: string;
    }>('/dimension/check_code', params);
  };

  return useRequest<
    {
      result: boolean;
      errorMsg: string;
    },
    [
      {
        name?: string;
        dimCode: string;
        action: EDimensionBatchSaveAction;
      },
    ]
  >(checkCode, {
    manual: true,
    onSuccess,
  });
};
