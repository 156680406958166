import {
  ECodeEditorPropsType,
  IListItem,
  TExternalInfo,
} from '@/components/CodeEditor/type';
import { IDataset, TAssociatedDataset } from '@/typings/dataset';
import t from '@/locales';
const PATH_LENGTH_TWO = 2;
export enum ECheckResultType {
  AST = 'AST',
  ERROR = 'ERROR',
}
export type TCheckResult =
  | {
      type: ECheckResultType.AST;
      path: string[];
    }
  | {
      type: ECheckResultType.ERROR;
      errorMsg: string;
    };
/**
 * 对于应用列的path进行校验，是否存在，存在则会替换displayName 返回新的path
 * @param ast
 * @param map
 * @param currentId
 * @returns TCheckResult
 */
export function checkDatasetNameRef(
  path: string[],
  dataset: IDataset | null,
  one2manyDatasets: TAssociatedDataset,
  many2oneDatasets: TAssociatedDataset,
): TCheckResult {
  // 内部引用，
  // 如果是在表中则只能引用当前表的列, 包括levelSheet是一致的
  if (path.length === 1 && dataset) {
    const [columnDisplayName] = path;
    const tempColumn = dataset.defColumns.find(
      (column) => column.displayName === columnDisplayName.trim(),
    );
    if (tempColumn)
      return {
        type: ECheckResultType.AST,
        path: [tempColumn.name],
      };
  }
  if (path.length === PATH_LENGTH_TWO) {
    const [datasetDisplayName, columnDisplayName] = path;
    const tempDataset = [...one2manyDatasets, ...many2oneDatasets].find(
      (associatedDataset) =>
        associatedDataset.displayName === datasetDisplayName,
    );
    if (tempDataset) {
      const tempColumn = tempDataset.defColumns.find(
        (column) => column.displayName === columnDisplayName.trim(),
      );
      if (tempColumn) {
        return {
          type: ECheckResultType.AST,
          path: [tempDataset.name, tempColumn.name],
        };
      }
    }
  }
  return {
    type: ECheckResultType.ERROR,
    errorMsg: `${path}${t.formula.parse.error.columnNotFound}`,
  };
}
// 指标的引用需要完全信任
export function checkListNameRef(
  path: string[],
  list: IListItem[],
): TCheckResult {
  if (path.length === 1) {
    const [itemDisplayName] = path;
    const item = list.find(
      (tempItem) => tempItem.displayName === itemDisplayName,
    );
    if (item) {
      return {
        type: ECheckResultType.AST,
        path: [item.id],
      };
    }
  }
  return {
    type: ECheckResultType.ERROR,
    errorMsg: `${path}${t.formula.parse.error.columnNotFound}`,
  };
}
export function checkNameRefAdapter(
  path: string[],
  externalInfo: TExternalInfo,
) {
  if (externalInfo.type === ECodeEditorPropsType.DATASET) {
    return checkDatasetNameRef(
      path,
      externalInfo.dataset,
      externalInfo.one2manyDatasets,
      externalInfo.many2oneDatasets || [],
    );
  } else if (externalInfo.type === ECodeEditorPropsType.LIST) {
    return checkListNameRef(path, externalInfo.list);
  } else {
    return checkListNameRef(path, [
      ...externalInfo.list,
      ...externalInfo.dimensionList,
    ]);
  }
}
