const sqlContent = `
SELECT "t6"."f1" AS "orderamount", "t2"."day_col" AS "metric_time"
FROM (SELECT "smart_date_table"."day_col", COALESCE("smart_date_table"."day_col", CAST('1969-01-01' AS DATE)) AS "f1", ("smart_date_table"."day_col") IS NOT NULL AS "f2"
FROM "catalog"."shencao"."smart_date_table" AS "smart_date_table"
WHERE "smart_date_table"."day_col" >= TIMESTAMPADDDAY(0, DATE_TRUNC('DAY', CURRENT_TIMESTAMP)) AND "smart_date_table"."day_col" < TIMESTAMPADDDAY(1, DATE_TRUNC('DAY', CURRENT_TIMESTAMP))
GROUP BY "smart_date_table"."day_col") AS "t2"
LEFT JOIN (SELECT SUM("dwd_order"."order_amount") AS "f1", COALESCE(DATE_TRUNC('DAY', "dwd_order"."order_date"), CAST('1969-01-01' AS DATE)) AS "f2", (DATE_TRUNC('DAY', "dwd_order"."order_date")) IS NOT NULL AS "f3"
FROM "anymetrics"."customerdb"."dwd_order" AS "dwd_order"
WHERE DATE_TRUNC('DAY', "dwd_order"."order_date") >= TIMESTAMPADDDAY(0, DATE_TRUNC('DAY', CURRENT_TIMESTAMP)) AND DATE_TRUNC('DAY', "dwd_order"."order_date") < TIMESTAMPADDDAY(1, DATE_TRUNC('DAY', CURRENT_TIMESTAMP))
GROUP BY DATE_TRUNC('DAY', "dwd_order"."order_date")) AS "t6" ON "t2"."f1" = "t6"."f2" AND "t2"."f2" = "t6"."f3"
WHERE "t2"."day_col" >= TIMESTAMPADDDAY(0, DATE_TRUNC('DAY', CURRENT_TIMESTAMP)) AND "t2"."day_col" < TIMESTAMPADDDAY(1, DATE_TRUNC('DAY', CURRENT_TIMESTAMP)) AND ((("t6"."f1") IS NULL)) IS FALSE
ORDER BY ("t2"."day_col") DESC
FETCH NEXT 1001 ROWS ONLY;
`;

export default sqlContent;
