import styles from './index.less';
import { SearchColumn } from '@/components/SearchColumn';
import t from '@/locales';
import { IDataset } from '@/typings/dataset';
import { Select } from '@aloudata/aloudata-design';
import { useMemo, useState } from 'react';
import dateTimeMap from '@/constants/dateTime';
import ColumnDataTypeIcon from '@/components/ColumnDataTypeIcon';
import { originDataType2DataTypeMap } from '@/components/Formula/constant';
import { IColumnPickerValue } from './types';

export default function ColumnPicker(props: IProps) {
  const {
    datasets,
    className,
    value,
    onChange,
    supportGranularity,
    supportTimeGranularity = true,
  } = props;

  const [open, setOpen] = useState(false);

  const items = useMemo(() => {
    return datasets.map((dataset) => {
      return {
        sheetId: dataset.name,
        sheetName: dataset.displayName,
        columns: dataset.columns.map((column) => ({
          dataType: column.dataType,
          displayName: column.displayName,
          name: column.name,
          id: column.name,
          columnType: column.type,
          originDataType: column.originDataType,
        })),
      };
    });
  }, [datasets]);

  const selectColumn = useMemo(() => {
    const dataset = datasets.find((ds) => ds.name === value?.datasetName);
    if (!dataset) return undefined;
    const column = dataset.columns.find(
      (col) => col.name === value?.columnName,
    );
    if (!column) return undefined;
    return column;
  }, [datasets, value]);

  const selectLabel = useMemo(() => {
    return selectColumn
      ? `${selectColumn.displayName}${
          value?.granularity ? `(${dateTimeMap[value.granularity].name})` : ''
        }`
      : undefined;
  }, [selectColumn, value]);

  const selectValue = {
    label: selectLabel,
    value: '',
  };

  return (
    <Select
      placeholder={t.components.filter.condition.pickerPlaceholder}
      showArrow={true}
      value={selectValue.label ? selectValue : undefined}
      labelInValue
      listHeight={150}
      className={className || styles.select}
      onDropdownVisibleChange={(visible) => {
        setOpen(visible);
      }}
      open={open}
      prefix={
        selectColumn ? (
          <ColumnDataTypeIcon
            dataType={originDataType2DataTypeMap[selectColumn.originDataType]}
            granularity={value?.granularity}
          />
        ) : null
      }
      options={[]}
      dropdownRender={() => {
        return (
          <SearchColumn
            hasBorder={false}
            items={items}
            supportGranularity={supportGranularity}
            supportTimeGranularity={supportTimeGranularity}
            onChange={(columnInfo, datasetName, treeKeyId, granularity) => {
              onChange?.({
                columnName: columnInfo.name,
                datasetName: datasetName || '',
                granularity,
              });
              setOpen(false);
            }}
          />
        );
      }}
    />
  );
}

interface IProps {
  datasets: IDataset[];
  className?: string;
  value?: IColumnPickerValue;
  onChange?: (value: IColumnPickerValue) => void;
  supportGranularity?: boolean;
  supportTimeGranularity?: boolean;
}
