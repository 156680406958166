export const simpleDatasetTpl = `#graphql
name
displayName
description
columns {
  name
  displayName
  description
  originDataType
  isPartition
  isFirstPartition
  description
  type
  dimension {
    name 
    displayName
   owner {
    userId
   } 
  }
}
category {
  id
  name
}
defColumns {
  name
  displayName
  description
  originDataType
  isPartition
  isFirstPartition
  description
  sourceColumn
  type
  ast
  dimension {
    name 
    owner {
      userId
     } 
  }
}
owner {
  userId
  nickname
}
updateTime
createTime
authorityResource {
  authorityRole
  authoritySource
  authority {
    canCreate
    canEdit
    canDelete
    canUsage
    canAuth
    authRoleList
    canTransfer
  }
}
sourceInfo {
 type
}
`;

export const datasetBaseTpl = `#graphql
${simpleDatasetTpl} 
  sourceInfo {
    name
    displayName
    description
    content
    type
    datasourceItems {
      id
      name
      queryEngineType
    }
    path {
      guid
      name
      entityType
    }
    datasource {
      name
      displayName
    }
  }
`;

export const datasetDetailTpl = `#graphql
  ${datasetBaseTpl}
  metricCount 
  relations {
    id
    type
    srcDatasetName
    dstDatasetName
    srcDatasetDisplayName
    dstDatasetDisplayName
   srcDataset {
    name
    displayName
    columns {
      name
      displayName 
    }
   } 
   dstDataset {
    name
    displayName
    columns {
      name
      displayName 
    }
   } 
    relationConditions {
      srcAst
      dstAst
    }
    owner {
      userId
      nickname
    }
    filters
  }
  partitionMapping {
    dateGranularity
    columnPartitionMappings {
      columnName
      interval
      sign
    }
  }
  relationFroms {
    id
    type
    srcDatasetName
    dstDatasetName
    srcDatasetDisplayName
    dstDatasetDisplayName
    srcDataset {
      name
      displayName
      columns {
        name
        displayName 
      }
     } 
     dstDataset {
      name
      displayName
      columns {
        name
        displayName 
      }
     } 
    relationConditions {
      srcAst
      dstAst
    }
    owner {
      userId
      nickname
    }
    filters
  }
`;
