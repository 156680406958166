import { EQuickCalcType, TMetricTimeConfig } from '@/typings/analysisView';
import { type TMetric } from './metric';
import { EDateGranularityType, EOriginDataType, IAuthority, IUser } from '.';
import { IAuthorityAccount } from './authority';
import { TCellData } from '@/components/Formula/type';
import { EMonitorPushChannelType } from '@/services/monitor/types';
export enum EEnterpriseWeChatMessageType {
  MARKDOWN = 'MARKDOWN',
  TXT = 'TXT',
}
export interface IPushConfig {
  // 推送渠道
  channels: IPushChannel[];
  title: string;
  content: string;
}
export interface IEnterpriseWeChatPushConfig extends IPushConfig {
  telephones: Array<{
    userId: string;
    phone?: string;
  }>;
  messageType: EEnterpriseWeChatMessageType;
  userPropertyKey?: string;
}

// 监控配置
export interface IMonitorConfig {
  id: string;
  displayName: string;
  metric?: TMetric;
  // 拆解维度
  dimensionSplit: IDimensionSplit[] | null;
  // 调度周期
  execCron: string;
  // 规则
  ruleConfig: IMonitorRuleGroup;
  // 推送配置
  pushConfig?: Array<IPushConfig | IEnterpriseWeChatPushConfig> | null;
  // 权限
  authority: IAuthority;
  // 前端需要用到的一些配置信息
  layout: {
    // 指标日期
    metricTimeFilter: TMetricTimeConfig;
  };
  monitorLevel?: string;
  status?: boolean;
}

export interface IMonitorRuleGroup {
  id: string;
  op: EMonitorRuleGroupOp;
  children: Array<TMonitorRuleCondition | IMonitorRuleGroup>;
}

export enum EMonitorRuleGroupOp {
  AND = 'AND',
  OR = 'OR',
}

type TRangeOp = EOperator.BETWEEN | EOperator.OUT_OF_RANGE;

export interface IMonitorRuleNumberCondition<T> {
  id: string;
  left: T;
  op: Exclude<EOperator, TRangeOp>;
  right: number;
}

export interface IMonitorRuleRangeCondition<T> {
  id: string;
  left: T;
  op: TRangeOp;
  right: [number, number];
}

export type TMonitorRuleCondition =
  | IMonitorRuleNumberCondition<IMonitorRuleMetric>
  | IMonitorRuleRangeCondition<IMonitorRuleMetric>;

export enum EOperator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  BETWEEN = 'BETWEEN',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
}

export interface IMonitorRuleMetric {
  code: string;
  // 类型定义
  calculateType?:
    | EQuickCalcType.VALUE
    | EQuickCalcType.GROWTH
    | EQuickCalcType.GROWTH_VALUE;

  // 粒度
  granularity?: EDateGranularityType;
}

export interface IDimensionSplit {
  dimension: string;
  values: string[];
  isAll?: boolean;
}

export interface IPushChannel {
  type: EMonitorPushChannelType;
  // 推送渠道的唯一id，channel为email的时候为EMAIL
  channelId: string;
  receivers: TPushChannelReceiver[];
}

export interface IPushChannelReceiverUserUserGroup {
  receiverType: EReceiverType.USER_USER_GROUP;
  receiverInfo: IAuthorityAccount;
}

export interface IPushChannelReceiverCustom {
  receiverType: EReceiverType.CUSTOM;
  receiverInfo: string;
}
export type TPushChannelReceiver =
  | IPushChannelReceiverCustom
  | IPushChannelReceiverUserUserGroup;

export enum EReceiverType {
  USER_USER_GROUP = 'USER_USER_GROUP', // 用户或用户组
  CUSTOM = 'CUSTOM', // 自定义
}

export enum EMonitorResultDataType {
  VALUE = 'VALUE',
  DIMENSION_SPLIT = 'DIMENSION_SPLIT',
}

export enum EMonitorResultDimensionSplitHeaderType {
  DIMENSION = 'DIMENSION',
  METRIC = 'METRIC',
  STATUS = 'STATUS',
}
export enum EMonitorResultDimensionSplitItemStatus {
  NORMAL = 'NORMAL',
  ABNORMAL = 'ABNORMAL',
}

export enum EMonitorResultStatus {
  NORMAL = 'NORMAL',
  ABNORMAL = 'ABNORMAL',
  METRIC_ABNORMAL = 'METRIC_ABNORMAL',
}

export interface IMonitorResultValue {
  resultDataType: EMonitorResultDataType.VALUE;
  value: TCellData;
}

export interface IMonitorResultDimensionSplitItemStatus {
  status: EMonitorResultDimensionSplitItemStatus;
  msg?: string;
}

export interface IMonitorResultDimensionSplit {
  resultDataType: EMonitorResultDataType.DIMENSION_SPLIT;
  headers: {
    type: EMonitorResultDimensionSplitHeaderType;
    id: string;
    displayName: string;
    originDataType: EOriginDataType;
  }[];
  data: {
    [id: string]: TCellData | IMonitorResultDimensionSplitItemStatus;
  }[];
}

// 单个监控的结果结构
export interface IMonitorResult {
  id: string;
  metricMonitorConfig: IMonitorConfig;
  execTime: number | null;
  monitorTime: number[];
  resultData: IMonitorResultDimensionSplit | IMonitorResultValue | null;
  resultStatus: EMonitorResultStatus | null;
  resultMsgs: string[] | null;
  owner?: IUser;
}
