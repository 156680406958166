import { graphql, rest } from 'msw';
import { getDimension } from './mockData';
import { getRestfulResponse } from '../utils';
import _ from 'lodash';
import { IListItems } from '../../src/typings';
import { TDimension } from '../../src/typings/dimension';
import { getDimensionList } from '../utils/getSimpleMetricList';

export const dimension = [
  graphql.query('queryDimensionList', (req, res, ctx) => {
    const list: IListItems<TDimension> = {
      pageSize: 20,
      pageNumber: 1,
      total: 10000,
      hasNext: false,
      data: getDimensionList(),
    };
    return res(
      ctx.data({
        queryDimensionList: list,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.query('queryDimension', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDimension: getDimension('dimension'),
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('deleteDimension', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteDimension: true,
      }),
      ctx.delay(1000),
    );
  }),

  rest.get('/queryDimensionValue', (req, res, ctx) => {
    const name = decodeURIComponent(req.url.search.split('=')[1]);
    return res(
      ctx.json(
        getRestfulResponse({
          table: {
            [name]: _.range(100).map((i) => {
              return [i, 1, 1];
            }),
          },
        }),
      ),
    );
  }),

  rest.post(
    '/dimension/value_preview_by_name',
    (
      req: {
        body: {
          name: string;
        };
      },
      res,
      ctx,
    ) => {
      const name = req.body?.name || '';
      return res(
        ctx.json(
          getRestfulResponse({
            table: {
              [name]: _.range(100).map((i) => {
                return [i, 1, 1];
              }),
            },
          }),
        ),
      );
    },
  ),

  rest.post('/dimension/batch_save', (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse([])));
  }),
  rest.get('/dimension/check_name', (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(true)));
  }),
  rest.get('/dimension/check_code', (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(true)));
  }),

  rest.post(
    '/dimension/value_preview_by_config',
    (
      req: {
        body: {
          name: string;
        };
      },
      res,
      ctx,
    ) => {
      const name = req.body?.name || '';
      return res(
        ctx.json(
          getRestfulResponse({
            table: {
              [name]: _.range(100).map((i) => {
                return [i, 1, 1];
              }),
            },
          }),
        ),
      );
    },
  ),
];
