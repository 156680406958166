import styles from './index.less';
import t from '@/locales';
import { Tooltip } from '@aloudata/aloudata-design';
import { useEffect, useState } from 'react';
import { TMetric } from '@/typings/metric';
import { useDragLayer } from 'react-dnd';
import { ReactComponent as AlertTriangle } from '@/assets/icon/Attention-triangle-light-line.svg';
export function PickerTooltip(props: IProps) {
  const { metric, children, reason } = props;
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isDragging) {
      setOpen(false);
    }
  }, [isDragging]);
  const tooltipContent = (
    <div className={styles.tooltipContent}>
      <div className={styles.row}>
        {t.workbook.workbook.picker.metric.displayName}
        {metric.name}
      </div>
      <div className={styles.row}>
        {t.workbook.workbook.picker.metric.desc}
        {metric.description}
      </div>
      {reason && (
        <div className={styles.errorRow}>
          <div className={styles.icon}>
            <AlertTriangle color="#fff" size={16} />
          </div>
          <div className={styles.row}>{reason}</div>
        </div>
      )}
    </div>
  );
  return (
    <Tooltip
      title={tooltipContent}
      placement="right"
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
      open={!isDragging && open}
    >
      {children}
    </Tooltip>
  );
}
interface IProps {
  children: React.ReactNode;
  metric: TMetric;
  reason: string;
}
