import copy from 'clipboard-copy';
import t from '@/locales';
import { EQueryStatus } from '@/services/queryColumns/types';
import { TextLink, message } from '@aloudata/aloudata-design';
export default function TraceInfo(props: IProps) {
  const { resultData } = props;
  const { status, detailErrorMsg } = resultData;
  if (status !== EQueryStatus.FAILED || !detailErrorMsg) {
    return null;
  }
  return (
    <TextLink
      size="small"
      onClick={(e) => {
        // 防止触发跳转
        e.stopPropagation();
        copy(detailErrorMsg);
        message.success(t.components.traceInfo.copySuccess);
      }}
    >
      {t.components.traceInfo.copy}
    </TextLink>
  );
}
interface IProps {
  resultData: {
    status: EQueryStatus;
    detailErrorMsg?: string;
  };
}
