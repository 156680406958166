export const header = {
  input: {
    btnText: '搜索',
  },
  metric: {
    all: '全部指标',
    atomic: '基础指标',
    derived: '派生指标',
    composite: '复合指标',
    category: '指标类目',
    allCategory: '全部类目',
  },
  more: '更多类目',
  helper: {
    title: '帮助中心',
    content: '获取产品简介、快速入门、最佳实践等信息',
    link: '了解更多',
  },
  vetting: {
    waitingForMyApproval: '待我审批',
    myApply: '我的申请',
    all: '全部',
    empty: '所有待办事项已完成',
  },
};

export const body = {
  title: '我的收藏',
  loadMore: '展示更多',
  empty: '暂无收藏',
  serverError: '获取收藏列表失败',
};

export const metricCard = {
  error: {
    queryError: '抱歉，请求失败',
  },
};
