import {
  TColumnFilterInForm,
  TDimensionCommonFilterInForm,
  TDimensionFilterInForm,
} from '@/pages/Metric/Define/components/Filter';
import {
  EDateGranularityType,
  EOriginDataType,
  EUnit,
  IRestfulResponse,
  IUser,
} from '@/typings';
import { TFormula } from '@/typings/formula';
import {
  EAggType,
  EDerivedCalculateMode,
  EMetricOwnerType,
  EMetricType,
  EOnlineStatus,
  EPeriodFormType,
  IAtomicCaliber,
  ICompositeMetricCaliber,
  IDerivedMetricCaliber,
  TFilter,
} from '@/typings/metric';
import { RecursivePartial } from '@/common/utils/type';
import { EPropertyType } from '@/typings/property';
import { EResourceType } from '@/typings/authority';
import { TSimpleCategory } from '@/typings/category';
import { EApprovalOperationType } from '@/typings/approve';

export enum EFormatType {
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
  CURRENCY = 'CURRENCY',
  AUTO = 'AUTO',
}

export enum EFormatUnit {
  EMPTY = 'EMPTY',
  K = 'K',
  M = 'M',
  B = 'B',
  TEN_THOUSAND = 'TEN_THOUSAND',
  MILLION = 'MILLION',
  TEN_MILLION = 'TEN_MILLION',
  HUNDRED_MILLION = 'HUNDRED_MILLION',
}

export enum ECurrency {
  DOLLAR = '$',
  EUR = '€',
  POUND = '£',
  RMB = '¥',
  HKD = 'HK￥',
}

export enum EFormatConfigType {
  CUSTOM = 'CUSTOM', //自定义
  PRESET = 'PRESET', // 系统预设的
}

export interface IFormatConfig {
  type: EFormatConfigType;
  format: IFormatFormValues;
}

export enum EFormatAutoType {
  CH = 'CH',
  EN = 'EN',
}

export interface IFormatFormValues {
  type: EFormatType;
  decimalPlaces?: number;
  unit?: EFormatUnit;
  currency?: string;
  thousand?: boolean;
  negative?: ENegativeType;
  autoType?: EFormatAutoType;
}

export enum ENegativeType {
  NORMAL = 'NORMAL', // -1234
  BRACKETS = 'BRACKETS', // (1234)
}

export interface ISimpleMetric {
  name: string;
  code: string;
  gmtModify: number;
  ownerInfo?: IUser;
  originDataType: EOriginDataType;
}

export interface IDefineMetricProperties {
  [EPropertyType.BASE]: {
    metricName: string;
    metricDisplayName?: string;
    unit?: EUnit;
    businessOwner?: string;
    [key: string]: unknown;
  };
  [EPropertyType.BUSINESS]: {
    businessCaliber?: string;
    metricCategoryId?: string;
    [key: string]: unknown;
  };
  [EPropertyType.MANAGE]: {
    [key: string]: unknown;
  };
  [EPropertyType.TECHNOLOGY]: {
    [key: string]: unknown;
  };
}
export interface IDefineMetric {
  // 指标名称
  name: string;
  // 指标名称
  metricName: string;
  // 指标编码
  metricCode: string;
  // 指标code
  code?: string;
  // 业务口径
  description: string;
  // 负责人
  owners: Array<{
    type: EMetricOwnerType;
    userId: string;
  }>;
  categoryId: string;
  unit: EUnit;
  //是否发布
  isPublish: boolean;
  enDisplayName?: string;
  version?: number;
  properties: IDefineMetricProperties;
  // 是否启用新维度
  isNewDimensionDefaultEnable: boolean;
  // 不可用维度列表
  disableDimensionNames: string[];
  // 可用的维度列表
  availableDimensionIds: string[];
}
export enum EExprMode {
  COLUMN = 'COLUMN',
  CUSTOM = 'CUSTOM',
}
export interface IFormAggColumnMethod {
  exprMode: EExprMode.COLUMN;
  columnName: string;
  aggType: EAggType;
}
export interface IFormAggCustomMethod {
  exprMode: EExprMode.CUSTOM;
  formula: TFormula;
  aggType: EAggType;
  originDataType: EOriginDataType;
}
export type TFormAggMethod = IFormAggColumnMethod | IFormAggCustomMethod;
export interface IAtomicMetricLayout {
  filters?: TColumnFilterInForm;
  // format?: IFormatFormValues;
  aggMethod: TFormAggMethod;
}
export enum EPeriodDefinedType {
  RECENTLY = 'RECENTLY',
  TO_DATE = 'TO_DATE',
  SPECIAL = 'SPECIAL',
  SHORT_CUT = 'SHORT_CUT',
}
export interface IDerivedMetricLayout {
  period?: EPeriodFormType;
  periodDefinedType?: EPeriodDefinedType;
  filters?: TDimensionFilterInForm;
  isPreAggCustom?: boolean;
}

export interface ICustomDimensionGroup {
  calculateType?: EDerivedCalculateMode;
  dimensions: Array<{
    name: string;
    granularity?: EDateGranularityType;
  }>;
}

export interface IDefineAtomicMetric extends IDefineMetric {
  // 基础指标的类型
  type: EMetricType.ATOMIC;
  // 用于前端布局使用
  layout: IAtomicMetricLayout;
  // 指标口径
  caliber: IAtomicCaliber;
}
// 指标定义草稿,除了code以外的其他字段都可以为空
export type TDefineAtomicMetricDraft = Required<
  Pick<IDefineAtomicMetric, 'metricName'>
> &
  RecursivePartial<Omit<IDefineAtomicMetric, 'metricName'>>;

export interface IDefineDerivedMetric extends IDefineMetric {
  // 派生指标的类型
  type: EMetricType.DERIVED;
  // 用于前端布局使用
  layout: IDerivedMetricLayout;
  // 指标口径
  caliber: IDerivedMetricCaliber;
}

export type TDefineDerivedMetricDraft = Required<
  Pick<IDefineDerivedMetric, 'metricName'>
> &
  RecursivePartial<Omit<IDefineDerivedMetric, 'metricName'>>;

export interface IDefineCompositeMetric extends IDefineMetric {
  type: EMetricType.COMPOSITE;
  caliber: ICompositeMetricCaliber;
  originDataType: EOriginDataType;
}

export type TDefineCompositeMetricDraft = Required<
  Pick<IDefineCompositeMetric, 'metricName'>
> &
  RecursivePartial<Omit<IDefineCompositeMetric, 'metricName'>>;

export type TDefineMetric =
  | IDefineAtomicMetric
  | IDefineDerivedMetric
  | IDefineCompositeMetric;

export type TDefineMetricDraft =
  | TDefineAtomicMetricDraft
  | TDefineDerivedMetricDraft
  | TDefineCompositeMetricDraft;

export interface IStatusFilter {
  isPublished?: boolean;
  onlineStatus?: EOnlineStatus;
  hasDraft?: boolean;
  approvalOperation?: EApprovalOperationType | null;
}

export interface IGetMetricListParams {
  keyword?: string; // 指标名称
  categoryId?: string; // 指标code
  types?: EMetricType[]; // 指标类型
  currentPage?: number; // 指定页码
  owners?: string[]; // 负责人
  useable?: boolean; // 我可用的
  authority?: EMetricFilterType;
  statusFilters?: IStatusFilter[];
  pager?: {
    pageSize: number;
    pageNumber: number;
  };
}

export enum EMetricFilterType {
  ALL = 'ALL',
  CREATE_BY_SELF = 'OWNER',
  SHARED = 'USER',
  COLLECTION = 'COLLECT',
}

export enum EResourceVersionStatus {
  /**
   * 未发布
   */
  UNPUBLISHED = 'UNPUBLISHED',
  /**
   * 已发布历史版本
   */
  PUBLISHED = 'PUBLISHED',
  /**
   * 已发布最新
   */
  CURRENT_VERSION = 'CURRENT_VERSION',
  /**
   * 草稿
   */
  DRAFT = 'DRAFT',
}

export interface IResourceVersionInfo {
  resourceId: string;
  resourceType: EResourceType;
  version: number;
  updater: IUser;
  gmtCreate: number;
  status: EResourceVersionStatus;
}

// 行权限相关
export enum ERowFilterType {
  TABLE_RULE = 'TABLE_RULE',
  CONDITION_RULE = 'CONDITION_RULE',
}

export interface IRowFilterDimensionRule {
  dimensionFlag: boolean; // 是否打开维度筛选
  // includes 和excludes同时只会存在一个
  includes?: string[];
  excludes?: string[];
}

export interface IRowBaseFilterRule {
  id?: string;
  tempId?: string; // 由于行级权限没有删除和更新操作，每次都是全量提交的，所以需要 前端生成tempId来标识，提交给服务端的时候去掉
  name: string;
  ruleType: ERowFilterType;
}
export interface IConditionalRule<T> extends IRowBaseFilterRule {
  ruleType: ERowFilterType.CONDITION_RULE;
  layout: TDimensionCommonFilterInForm;
  rule: {
    users: T[];
    ruleContents: TFilter[];
    owner?: IUser;
    whitelists: T[];
    dimensionFilter: IRowFilterDimensionRule;
  };
}

export interface ITableRuleContent {
  name: string; // 维度name
  value: string; // 物理表字段name
  dimensionFilter: IRowFilterDimensionRule;
}

export enum ETableRulLogicType {
  AND = 'AND',
  OR = 'OR',
}

export interface ITableRule extends IRowBaseFilterRule {
  ruleType: ERowFilterType.TABLE_RULE;
  rule: {
    table: string; // 选择的物理表guid
    userIdentifier: string; // 用户标识
    dimensionFilterFlag: boolean; // 是否打开维度筛选
    logicType: ETableRulLogicType;
    tableRuleContents: ITableRuleContent[];
  };
}

export type TRowFilterRule<T> = IConditionalRule<T> | ITableRule;

export interface IRowFilter<T> {
  resourceId: string;
  resourceType: EResourceType;
  ruleType: ERowFilterType;
  rules: TRowFilterRule<T>[];
}

export type TDefineMetricInQuery = Omit<
  TDefineMetric,
  | 'owners'
  | 'isPublish'
  | 'version'
  | 'layout'
  | 'categoryId'
  | 'isPublished'
  | 'isNewDimensionDefaultEnable'
  | 'disableDimensionNames'
  | 'availableDimensionIds'
  | 'properties'
>;

export type TUploadMetricRes = IRestfulResponse<{
  totalCount: number;
  successCount: number;
  info: string;
  uploadId: string;
}>;

export interface IStatisticsMetric {
  type: EMetricType;
  category: TSimpleCategory[];
  isPublished: boolean;
  status: EOnlineStatus;
}
