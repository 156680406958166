import { LargeModal } from '@/components/LargeModalForCreating';
import {
  Form,
  Input,
  Card,
  Radio,
  Select,
  message,
  Spin,
} from '@aloudata/aloudata-design';
import t from '@/locales';
import styles from './index.less';
import SelectInputExtTable from './components/SelectInputExtTable';
import {
  EAssetPropertyType,
  EDataType,
  EInputType,
  EPropertyType,
} from '@/typings/property';
import _ from 'lodash';
import { propertyTypeTextMap } from '../constant';
import {
  useAddAssetProperty,
  useQueryAssetProperty,
  useUpdateAssetProperty,
} from '@/services/property';
import { useEffect } from 'react';
import { EPropertyCompType } from '../type';
import { TDefineProperty } from '@/services/property/type';
import Checkbox from './components/Checkbox';
import { isLongText, isSelect, isText, isUser, isUserGroup } from '../function';

interface IPropertyFormValuesBase {
  name: string;
  displayName: string;
  description: string;
  isRequired: boolean;
  isCreatingDisplayed: boolean;
  propertyType: EPropertyType;
  propertyCompType: EPropertyCompType;
}

interface IPropertyFormValuesText extends IPropertyFormValuesBase {
  propertyCompType: EPropertyCompType.TEXT;
}

interface IPropertyFormValuesLongText extends IPropertyFormValuesBase {
  propertyCompType: EPropertyCompType.LONG_TEXT;
}

interface IPropertyFormValuesSelect extends IPropertyFormValuesBase {
  propertyCompType: EPropertyCompType.SELECT;
  options: { value: string; isDefault?: boolean }[];
}

interface IPropertyFormValuesSelectUser extends IPropertyFormValuesBase {
  propertyCompType: EPropertyCompType.USER;
  useDefaultOwner: boolean;
}

interface IPropertyFormValuesSelectUserGroup extends IPropertyFormValuesBase {
  propertyCompType: EPropertyCompType.USER_GROUP;
  useDefaultOwner: boolean;
}

type TPropertyFormValues =
  | IPropertyFormValuesText
  | IPropertyFormValuesLongText
  | IPropertyFormValuesSelect
  | IPropertyFormValuesSelectUser
  | IPropertyFormValuesSelectUserGroup;

interface IProps {
  onCancel: () => void;
  open: boolean;
  isEdit?: boolean;
  name?: string;
  type: EAssetPropertyType;
  onSuccess: () => void;
  defaultPropertyType: EPropertyType;
}

export default function Define({
  onCancel,
  open,
  isEdit,
  name,
  type,
  onSuccess,
  defaultPropertyType,
}: IProps) {
  const [form] = Form.useForm<TPropertyFormValues>();
  const { addAssetProperty, loading: addAssetPropertyLoading } =
    useAddAssetProperty();
  const { queryAssetProperty, loading: queryAssetPropertyLoading } =
    useQueryAssetProperty();
  const { updateAssetProperty, loading: updateAssetPropertyLoading } =
    useUpdateAssetProperty();

  const formPropertyCompType = Form.useWatch('propertyCompType', form);
  const formName = Form.useWatch('name', form);

  useEffect(() => {
    if (open && isEdit && name) {
      queryAssetProperty({ name, type }).then((res) => {
        if (res.queryAssetPropertyDef) {
          const { dataInput } = res.queryAssetPropertyDef;
          let propertyCompType: EPropertyCompType = EPropertyCompType.TEXT;
          let options: { value: string; isDefault?: boolean }[] = [];
          if (isSelect(dataInput)) {
            options = dataInput.options;
            propertyCompType = EPropertyCompType.SELECT;
          }
          if (isText(dataInput)) {
            propertyCompType = EPropertyCompType.TEXT;
          }
          if (isUser(dataInput)) {
            propertyCompType = EPropertyCompType.USER;
          }
          if (isUserGroup(dataInput)) {
            propertyCompType = EPropertyCompType.USER_GROUP;
          }
          if (isLongText(dataInput)) {
            propertyCompType = EPropertyCompType.LONG_TEXT;
          }
          form.setFieldsValue({
            ...res.queryAssetPropertyDef,
            propertyCompType,
            options,
          });
        }
      });
    }
  }, [form, isEdit, name, open, queryAssetProperty, type]);

  useEffect(() => {
    if (formName && isEdit) {
      form.validateFields();
    }
  }, [form, formName, isEdit]);

  useEffect(() => {
    form.setFieldsValue({
      propertyType: defaultPropertyType,
    });
  }, [defaultPropertyType, form]);

  const onValuesChange = (changedValues: Partial<TPropertyFormValues>) => {
    const { propertyCompType } = changedValues;
    if (propertyCompType) {
      if (propertyCompType === EPropertyCompType.SELECT) {
        form.setFieldsValue({
          options: [],
        });
      } else {
        form.setFieldsValue({
          options: undefined,
        });
      }
    }
  };

  const onSubmit = () => {
    form.validateFields().then((values) => {
      const requestParams = convertFormValues2Request(values, type);
      if (isEdit) {
        updateAssetProperty({
          assetPropertyDefRequest: {
            ...requestParams,
            isCreatingDisplayed: true,
          },
        }).then((res) => {
          if (res.data) {
            message.success(t.property.define.message.update.success);
            onSuccess();
            onHide();
          }
        });
      } else {
        addAssetProperty({
          assetPropertyDefRequest: {
            ...requestParams,
            isCreatingDisplayed: true,
          },
        }).then((res) => {
          if (res.data) {
            message.success(t.property.define.message.add.success);
            onHide();
            onSuccess();
          }
        });
      }
    });
  };

  const onHide = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <LargeModal
      open={open}
      onOk={onSubmit}
      okButtonProps={{
        loading: addAssetPropertyLoading || updateAssetPropertyLoading,
      }}
      destroyOnClose
      onCancel={onHide}
      title={`${
        isEdit
          ? t.property.define.modal.title.edit
          : t.property.define.modal.title.create
      }${
        type === EAssetPropertyType.METRIC
          ? t.property.define.modal.title.metric
          : t.property.define.modal.title.dimension
      }${t.property.define.modal.title.property}`}
    >
      <Spin spinning={queryAssetPropertyLoading}>
        <Form
          form={form}
          layout="vertical"
          className={styles.form}
          onValuesChange={onValuesChange}
          initialValues={{
            propertyCompType: EPropertyCompType.TEXT,
          }}
        >
          <div className={styles.left}>
            <div className={styles.title}>
              {t.property.define.title.basicInfo}
            </div>
            <Form.Item
              label={t.property.define.label.displayName}
              required
              name="displayName"
              rules={[
                {
                  required: true,
                  message: t.property.define.validate.empty(
                    t.property.define.label.displayName,
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t.property.define.label.name}
              required
              name="name"
              rules={[
                {
                  required: true,
                  message: t.property.define.validate.empty(
                    t.property.define.label.name,
                  ),
                },
                {
                  pattern: /^[a-zA-Z0-9_]*$/,
                  message: t.property.define.validate.invalid,
                },
              ]}
            >
              <Input disabled={isEdit} maxLength={50} />
            </Form.Item>
            <Form.Item
              name="propertyType"
              required
              label={t.property.define.label.propertyType}
              rules={[
                {
                  required: true,
                  message: t.property.define.validate.empty(
                    t.property.define.label.propertyType,
                  ),
                },
              ]}
            >
              <Select
                disabled={isEdit}
                options={_.values(EPropertyType).map((item) => ({
                  label: propertyTypeTextMap[item],
                  value: item,
                }))}
              />
            </Form.Item>
            <Form.Item
              label={t.property.define.label.description}
              name="description"
            >
              <Input.TextArea maxLength={100} />
            </Form.Item>

            <Form.Item name="isRequired">
              <Checkbox>{t.property.define.label.isRequired}</Checkbox>
            </Form.Item>
          </div>
          <div className={styles.right}>
            <div className={styles.title}>
              {t.property.define.title.propertyType}
            </div>
            <Card className={styles.card}>
              <div className={styles.cardLeft}>
                {t.property.define.title.propertyType}
              </div>
              <div className={styles.cardRight}>
                <Form.Item name="propertyCompType">
                  <Radio.Group disabled={isEdit}>
                    <Radio value={EPropertyCompType.TEXT}>
                      {t.property.define.propertyType.text}
                    </Radio>
                    <Radio value={EPropertyCompType.LONG_TEXT}>
                      {t.property.define.propertyType.longText}
                    </Radio>
                    <Radio value={EPropertyCompType.SELECT}>
                      {t.property.define.propertyType.select}
                    </Radio>
                    <Radio value={EPropertyCompType.USER}>
                      {t.property.define.propertyType.selectUser}
                    </Radio>
                    <Radio value={EPropertyCompType.USER_GROUP}>
                      {t.property.define.propertyType.selectUserGroup}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {formPropertyCompType === EPropertyCompType.SELECT && (
                  <Card className={styles.innerCard}>
                    <Form.Item
                      name="options"
                      rules={[
                        {
                          required: true,
                          message: t.property.define.validate.empty(
                            t.property.define.select.title.candidate,
                          ),
                        },
                        () => ({
                          validateTrigger: 'onSubmit',
                          validator(
                            __,
                            value: { value?: string; isDefault?: boolean }[],
                          ) {
                            const map: Record<string, boolean> = {};
                            let error = '';
                            _.forEach(value, (item) => {
                              if (!item.value) {
                                error = t.property.define.validate.empty(
                                  t.property.define.select.title.candidate,
                                );
                                return false;
                              }

                              if (map[item.value]) {
                                error = t.property.define.validate.duplicate(
                                  t.property.define.select.title.candidate,
                                );
                                return false;
                              }
                              map[item.value] = true;
                            });
                            if (error) {
                              return Promise.reject(error);
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <SelectInputExtTable />
                    </Form.Item>
                  </Card>
                )}
              </div>
            </Card>
          </div>
        </Form>
      </Spin>
    </LargeModal>
  );
}

function convertFormValues2Request(
  values: TPropertyFormValues,
  type: EAssetPropertyType,
): TDefineProperty {
  const {
    propertyCompType,
    name,
    displayName,
    description,
    isRequired,
    propertyType,
  } = values;

  const base = {
    name,
    displayName,
    description,
    isRequired,
    propertyType,
    type,
    isCreatingDisplayed: true,
  };

  if (propertyCompType === EPropertyCompType.SELECT) {
    const { options } = values;
    return {
      ...base,
      dataInput: {
        dataType: EDataType.TEXT,
        inputType: EInputType.SELECT,
        options,
      },
    };
  }
  if (propertyCompType === EPropertyCompType.USER) {
    const { useDefaultOwner } = values;
    return {
      ...base,
      dataInput: {
        dataType: EDataType.USER,
        inputType: EInputType.SELECT,
        useDefaultOwner,
      },
    };
  }

  if (propertyCompType === EPropertyCompType.USER_GROUP) {
    const { useDefaultOwner } = values;
    return {
      ...base,
      dataInput: {
        dataType: EDataType.USER_GROUP,
        inputType: EInputType.SELECT,
        useDefaultOwner,
      },
    };
  }

  if (propertyCompType === EPropertyCompType.LONG_TEXT) {
    return {
      ...base,
      dataInput: {
        dataType: EDataType.TEXT,
        inputType: EInputType.LONG_TEXT,
      },
    };
  }

  return {
    ...base,
    dataInput: {
      dataType: EDataType.TEXT,
      inputType: EInputType.TEXT,
    },
  };
}
