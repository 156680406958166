import AIR from './AIR';
import STARROCKS from './STARROCKS';
import DORIS from './DORIS';
const functionConfig = {
  AIR,
  STARROCKS,
  DORIS,
};

export default functionConfig;
