import { graphql, rest } from 'msw';
import _ from 'lodash';
import { getRestfulResponse } from '../utils';

export const csvUpload = [
  // 上传文件配置file/persisted
  rest.post('/persisted', (req, res, ctx) => {
    return res(
      ctx.delay(2000),
      ctx.json(
        getRestfulResponse({
          // sourceMeta: { ...getSource('file-1'), type: EEntityType.FILE },
          authorityResource: {
            authority: {
              authRoleList: ['ADMIN', 'USAGER'],
              canAuth: true,
              canCreate: true,
              canDelete: true,
              canEdit: true,
              canUsage: true,
            },
            authorityRole: 'OWNER',
            authoritySource: 'DIRECT',
          },
          resourceGuid:
            'sp_578:/aloudata_hive.tn_72173_sp_578.table_250271697473642496_1669183835540_table_1669183826912_txt_view',
          owner: {
            nickname: 'yunxi_070',
            userId: '250271697473642496',
          },
        }),
      ),
    );
  }),
  
];
