import _ from 'lodash';
import {
  IUserAuthority,
  EUserRole,
  EAuthoritySource,
  EResourceType,
  EAccountType,
} from './../../src/typings/authority';
import { getMockProperty } from '../property/mock';
import {
  EAssetPropertyType,
  EDataType,
  EPropertyType,
  TUserAssociatedAssetProperty,
} from '../../src/typings/property';

export const mockUserAuthorityList: IUserAuthority[] = Array(10)
  .fill(0)
  .map((n, i): IUserAuthority => {
    return {
      authorityResource: {
        resourceId: '',
        resourceType: EResourceType.METRIC,
        authorityRole: _.sample([
          EUserRole.ADMIN,
          EUserRole.OWNER,
          EUserRole.USAGER,
        ]) as EUserRole,
        authoritySource: _.sample([
          EAuthoritySource.DIRECT,
          EAuthoritySource.EXTEND_DATABASE,
          EAuthoritySource.EXTEND_DATASOURCE,
          EAuthoritySource.EXTEND_PERSONAL_SPACE,
        ]) as EAuthoritySource,
        authority: {
          canCreate: false,
          canEdit: true,
          canDelete: true,
          canUsage: true,
          canAuth: true,
          authRoleList: [EUserRole.ADMIN, EUserRole.USAGER],
          canMove: true,
        },
      },
      authorityAccount: {
        accountType: _.sample([EAccountType.USER, EAccountType.USER_GROUP])!,
        account: 'id' + i,
        displayName: 'name' + i,
        id: 'user' + i,
        photo: '',
      },
    };
  });

export function getMockUserBindRoleList(assetPropertyType: EAssetPropertyType) {
  const mockUserBindRoleList: TUserAssociatedAssetProperty[] = _.map(
    _.range(4),
    (i) => {
      const property = getMockProperty(
        `property${i}`,
        _.sample([EAssetPropertyType.METRIC, EAssetPropertyType.DIMENSION]),
        _.sample([
          EPropertyType.BASE,
          EPropertyType.BUSINESS,
          EPropertyType.MANAGE,
          EPropertyType.TECHNOLOGY,
        ]),
        false,
        undefined,
        _.sample([EDataType.USER, EDataType.USER_GROUP]),
      ) as TUserAssociatedAssetProperty;
      property.bindRole = _.sample([EUserRole.ADMIN, EUserRole.USAGER]);
      return property;
    },
  );

  const ownerProperty = getMockProperty(
    'owner',
    assetPropertyType,
    EPropertyType.BASE,
    true,
    undefined,
    EDataType.USER,
  ) as TUserAssociatedAssetProperty;
  ownerProperty.bindRole = EUserRole.OWNER;
  mockUserBindRoleList.unshift(ownerProperty);

  return mockUserBindRoleList;
}
