import { ISearchBaseProps, PColumn, SearchColumn } from '.';
import { ISimpleDataset } from '@/typings/dataset';
import _ from 'lodash';
import { originDataType2DataTypeMap } from '../Formula/constant';

interface IDatasetProps extends ISearchBaseProps {
  items: ISimpleDataset[];
}

type TSearchColumnWrapperProps = IDatasetProps;

const getItems = (props: TSearchColumnWrapperProps) => {
  return {
    type: 'multiple',
    items: props.items.map((datasetItem) => ({
      sheetId: datasetItem.name,
      columns: (datasetItem.defColumns || datasetItem.columns).map(
        (column) => ({
          dataType: originDataType2DataTypeMap[column.originDataType],
          name: column.name,
          displayName: column.displayName,
          id: column.name,
          isPartition: column?.isPartition || false,
          columnType: column.type,
          originDataType: column.originDataType,
        }),
      ) as PColumn[],
      sheetName: datasetItem.displayName,
    })),
  };
};

const SearchColumnWrapper = (props: TSearchColumnWrapperProps) => {
  const { items } = getItems(props);

  return (
    <SearchColumn
      {..._.omit(props, ['type', 'items'])}
      items={
        items as {
          sheetId: string;
          columns: PColumn[];
          sheetName: string;
        }[]
      }
    />
  );
};

export default SearchColumnWrapper;
