import { ECategoryType, ICategory } from '@/typings/category';
import {
  IUseGqlQueryOptions,
  useGqlLazyQuery,
  useGqlMutation,
  useGqlQuery,
} from '@/common/utils/gqlRequest';

import { useMemo } from 'react';
import { gql } from '@apollo/client';
import {
  ICategoryRequest,
  IDeleteCategoryRequest,
  IEditCategoryRequest,
} from './type';
import { EResourceType } from '@/typings/authority';
import _ from 'lodash';

export const QUERY_CATEGORY = gql`
  query queryCategory($categoryType: ResourceTypeEnum) {
    queryCategory(categoryType: $categoryType) {
      id
      name
      resourceCount
      parentId
      frontId
      type
    }
  }
`;

/**
 * 获取指标类目
 */
export function useGetCategoryList(
  reqParams: { categoryType: ECategoryType },
  opt?: IUseGqlQueryOptions<{ queryCategory: ICategory[] }>,
) {
  const schema = QUERY_CATEGORY;
  const { data, loading, refetch } = useGqlQuery<
    { queryCategory: ICategory[] },
    { categoryType: string }
  >(
    schema,
    {
      categoryType: reqParams.categoryType,
    },
    opt,
  );

  const categoryList: ICategory[] | null = useMemo(() => {
    const list = data?.queryCategory;
    if (!list || list.length === 0) {
      return null;
    }
    return _.cloneDeep(list);
  }, [data]);

  return {
    data: categoryList,
    loading,
    refetch,
  };
}
/**
 * 手动获取指标类目
 */
export function useGetCategoryListLazy() {
  const schema = QUERY_CATEGORY;
  const { data, loading, run } = useGqlLazyQuery<
    { queryCategory: ICategory[] },
    { categoryType: string }
  >(schema);

  const categoryList: ICategory[] | null = useMemo(() => {
    const list = data?.queryCategory;
    if (!list || list.length === 0) {
      return null;
    }
    return list.map((item) => ({
      ...item,
    }));
  }, [data]);

  return {
    data: categoryList,
    loading,
    run,
  };
}
/**
 * 创建类目
 */
export function useCreateCategory() {
  const schema = gql`
    mutation addCategory($createCategoryRequest: CreateCategoryRequest) {
      addCategory(createCategoryRequest: $createCategoryRequest)
    }
  `;
  const { run: createCategory, loading } = useGqlMutation<
    {
      addCategory: string;
    },
    {
      createCategoryRequest: ICategoryRequest;
    }
  >(schema);

  return {
    createCategory,
    loading,
  };
}
/**
 * 删除类目
 */
export function useDeleteCategory() {
  const schema = gql`
    mutation deleteCategory($deleteCategoryRequest: DeleteCategoryRequest) {
      deleteCategory(deleteCategoryRequest: $deleteCategoryRequest)
    }
  `;
  const { run: deleteCategory } = useGqlMutation<
    {
      deleteCategory: boolean;
    },
    {
      deleteCategoryRequest: IDeleteCategoryRequest;
    }
  >(schema);

  return {
    deleteCategory,
  };
}

/**
 * 编辑类目
 */
export function useEditCategory() {
  const schema = gql`
    mutation editCategory($editCategoryRequest: EditCategoryRequest) {
      editCategory(editCategoryRequest: $editCategoryRequest)
    }
  `;
  const { run: editCategory } = useGqlMutation<
    {
      editCategory: boolean;
    },
    {
      editCategoryRequest: IEditCategoryRequest;
    }
  >(schema);

  return {
    editCategory,
  };
}

/**
 * 调整资源到类目下
 */
export function useMoveResourceToCategory() {
  const schema = gql`
    mutation moveResource($moveResourceRequest: MoveResourceRequest) {
      moveResource(moveResourceRequest: $moveResourceRequest)
    }
  `;
  const { run: moveResource, loading } = useGqlMutation<
    {
      moveResource: boolean;
    },
    {
      moveResourceRequest: {
        resourceType: EResourceType;
        resourceId: string | number;
        categoryId: string;
      };
    }
  >(schema);

  return {
    moveResource,
    loading,
  };
}

export function useBatchChangeCategory() {
  const schema = gql`
    mutation batchChangeCategory(
      $resourceCategoryRequest: ResourceCategoryRequest
    ) {
      batchChangeCategory(resourceCategoryRequest: $resourceCategoryRequest)
    }
  `;
  const { run: batchChangeCategory, loading } = useGqlMutation<
    {
      batchChangeCategory: boolean;
    },
    {
      resourceCategoryRequest: {
        resourceType: EResourceType;
        resourceIds: Array<string | number>;
        categoryId: string;
      };
    }
  >(schema);

  return {
    run: batchChangeCategory,
    loading,
  };
}
