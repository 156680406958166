import {
  ETokenType,
  IBaseToken,
  IEditorError,
  IToken,
  TExternalInfo,
} from '@/components/CodeEditor/type';
import functionConfig from '@/components/Formula/functionConfig/functionConfig';
import {
  EAutoCompleteType,
  IPrompt,
} from '@/components/Formula/functions/autoComplete';
import {
  EFindEntityResultType,
  findEntityAdapter,
} from '@/components/Formula/functions/findEntity';
import {
  genFormulaAdapter,
  processingEscapeCharacter,
} from '@/components/Formula/functions/genFormula';
import { generateParser } from '@/components/Formula/functions/parser/astParser';
import { getEngineType } from '@/components/Formula/functions/tool';
import {
  checkNameRefAdapter,
  ECheckResultType,
} from '@/components/Formula/functions/validate/nameRef';
import { DEFAULT_SEPARATOR } from '@/constants';
import _ from 'lodash';
import { IFilterFunctionsList } from '@/components/Formula/type';
import { parseErrorAdapter } from '@/components/Formula/functions/parseError';

// 将token转为dsl
export const tokenList2Dsl = (tokenList: IToken[]) => {
  return tokenList.map((token) => token.text).join('');
};

// 如果是列，则采用idPath填充
export const tokenList2RealDsl = (tokenList: IToken[]) => {
  return tokenList
    .map((token) => {
      if ([ETokenType.COLUMN, ETokenType.PARTIAL_COLUMN].includes(token.type)) {
        if (token.idPath)
          return `[${token.idPath
            .map((item) => processingEscapeCharacter(item))
            .filter(Boolean)
            ?.join(DEFAULT_SEPARATOR)}]`;
        if (_.isArray(token.value))
          return `[${token.value
            .map((item) => processingEscapeCharacter(item))
            .filter(Boolean)
            ?.join(DEFAULT_SEPARATOR)}]`;
      }
      return token.text;
    })
    .join('');
};

// 将dsl转为token
export const getTokens = (dsl: string): IToken[] => {
  const parser = generateParser();
  const lexer = parser.lexer;

  lexer.reset(dsl);

  const result = [];

  for (
    let token = lexer.next() as unknown as IBaseToken;
    !!token;
    token = lexer.next() as unknown as IBaseToken
  ) {
    result.push({
      ...token,
      end: token.offset + token.text.length,
    });
  }

  return result as IToken[];
};

export const getTokenListWithIdPath = (
  dsl: string,
  externalInfo: TExternalInfo,
) => {
  return replacePath(getTokens(dsl), externalInfo);
};

// 将写的列中的path替换为idPath
export const replacePath = (
  tokenList: IToken[],
  externalInfo: TExternalInfo,
): IToken[] => {
  const result = tokenList.map((token) => {
    if (token.type === ETokenType.COLUMN && !token.idPath) {
      const nameResult = checkNameRefAdapter(
        token.value as string[],
        externalInfo,
      );

      if (nameResult.type === ECheckResultType.AST) {
        return {
          ...token,
          idPath: nameResult.path,
        };
      } else {
        // 由于解析出的token value是之前的id，所以在checkNameRef没有结果的时候尝试findEntity，找到则返回正确
        let tempResult;
        try {
          tempResult = findEntityAdapter(token.value as string[], externalInfo);
        } catch {}

        if (tempResult?.type === EFindEntityResultType.COLUMN) {
          return {
            ...token,
            idPath: token.value as string[],
          };
        }

        return {
          ...token,
          error: {
            errorMsg: nameResult.errorMsg,
          },
        };
      }
    }
    return token;
  });

  return result;
};

// 获取函数的提示信息
export const getFuncPrompt = (funcName: string): IPrompt | null => {
  const LAST_INDEX = -1;
  const queryEngineType = getEngineType();
  const upperCaseFunctionName = funcName.endsWith('(')
    ? funcName.slice(0, LAST_INDEX).toUpperCase()
    : funcName.toUpperCase();
  if (functionConfig[queryEngineType].functions[upperCaseFunctionName]) {
    const functionInfo =
      functionConfig[queryEngineType].functions[upperCaseFunctionName];

    const functionDesc = functionInfo.descZh || {};

    return {
      type: EAutoCompleteType.PROMPT,
      functionName: functionInfo.name,
      functionDesc: functionDesc.desc,
      functionGrammar: functionDesc.formula,
      functionParamsDesc:
        functionDesc.paramArr?.map((param) => ({
          name: param.name,
          desc: param.desc,
        })) || [],
      index: 0,
    };
  }
  return null;
};

/**
 * 提示编辑器中错误的部分
 */
export const parseDslError = (
  dsl: string,
  externalInfo: TExternalInfo,
  filterFunctionList: IFilterFunctionsList,
): IEditorError[] => {
  const originTokenList = replacePath(getTokens(dsl), externalInfo);

  const { ast } = parseErrorAdapter(
    tokenList2RealDsl(originTokenList),
    externalInfo,
    filterFunctionList,
  );

  const tokenListHasError = genFormulaAdapter(ast, externalInfo);

  const errorList = tokenListHasError
    .map(
      (token, index): IEditorError => ({
        ...getTokenRange(tokenListHasError, index),
        errorMsg: token.error?.errorMsg || '',
      }),
    )
    .filter((token) => token.errorMsg);

  return mergeEditorErrors(errorList);
};

function mergeEditorErrors(errors: IEditorError[]): IEditorError[] {
  if (errors.length <= 1) {
    return errors;
  }

  const mergedErrors: IEditorError[] = [];
  let currentError = errors[0];

  for (let i = 1; i < errors.length; i++) {
    const nextError = errors[i];

    if (
      currentError.to === nextError.from &&
      currentError.errorMsg === nextError.errorMsg
    ) {
      // Merge the errors
      currentError = {
        from: currentError.from,
        to: nextError.to,
        errorMsg: currentError.errorMsg,
      };
    } else {
      // Push the current merged error to the result and update currentError
      mergedErrors.push(currentError);
      currentError = nextError;
    }
  }

  // Push the last merged error
  mergedErrors.push(currentError);

  return mergedErrors;
}

export const getNextToken = (tokenList: IToken[], token: IToken | null) => {
  if (!token) return null;
  const tokenIndex = tokenList.findIndex(
    (item) => item.offset === token.offset,
  );

  if (tokenIndex === tokenList.length - 1) return null;
  return tokenList[tokenIndex + 1];
};

export const getPrevToken = (tokenList: IToken[], token: IToken | null) => {
  if (!token) return null;
  const tokenIndex = tokenList.findIndex(
    (item) => item.offset === token.offset,
  );

  if (tokenIndex === 0) return null;
  return tokenList[tokenIndex - 1];
};

export const getTokenEnd = (token: IToken) => {
  return token.offset + token.text.length;
};

// 获取某个token在dsl中的的from和to的值，其中 /n 的长度为一
export const getTokenRange = (tokenList: IToken[], tokenIndex: number) => {
  const token = tokenList[tokenIndex];
  if (tokenIndex === 0)
    return {
      from: 0,
      to: token.text.length,
    };

  const tempTokenList = tokenList.slice(0, tokenIndex);

  const lineArray = tempTokenList
    .map((_token) => _token.text)
    .join('')
    .split('\n');

  const from =
    lineArray.reduce((result, item) => (result += item.length), 0) +
    lineArray.length -
    1;
  const to = from + token.text.length;

  return { from, to };
};

export function replaceNewlines(text: string): string {
  // 使用正则表达式替换所有换行符
  return text.replace(/\r?\n/g, '');
}
