import {
  EFormulaType,
  IFunctionOperator,
  TCorrectFormula,
  TFormula,
} from '@/typings/formula';
import t from '@/locales';
import _ from 'lodash';
import {
  derivateOriginDataType as derivateOriginDataTypeAdapter,
  TDerivateOriginDataTypeResult,
} from './derivation/originDataType';
import {
  EFindEntityResultType,
  TFindEntityResult,
  findEntityAdapter,
} from './findEntity';
import iterate from './iterate';
import { generateParser } from './parser/astParser';
import {
  ECodeEditorPropsType,
  TExternalInfo,
} from '@/components/CodeEditor/type';
import checkFunction, { ECheckResultType } from './validate/function';
import functionConfig from '../functionConfig/functionConfig';
import { getEngineType } from './tool';
import { IFilterFunctionsList } from '../type';
import { fuzzyMatch } from '@/pages/Dataset/helpers';
export enum EParseResultType {
  PARSE_RESULT = 'PARSE_RESULT',
  ERROR = 'ERROR',
}
export interface IParseErrorResult {
  type: EParseResultType;
  ast: TFormula;
  errorMsg?: string;
}
const queryEngineType = getEngineType();
export const parseError = (
  formula: string,
  externalInfo: TExternalInfo,
  filterFunctionList: IFilterFunctionsList,
  findEntity: (
    path: string[],
    externalInfo: TExternalInfo,
  ) => TFindEntityResult,
  derivateOriginDataType: (
    ast: TFormula,
    externalInfo: TExternalInfo,
    filterFunctionList: IFilterFunctionsList,
  ) => TDerivateOriginDataTypeResult,
): IParseErrorResult => {
  const parser = generateParser();
  if (!formula) {
    return {
      type: EParseResultType.PARSE_RESULT,
      ast: {
        type: EFormulaType.EMPTY,
      },
    };
  }
  try {
    let originAst = {} as TCorrectFormula;

    try {
      const result = parser.feed(formula);

      originAst = result.results[0];
    } catch (e) {
      throw new Error(t.formula.derivate.error.parserError);
    }
    if (!originAst) {
      throw new Error(t.formula.derivate.error.parserError);
    }
    let errorMsg = '';
    let one2manyErrorMsg: string[] = [];
    const isFilteredFnMsg: string[] = [];
    // 是否是在聚合函数内部
    const ast = iterate(
      originAst,
      {} as TCorrectFormula,
      (astItem, previous) => {
        const { type: astItemType } = astItem;
        if (astItemType === EFormulaType.COLUMN) {
          if (externalInfo.type === ECodeEditorPropsType.COMPOSE_METRIC) {
            const { path } = astItem;
            if (path.length !== 1) {
              errorMsg = `${astItem.path}${t.formula.parse.error.columnNotFound}`;
              return {
                ...astItem,
                errorMsg: `${astItem.path}${t.formula.parse.error.columnNotFound}`,
              };
            } else {
              const isDimension = externalInfo.dimensionList.some(
                (item) => item.displayName === path[0] || item.id === path[0],
              );

              if (isDimension) {
                isFilteredFnMsg.push(
                  t.formula.parse.error.dimensionOnlyCanUsedInIsFilteredFn,
                );
                return {
                  ...astItem,
                  isDimension: true,
                  errorMsg: `${t.formula.parse.error.dimensionOnlyCanUsedInIsFilteredFn}`,
                };
              }
            }
          }

          if (externalInfo.type !== ECodeEditorPropsType.COMPOSE_METRIC) {
            let findEntityResult: TFindEntityResult;
            try {
              findEntityResult = findEntity(astItem.path, externalInfo);
            } catch (e) {
              errorMsg = `${astItem.path}${t.formula.parse.error.columnNotFound}`;
              return {
                ...astItem,
                errorMsg: `${astItem.path}${t.formula.parse.error.columnNotFound}`,
              };
            }
            if (findEntityResult.type === EFindEntityResultType.COLUMN) {
              if (findEntityResult.isOne2ManyDatasetColumn) {
                one2manyErrorMsg.push(
                  `${astItem.path}${t.formula.parse.error.columnNeedAggFn}`,
                );
                return {
                  ...astItem,
                  isChildrenHasOne2manyDatasetColumn: true,
                  errorMsg: `${astItem.path}${t.formula.parse.error.columnNeedAggFn}`,
                };
              }
              return astItem;
            } else {
              errorMsg = `${astItem.path}${findEntityResult.errorMsg}`;
              return {
                ...astItem,
                errorMsg: `${astItem.path}${findEntityResult.errorMsg}`,
              };
            }
          }
        }
        if (astItemType === EFormulaType.FUNCTION) {
          const isChildrenHasOne2manyDatasetColumn = previous.some(
            (item) => item.isChildrenHasOne2manyDatasetColumn,
          );
          let tempAst: TCorrectFormula = astItem.args.length
            ? {
                ...astItem,
                isChildrenHasOne2manyDatasetColumn,
                args: [...previous],
              }
            : {
                ...astItem,
                args: [],
              };
          // 如果当前分支下有引用自n端表的字段且当前函数是聚合函数，就遍历当前分支消除掉之前做的标记
          if (
            validateMayToOneColumn(astItem) &&
            isChildrenHasOne2manyDatasetColumn
          ) {
            const result = removeOne2manyMark(tempAst, one2manyErrorMsg);
            tempAst = result.ast;
            one2manyErrorMsg = result.errorMsgs;
          }
          const { op } = tempAst;

          if (fuzzyMatch(op, 'isFiltered')) {
            if (tempAst.args.length === 1) {
              if (
                tempAst.args[0].type !== EFormulaType.COLUMN ||
                !tempAst.args[0].isDimension
              ) {
                return {
                  ...tempAst,
                  errorMsg:
                    t.formula.parse.error.isFilteredFnOnlyCanUseDimension,
                };
              } else {
                isFilteredFnMsg.pop();
                return {
                  ...tempAst,
                  args: [
                    {
                      ..._.omit(tempAst.args[0], ['isDimension', 'errorMsg']),
                    },
                  ],
                };
              }
            }
          }
          const checkResult = checkFunction({
            type: astItemType,
            op,
            args: tempAst.args.map(
              (arg) =>
                derivateOriginDataType(arg, externalInfo, filterFunctionList)
                  .originDataType,
            ),
            ast: astItem,
            filterFunctionList,
          });
          if (checkResult.type === ECheckResultType.ORIGIN_DATA_TYPE) {
            return tempAst;
          } else {
            errorMsg = checkResult.errorMsg;
            return {
              ...tempAst,
              errorMsg: checkResult.errorMsg,
            };
          }
        }
        if (astItemType === EFormulaType.BINARY_OPERATOR) {
          const isChildrenHasOne2manyDatasetColumn = previous.some(
            (item) => item.isChildrenHasOne2manyDatasetColumn,
          );
          const tempAst = {
            ...astItem,
            isChildrenHasOne2manyDatasetColumn,
            x: previous[0],
            y: previous[1],
          };
          const leftOriginDataType = derivateOriginDataType(
            previous[0],
            externalInfo,
            filterFunctionList,
          ).originDataType;
          const rightOriginDataType = derivateOriginDataType(
            previous[1],
            externalInfo,
            filterFunctionList,
          ).originDataType;
          const { binOperators } = functionConfig[queryEngineType];
          const { op } = astItem;
          const binOperator = binOperators[op];
          const hasThisArg = binOperator.signatures.find(
            (item) =>
              item.args[0] === leftOriginDataType &&
              item.args[1] === rightOriginDataType,
          );
          if (hasThisArg) {
            return tempAst;
          }
          errorMsg = t.formula.derivate.error.binaryOperatorError;
          return {
            ...tempAst,
            errorMsg: t.formula.derivate.error.binaryOperatorError,
          };
        }
        if (astItemType === EFormulaType.UNARY_OPERATOR) {
          const isChildrenHasOne2manyDatasetColumn = previous.some(
            (item) => item.isChildrenHasOne2manyDatasetColumn,
          );
          const tempAst = {
            ...astItem,
            isChildrenHasOne2manyDatasetColumn,
            x: previous[0],
          };
          const argOriginDataType = derivateOriginDataType(
            previous[0],
            externalInfo,
            filterFunctionList,
          ).originDataType;
          const { unOperators } = functionConfig[queryEngineType];
          const { op } = astItem;
          const unOperator = unOperators[op.toUpperCase()];
          const hasThisArg = unOperator.signatures.find(
            (item) => item.args[0] === argOriginDataType,
          );
          if (hasThisArg) {
            return tempAst;
          }
          errorMsg = t.formula.derivate.error.constantError;
          return {
            ...tempAst,
            errorMsg: t.formula.derivate.error.constantError,
          };
        }
        if (astItemType === EFormulaType.PARENTHESIS) {
          const isChildrenHasOne2manyDatasetColumn = previous.some(
            (item) => item.isChildrenHasOne2manyDatasetColumn,
          );
          return {
            ...astItem,
            isChildrenHasOne2manyDatasetColumn,
            x: previous[0],
          };
        }
        return astItem;
      },
    );
    if (
      errorMsg ||
      one2manyErrorMsg.length !== 0 ||
      isFilteredFnMsg.length !== 0
    ) {
      return {
        type: EParseResultType.ERROR,
        ast,
        errorMsg: errorMsg || one2manyErrorMsg[0] || isFilteredFnMsg[0],
      };
    }
    return {
      type: EParseResultType.PARSE_RESULT,
      ast,
    };
  } catch (e) {
    return {
      type: EParseResultType.ERROR,
      ast: {
        type: EFormulaType.PARTIAL,
        text: formula,
        errorMsg: (
          e as {
            message: string;
          }
        ).message,
      },
      errorMsg: (
        e as {
          message: string;
        }
      ).message,
    };
  }
};
export const curriedParse = _.curryRight(parseError);
export const parseErrorAdapter: (
  formula: string,
  externalInfo: TExternalInfo,
  filterFunctionList: IFilterFunctionsList,
) => IParseErrorResult = curriedParse(derivateOriginDataTypeAdapter)(
  findEntityAdapter,
);
function isAggFunc(opName: string) {
  const functionInfo =
    functionConfig[getEngineType()].functions[opName.toUpperCase()];
  return !!functionInfo?.isAggFunction;
}

function removeOne2manyMark(ast: TCorrectFormula, errorMsg: string[]) {
  const tempErrorMsgs = [...errorMsg];
  const tempAst = iterate(
    ast,
    {} as TCorrectFormula,
    (thisAstItem, thisPrevious): TCorrectFormula => {
      const { type: thisAstItemType } = thisAstItem;
      if (thisAstItemType === EFormulaType.COLUMN) {
        if (thisAstItem.isChildrenHasOne2manyDatasetColumn) {
          tempErrorMsgs.pop();
          return _.omit(thisAstItem, [
            'isChildrenHasOne2manyDatasetColumn',
            'errorMsg',
          ]);
        }
        return thisAstItem;
      }
      if (thisAstItemType === EFormulaType.FUNCTION) {
        if (thisAstItem.args.length) {
          return {
            ..._.omit(thisAstItem, ['isChildrenHasOne2manyDatasetColumn']),
            args: [...thisPrevious],
          };
        } else {
          return {
            ..._.omit(thisAstItem, ['isChildrenHasOne2manyDatasetColumn']),
            args: [],
          };
        }
      }
      if (thisAstItemType === EFormulaType.BINARY_OPERATOR) {
        return {
          ..._.omit(thisAstItem, ['isChildrenHasOne2manyDatasetColumn']),
          x: thisPrevious[0],
          y: thisPrevious[1],
        };
      }
      if (thisAstItemType === EFormulaType.UNARY_OPERATOR) {
        return {
          ..._.omit(thisAstItem, ['isChildrenHasOne2manyDatasetColumn']),
          x: thisPrevious[0],
        };
      }
      if (thisAstItemType === EFormulaType.PARENTHESIS) {
        return {
          ..._.omit(thisAstItem, ['isChildrenHasOne2manyDatasetColumn']),
          x: thisPrevious[0],
        };
      }
      return _.omit(thisAstItem, [
        'isChildrenHasOne2manyDatasetColumn',
      ]) as TCorrectFormula;
    },
  ) as IFunctionOperator;
  return {
    ast: tempAst,
    errorMsgs: tempErrorMsgs,
  };
}

// 当多端表上游公式存在 [PowerADD、PowerFix、PowerSum] 且该函数的第一个参数为聚合函数时，多端表字段外侧不需要做聚合函数嵌套
export const validateMayToOneColumn = (ast: TFormula): boolean => {
  const POWER_FUNC = ['POWERADD', 'POWERFIX', 'POWERSUM'];

  if (ast.type === EFormulaType.FUNCTION) {
    if (POWER_FUNC.includes(ast.op.toUpperCase())) {
      if (ast.args[0].type === EFormulaType.FUNCTION) {
        if (isAggFunc(ast.args[0].op)) {
          return true;
        }
      }
    }

    if (isAggFunc(ast.op)) {
      return true;
    }
  }

  return false;
};
