import zhCN from './zh';

// TODO 如果需要支持多语言，支持自动检测 UserLanguage 等，可以结合 BrowserLanguageDetector 等实现

enum ESupportedLanguage {
  zh = 'zh-CN',
}

/**
 * 获取翻译器
 * @param locale 支持的语言
 * @returns translator
 */
export const getTranslator = (
  locale: ESupportedLanguage = ESupportedLanguage.zh,
) => {
  if (locale === ESupportedLanguage.zh) return zhCN;
  return zhCN;
};

export default getTranslator();
