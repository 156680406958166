import {
  ECurrency,
  EFormatAutoType,
  EFormatConfigType,
  EFormatType,
  EFormatUnit,
  IFormatConfig,
} from '@/services/metric/types';
import { IMetricPreviewDocumentV0, TMetric } from '@/typings/metric';
import _ from 'lodash';

enum EOldFormatType {
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
  CURRENCY = 'CURRENCY',
}

enum EOldFormatUnit {
  EMPTY = 'EMPTY',
  AUTO = 'AUTO',
  K = 'K',
  M = 'M',
  B = 'B',
}

interface IOldMetricFormat {
  type: EOldFormatType;
  decimalPlaces?: number;
  unit?: EOldFormatUnit;
  currency?: ECurrency;
  thousand?: boolean;
}

/**
 * 该方法是为了将旧的指标格式化记录转为新的指标格式化记录
 */

export const convertOldMetricToNew = (
  originMetric: TMetric | null,
): TMetric | null => {
  if (!originMetric) return null;

  const layout = originMetric.previewConfig?.layout;
  if (!layout || layout?.version) {
    // 原来不存在预览配置，或是新的 layout 结构
    return originMetric;
  }
  const oldLayout = layout as unknown as IMetricPreviewDocumentV0;

  // 如果metric的type符合这个条件则可以判断是新设置的格式化配置
  if (
    [EFormatConfigType.CUSTOM, EFormatConfigType.PRESET].includes(
      oldLayout.formatConfig?.type as unknown as EFormatConfigType,
    )
  )
    return originMetric;

  const metric: TMetric = _.cloneDeep(originMetric);

  if (oldLayout.formatConfig) {
    const format = oldLayout.formatConfig as unknown as IOldMetricFormat;

    let newFormat: IFormatConfig = {
      type: EFormatConfigType.CUSTOM,
      format: {
        type: format.type as unknown as EFormatType,
        decimalPlaces: format.decimalPlaces,
        unit: format.unit as unknown as EFormatUnit,
        currency: format.currency,
        thousand: format.thousand,
      },
    };

    if (format.unit === EOldFormatUnit.AUTO) {
      newFormat = {
        type: EFormatConfigType.CUSTOM,
        format: {
          type: EFormatType.AUTO,
          autoType: EFormatAutoType.EN,
        },
      };
    }

    oldLayout.formatConfig = newFormat;
  }

  return metric;
};
