import styles from './index.less';
import { Tooltip } from '@aloudata/aloudata-design';
import { useEffect, useState } from 'react';
import t from '@/locales';
import { useDragLayer } from 'react-dnd';
import { ReactComponent as AlertTriangle } from '@/assets/icon/Attention-triangle-light-line.svg';
import { IPickerDimension } from './type';
export default function PickerTooltip(props: IProps) {
  const { children, dimensionItem, reason } = props;
  const [open, setOpen] = useState(false);
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));
  useEffect(() => {
    if (isDragging) {
      setOpen(false);
    }
  }, [isDragging]);
  const tooltipContent = (
    <div className={styles.tooltipContent}>
      <div className={styles.row}>
        {t.workbook.workbook.picker.dimension.displayName}
        {dimensionItem.dimName}
      </div>
      <div className={styles.row}>
        {t.workbook.workbook.picker.dimension.desc}
        {dimensionItem.description}
      </div>
      {reason && (
        <div className={styles.errorRow}>
          <div className={styles.icon}>
            <AlertTriangle fill="#fff" size={16} />
          </div>
          <div className={styles.row}>{reason}</div>
        </div>
      )}
    </div>
  );
  return (
    <Tooltip
      title={tooltipContent}
      placement="right"
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
      open={!isDragging && open}
    >
      {children}
    </Tooltip>
  );
}
interface IProps {
  children: React.ReactNode;
  dimensionItem: IPickerDimension;
  reason: string;
}
