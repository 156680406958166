import { createPrefix } from '@/common';

const prefix = createPrefix('home_');

export const HomeIds = {
  test: prefix('test'),
  logo: prefix('logo'),
  search: prefix('search'),
  inviteBtn: prefix('invite'),
};
