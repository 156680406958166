import t from '@/locales';
import {
  EAssetPropertyType,
  EDataType,
  EInputType,
  IAssetPropertySelect,
  ISelectDataInputOption,
} from '@/typings/property';
import {
  ITableColumn,
  IconButton,
  Input,
  Modal,
  Radio,
  Spin,
  Table,
  Tooltip,
  message,
} from '@aloudata/aloudata-design';
import styles from './index.less';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as ClockIcon } from '@/assets/icon/Close-light-line.svg';
import { ReactComponent as AttentionIcon } from '@/assets/icon/Attention-triangle-fill.svg';
import {
  useQueryAssetProperty,
  useUpdateAssetProperty,
} from '@/services/property';
import { IDefinePropertySelect } from '@/services/property/type';
import { useMount } from 'ahooks';
import _ from 'lodash';
import classNames from 'classnames';

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  metricLevelInfo: IAssetPropertySelect;
}

const DEFAULT_INDEX = 2;

const getDefaultOption = (index: number): ISelectDataInputOption => {
  return {
    isDefault: false,
    internalValue: 'T' + index,
    value: '',
  };
};

const EditMetricLevelModal: React.FC<IProps> = ({
  open,
  onClose,
  onConfirm,
  metricLevelInfo,
}: IProps) => {
  const [options, setOptions] = useState<ISelectDataInputOption[]>([]);

  const [errorMsgs, setErrorMsgs] = useState<
    {
      index: number;
      msg: string;
    }[]
  >([]);

  const { queryAssetProperty, loading: queryLoading } = useQueryAssetProperty();
  // getDefaultOption(tempOptions.length + 1)
  useMount(() => {
    queryAssetProperty({
      name: metricLevelInfo.name,
      type: EAssetPropertyType.METRIC,
    }).then((res) => {
      const tempOptions = (res.queryAssetPropertyDef as IAssetPropertySelect)
        .dataInput.options as ISelectDataInputOption[];
      setOptions(tempOptions);
    });
  });

  const onChange = (value: ISelectDataInputOption, internalValue: string) => {
    setOptions((prev) => {
      if (prev.some((item) => item.internalValue === internalValue))
        return prev.map((item) =>
          item.internalValue === internalValue ? value : item,
        );
      else return [...prev, value];
    });
  };

  const { updateAssetProperty, loading } = useUpdateAssetProperty();

  const validateValue = useCallback(
    (value: string, index: number) => {
      let hasError = false;
      if (value === '' || _.isNil(value)) {
        hasError = true;
        setErrorMsgs((prev) => [
          ...prev,
          {
            index,
            msg: t.property.list.editMetricLevelModal.notEmpty,
          },
        ]);
      } else if (
        options.some((item, i) => item.internalValue === value && i !== index)
      ) {
        hasError = true;
        setErrorMsgs((prev) => [
          ...prev,
          {
            index,
            msg: t.property.list.editMetricLevelModal.nameRepeat,
          },
        ]);
      } else {
        setErrorMsgs((prev) => prev.filter((item) => item.index !== index));
      }

      return hasError;
    },
    [options],
  );

  const columns: ITableColumn<ISelectDataInputOption>[] = useMemo(
    () => [
      {
        title: t.property.list.editMetricLevelModal.isDefault,
        dataIndex: '',
        width: 88,
        render: (text, record: ISelectDataInputOption, index: number) => {
          return (
            <Radio
              checked={record.isDefault}
              disabled={index > options.length - 1}
              onClick={() => {
                setOptions((prev) =>
                  prev.map((item) =>
                    item.internalValue === record.internalValue
                      ? { ...item, isDefault: !item.isDefault }
                      : { ...item, isDefault: item.isDefault },
                  ),
                );
              }}
            />
          );
        },
      },
      {
        title: t.property.list.editMetricLevelModal.level,
        dataIndex: 'internalValue',
        width: 61,
        render: (text, record, index) => {
          return 'T' + (index + 1);
        },
      },
      {
        title: t.property.list.editMetricLevelModal.name,
        dataIndex: 'value',
        render: (text, record, index) => {
          const errorMsg = errorMsgs.find((item) => item.index === index)?.msg;

          return (
            <div
              className={classNames(styles.inputWrapper, {
                [styles.hasError]: errorMsg,
              })}
            >
              <Input
                placeholder={
                  t.property.list.editMetricLevelModal.nameInputPlaceholder
                }
                value={text}
                status={errorMsg ? 'error' : undefined}
                bordered={false}
                suffix={
                  errorMsg ? (
                    <Tooltip title={errorMsg}>
                      <AttentionIcon size={20} color="#EF4444" />
                    </Tooltip>
                  ) : undefined
                }
                onChange={(e) => {
                  const value = e.target.value.trim();
                  onChange({ ...record, value }, record.internalValue!);
                }}
                onBlur={(e) => {
                  validateValue(e.target.value.trim(), index);
                }}
              />
            </div>
          );
        },
      },
      {
        title: t.property.list.editMetricLevelModal.delete,
        dataIndex: '',
        width: 68,
        render: (text, record, index) => {
          const disabled = index <= DEFAULT_INDEX;
          return (
            <IconButton
              icon={<ClockIcon color={!disabled ? '#6B7280' : '#0000003D'} />}
              disabled={disabled}
              onClick={() => {
                setOptions((prev) =>
                  prev.filter(
                    (item) => item.internalValue !== record.internalValue,
                  ),
                );
              }}
            ></IconButton>
          );
        },
      },
    ],
    [errorMsgs, options.length, validateValue],
  );

  const onOk = useCallback(() => {
    let hasError = false;
    options.forEach((item, index) => {
      hasError =
        index !== options.length - 1 && validateValue(item.value!, index);
    });

    if (hasError) {
      return;
    } else {
      const request: IDefinePropertySelect = {
        ..._.omit(metricLevelInfo, 'owner'),
        description: metricLevelInfo.description || '',
        type: EAssetPropertyType.METRIC,
        dataInput: {
          dataType: EDataType.TEXT,
          inputType: EInputType.SELECT,
          options: options.map((item, index) => ({
            ...item,
            internalValue: 'T' + (index + 1),
          })),
        },
      };
      updateAssetProperty({
        assetPropertyDefRequest: request,
      }).then(() => {
        message.success(t.property.list.editMetricLevelModal.success);
        onConfirm();
      });
    }
  }, [metricLevelInfo, onConfirm, options, updateAssetProperty, validateValue]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={onOk}
      title={t.property.list.editMetricLevelModal.title}
      width={470}
      okButtonProps={{
        loading,
      }}
    >
      <Spin spinning={queryLoading}>
        <div className={styles.modalInner}>
          <div className={styles.header}>
            {t.property.list.editMetricLevelModal.chName}
            <Input
              disabled
              value={metricLevelInfo.displayName}
              className={styles.input}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              {t.property.list.editMetricLevelModal.options}
            </div>
            <div className={styles.contentDesc}>
              {t.property.list.editMetricLevelModal.optionsDesc}
            </div>
            <div className={styles.table}>
              <Table
                data={[...options, getDefaultOption(options.length + 1)]}
                columns={columns}
                scroll={{ y: '300px' }}
              />

              {errorMsgs.length > 0 && (
                <div className={styles.errorList}>
                  <AttentionIcon size={20} color="#EF4444" />
                  {errorMsgs[0].msg}
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default EditMetricLevelModal;
