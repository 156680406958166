export const define = {
  label: {
    displayName: '展示名',
    name: '名称',
    description: '描述',
    isRequired: '设为必填项',
    propertyType: '属性分类',
  },
  title: {
    basicInfo: '基本信息',
    propertyType: '属性分类',
  },
  propertyType: {
    text: '短文本',
    longText: '长文本',
    select: '单选列表',
    selectUser: '单选用户',
    selectUserGroup: '单选用户组',
    base: '基础属性',
    business: '业务属性',
    manage: '管理属性',
    technology: '技术属性',
  },

  select: {
    title: {
      candidate: '候选项',
      default: '默认值',
      delete: '删除',
    },
    placeholder: '请输入候选项',
  },
  message: {
    add: {
      success: '添加成功',
    },
    update: {
      success: '保存成功',
    },
  },
  validate: {
    empty: (filed: string) => `${filed}不能为空`,
    duplicate: (filed: string) => `${filed}不能重复`,
    invalid: '名称不合法，只允许英文、数字、下划线，并且长度不能超过50个字符',
  },
  modal: {
    title: {
      metric: '指标',
      dimension: '维度',
      property: '属性',
      edit: '编辑',
      create: '新建',
    },
  },
};

export const list = {
  column: {
    displayName: '展示名',
    name: '名称',
    description: '描述',
    type: '类型',
    isRequired: '是否必填',
    required: '必填',
    notRequired: '非必填',
    defaultValue: '默认值',
    owner: '负责人',
    system: '系统属性',
    enable: '启用状态',
    operation: '操作',
  },
  tab: {
    base: '基础属性',
    business: '业务属性',
    manage: '管理属性',
    technology: '技术属性',
  },
  total: (total: number, item: string) => `${total}个${item}`,
  title: {
    dimension: '维度属性',
    metric: '指标属性',
  },
  createBtn: {
    dimension: '新建维度属性',
    metric: '新建指标属性',
  },

  editMetricLevelModal: {
    title: '编辑指标属性',
    chName: '展示名',
    options: '候选值',
    success: '保存成功',
    optionsDesc: '顺序为指标等级从高到低',
    isDefault: '默认值',
    level: '等级',
    name: '名称',
    delete: '删除',
    nameRepeat: '名称不能重复',
    notEmpty: '名称不能为空',
    nameInputPlaceholder: '输入名称',
  },
  operation: {
    edit: '编辑',
    delete: '删除',
  },
};

export const form = {
  validate: {
    empty: (filed: string) => `${filed}不能为空`,
    dimNamePattern: '维度名称只能包含字母、数字、下划线且不能使用双下划线',
    dimCodePattern: '维度编码只能包含字母、数字、下划线且不能使用双下划线',
    metricNamePattern: '指标名称只能包含字母、数字、下划线且不能使用双下划线',
    metricCodePattern: '指标编码只能包含字母、数字、下划线且不能使用双下划线',
    repeated: (filed: string) => `${filed}重复`,
  },
  placeholder: {
    memberPicker: (filed: string) => `请选择${filed}`,
    input: '请输入',
  },
  title: {
    base: '基础属性',
    business: '业务属性',
    manage: '管理属性',
    technology: '技术属性',
  },
  system: {
    label: {
      dimName: '维度名称',
      dimCode: '维度编码',
      dimDisplayName: '维度展示名',
      dimDescription: '维度描述',
      dimCategoryId: '维度分类',
      metricName: '指标名称',
      metricCode: '指标编码',
      metricDisplayName: '指标展示名',
      unit: '单位',
      metricLevel: '指标等级',
      businessOwner: '业务负责人',
      businessCaliber: '业务口径',
      metricCategory: '指标类目',
    },
  },
};

export const detail = {
  title: {
    base: '基础属性',
    business: '业务属性',
    manage: '管理属性',
    technology: '技术属性',
  },
};
