import t from '@/locales';
import { ESourceType } from '@/typings/dataset';
/**
 * 获取数据集类型
 * @param sourceInfoType 数据集来源类型
 */
export function getDatasetType(sourceInfoType: ESourceType): string {
  const typeMap = {
    [ESourceType.TABLE]: t.dataset.type.table,
    [ESourceType.SQL]: t.dataset.type.sql,
    [ESourceType.FILE]: t.dataset.type.file,
  };
  return typeMap[sourceInfoType];
}
/**
 * 判断一个字符串是否包含另一个字符串（大小写不敏感）
 * @param baseValue 第一个字符串
 * @param targetValue 第二个字符串
 */
export function fuzzyMatch(baseValue: string, targetValue: string): boolean {
  return baseValue.toLowerCase().includes(targetValue.toLowerCase().trim());
}
