import { useCallback } from 'react';
import NumberInput from '../NumberInput';
import styles from './index.less';
import { IBaseProps } from '../../types';

export default function NumberRangeInput(props: IProps) {
  const { value = [0, 0], onChange, ratio, disabled } = props;
  const [num1, num2] = value;

  const onChangeNum1 = useCallback(
    (val: number) => {
      onChange?.([val, num2]);
    },
    [num2, onChange],
  );

  const onChangeNum2 = useCallback(
    (val: number) => {
      onChange?.([num1, val]);
    },
    [num1, onChange],
  );

  return (
    <div className={styles.wrap}>
      <NumberInput
        className={styles.input}
        value={num1}
        onChange={onChangeNum1}
        ratio={ratio}
        disabled={disabled}
      />
      <span>~</span>
      <NumberInput
        className={styles.input}
        value={num2}
        onChange={onChangeNum2}
        ratio={ratio}
        disabled={disabled}
      />
    </div>
  );
}

interface IProps extends IBaseProps {
  ratio?: boolean;
  value?: [number, number];
  onChange?: (value: [number, number]) => void;
}
