export const list = {
  title: '数据源',
  total: {
    datasource: ({ total }: { total: string | number }) => `${total} 个数据源`,
    item: ({ total }: { total: string | number }) => `${total} 个内容`,
  },
  column: {
    name: '名称',
    operate: '操作',
    createTime: '创建时间',
  },
  content: '内容',
  authority: '权限',
  create: '新建数据源',
  menu: {
    edit: '编辑',
    transfer: '转交',
  },
  delete: {
    title: '删除数据源',
    content: '确认删除数据源',
    confirm: '确认',
    deleteSuccess: '删除成功',
  },
};

export const tree = {
  fileCatalog: '文件上传',
  empty: '未找到内容',
  search: '搜索',
  column: '字段',
};

export const edit = {
  title: '编辑数据源',
  label: {
    displayName: '展示名',
    name: '名称',
  },
  required: {
    displayName: '展示名不能为空',
  },
};

export const define = {
  create: '新建数据源',
  select: '选择数据源',
  edit: '编辑数据源',
  res: {
    success: (type: string) => `${type}成功`,
    fail: (type: string) => `${type}失败`,
  },
};
export const role = {
  admin: '管理员',
  usager: '使用者',
  owner: '负责人',
};

export const external = '外部';

export const fileList = {
  transfer: '转交',
  title: '文件上传',
  uploadBtn: '上传文件',
  empty: '当前页面没有任何内容，您可以上传本地文件以创建表',
  cnName: '展示名',
  enName: '名称',
  describe: '描述',
  updateTime: '更新时间',
  owner: '负责人',
  handle: '操作',
  view: '查看',
  delete: '删除',
  editModalTitle: '修改基础信息',
  edit: '编辑',
  total: (total: number) => {
    return `${total}个文件`;
  },
  deleteSuccess: '已删除',
  batch: {
    transfer: (value: string) => {
      return `文件 ${value} 无转交权限`;
    },
  },
};
export const editFileInfo = {
  cnName: '展示名',
  enName: '名称',
  describe: '描述',
  enNameRule: '名称只能包含字母、数字、下划线且不能使用双下划线',
  cnNameRule: '展示名不能为空',
  success: '文件信息修改成功',
};
export const fileDetail = {
  preview: {
    baseInfo: {
      title: '基础信息',
      displayName: '展示名',
      name: '名称',
      type: '类型',
      typeValue: '文件上传',
      owner: '负责人',
      createTime: '创建时间',
      updateTime: '更新时间',
      desc: '描述',
    },
  },
  file: {
    tab: '文件',
    add: '追加文件',
    fileName: '文件',
    createDate: '添加时间',
    owner: '负责人',
    handle: '操作',
    total: (num: number) => `${num} 条数据`,
  },
  authority: {
    addAuth: '添加授权',
  },
};
export const fileUpload = {
  upload: '上传文件',
  appendTitle: '追加文件',
  uploadSuccess: '上传成功',
  uploadError: '上传失败',
  modalOk: '确定',
  tip: '仅支持上传 csv、txt 格式文件，且单个文件大小不可超过 100 MB。',
  modalCancel: '取消',
  modalTitle: '文件上传',
  uploadTypeError: '仅支持上传 csv、txt 格式',
  uploadNameError: '文件名重复，请重新命名',
  fileSizeErrorMessage: '文件大小超过限制',
  fileSizeErrorDes: '文件大小不能超过100MB',
  uploadBtn: '点击上传',
  uploadDrag: ' 或 拖放文件到此处',
  uploadReplace: '替换文件',
  comma: '逗号 (,)',
  verticalBar: '竖线（|）',
  tab: '制表符（TAB）',
  spaceKey: '空格',
  semicolon: '分号 (;)',
  separator: '分隔符',
  charset: '编码格式',
  containsHeader: '首行设置为字段展示名',
  appendInfoText:
    '追加文件的字段会使用第一次上传文件设置的字段类型和数据格式进行数据类型的转换。',
  name: '名称',
  nameError: '名称只能包含字母、数字、下划线且不能使用双下划线、不能以数字开头',
  displayName: '展示名',
  dataPreview: '数据预览',
  errorData: '异常数据',
  errorDataEmpty: '当前文件内没有读取异常的数据',
  errorLineNumber: '行号',
  errorContent: '异常内容',
  originData: '原始数据',
  errorLines: (errorNumber: string | number) => {
    return `有 ${errorNumber} 行的数据在数据类型转换时出现异常。`;
  },
  seeErrorPreview: '查看',
  useNullTipLeft: '使用空值 ( ',
  useNullTipRight: ' ) 替换异常值',
  skipTip: '忽略包含异常值的数据行',
  previewFooterLine: '行：',
  previewFooterAppendLine: '追加数据行：',
  previewFooterColumns: '字段：',
  formatBooleanText:
    '满足内容为 “真” 的字段，会被识别为 “true”; 内容为 “假” 的字段，会被识别为 “false”',
  formatDateText:
    '满足格式 yyyy/MM/dd (2020/05/20) 的字段会被转换为日期，转换后格式为 yyyy-MM-dd (2020-05-20)。',
  formatDateTimeText:
    '满足格式 yyyy/MM/dd hh:mm:ss (2020/05/20 14:21:16) 的字段会被转换为日期时间，转换后格式为 yyyy-MM-dd (2020-05-20 14:21:16)。',
  formatBooleanTitle: '布尔格式',
  formatDateTitle: '日期格式',
  formatDateTimeTitle: '日期时间格式',
  columnDataTypeInt: '整数',
  columnDataTypeDouble: '小数',
  columnDataTypeDecimal: '高精度小数',
  columnDataTypeDate: '日期',
  columnDataTypeDateTime: '日期时间',
  columnDataTypeText: '文本',
  columnDataTypeBoolean: '布尔',
  columnDataTypeJson: 'JSON',
  dataType: '数据类型：',
  dataFormat: '数据格式：',
  dateFormat: 'yyyy年MM月dd日',
  dateTimeFormat: 'yyyy年MM月dd日 hh:mm:ss',
  dateTimeNsFormat: 'yyyy年MM月dd日 hh时mm分ss秒',
  realOrFake: '真,假',
  yesOrNo: '是,否',
  real: '真',
  fake: '假',
  yes: '是',
  no: '否',
  validate: {
    empty: (name: string) => {
      return `${name}不能为空`;
    },
    nameIsExists: '名称已存在',
  },
  describe: '描述',
  title: '数据预览',
  noFile: {
    title: '暂无预览内容',
    description: '请上传文件后预览数据内容',
  },
};
export const table = {
  preview: '概览',
  column: {
    default: '字段',
    name: '名称',
    dataType: '数据类型',
    desc: '描述',
    search: '搜索',
    total: ({ total }: { total: string | number }) => `${total} 个字段`,
  },
  refreshSetting: {
    title: '更新设置',
  },
  dataPreview: '数据预览',
  refresh: '刷新',
  basicInfo: {
    default: '基础属性',
    name: '名称',
    type: '类型',
    table: '表',
    desc: '描述',
    position: '位置',
    dataset: '相关数据集',
  },
};

export const error = {
  msg: '系统开小差了',
  tree: '获取数据失败，请刷新页面',
  noPermission: '无权查看，请向管理员申请权限',
};

export const refreshSetting = {
  edit: '编辑',
  refreshData: '更新数据',
  partitionAndDateField: {
    title: '分区和日期字段设置',
    formItemNotEmpty: '该项不能为空',
    desc: '设置当前表的分区字段以及日期字段与分区的映射关系，用于增量更新和查询优化。',
    partitionField: '分区字段',
    dateFormat: '日期格式：',
    dateFormat1: '日期格式',
    dateGranularity: '粒度：',
    dateGranularity1: '分区日期粒度',
    dateTypeOptions: {
      nonDate: '非日期类分区字段',
    },
    partitionMapping: '分区映射',
  },
  dataRefresh: {
    title: '数据更新',
    desc: '当前表数据更新周期及数据更新内容，用于更新该表相关物化表数据。',
    refreshMethod: '更新方式',
    manualApiRefresh: '手动/API 更新',
    manualApiTip: '数据更新 API ',
    docUrl: '使用说明',
    scheduledRefresh: '定时更新',
    refreshContent: {
      title: '更新内容',
      fullRefresh: '全量更新',
      incrementalRefresh: '增量更新',
      incrementalRefreshPartition: (_granularity: string) =>
        `增量分区更新（${_granularity}）`,
      save: '保存',
      notEmpty: '该项不能为空',
      cancel: '取消',
      saveSuccess: '保存成功',
    },
  },
  updateModal: {
    title: '更新数据',
    confirmBtn: '更新数据',
    desc: '本次更新会依次更新该表相关的全部物化表数据。',
    refreshRange: '更新范围',
    refreshLink: '更新链路',
    desc2: '系统会依次更新以下物化表的对应数据内容',
    table: {
      table: '物化表',
      updateTime: '更新时间',
    },
    success: '更新成功',
  },
};
