import t from '@/locales';
import { EColumnType, IColumn } from '@/typings/dataset';
import { Empty, Input } from '@aloudata/aloudata-design';
import classNames from 'classnames';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
// 指标 IMetric
import styles from './index.less';
import { ReactComponent as SearchLine } from '@/assets/icon/Search-light-line.svg';
import MultipleColumnList, { ICheckedKey } from './multipleColumnList';
import { EDateGranularityType } from '@/typings';
// TODO: 需要修改为引用公共定义的IMetric
// interface IMetric extends IBaseColumn {
//   dsl?: string;
// }
export interface ISearchBaseProps {
  onChange?: (
    selectedColumnInfo: PColumn,
    datasetId?: string,
    datasetDisplayName?: string,
    granularity?: EDateGranularityType,
  ) => void;
  className?: string;
  onClose?: () => void;
  hasBorder?: boolean;
  hasPadding?: boolean;
  openStatus?: boolean;
  prefixNode?: React.ReactNode;
}
export type PColumn = Partial<IColumn> & {
  originDataType: IColumn['originDataType'];
  dataType: IColumn['dataType'];
  name: IColumn['name'];
  id: IColumn['name'];
  isPartition?: boolean;
  columnType?: EColumnType;
};
// type PMetric = Partial<IMetric> & {
//   dataType: IMetric['dataType'];
//   displayName: IMetric['displayName'];
//   id: IMetric['id'];
// };
export type TColumnOrMetric = PColumn;
// 普通单表字段 和指标 是一样的显示规则
export interface ISearchMetric extends ISearchBaseProps {
  items: TColumnOrMetric[];
  // 下面这个属性在 single 模式下是没有用的，只是为了在 multiple 模式下使用，让 ts 不报错
  supportGranularity?: boolean;
}
// 模型字段
export interface ISearchColumns extends ISearchBaseProps {
  items: {
    sheetId: string;
    columns: PColumn[];
    sheetName: string;
  }[];
  supportGranularity?: boolean;
  supportTimeGranularity?: boolean;
  checkedKeys?: ICheckedKey[];
  onCheck?: (checkedKeys: ICheckedKey[]) => void;
  unremovable?: ICheckedKey[];
}
// // 模型指标
// export interface ISearchModelMetric extends ISearchBaseProps {
//   items: Partial<IMetric>[];
//   type: 'modelMetric';
// }
export type TranscriptComponent = (
  props: ISearchColumns,
  ref?: React.Ref<HTMLDivElement>,
) => React.ReactElement | null;
const TIME_OUT = 500;
const Component: TranscriptComponent = (
  {
    items,
    onChange = () => {},
    className,
    hasBorder = true,
    hasPadding = false,
    openStatus,
    supportGranularity,
    checkedKeys,
    onCheck,
    unremovable,
    supportTimeGranularity = true,
  },
  ref,
) => {
  const [searchKey, setSearchKey] = useState('');
  useEffect(() => {
    if (openStatus === undefined) return;
    if (!openStatus && searchKey) {
      setTimeout(() => {
        setSearchKey('');
      }, TIME_OUT);
    }
  }, [openStatus, searchKey]);

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.border]: hasBorder,
        [styles.padding]: hasPadding,
      })}
      ref={ref}
    >
      <div className={styles.searchWrap}>
        <Input
          placeholder={t.filter.sort.search}
          onChange={(e) => {
            setSearchKey(_.trim(e.target.value));
          }}
          prefix={<SearchLine />}
          size="small"
          allowClear
          value={searchKey}
          className={styles.inputSearch}
        />
      </div>
      <div className={styles.contentWrapper}>
        {items.length !== 0 ? (
          <MultipleColumnList
            onItemClick={onChange}
            items={items}
            searchKey={searchKey}
            supportGranularity={supportGranularity}
            checkedKeys={checkedKeys}
            onCheck={onCheck}
            unremovable={unremovable}
            supportTimeGranularity={supportTimeGranularity}
          />
        ) : (
          <>
            {!!searchKey ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SEARCH_SMALL}
                size="small"
                title={t.filter.sidebar.noDataTip}
                className={styles.empty}
              />
            ) : (
              <div className={styles.emptyText}>
                {t.filter.sidebar.noDataTip}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export const SearchColumn = memo(React.forwardRef(Component));
