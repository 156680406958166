import { createPrefix } from '@/common';

const prefix = createPrefix('login_');

export const LoginIds = {
  loginBtn: prefix('login'),
  reloginBtn: prefix('relogin'),
  userNameInput: prefix('userName'),
  passwordInput: prefix('password'),
  backToHome: prefix('backToHome'),
};
